import React,{ useEffect, useState, useContext }from 'react';
import { Link } from 'react-router-dom';
import bookIcon from '../../../../../images/icon_book.webp';


import { LMSContext } from '../LMS';
import Lessons from './Lessons/Lessons';

const Syllabus = () => {
    const[syllabusesInfo, setSyllabusesInfo] = useState({});
    const[showCertificate, setShowCertificate] = useState(false);
    const[stop, setStop] = useState(true);
    const {batch_id, course_id} = useContext(LMSContext);
    
    useEffect(()=>{
        async function fetchSyllabusesInfo(){
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL_STUDENT}course-syllabus?course_id=${course_id}&batch_id=${batch_id}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                }
            );
            response = await response.json();
            setSyllabusesInfo(response.data);
        }

        fetchSyllabusesInfo() 
    },[course_id, batch_id, showCertificate]);
   /*  syllabusesInfo?.syllabuses?.forEach((syllabus)=>{
        syllabus?.lessons?.forEach((lesson)=>{
            lesson?.topics?.forEach((topic)=>{
                topics.push(topic);
            })
        })
    }) */

    const handelGetCertificate = () =>{   
            setShowCertificate(true) 
            setStop(false)
    }


    const [imageLoadedMap, setImageLoadedMap] = useState({});
    const handleImageLoad = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: true
        }));
    };

    const handleImageError = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: false
        }));
    };


    
    return (
        <div className="row gx-5 mx-0">
            <div className='col-lg-3 lms-subject-section'>
                <div className="nav flex-column nav-pills assignment-subject pt-0 p-2 bg-white custom-card-shadow mb-4" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ borderRadius:'16px' }}>
                    {
                        syllabusesInfo?.syllabuses?.map((syllabus, index) => 
                            <button key={syllabus?.subject_id} className={`nav-link text-dark mt-2 ${index === 0 ? "active" : ""}`}  id={`v-pills-syllabus-subject_${syllabus?.subject_id}-tab`} data-bs-toggle="pill" data-bs-target={`#v-pills-syllabus-subject_${syllabus?.subject_id}`} type="button" role="tab" aria-controls={`v-pills-syllabus-subject_${syllabus?.subject_id}`} aria-selected="false">
                                {/* <img src={`${syllabusesInfo?.subject_image_path}${syllabus?.subject_image}`} alt="" width={48} height={48} className='me-2' style={{ borderRadius: '8px' }}/> */}
                                {imageLoadedMap[index] !== false && (
                                <img
                                    src={`${syllabusesInfo?.subject_image_path}${syllabus?.subject_image}`}
                                    width={48} height={48} alt=""
                                    className="px-1"
                                    onLoad={() => handleImageLoad(index)}
                                    onError={() => handleImageError(index)}
                                />
                                )}
                                {imageLoadedMap[index] === false && <img
                                    src={bookIcon}
                                    width={48} height={48} alt=""
                                />}
                                
                                {syllabus?.subject_name}
                            </button>

                        )
                    }
                    
                </div>
            </div>
            <div className="col-lg-9 px-0 mx-0 tab-content bg-white custom-card-shadow all-assignment-section" id="v-pills-tabContent">         
                {
                     syllabusesInfo?.syllabuses?.map((syllabus, index) => {
                        return(
                            <div key={syllabus?.subject_id}  className={index === 0 ? 'tab-pane fade active show' : 'tab-pane fade' } id={`v-pills-syllabus-subject_${syllabus?.subject_id}`} role="tabpanel" aria-labelledby={`v-pills-syllabus-subject_${syllabus?.subject_id}-tab`}>
                            <p className='course-total-assignment mb-0'>{syllabus?.syllabus_title}</p>
                            <div className='total-assignment-divider'></div>
                            <div className="accordion accordion-flush mt-4 px-4" id="syllabus-list">
                            {
                                syllabus?.lessons?.map((lesson, index) => {
                                        return <div key={index} >
                                                    <Lessons 
                                                        lesson={lesson} 
                                                        LessonSerial={index} 
                                                        course_name={syllabusesInfo?.course_name} 
                                                        user_name={syllabusesInfo?.user_name}
                                                        lessons = {syllabus?.lessons}
                                                        handelGetCertificate={handelGetCertificate}
                                                        setShowCertificate = {setShowCertificate}
                                                    />
                                                    <div>
                                                        {
                                                            syllabus?.lessons[syllabus?.lessons?.length-1]?.topics[lesson?.topics?.length - 1]?.is_viewed === 1  && stop &&
                                                            handelGetCertificate() 
                                                        }
                                                    </div>
                                                </div>
                                            })
                            }
                            </div>
                            {
                                showCertificate &&
                                    <div className='d-flex justify-content-center mb-2'>
                                        <Link to={`/certificate`}
                                        state={{
                                                course_name: syllabusesInfo?.course_name,
                                                user_name: syllabusesInfo?.user_name
                                            }}
                                            className='btn-submission-syllabus text-center d-flex align-items-center me-3'>Get Certificate</Link>
                                    </div>
                            }
                            <p className='text-center mb-4' style={{ color: '#BDBCBE' }}>আপনি তালিকার শেষে পৌঁছে গিয়েছেন</p>
                        </div>
                        )
                    }
                        
                    )
                }
            
            </div> 
        </div>
    );
};

export default Syllabus;