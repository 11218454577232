import React from 'react';
import Steps from "../Components/Steps";
import Breadcrumbs from "../Components/Breadcrumbs";
import Header from "../../Header/Header";
import PaymentDetails from "./Components/PaymentDetails";
import { useLocation} from "react-router-dom";

const CoursePayment = () => {
    const location = useLocation();
    return (
        <div>
            <Header/>
            <div className="tw-max-w-[1300px] tw-mx-auto tw-mt-10">
                <Breadcrumbs/>
                <Steps/>
                <PaymentDetails/>
            </div>

        </div>
    );
};

export default CoursePayment;