import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { socialLinks } from '../../../../../../utils/Constant';
import MojaruLogo from "../../../../../../images/dashboard-logo.png";
import {useTranslation} from "react-i18next";

export default function Modal({ open, setOpen }) {
    const {t} = useTranslation();
    return (
        <Dialog open={open} onClose={() => setOpen(false)} className="tw-relative tw-z-10">
            <DialogBackdrop
                transition
                className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in"
            />

            <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
                <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                    <DialogPanel
                        transition
                        className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all data-[closed]:tw-translate-y-4 data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6 data-[closed]:sm:tw-translate-y-0 data-[closed]:sm:tw-scale-95"
                    >
                        <div>
                            <div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full">
                                <img src={MojaruLogo} alt="mojaru logo"/>
                            </div>
                            <div className="tw-mt-3 tw-text-center sm:tw-mt-5">
                                <DialogTitle as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                                    {t("Mojaru Community")}
                                </DialogTitle>
                                <div className="tw-mt-6 tw-grid tw-grid-cols-3 tw-gap-10">
                                    {socialLinks.social.map((socialLink, index) => (
                                        <div key={index} className="tw-flex tw-justify-center tw-items-center">
                                            <a href={socialLink.href} target="_blank" rel="noopener noreferrer">
                                                <img src={socialLink.icon} alt={socialLink.name} className="tw-h-8 tw-w-8 tw-object-contain" />
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
