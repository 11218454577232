import React, { useEffect, useState} from "react";
import "./BookDetails.css";
import BookShortDescription from "./BookShortDescription/BookShortDescription";
import AuthorDescription from "./AuthorDescription/AuthorDescription";
/* import MoreBooks from "./MoreBooks/MoreBooks"; */
import PageTitle from "../../../shared/PageTitle/PageTitle";
import Loader from "../../../shared/Loader/Loader";
import { useLocation, Link } from "react-router-dom";
import { decode as base64_decode } from "base-64";
import bookNotFoundImg from "../../../../../images/default_img/Default book image.jpg";
import { englishToBengaliNumberFormat } from "../../../../../utilities/utilities";
import ReactGA from "react-ga4";
import mixpanel from 'mixpanel-browser';

const BookDetails = () => {
    const [bookDetail, setBookDetail] = useState([]);
    const [loading, setLoading] = useState(false);

    const search = useLocation().search;
    const book_id = new URLSearchParams(search).get("e_book_id");
    let decode = base64_decode(`${book_id}`);
    
    useEffect(() => {
        async function fetchBookDetailAPI() {
            setLoading(true);
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/book-detail?e_book_id=${decode}`
            );
            response = await response.json();
            setBookDetail(response.data);
            setLoading(false);
        }
        fetchBookDetailAPI();
    }, [decode]);


    useEffect(() => {
        if (bookDetail?.e_book?.book_title) {
          ReactGA.event({
            category: 'e-book view',
            action: 'e-book view action',
            label: `Viewed ${bookDetail.e_book.book_title} e-book`,
            value: localStorage.getItem('mobile_or_email') ? Number(localStorage.getItem('mobile_or_email')) : undefined
          });

          mixpanel.track('Interested Book', {
            number : localStorage.getItem('mobile_or_email') ? Number(localStorage.getItem('mobile_or_email')) : 'Not Registered User'
        });
        }
      }, [bookDetail]);


    return (
        <div>
            <PageTitle title="Book Details"></PageTitle>
            {
                loading ? (
                
                <div className="pos-center">
                    <Loader></Loader>
                </div>
                
                
        ) :(
            <>
            {/* <Header></Header> */}
            <div className="borderTop" style={{ paddingBottom: "23rem" }}>
                <div className="container p-0">
                    <div className="row mx-0 book-details-top-section">
                        <div className="col-lg-5 p-0 d-flex justify-content-center">
                            {bookDetail?.e_book?.book_image !== undefined &&
                            bookDetail?.e_book?.book_image !== null ? (
                                <div>

                                <img src={`${bookDetail?.ebook_image_path}${bookDetail?.e_book?.book_image}`} alt="" className="img-fluid" style={{ borderRadius:'16px' }}/>
                                </div>
                            ) : (
                                <img
                                    src={bookNotFoundImg}
                                    className="book_border img-fluid"
                                    alt=""
                                    id="course-img"
                                />
                            )}
                        </div>
                        <div className="col-lg-7">
                            <p className="book-name">
                                {bookDetail?.e_book?.book_title}
                            </p>
                            <p className="writer-name">
                                লেখক:
                                <Link to="/e-book/detail/author-detail" state={{ authorDetail: bookDetail?.author, imagePath:bookDetail?.author_image_path}} style={{ textDecoration: 'none', color:'#532D80' }}><span> &nbsp;&nbsp;&nbsp;{bookDetail?.author?.writter_name} </span></Link>
                            </p>
                            <p className="book-language">
                                ভাষা:
                                <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {bookDetail?.e_book?.book_language}
                                </span>
                            </p>
                            <p className="book-sector">
                                বিভাগ:
                                <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {bookDetail?.e_book?.book_type}
                                </span>
                            </p>

                            <p className="book-sector">
                            প্রকাশক:
                                <Link to="/e-book/detail/publisher-detail" state={{ publisherDetail: bookDetail?.publisher, imagePath:bookDetail?.publisher_image_path}} style={{ textDecoration: 'none', color:'#532D80' }}><span> &nbsp;{bookDetail?.publisher?.publisher_name}</span></Link>
                            </p>
                            <div className="book-price d-flex align-items-center">
                                <p className="actual-price pe-4 balooda-medium">
                                    <del>
                                        ৳{englishToBengaliNumberFormat(bookDetail?.e_book?.book_original_price)}/=
                                    </del>
                                </p>
                                <p className="book-offer-price balooda-medium">
                                    {englishToBengaliNumberFormat(bookDetail?.e_book?.book_special_price)}/=
                                </p>
                            </div>
                            <p
                                className="short-about-book"
                                style={{ textAlign: `justify` }}
                            >
                                {bookDetail?.e_book?.book_short_description}
                            </p>

                            <div className="btn-book mt-5">
                                <a href="https://play.google.com/store/apps/details?id=com.aitl.mojaru" target='_blank' rel='noreferrer noopener'>
                                    বইটি পড়তে মজারু অ্যাপ ডাউনলোড করুন
                                </a>
                            </div>
                        </div>
                    </div>

                    {
                        bookDetail?.e_book?.book_description && bookDetail?.author?.writter_description &&
                        <>
                            <ul className="nav nav-tabs profileTab mt-5" id="myTab" role="tablist">
                                <li className="nav-item profileNavItem" role="presentation" id="book-details-description-1">
                                    <button
                                        className="nav-link profileNavLink active mb-0"
                                        id="book-short-description-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#book-short-description"
                                        type="button"
                                        role="tab"
                                        aria-controls="book-short-description"
                                        aria-selected="true"
                                    >
                                        সংক্ষিপ্ত
                                    </button>
                                </li>
                                <li className="nav-item profileNavItem" role="presentation" id="book-details-description-2">
                                    <button
                                        className="nav-link profileNavLink mb-0"
                                        id="author-description-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#author-description"
                                        type="button"
                                        role="tab"
                                        aria-controls="author-description"
                                        aria-selected="false"
                                        style={{ width: "100%" }}
                                    >
                                        লেখকের বিবরণ
                                    </button>
                                </li>
                            </ul>

                            <div className="tab-content profileTabContent" id="myTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="book-short-description"
                                    role="tabpanel"
                                    aria-labelledby="book-short-description-tab"
                                >
                                    <BookShortDescription
                                        bookDescription={
                                            bookDetail?.e_book?.book_description
                                        }
                                    ></BookShortDescription>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="author-description"
                                    role="tabpanel"
                                    aria-labelledby="author-description-tab"
                                >
                                    <AuthorDescription
                                        writer={bookDetail?.author?.writter_name}
                                        authorDescription={
                                            bookDetail?.author?.writter_description
                                        }
                                    ></AuthorDescription>
                                </div>
                            </div>
                        </>
                    }
                    

                    {/* <div className="mt-5">
                        <MoreBooks></MoreBooks>
                    </div> */}
                </div>
            </div>
            {/* <Message></Message>
            <Footer></Footer> */}
            </>
        )
            }
        </div>
    );
};

export default BookDetails;
