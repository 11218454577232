import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import ExcelJS from 'exceljs';
import { toast } from "react-toastify";
const CadetVivaRegistrationList = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [password, setPassword] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);

  const itemsPerPage = 20;


  const checkAuthorization = () => {
    if (password === '0qLvrZnPxsKlLm0@mojaru') {
      setIsAuthorized(true);
    } else {
      toast.error('Incorrect password. You are not authorized to access this page.');
    }
  };

  const fetchData = async () => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}api/v1/registration-cadet-viva/all`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {


          setUser(data?.data);
          setLoading(false);

        })

    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = user.slice(startIndex, endIndex);

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('AllRegistrationList');

    // Define column headers
    worksheet.columns = [
      { header: 'Student Name', key: 'student_name', width: 20 },
      { header: 'Cadet admission Index number', key: 'cadet_admission_index_number', width: 20 },
      { header: 'Guardian Name', key: 'guardian_name', width: 15 },
      { header: 'Guardian Mobile Number', key: 'mobile', width: 15 },
      { header: 'Guardian Whatsapp Number', key: 'whatsapp_number', width: 15 },
      { header: 'School Name', key: 'existing_school_name', width: 15 },
      { header: 'Address', key: 'address', width: 15 },
      { header: 'Client Requirement', key: 'client_requirement', width: 20 },
      { header: 'Student Image', key: 'student_image_link', width: 20 },
      { header: 'Admitcard Image', key: 'admit_card_image_link', width: 20 },
      { header: 'Date', key: 'created_at', width: 15 },
    ];

    // Replace the following data with your actual data source
    const data = user.map(item => ({
      student_name: item.student_name,
      cadet_admission_index_number: item.cadet_admission_index_number,
      guardian_name: item.guardian_name,
      mobile: item.mobile,
      whatsapp_number: item.whatsapp_number,
      existing_school_name: item.existing_school_name,
      address: item.address,
      client_requirement: item.client_requirement,
      student_image_link: item.student_image_link ? `https://mojaru-prod.s3.ap-southeast-1.amazonaws.com${item.student_image_link}` : "Image Not Found",
      admit_card_image_link: item.admit_card_image_link ? `https://mojaru-prod.s3.ap-southeast-1.amazonaws.com${item.admit_card_image_link}` : "Image Not Found",
      created_at: item.created_at.slice(0, 10),
    }));

    // Add data to the worksheet
    worksheet.addRows(data);

    // Create a buffer from the workbook
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger the download0
      const a = document.createElement('a');
      a.href = url;
      a.download = 'registration-list.xlsx';
      a.click();
      URL.revokeObjectURL(url);
    });
  };


  const PageContent = () => {
    if (isAuthorized) {
      return (
        <>
          <h2>All Cadet Viva Registration List</h2>
          <button className="btn btn-primary" onClick={exportToExcel}>Download Excel</button>
          <div className="BkashTableList">
            <table>
              <thead>
                <tr>
                  <th>শিক্ষার্থীর নাম</th>
                  <th>ক্যাডেট ভর্তি পরীক্ষার ইনডেক্স নাম্বার</th>
                  <th>অভিভাবকের নাম</th>
                  <th>অভিভাবকের মোবাইল নাম্বার</th>
                  <th>অভিভাবকের হোয়াটসঅ্যাপ নাম্বার</th>
                  {/* <th>District</th> */}
                  {/* <th>Course</th> */}
                  <th>বর্তমান স্কুলের নাম</th>
                  <th>ঠিকানা</th>
                  <th>আপনার মন্তব্য</th>
                  <th>শিক্ষার্থীর ছবি</th>
                  <th>এডমিট কার্ডের ছবি</th>
                  <th>Created At</th>
                </tr>
              </thead>

              <tbody>
                {currentPageData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.student_name}</td>
                    <td>{item.cadet_admission_index_number}</td>
                    <td>{item.guardian_name}</td>
                    <td>{item.mobile}</td>
                    <td>{item.whatsapp_number}</td>
                    <td>{item.existing_school_name}</td>
                    <td>{item.address}</td>
                    <td>{item.client_requirement}</td>
                    {/* <td><img style={{ width: "100px" }} src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com${item.student_image_link}`} /></td> */}
                    <td>
                      {
                        item.student_image_link ? <img style={{ width: "100px" }} src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com${item.student_image_link}`} /> : "Image Not Found"
                      }
                    </td>
                    {/* <td><img style={{ width: "100px" }} src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com${item.admit_card_image_link}`} /></td> */}
                    <td>
                      {
                        item.admit_card_image_link ? <img style={{ width: "100px" }} src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com${item.admit_card_image_link}`} /> : "Image Not Found"
                      }
                    </td>
                    <td>{item.created_at.slice(0, 10)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            current={currentPage}
            total={user.length}
            pageSize={itemsPerPage}
            onChange={setCurrentPage}
          />
        </>
      );
    }
    else {
      return (
        <div>
          <div className="container col-6">
            <h3 className="text-center mt-5">Authorization Required</h3>
            <p className="text-center mt-3">Please enter the password to access this page:</p>
            <form>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">Password</label>
                <input onChange={(e) => setPassword(e.target.value)} type="password" class="form-control" id="" />
              </div>
              <button onClick={checkAuthorization} type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <section className="BkashLeadsList">
        <Container>
          <Row>
            <Col lg={12}>
              {PageContent()}
            </Col>
          </Row>
        </Container>
      </section>
    </>

  );
};

export default CadetVivaRegistrationList;
