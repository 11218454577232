import React from 'react';
import noPerson from '../../../.../../../../../../../../images/blank.png';

const SmartNoteModal = ({syllabusContent}) => {
    return (
        <div className='smart-note-mentor-section p-1'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center smart-note-mentor-img'>
                {
                    syllabusContent?.smart_note?.teacher_image === undefined ||  syllabusContent?.smart_note?.teacher_image === null ? 
                    <img src={noPerson} alt=""/> : <img src={`${syllabusContent?.smart_note?.teacher_image_path}${syllabusContent?.smart_note?.teacher_image}`} alt=""/>
                }
                    
                    <p className='mb-0 ms-2'>{syllabusContent?.smart_note?.teacher_name}</p>
                </div>
                <p className='mb-0 pe-3' style={{ fontSize:'20px', fontWeight:'500', color:'rgba(0, 0, 0, 0.5)' }}>মেন্টর</p>
            </div>
        </div>
    );
};

export default SmartNoteModal;