import React from 'react';
import lockScreen from '../../../../../images/LOCK.png';
import PartialPayment from '../../../shared/PartialPayment/PartialPayment';
import './LockScreen.css';

const LockScreen = ({courseBasicInfo}) => {
    return (
        <div className='d-flex justify-content-center align-items-center h-100'>
            <div>
                <div className='d-flex justify-content-center'>
                    <img src={lockScreen} alt=""/>
                </div>
                {
                    courseBasicInfo?.permission ?
                    <>
                        <p className='mb-0 text-center unlock-screen-title'>Pay to unlock!</p>
                        <p className='mb-0 unlock-screen-details'>You need to pay before viewing this contents. Please go to our payment portal for course payment.</p>
                        
                        <div className='d-flex justify-content-center'>
                            <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasPartialPayment" aria-controls="offcanvasPartialPayment" className='btn-assignment-start text-center mt-3'>Pay Now</button>
                            <PartialPayment courseBasicInfo ={courseBasicInfo}/>
                        </div>
                    </>

                    :

                    <>
                        <p className='mb-0 text-center unlock-screen-title'>Course Access Denied!</p>
                        <p className='mb-0 unlock-screen-details'>Please contact with mojaru for your problem.</p>
                        
                    </>
                }
                
            </div>
        </div>
    );
};

export default LockScreen;