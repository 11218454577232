import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SingleQuestion from './SingleQuestion/SingleQuestion';

const AssignmentSubmissionViewCard = ({attempt}) => {
    const [questions, setQuestions] = useState([]);
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}submissions?assignment_id=${attempt?.assignment_id}&attempt_id=${attempt?.attempt_id}&user_id=${localStorage.getItem("user_id")}`)
            .then((res) => res.json())
            .then((data) => setQuestions(data.data));
    },[attempt?.assignment_id, attempt?.attempt_id]);


   

    return (
        <div className='custom-card-shadow bg-white' style={{ borderRadius:'16px' }}>
            <div className='p-4 d-flex justify-content-between align-items-center'>
                <p className='mb-0' style={{ fontSize:"20px", fontWeight:'500' }}>{questions?.assignment_name}</p>
                <span className='text-center submission-show-marks px-3'>Marks: <span className='marks'>{questions?.teacher_given_marks ? questions?.teacher_given_marks : 'Not Checked Yet'}</span></span>
            </div>

            <div className='total-assignment-divider'></div>
            <div className='mt-4 px-4 pb-4'>
                <div className='d-flex align-items-center mb-3'>
                    <p className='mb-0 submission-shortDescription me-5'>Total Questions <span className='fw-bold text-dark ms-2'>{questions?.questions?.length}</span></p>
                    {
                        questions?.teacher_given_marks && 
                            <>
                                <p className='mb-0 submission-shortDescription me-5'>Right Answer(s) <span className='fw-bold text-success ms-2'>{questions?.right_answers}</span></p>
                                <p className='mb-0 submission-shortDescription'>Wrong Answer(s) <span className='fw-bold text-danger ms-2'>{questions?.wrong_answers}</span></p>
                            </>
                     }
                </div>
                {
                    questions?.questions?.map((question, index) => 
                                                                    <SingleQuestion 
                                                                    key={question.id} 
                                                                    question={question} 
                                                                    q_number={index+1} 
                                                                    student_answers={questions?.answers}
                                                                    student_marks={questions?.obtained_marks}
                                                                    teacher_given_marks = {questions?.teacher_given_marks}
                                                                    answer_path_link = {questions?.file_path}
                                                                    />
                    )
                }
           </div>
        </div>
    );
};

export default AssignmentSubmissionViewCard;