
import image1 from '../../../../../../images/quizImage/image1.jpg'
import image2 from '../../../../../../images/quizImage/image2.png'
import image3 from '../../../../../../images/quizImage/image3.png'
import image4 from '../../../../../../images/quizImage/image4.png'
import image5 from '../../../../../../images/quizImage/image5.png'
import image6 from '../../../../../../images/quizImage/image6.jpg'
import image7 from '../../../../../../images/quizImage/image7.png'

// image Age limit 4 To 5
import question3 from '../../../../../../images/quizImage/age4To5/question3.png';
import question5 from '../../../../../../images/quizImage/age4To5/question5.png';
import question6 from '../../../../../../images/quizImage/age4To5/question6.png';
import question7 from '../../../../../../images/quizImage/age4To5/question7.png';
import question8 from '../../../../../../images/quizImage/age4To5/question8.png';
import question9 from '../../../../../../images/quizImage/age4To5/question9.png';
import question10 from '../../../../../../images/quizImage/age4To5/question10.png';
import question11 from '../../../../../../images/quizImage/age4To5/question11.png';
import question12 from '../../../../../../images/quizImage/age4To5/question12.png';
import question13 from '../../../../../../images/quizImage/age4To5/question13.jpg';
import question14 from '../../../../../../images/quizImage/age4To5/question14.jpg';
import question15 from '../../../../../../images/quizImage/age4To5/question15.jpg';

// image Age limit 6 To 7
import question2Age6 from '../../../../../../images/quizImage/age6to7/question2.png';
import question3Age6 from '../../../../../../images/quizImage/age6to7/question3.png';
import question4Age6 from '../../../../../../images/quizImage/age6to7/question4.png';
import question5Age6 from '../../../../../../images/quizImage/age6to7/question5.png';
import question6Age6 from '../../../../../../images/quizImage/age6to7/question6.png';
import question7Age6 from '../../../../../../images/quizImage/age6to7/question7.png';

// image Age limit 8 To 9
import question3Age8 from '../../../../../../images/quizImage/age8to9/question3.png';
import question4Age8 from '../../../../../../images/quizImage/age8to9/question4.jpg';
import question5Age8 from '../../../../../../images/quizImage/age8to9/question5.png';
import question6Age8 from '../../../../../../images/quizImage/age8to9/question6.jpg';

//image Age limit 10 T0 11
import question3Age10 from '../../../../../../images/quizImage/age10To11/question3.png';
import question9Age10 from '../../../../../../images/quizImage/age10To11/question9.jpg';
import question10Age10 from '../../../../../../images/quizImage/age10To11/question10.jpg';
import question11Age10 from '../../../../../../images/quizImage/age10To11/question11Age10.jpeg';

//image Age limit 12 T0 13

import question1Age12 from '../../../../../../images/quizImage/age12To13/question1.jpg';

//image Age limit 14 T0 15
import question1Age14 from '../../../../../../images/quizImage/age14To15/question1.png';
import question2Age14 from '../../../../../../images/quizImage/age14To15/question2.png';
// import question3Age14 from '../../../../../../images/quizImage/age14To15/';



const dataDefault = [
    {
        id: 1,
        question: "কোন রঙের আকৃতি পাঁচকোণা?",
        images: image1,
        options: [
            { text: "বেগুনী", selected: false },
            { text: "কমলা", selected: false },
            { text: "নীল", selected: false }
        ],
        ans: "কমলা"
    },
    {
        id: 2,
        question: "নীচের কোন ছবিতে বেশি এবং কম?",
        images: image2,
        options: [
            { text: "প্রথম ছবিতে", selected: false },
            { text: "দ্বিতীয় ছবিতে", selected: false }
        ],
        ans: "প্রথম ছবিতে"
    },
    {
        id: 3,
        question: "ছবিতে কয়টি আপেল আছে?",
        images: image3,
        options: [
            { text: "৭টি", selected: false },
            { text: "৫ টি", selected: false },
            { text: "৬ টি", selected: false },
            { text: "৮ টি", selected: false }
        ],
        ans: "৫ টি"
    },
    {
        id: 4,
        question: "তোমার কাছে যদি ৫টি ফল থাকে এবং সেখান থেকে তুমি তোমার মাকে দুটি ফল দিয়ে দাও। তাহলে তোমার কাছে আর কয়টি ফল থাকবে?",
        images: image4,
        options: [
            { text: "৪ টি", selected: false },
            { text: "২ টি", selected: false },
            { text: "৩ টি", selected: false },
            { text: "৫ টি", selected: false }
        ],
        ans: "৩ টি"
    },
    {
        id: 5,
        question: "পাজেলটি মিলাও",
        images: image5,
        options: [
            { text: "১ম", selected: false },
            { text: "২য়", selected: false },
            { text: "৩য়", selected: false },
        ],
        ans: "১ম"
    },
    {
        id: 6,
        question: "কোন রঙের আকৃতি পাঁচকোণা?",
        images: image6,
        options: [
            { text: "বেগুনী", selected: false },
            { text: "কমলা", selected: false },
            { text: "নীল", selected: false }
        ],
        ans: "কমলা"
    },
    {
        id: 7,
        question: "কোন রঙের আকৃতি পাঁচকোণা?",
        images: image7,
        options: [
            { text: "বেগুনী", selected: false },
            { text: "কমলা", selected: false },
            { text: "নীল", selected: false }
        ],
        ans: "কমলা"
    },
    {
        id: 8,
        question: "কোন রঙের আকৃতি পাঁচকোণা?",
        images: image7,
        options: [
            { text: "বেগুনী", selected: false },
            { text: "কমলা", selected: false },
            { text: "নীল", selected: false }
        ],
        ans: "কমলা"
    },
    {
        id: 8,
        question: "কোন রঙের আকৃতি পাঁচকোণা?",
        images: image7,
        options: [
            { text: "বেগুনী", selected: false },
            { text: "কমলা", selected: false },
            { text: "নীল", selected: false }
        ],
        ans: "কমলা"
    },
    {
        id: 8,
        question: "কোন রঙের আকৃতি পাঁচকোণা?",
        images: image7,
        options: [
            { text: "বেগুনী", selected: false },
            { text: "কমলা", selected: false },
            { text: "নীল", selected: false }
        ],
        ans: "কমলা"
    },
];

const dataAge4To5 = [
    {
        id: 1,
        question: "তোমার কাছে যদি ৭ টা চকোলেট থাকে এবং সেখান থেকে তুমি ২টি চকোলেট তোমার বন্ধুকে, ২টি চকলেট তোমার মাকে দিয়ে দাও। তাহলে তোমার কাছে কয়টি চকোলেট থাকবে?",
        images: "",
        options: [
            { text: "৫ টি", selected: false },
            { text: "৪ টি", selected: false },
            { text: "৩ টি", selected: false },
            { text: "২ টি", selected: false }
        ],
        ans: "৩ টি"
    },
    {
        id: 2,
        question: "Which word does not contain the letter «D»?",
        images: "",
        options: [
            { text: "Mouse", selected: false },
            { text: "Dog", selected: false },
            { text: "Hedgehog", selected: false }
        ],
        ans: "Mouse",
    },
    {
        id: 3,
        question: "যদি ৩ জুলাই Tuesday হয়, তাহলে ৬ জুলাই কি বার হবে?",
        images: question3,
        options: [
            { text: "Wednesday", selected: false },
            { text: "Thursday", selected: false },
            { text: "Friday", selected: false }
        ],
        ans: "Friday",
    },
    {
        id: 4,
        question: "নিচের পাঁচটি জিনিসের মধ্যে কোনটি যে কোনো চারটি জিনিসের থেকে আলাদা?",
        images: "",
        options: [
            { text: "গোলাপ", selected: false },
            { text: "শাপলা", selected: false },
            { text: "টমেটো", selected: false },
            { text: "বেলি", selected: false },
            { text: "টগর", selected: false }
        ],
        ans: "টমেটো",
    },
    {
        id: 5,
        question: "হলুদ এবং নীল রঙ মেশালে কি রঙ হবে?",
        images: question5,
        options: [
            { text: "বেগুনী", selected: false },
            { text: "সবুজ", selected: false },
            { text: "খয়েড়ি", selected: false },
            { text: "সাদা", selected: false }
        ],
        ans: "সবুজ",
    },
    {
        id: 6,
        question: "নিচের ৪টির মধ্যে কোনটি সবার থেকে আলাদা?",
        images: question6,
        options: [
            { text: "বাস", selected: false },
            { text: "সাইকেল", selected: false },
            { text: "স্কুটার", selected: false },
            { text: "গাড়ি", selected: false }
        ],
        ans: "সাইকেল",
    },
    {
        id: 7,
        question: "নিচের পাজলটি মিলাও?",
        images: question7,
        options: [
            { text: "১", selected: false },
            { text: "২", selected: false },
            { text: "৩", selected: false },
            { text: "৪", selected: false },
            { text: "৫", selected: false },
            { text: "৬", selected: false }
        ],
        ans: "২",
    },
    {
        id: 8,
        question: "এর মধ্যে সবচেয়ে মিষ্টি কোনটি?",
        images: "",
        options: [
            { text: "বার্গার", selected: false },
            { text: "আইসক্রিম", selected: false },
            { text: "স্যুপ", selected: false },
            { text: "মধু", selected: false }
        ],
        ans: "মধু",
    },
    {
        id: 9,
        question: "? স্থানে সঠিক ছবিটি বসাও",
        images: question8,
        options: [
            { text: "1", selected: false },
            { text: "2", selected: false },
            { text: "3", selected: false },
        ],
        ans: "3",
    },
    {
        id: 10,
        question: "নিচের Shape টি কয়টা সাইডস আছে?",
        images: question9,
        options: [
            { text: "৬টি", selected: false },
            { text: "৮টি", selected: false },
            { text: "১০টি", selected: false },
            { text: "৫টি", selected: false },
        ],
        ans: "৮টি",
    },
    {
        id: 11,
        question: "নিচের কোনটি সঠিক?",
        images: question10,
        options: [
            { text: "1", selected: false },
            { text: "2", selected: false },
            { text: "3", selected: false },
            { text: "4", selected: false },
            { text: "5", selected: false },
            { text: "6", selected: false },
        ],
        ans: "5",
    },
    {
        id: 12,
        question: "কোনটার ভারী?",
        images: question11,
        options: [
            { text: "বাস", selected: false },
            { text: "বাইসাইকেল", selected: false },
        ],
        ans: "বাস",
    },
    {
        id: 13,
        question: "তোমার কাছে যদি ৫টি ফল থাকে এবং সেখান থেকে তুমি তোমার মাকে দুটি ফল দিয়ে দাও। তাহলে তোমার কাছে আর কয়টি ফল থাকবে?",
        images: question12,
        options: [
            { text: "৪ টি", selected: false },
            { text: "২ টি", selected: false },
            { text: "৩ টি", selected: false },
            { text: "৫ টি", selected: false },
        ],
        ans: "৩ টি",
    },
    {
        id: 14,
        question: "আমাদের রক্তের রং কি?",
        images: "",
        options: [
            { text: "লাল", selected: false },
            { text: "কালো", selected: false },
            { text: "গোলাপী", selected: false },
            { text: "সবুজ", selected: false },
        ],
        ans: "লাল",
    },
    {
        id: 15,
        question: "কোন পোকাটি বড়?",
        images: question13,
        options: [
            { text: "1", selected: false },
            { text: "2", selected: false },
        ],
        ans: "1",
    },
    {
        id: 16,
        question: "বিড়ালটি আয়নায় নিজেকে দেখছে। আয়নায় বিড়ালটিকে কেমন দেখা যাবে?",
        images: question14,
        options: [
            { text: "1", selected: false },
            { text: "2", selected: false },
            { text: "3", selected: false },
        ],
        ans: "3",
    },
    {
        id: 17,
        question: "যেই ঋতুতে আমরা সোয়েটার এবং মাফলার পরে থাকি, সেই ঋতুর নাম কি?",
        images: "",
        options: [
            { text: "গ্রীষ্মকাল", selected: false },
            { text: "বর্ষাকাল", selected: false },
            { text: "শীতকাল", selected: false },
            { text: "বসন্তকাল", selected: false },
        ],
        ans: "শীতকাল",
    },
    {
        id: 18,
        question: "পাজেলটি মিলাও।",
        images: question15,
        options: [
            { text: "1", selected: false },
            { text: "2", selected: false },
            { text: "3", selected: false },
            { text: "4", selected: false },
        ],
        ans: "1",
    },
];

const dataAge6To7 = [
    {
        id: 1,
        question: 'If you rearrange the letters "RIGET", you would have the name of a:',
        images: "",
        options: [
            { text: "Ocean", selected: false },
            { text: "Animal", selected: false },
            { text: "Country", selected: false }
        ],
        ans: "Animal"
    },
    {
        id: 2,
        question: 'Which pencil is not yellow and not blue, not the shortest and not the longest?',
        images: question2Age6,
        options: [
            { text: "Blue", selected: false },
            { text: "Red", selected: false },
            { text: "Yellow", selected: false },
            { text: "Green", selected: false },
            { text: "Purple", selected: false },
        ],
        ans: "Green"
    },
    {
        id: 3,
        question: 'বন্য প্রাণী কোনটি?',
        images: "",
        options: [
            { text: "গরু", selected: false },
            { text: "ছাগল", selected: false },
            { text: "বাঘ", selected: false },
            { text: "মহিষ", selected: false }
        ],
        ans: "বাঘ"
    },
    {
        id: 4,
        question: 'কোন প্রাণির ওজন বেশি?',
        images: "",
        options: [
            { text: "বাঘ", selected: false },
            { text: "ভাল্লুক", selected: false },
            { text: "হাতি", selected: false },
            { text: "শেয়াল", selected: false }
        ],
        ans: "হাতি"
    },
    {
        id: 5,
        question: 'এখন ঘড়িতে সময় যদি ৫ঃ১০ হয় তাহলে ৩০ মিনিট আগে ঘড়িতে সময় কত ছিল?',
        images: "",
        options: [
            { text: "৪ঃ২০", selected: false },
            { text: "৫ঃ৪০", selected: false },
            { text: "৪ঃ৪০", selected: false },
            { text: "৪ঃ৩০", selected: false }
        ],
        ans: "৪ঃ৪০"
    },
    {
        id: 6,
        question: 'Finish the series 10,9,8,7…',
        images: "",
        options: [
            { text: "৫", selected: false },
            { text: "১১", selected: false },
            { text: "৬", selected: false },
            { text: "৩৩", selected: false }
        ],
        ans: "৬"
    },
    {
        id: 7,
        question: 'বাংলাদেশের সীমানাজুড়ে কোন দুটি দেশ রয়েছে?',
        images: "",
        options: [
            { text: "নেপাল ও ভুটান", selected: false },
            { text: "ইন্ডিয়া ও মায়ানমার", selected: false },
            { text: "তীব্বত ও পাকিস্তান", selected: false },
            { text: "চায়না ও শ্রীলঙ্কা", selected: false }
        ],
        ans: "ইন্ডিয়া ও মায়ানমার"
    },
    {
        id: 8,
        question: 'একটা মোরোগ যদি একটি চালের উপর ডিম পারে তাহলে ডিমটি কোন দিকে পরবে?',
        images: question3Age6,
        options: [
            { text: "ডানে", selected: false },
            { text: "বামে", selected: false },
            { text: "নীচে", selected: false },
            { text: "কোনোটাই না", selected: false }
        ],
        ans: "কোনোটাই না"
    },
    {
        id: 9,
        question: 'মৌলিক রঙ কোনগুলো?',
        images: "",
        options: [
            { text: "কমলা, সবুজ এবং বেগুনী", selected: false },
            { text: "লাল, হলুদ এবং নীল", selected: false },
            { text: "কমলা, হলুদ এবং বেগুনী", selected: false },
            { text: "লাল, সবুজ এবং নীল", selected: false }
        ],
        ans: "লাল, হলুদ এবং নীল"
    },
    {
        id: 10,
        question: 'পাজেলটি মিলাও।',
        images: question4Age6,
        options: [
            { text: "1", selected: false },
            { text: "2", selected: false },
            { text: "3", selected: false },
            { text: "4", selected: false },
            { text: "5", selected: false },
            { text: "6", selected: false }
        ],
        ans: "1"
    },
    {
        id: 11,
        question: 'কোনটি বড়?',
        images: "",
        options: [
            { text: "২ ঘন্টা", selected: false },
            { text: "৯০ মিনিট", selected: false }
        ],
        ans: "২ ঘন্টা"
    },
    {
        id: 12,
        question: 'তোমার কাছে ৫ টি বেলুন আছে। ৫টি বেলুন থেকে একটি বেলুন ফেটে গেলো এবং একটি বেলুন তুমি তোমার বোনকে দিয়ে দিলে, তাহলে তোমার কাছে কয়টি বেলুন থাকবে?',
        images: "",
        options: [
            { text: "৫টি", selected: false },
            { text: "৪টি", selected: false },
            { text: "৩টি", selected: false }
        ],
        ans: "৪টি"
    },
];

const dataAge8T09 = [
    {
        id: 1,
        question: 'মজারু শব্দটি কোন শব্দ থেকে উদ্ভব হয়েছে?',
        images: "",
        options: [
            { text: "মাজার", selected: false },
            { text: "সাজার", selected: false },
            { text: "সজার", selected: false },
            { text: "মজার", selected: false }
        ],
        ans: "মজার"
    },
    {
        id: 2,
        question: 'অ্যাবাকাস সোরোবান কোন দেশের যন্ত্র?',
        images: "",
        options: [
            { text: "বাংলাদেশ", selected: false },
            { text: "চায়না", selected: false },
            { text: "জাপান", selected: false },
            { text: "কোরিয়া", selected: false }
        ],
        ans: "জাপান"
    },
    {
        id: 3,
        question: 'একটা মোরোগ যদি একটি চালের উপর ডিম পারে তাহলে ডিমটি কোন দিকে পরবে?',
        images: question3Age8,
        options: [
            { text: "ডানে", selected: false },
            { text: "বামে", selected: false },
            { text: "নীচে", selected: false },
            { text: "কোনোটাই না", selected: false }
        ],
        ans: "কোনোটাই না"
    },
    {
        id: 4,
        question: 'ধারাটি সমাধান কর  7 9 5 11  4 15 12 7   13 8 11 ?',
        images: "",
        options: [
            { text: "8", selected: false },
            { text: "10", selected: false },
            { text: "11", selected: false },
            { text: "13", selected: false }
        ],
        ans: "10"
    },
    {
        id: 5,
        question: 'Dogs are weaker than wolves. But that dog is stronger than a mouse. Who is the weakest?',
        images: "",
        options: [
            { text: "Dog", selected: false },
            { text: "Mouse", selected: false },
            { text: "Wolf", selected: false },
        ],
        ans: "Mouse"
    },
    {
        id: 6,
        question: 'Sasha is 25, Anya is 15, and Maxim is 30. How old is Dima?',
        images: "",
        options: [
            { text: "40", selected: false },
            { text: "20", selected: false },
            { text: "35", selected: false },
        ],
        ans: "20"
    },
    {
        id: 7,
        question: 'কোনটি ভারি- এক কেজি তুলা নাকি এক কেজি লোহা?',
        images: "",
        options: [
            { text: "তুলার ভর বেশি", selected: false },
            { text: "লোহার ভর বেশি", selected: false },
            { text: "দুটির ভর সমান", selected: false },
        ],
        ans: "দুটির ভর সমান"
    },
    {
        id: 8,
        question: 'There were 3 carrots and 4 apples on the table. The boy took one carrot. How many fruits are left on the table?',
        images: "",
        options: [
            { text: "2", selected: false },
            { text: "3", selected: false },
            { text: "4", selected: false },
        ],
        ans: "4"
    },
    {
        id: 9,
        question: 'ধারাটা মিলাও ? 9 27 81',
        images: "",
        options: [
            { text: "0", selected: false },
            { text: "1", selected: false },
            { text: "3", selected: false },
            { text: "5", selected: false },
        ],
        ans: "3"
    },
    {
        id: 10,
        question: 'কয়টি মহাদেশ আছে?',
        images: "",
        options: [
            { text: "৫টি", selected: false },
            { text: "৬টি", selected: false },
            { text: "৭টি", selected: false },
            { text: "৮টি", selected: false },
        ],
        ans: "৭টি"
    },
    {
        id: 11,
        question: '',
        images: question4Age8,
        options: [
            { text: "10", selected: false },
            { text: "7", selected: false },
            { text: "9", selected: false },
            { text: "11", selected: false },
        ],
        ans: "9"
    },
    {
        id: 12,
        question: 'আগামি পরশু যদি রবিবার হয়, তবে গতকাল কি বার ছিল?',
        images: "",
        options: [
            { text: "সোমবার", selected: false },
            { text: "মঙ্গলবার", selected: false },
            { text: "বুধবার", selected: false },
            { text: "বৃহস্পতিবার", selected: false },
        ],
        ans: "বৃহস্পতিবার"
    },
    {
        id: 13,
        question: 'সঠিক উত্তর কত হবে?',
        images: question5Age8,
        options: [
            { text: "6", selected: false },
            { text: "10", selected: false },
            { text: "8", selected: false },
            { text: "9", selected: false },
        ],
        ans: "9"
    },

];

const dataAge10To11 = [
    {
        id: 1,
        question: 'আগামি পরশু যদি রবিবার হয়, তবে গতকাল কি বার ছিল?',
        images: "",
        options: [
            { text: "সোমবার", selected: false },
            { text: "মঙ্গলবার", selected: false },
            { text: "বুধবার", selected: false },
            { text: "বৃহস্পতিবার", selected: false }
        ],
        ans: "বৃহস্পতিবার"
    },
    {
        id: 2,
        question: 'Z,U,Q,?,L-প্রশ্নবোধক চিহ্নের স্থানের বর্ণটি লেখ।',
        images: "",
        options: [
            { text: "N", selected: false },
            { text: "M", selected: false },
            { text: "O", selected: false },
            { text: "P", selected: false }
        ],
        ans: "N"
    },
    {
        id: 3,
        question: '',
        images: question3Age10,
        options: [
            { text: "২১", selected: false },
            { text: "২২", selected: false },
            { text: "২০", selected: false },
            { text: "২৩", selected: false }
        ],
        ans: "২২"
    },
    {
        id: 4,
        question: '১২১,৮১,৪৯,২৫,____?',
        images: "",
        options: [
            { text: "১৬", selected: false },
            { text: "৯", selected: false },
            { text: "৪", selected: false },
            { text: "১", selected: false }
        ],
        ans: "৯"
    },
    {
        id: 5,
        question: 'তোমার মার মামার একমাত্র ভাগ্নি জামাই তোমার কি হবে?',
        images: "",
        options: [
            { text: "নানা", selected: false },
            { text: "মামা", selected: false },
            { text: "বাবা", selected: false },
            { text: "চাচা", selected: false }
        ],
        ans: "বাবা"
    },
    {
        id: 6,
        question: 'যদি 5314=FEAR, 5790=FOLK তবে 9770=?',
        images: "",
        options: [
            { text: "ROLE", selected: false },
            { text: "FORK", selected: false },
            { text: "FLAKE", selected: false },
            { text: "LOOK", selected: false }
        ],
        ans: "LOOK"
    },
    {
        id: 7,
        question: '২৭০৩৪ সংখ্যাটিতে ৭ এর স্থানীয় মান ও স্বকীয় মানের পার্থক্য কত?',
        images: "",
        options: [
            { text: "৭০২৭", selected: false },
            { text: "৬৯৯৩", selected: false },
            { text: "৭০৪১", selected: false },
            { text: "৩৯৯৬", selected: false }
        ],
        ans: "৬৯৯৩"
    },
    {
        id: 8,
        question: 'কোনটি নদী নয়?',
        images: "",
        options: [
            { text: "কপোতাক্ষ", selected: false },
            { text: "কয়রা", selected: false },
            { text: "দাড়ির গাঙ", selected: false },
            { text: "হরিহর", selected: false }
        ],
        ans: "কপোতাক্ষ"
    },
    {
        id: 9,
        question: '',
        images: question9Age10,
        options: [
            { text: "1", selected: false },
            { text: "2", selected: false },
            { text: "3", selected: false },
            { text: "4", selected: false }
        ],
        ans: "4"
    },
    {
        id: 10,
        question: 'what is the full meaning of IQ ?',
        images: "",
        options: [
            { text: "Ineptness Quantity", selected: false },
            { text: "Intelligent Quotient", selected: false },
            { text: "intellectual Quantity", selected: false },
            { text: "intelligence Quotient", selected: false }
        ],
        ans: "intelligence Quotient"
    },
    {
        id: 11,
        question: 'একটি সমকোণী ত্রিভুজের পরিধি নির্ণয় কর, যার অতিভুজের দৈর্ঘ্য ৫ মিটার এবং ভূমি ও উচ্চতা সমান দৈর্ঘ্যের।',
        images: "",
        options: [
            { text: "5(2+√2)/√2 m", selected: false },
            { text: "2+√2/√2m", selected: false },
            { text: "17√2 m", selected: false },
            { text: "16√2 m", selected: false }
        ],
        ans: "5(2+√2)/√2 m"
    },
    {
        id: 12,
        question: 'প্রদত্ত চিত্রে, মোট ত্রিভুজের সংখ্যা কতটি?',
        images: question10Age10,
        options: [
            { text: "৫", selected: false },
            { text: "৬", selected: false },
            { text: "৮", selected: false },
            { text: "১০", selected: false }
        ],
        ans: "১০"
    },
    {
        id: 13,
        question: 'A kind of vegetable-',
        images: "",
        options: [
            { text: "OTRNH", selected: false },
            { text: "PRECOP", selected: false },
            { text: "OPTTAO", selected: false },
            { text: "NZCI", selected: false }
        ],
        ans: "OPTTAO"
    },
    {
        id: 14,
        question: 'রাহুল পূর্ব দিকে সোজা হাঁটা শুরু করেন। তিনি একটি নির্দিষ্ট দূরত্ব হাঁটার পর ডান দিকে ঘুরে আবার হাঁটা শুরু করেন। এখন তিনি কোন দিকে হাঁটছেন তা নির্ণয় করুন।',
        images: "",
        options: [
            { text: "উত্তর", selected: false },
            { text: "পূর্ব", selected: false },
            { text: "দক্ষিণ", selected: false },
            { text: "পশ্চিম", selected: false }
        ],
        ans: "দক্ষিণ"
    },
    {
        id: 15,
        question: 'একটি চার সদস্যের পরিবারে, R হলেন P এর স্ত্রী, এবং L হলেন P ও R এর পুত্র। D হলেন P এর নাতি। D, L এর সাথে কীভাবে সম্পর্কিত?',
        images: "",
        options: [
            { text: "বাবা", selected: false },
            { text: "পুত্র", selected: false },
            { text: "ভাই", selected: false },
            { text: "চাচা", selected: false }
        ],
        ans: "পুত্র"
    },
    {
        id: 16,
        question: 'In a certain code, GOODNESS is coded as HNPCODTR. How is GREATNESS coded in that code?',
        images: "",
        options: [
            { text: "HQFZUODTR", selected: false },
            { text: "HQFZUMFRT", selected: false },
            { text: "HQFZSMFRT", selected: false },
            { text: "FSDBSODTR", selected: false }
        ],
        ans: "HQFZUMFRT"
    },
    {
        id: 17,
        question: 'In a certain code, GOODNESS is coded as HNPCODTR. How is GREATNESS coded in that code?',
        images: "",
        options: [
            { text: "HQFZUODTR", selected: false },
            { text: "HQFZUMFRT", selected: false },
            { text: "HQFZSMFRT", selected: false },
            { text: "FSDBSODTR", selected: false }
        ],
        ans: "HQFZUMFRT"
    },
    {
        id: 18,
        question: 'পরবর্তীটি কি?',
        images: question11Age10,
        options: [
            { text: "A", selected: false },
            { text: "B", selected: false },
            { text: "C", selected: false },
            { text: "D", selected: false }
        ],
        ans: "C"
    },
    {
        id: 19,
        question: 'বাংলাদেশের উষ্ণতম স্থানের নাম কি ?',
        images: "",
        options: [
            { text: "লালপুর, নাটোর", selected: false },
            { text: "রুপাতলি, বরিশাল", selected: false },
            { text: "লালখান, রংপুর", selected: false },
            { text: "কালীগঞ্জ, সাতক্ষীরা", selected: false }
        ],
        ans: "লালপুর, নাটোর"
    },
    {
        id: 20,
        question: 'NASA এর পূর্ণ কি ?',
        images: "",
        options: [
            { text: "National Aeronautics and Space Administration", selected: false },
            { text: "National Aviation and Space Agency", selected: false },
            { text: "National Association of Space Activities", selected: false },
            { text: "North American Space Agency", selected: false }
        ],
        ans: "National Aeronautics and Space Administration"
    },

];

const dataAge12To13 = [
    {
        id: 1,
        question: '',
        images: question1Age12,
        options: [
            { text: "1", selected: false },
            { text: "2", selected: false },
            { text: "3", selected: false },
            { text: "4", selected: false }
        ],
        ans: "4"
    },
    {
        id: 2,
        question: 'What is the full meaning of IQ?',
        images: "",
        options: [
            { text: "Ineptness Quantity", selected: false },
            { text: "Intelligent Quotient", selected: false },
            { text: "intellectual Quantity", selected: false },
            { text: "intelligence Quotient", selected: false }
        ],
        ans: "intelligence Quotient"
    },
    {
        id: 3,
        question: 'অর্থনীতিতে ২০১৯ সালে কোন এশিয়ান নোবেল পুরষ্কার লাভ করেন?',
        images: "",
        options: [
            { text: "অভিজিৎ বন্দ্যোপাধ্যায়", selected: false },
            { text: "রবার্ট জি আমান", selected: false },
            { text: "মো ইয়ান", selected: false },
            { text: "রোজা ডি", selected: false }
        ],
        ans: "অভিজিৎ বন্দ্যোপাধ্যায়"
    },
    {
        id: 4,
        question: 'যদি 17= ACID তবে, 81=?',
        images: "",
        options: [
            { text: "BIRTHDAY", selected: false },
            { text: "TRIANGLE", selected: false },
            { text: "LAVENDER", selected: false },
            { text: "DECISION", selected: false }
        ],
        ans: "LAVENDER"
    },
    {
        id: 5,
        question: 'নাসা কবে প্রতিষ্ঠিত হয়?',
        images: "",
        options: [
            { text: "১৯৫৮", selected: false },
            { text: "১৯৪৮", selected: false },
            { text: "২০০৩", selected: false },
            { text: "১৮২৩", selected: false }
        ],
        ans: "১৯৫৮"
    },
    {
        id: 6,
        question: '৫, ১৭, ৩৭, ৬৫,?, ১৪৫',
        images: "",
        options: [
            { text: "৫", selected: false },
            { text: "৯৭", selected: false },
            { text: "৯৯", selected: false },
            { text: "১০১", selected: false }
        ],
        ans: "১০১"
    },
    {
        id: 7,
        question: 'Arrange the words given in a meaningful sequence. 1. Poverty  2.  Population 3. Death 4. Unemployment 5. Disease',
        images: "",
        options: [
            { text: "2, 3, 4, 5, 1", selected: false },
            { text: " 3, 4, 2, 5, 1", selected: false },
            { text: "2, 4, 1, 5, 3", selected: false },
            { text: "1, 2, 3, 4, 5", selected: false }
        ],
        ans: "2, 4, 1, 5, 3"
    },
    {
        id: 8,
        question: 'কোন সংখ্যাটি ৭০০ এর এক পঞ্চমাংশের এক দশমাংশের এক পঞ্চমাংশ?',
        images: "",
        options: [
            { text: "১.৮", selected: false },
            { text: "২.৮", selected: false },
            { text: "৩.৮", selected: false },
            { text: "৪.৮", selected: false }
        ],
        ans: "৪.৮"
    },
    {
        id: 9,
        question: 'মাউন্ট এভারেস্ট আবিষ্কারের আগে পৃথিবীর সবচেয়ে উঁচু পর্বতশৃঙ্গের ণাম কি?',
        images: "",
        options: [
            { text: "মাউন্ট এভারেস্ট", selected: false },
            { text: "মাউন্ট কাঙ্গচেনজুঙ্গা", selected: false },
            { text: "মাউন্ট কামেচেঙ্গা", selected: false },
            { text: "মাউন্ট দেনালি", selected: false }
        ],
        ans: "মাউন্ট এভারেস্ট"
    },
    {
        id: 10,
        question: 'একটি সমকোণী ত্রিভুজের পরিধি নির্ণয় কর, যার অতিভুজের দৈর্ঘ্য ৫ মিটার এবং ভূমি ও উচ্চতা সমান দৈর্ঘ্যের।',
        images: "",
        options: [
            { text: "5(2+√2)/√2 m", selected: false },
            { text: "2+√2/√2m", selected: false },
            { text: "17√2 m", selected: false },
            { text: "16√2 m", selected: false }
        ],
        ans: "5(2+√2)/√2 m"
    },
    {
        id: 11,
        question: 'বাংলাদেশের উষ্ণতম স্থানের নাম কি ?',
        images: "",
        options: [
            { text: "লালপুর, নাটোর", selected: false },
            { text: "রুপাতলি, বরিশাল", selected: false },
            { text: "লালখান, রংপুর", selected: false },
            { text: "কালীগঞ্জ, সাতক্ষীরা", selected: false }
        ],
        ans: "লালপুর, নাটোর"
    },
    {
        id: 12,
        question: 'In a certain code, GOODNESS is coded as HNPCODTR. How is GREATNESS coded in that code?',
        images: "",
        options: [
            { text: "HQFZUODTR", selected: false },
            { text: "HQFZUMFRT", selected: false },
            { text: "HQFZSMFRT", selected: false },
            { text: "FSDBSODTR", selected: false }
        ],
        ans: "HQFZUMFRT"
    },
    {
        id: 13,
        question: 'তোমার বন্ধু তোমাকে তার বিয়ের পার্টিতে আমন্ত্রণ জানায় না। তুমি কী করবে?',
        images: "",
        options: [
            { text: "তাকে আমার বিয়েতে দাওয়াত করবো না", selected: false },
            { text: "অনুষ্ঠানে উপস্থিত হবেন।", selected: false },
            { text: "তাকে তোমার শুভেচ্ছা প্রেরণ করবেন।", selected: false },
            { text: "এই সম্পূর্ণ ঘটনাকে উপেক্ষা করবেন।", selected: false },
            { text: "কোনটাই না।", selected: false },
        ],
        ans: "তাকে তোমার শুভেচ্ছা প্রেরণ করবেন।"
    },
    {
        id: 14,
        question: 'If 1st October falls on Sunday, what day of the week will fall on 1st November?',
        images: "",
        options: [
            { text: "Monday", selected: false },
            { text: "Tuesday", selected: false },
            { text: "Wednesday", selected: false },
            { text: "Thursday", selected: false },
        ],
        ans: "Wednesday"
    },
    {
        id: 15,
        question: 'Yen: Currency',
        images: "",
        options: [
            { text: "Brass: Metal", selected: false },
            { text: "Hen: Poultry", selected: false },
            { text: "Paper: Book", selected: false },
            { text: "Karnataka: State", selected: false },
        ],
        ans: "Karnataka: State"
    },
];

const dataAge14To15 = [
    {
        id: 1,
        question: 'কোন সংখ্যাটি ৭০০ এর এক পঞ্চমাংশের এক দশমাংশের এক পঞ্চমাংশ?',
        images: "",
        options: [
            { text: "১.৮", selected: false },
            { text: "২.৮", selected: false },
            { text: "৩.৮", selected: false },
            { text: "৪.৮", selected: false }
        ],
        ans: "২.৮"
    },
    {
        id: 2,
        question: 'মাউন্ট এভারেস্ট আবিষ্কারের আগে পৃথিবীর সবচেয়ে উঁচু পর্বতশৃঙ্গের ণাম কি?',
        images: "",
        options: [
            { text: "মাউন্ট এভারেস্ট", selected: false },
            { text: "মাউন্ট কাঙ্গচেনজুঙ্গা", selected: false },
            { text: "মাউন্ট কামেচেঙ্গা", selected: false },
            { text: "মাউন্ট দেনালি", selected: false }
        ],
        ans: "মাউন্ট এভারেস্ট"
    },
    {
        id: 3,
        question: 'প্রকাশ রাজনের দিকে ইঙ্গিত করে বলেন, "সে আমার বোনের একমাত্র ভাইয়ের ছেলে।" প্রকাশের সাথে রাজনের সম্পর্ক কি?',
        images: "",
        options: [
            { text: "পুত্র", selected: false },
            { text: "ভাই", selected: false },
            { text: "ভাগ্নে", selected: false },
            { text: "এগুলোর কোনটিই নয়", selected: false }
        ],
        ans: "পুত্র"
    },
    {
        id: 4,
        question: 'বাংলাদেশের উষ্ণতম স্থানের নাম কি ?',
        images: "",
        options: [
            { text: "লালপুর, নাটোর", selected: false },
            { text: "রুপাতলি, বরিশাল", selected: false },
            { text: "লালখান, রংপুর", selected: false },
            { text: "কালীগঞ্জ, সাতক্ষীরা", selected: false }
        ],
        ans: "লালপুর, নাটোর"
    },
    {
        id: 5,
        question: '123456147, 12345614, 2345614, 234561,?',
        images: "",
        options: [
            { text: "3456", selected: false },
            { text: "2345", selected: false },
            { text: "23456", selected: false },
            { text: "34561", selected: false }
        ],
        ans: "34561"
    },
    {
        id: 6,
        question: 'তোমার বন্ধু তোমাকে তার বিয়ের পার্টিতে আমন্ত্রণ জানায় না। তুমি কী করবে?',
        images: "",
        options: [
            { text: "এটি তার বিরুদ্ধে ধার্য করবে।", selected: false },
            { text: "অনুষ্ঠানে উপস্থিত হবেন।", selected: false },
            { text: "তাকে তোমার শুভেচ্ছা প্রেরণ করবেন।", selected: false },
            { text: "এই সম্পূর্ণ ঘটনাকে উপেক্ষা করবেন।", selected: false },
            { text: "কোনটাই না।", selected: false },
        ],
        ans: "তাকে তোমার শুভেচ্ছা প্রেরণ করবেন।"
    },
    {
        id: 7,
        question: 'একটি চিত্র X দেওয়া হয়েছে। চারটি বিকল্প থেকে একটি চিত্র নির্বাচন করুন যা এই চিত্র এবং চিত্র X একে ওপরের সাথে মিলিত হলে একটি পূর্ণ বর্গ তৈরি হবে?',
        images: question1Age14,
        options: [
            { text: "A", selected: false },
            { text: "B", selected: false },
            { text: "C", selected: false },
            { text: "D", selected: false }
        ],
        ans: "B"
    },
    {
        id: 8,
        question: 'J Y 2 = £  x E M ¤ 7 $ H P 9 K L β @ W Q 1 3 # C D ©  উপরোক্ত আয়াতনে এমন কতগুলি চিহ্ন আছে যাদের প্রত্যেকটি সরাসরি একটি সংখ্যার পরে অথবা একটি অক্ষরের পূর্বে অবস্থিত, তবে উভয়ই না।',
        images: "",
        options: [
            { text: "কোনটি না", selected: false },
            { text: "একটি", selected: false },
            { text: "তিনটি", selected: false },
            { text: "চারটি", selected: false }
        ],
        ans: "তিনটি"
    },
    {
        id: 9,
        question: 'If HEALTH is written as GSKZDG, then how will NORTH be written in that code?',
        images: "",
        options: [
            { text: "OPSUI", selected: false },
            { text: "FRPML", selected: false },
            { text: "GSQNM", selected: false },
            { text: "IUSPQ", selected: false }
        ],
        ans: "GSQNM"
    },
    {
        id: 10,
        question: 'নিম্নলিখিত বিকল্পগুলির মধ্যে কোনটিতে আলোচ্য আকৃতি (X) সঠিকভাবে সংযুক্ত আছে?',
        images: question2Age14,
        options: [
            { text: "A", selected: false },
            { text: "B", selected: false },
            { text: "C", selected: false },
            { text: "D", selected: false }
        ],
        ans: "A"
    },
    {
        id: 11,
        question: "P and Q are sisters. P is the mother of R. Q's daughter S is married to T. X is the husband of P. How is Q related to T?",
        images: "",
        options: [
            { text: "Mother", selected: false },
            { text: "Sister-in-law", selected: false },
            { text: "Mother-in-law", selected: false },
            { text: "None of these", selected: false }
        ],
        ans: "Mother-in-law"
    },
    // {
    //     id: 12,
    //     question: 'প্রদত্ত সিরিজে পরবর্তী চিত্রটি খুঁজে বের কর।',
    //     images: question3Age14,
    //     options: [
    //         { text: "A", selected: false },
    //         { text: "B", selected: false },
    //         { text: "C", selected: false },
    //         { text: "D", selected: false }
    //     ],
    //     ans: "A"
    // },
    {
        id: 13,
        question: 'বিশ্ব স্বাস্থ্য দিবস কবে?',
        images: "",
        options: [
            { text: "৭ এপ্রিল", selected: false },
            { text: "৮ এপ্রিল", selected: false },
            { text: "৯ এপ্রিল", selected: false },
            { text: "১০ এপ্রিল", selected: false }
        ],
        ans: "৭ এপ্রিল"
    },
    {
        id: 14,
        question: 'মান নির্ণয় করঃ (√১৯৬ + √২৫৬)/ √২২৫?',
        images: "",
        options: [
            { text: "১", selected: false },
            { text: "২", selected: false },
            { text: "৩", selected: false },
            { text: "৪", selected: false }
        ],
        ans: "২"
    },
    {
        id: 15,
        question: 'At my farmhouse, I am facing East, then I turn left and walk 10 metres. I again turn right and walk 5 meters. Again I go 5 metres towards the South and from there, I walk 5 metres towards the West. In which direction am I from my farmhouse?',
        images: "",
        options: [
            { text: "East", selected: false },
            { text: "West", selected: false },
            { text: "North", selected: false },
            { text: "South", selected: false }
        ],
        ans: "North"
    },
    {
        id: 16,
        question: 'একটি চাকার ব্যাসার্ধ ০.২৫ মিটার। ১১ কিলোমিটার দুরত্ব পার করার জন্য সে কত পর্যায়বৃত্তি ঘুরাবে?',
        images: "",
        options: [
            { text: "৮০০০", selected: false },
            { text: "৯০০০", selected: false },
            { text: "৭০০০", selected: false },
            { text: "৬০০০", selected: false }
        ],
        ans: "৭০০০"
    },
    {
        id: 17,
        question: 'A clock is set to show the correct time at 8 a.m. The clock gains 10 minutes in a day. What will be the approximate time, when the watch indicates 4 p.m. the next day?',
        images: "",
        options: [
            { text: "3 h 36 min.", selected: false },
            { text: "3 h 47 min.", selected: false },
            { text: "3 h 50 min.", selected: false },
            { text: "3 h 54 min.", selected: false }
        ],
        ans: "3 h 47 min."
    },
];


export {dataDefault, dataAge4To5, dataAge6To7, dataAge8T09, dataAge10To11, dataAge12To13, dataAge14To15};
