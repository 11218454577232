import React from 'react';

const CardSkeleton = () => {
    return (
        <div className="tw-w-full tw-relative tw-h-auto tw-flex tw-items-center tw-justify-center tw-p-2">
            <div className="tw-w-full tw-h-auto md:tw-w-[360px] md:tw-bg-white tw-p-4 md:tw-p-0 tw-rounded-3xl tw-flex tw-flex-col tw-justify-center tw-items-center tw-animate-pulse">
                {/* Image Skeleton */}
                <div className="tw-w-full tw-h-[192px] tw-bg-gray-300 tw-rounded-tr-3xl tw-rounded-tl-3xl"></div>

                {/* Content Skeleton */}
                <div className="tw-w-full tw-bg-white tw-rounded-bl-3xl tw-rounded-br-3xl tw-p-6">
                    <div className="tw-flex tw-mb-4">
                        <div className="tw-w-24 tw-h-6 tw-bg-gray-300 tw-rounded-3xl"></div>
                        <div className="tw-ml-4 tw-w-16 tw-h-6 tw-bg-gray-300 tw-rounded-3xl"></div>
                    </div>

                    <div className="tw-h-6 tw-bg-gray-300 tw-rounded-md tw-mt-4 tw-w-3/4"></div>

                    <div className="tw-flex tw-mt-4">
                        <div className="tw-w-20 tw-h-4 tw-bg-gray-300 tw-rounded-md tw-mr-4"></div>
                        <div className="tw-w-20 tw-h-4 tw-bg-gray-300 tw-rounded-md"></div>
                    </div>

                    <div className="tw-w-full tw-flex tw-justify-between tw-mt-4">
                        <div className="tw-w-24 tw-h-6 tw-bg-gray-300 tw-rounded-md"></div>
                        <div className="tw-w-16 tw-h-6 tw-bg-gray-300 tw-rounded-md"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardSkeleton;
