import React from 'react';
import { Modal } from 'react-bootstrap';
import unionIcon from '../../../../../../../images/book-half.png';
import noPerson from '../../../../../../../images/blank.png';
import shape from '../../../../../../../images/shape.png';

const NoticeDetails = (props) => {
    return (
        <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton style={{ border:'1px solid #F4F4F4' }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Title of the notice
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="container">
                <div className="row g-2">
                    <div className="col-lg-4">
                        <div className='details-subject-section'>
                            <div className='d-flex align-items-center mb-3'>
                                <img src={unionIcon} alt="" width={24} height={24}/>
                                <p className='submission-view-top ms-2 mb-0'>অন্যান্য তথ্য</p>
                            </div>
                            <table className="table table-borderless mb-0 my-4">
                        <tbody>
                             
                            <tr className="font-400">
                                <td className="font-400">কোর্স</td>
                                <td className="">: &nbsp; &nbsp; ৯ম শ্রেণির বিজ্ঞান কোর্স</td>
                            </tr>
                            <tr className="font-400">
                                <td className="">ধরন</td>
                                <td className="">: &nbsp; &nbsp; পরীক্ষার নোটিস</td>
                            </tr>
                            <tr className="font-400">
                                <td className="">প্রকাশের তারিখ</td>
                                <td className="balooda-regular ">: &nbsp; &nbsp; ৪/০৯/২০২২</td>
                            </tr>
                            <tr className="font-400">
                                <td className="">প্রকাশক</td>
                                <td className="">:</td>
                            </tr>
                            <tr className="font-400">
                                <td colSpan={2} className="">
                                    <div className='d-flex align-items-center w-100'>
                                        <img src={noPerson} alt="" width={64} height={64} className='me-2' style={{ borderRadius: '50%' }}/>

                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <div>
                                                <p className='mb-0 font-400' style={{ fontSize:'18px' }}>MD Shakibul Islam</p>
                                                <p className='result-subject-name mb-0'>পদার্থ বিজ্ঞান, রসায়ন</p>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className='details-subject-section'>
                            <div className='d-flex align-items-center mb-3'>
                                <img src={unionIcon} alt="" width={24} height={24}/>
                                <p className='submission-view-top ms-2 mb-0'>বিস্তারিত</p>
                            </div>

                            <div>
                                <img src={shape} alt="" className='img-fluid' style={{ borderRadius:'8px' }}/>
                            </div>
                            <p className='mb-0 mt-3 font-400' style={{  textAlign: 'justify' }} >
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium vero illo necessitatibus totam accusamus 
                                minima repudiandae corrupti excepturi inventore illum officiis exercitationem corporis, reprehenderit assumenda. 
                                Harum quae fuga quos possimus totam, cupiditate unde, eligendi incidunt 
                                ab voluptates atque repudiandae, magni alias libero modi doloremque nobis sint enim veniam magnam minima.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer style={{ border:'none' }}>
                {/* <Button onClick={props.onHide}>Close</Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default NoticeDetails;