import React from "react";
import { useContext, useEffect, useState } from "react";
import { englishToBengaliNumberFormat } from "../../../../../utilities/utilities";
import { LMSContext } from "../../../Pages/LMS/LMS";
import defaultImage from "../../../../../images/default_course_image.jpg";

const PaymentCalculation = ({
    courseBasicInfo,
    handleActivePaymentMethod,
    setData,
}) => {
     /* const [finalBalanceUsingCoupon, setFinalBalanceUsingCoupon] = useState(0);
    const [couponDetail, setCouponDetail] = useState("");
    const [isCouponUsed, setIsCouponUsed] = useState(false); */
    const { batch_id, course_id } = useContext(LMSContext);
    /* const [days, setDays] = useState();
    const [minDaysPurchese, setMinDaysPurchese] = useState();
    const [stopDays, setStopDays] = useState(true); */
    /* const [autoInc, setAutoInc] = useState(true) */

    /*     if(courseBasicInfo?.subscription_details?.min_days && stopDays){
        setDays(courseBasicInfo?.subscription_details?.min_days);
        setMinDaysPurchese(courseBasicInfo?.subscription_details?.min_days);
        setStopDays(false);
    }

    let daysDifference = courseBasicInfo?.subscription_details?.max_days - days;
    if( daysDifference < 10 && autoInc){
        setDays(courseBasicInfo?.subscription_details?.max_days);
        setAutoInc(false);
    }
    
    console.log(courseBasicInfo); */

    /*     const handelIncreaseMonth =() =>{
        if(courseBasicInfo?.subscription_details?.max_days > days){
            setDays(days + minDaysPurchese);
            let daysDifference = courseBasicInfo?.subscription_details?.max_days - days;
            if(daysDifference > minDaysPurchese &&  daysDifference <= minDaysPurchese+10){
                setDays(courseBasicInfo?.subscription_details?.max_days);
            }
            
        }
    } */
    /*     const handelDecreaseMonth =() =>{
        if(courseBasicInfo?.subscription_details?.min_days < days){
            let daysDifference = days % 30;

            if(daysDifference <= 10 && daysDifference > 0){
                setDays(days - (daysDifference+minDaysPurchese));
            }  
            else
                setDays(days - minDaysPurchese);
            let month = Math.floor(days/minDaysPurchese);
            if(month == 1)
                setDays(minDaysPurchese);
        }
    } */

    /* const handelCoupon = event =>{
        event.preventDefault();
        const couponInfo = {
            coupon_code : event.target.promoCode.value
        };

            if(couponInfo.coupon_code === ''){
                toast.error('Coupon code is required');

            }else{
                fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}apply-coupon-code?coupon_code=${event.target.promoCode.value}`)
                .then(res => res.json())
                .then(data =>{
                    if(data.message_type === 'success'){
                        let actualPrice = parseInt((Math.floor(days/30) * courseBasicInfo?.subscription_details?.min_price));
                        setCouponDetail(data.data);
                        if(data?.data?.coupon_percentage_or_fixed === 'percentage'){
                            let percentage = parseInt(data?.data?.coupon_amount);
                            setFinalBalanceUsingCoupon(((percentage*actualPrice)/100));
                        }else{
                            setFinalBalanceUsingCoupon(parseInt(data?.data?.coupon_amount));
                        }
                        setIsCouponUsed(true);
                        toast.success(data.message);
                        event.target.reset();
                    }else{
                        setIsCouponUsed(false);
                        toast.error(data.message);
                    }
                })
            }
    } */

    const [dueAmount, setDueAmount] = useState(courseBasicInfo?.subscription_details?.due_amount);
    const [minAmount, setMinAmount] = useState(courseBasicInfo?.subscription_details?.min_price);
    const [minDays, setMinDays] = useState(courseBasicInfo?.subscription_details?.min_days);
    const [maxDays, setMaxDays] = useState(courseBasicInfo?.subscription_details?.max_days);
    const [admissionPeriod, setAdmissionPeriod] = useState();
    const [getAdmissionPeriod, setGetAdmissionPeriod] = useState(true);
    const [monthPayment, setMonthPayment] = useState(1);

    const getAccessDays = (minDays, maxDays, monthPayment) => {
        let access = minDays * monthPayment;
        if (Math.abs(maxDays - access) < 10) {
            access = maxDays;
        }
        if (getAdmissionPeriod) {
            setAdmissionPeriod(access);
            setGetAdmissionPeriod(false);
        }
        return access;
    };

    const handelIncreaseMonth = () => {
        if (minAmount < courseBasicInfo?.subscription_details?.due_amount) {
            let month = monthPayment + 1;
            setMonthPayment(month);
            let amount =
                courseBasicInfo?.subscription_details?.min_price * month;
            if (amount > courseBasicInfo?.subscription_details?.due_amount) {
                amount = courseBasicInfo?.subscription_details?.due_amount;
            }
            setMinAmount(amount);
            setGetAdmissionPeriod(true);
        }
    };
    const handelDecreaseMonth = () => {
        if (monthPayment > 1) {
            let month = monthPayment - 1;
            setMonthPayment(month);
            let amount =
                courseBasicInfo?.subscription_details?.min_price * month;
            if (amount > courseBasicInfo?.subscription_details?.due_amount) {
                amount = courseBasicInfo?.subscription_details?.due_amount;
            }
            setMinAmount(amount);
            setGetAdmissionPeriod(true);
        }
    };
   

    useEffect(() => {
        const value = {
            //transaction_amount: isCouponUsed ? Math.floor(days / minDaysPurchese) * courseBasicInfo?.subscription_details?.min_price - finalBalanceUsingCoupon : minAmount,
            transaction_amount: minAmount,
            user_id: localStorage.getItem("user_id"),
            balance: courseBasicInfo?.wallet_balance,
            user_name: courseBasicInfo?.user_name,
            user_mobile_number: courseBasicInfo?.mobile_number,
            user_address: "374, Free School Street Hatirpool, Kalabagan, Dhaka-1205",
            course_id: course_id,
            course_name: courseBasicInfo?.course_name,
            batch_id: batch_id,
            batch_name: courseBasicInfo?.batch_name,
            book_id: 1,
            book_title: "testBook",
            batch_start_date: courseBasicInfo?.subscription_details?.batch_start_date,
            batch_total_duration: courseBasicInfo?.subscription_details?.batch_total_duration_in_days,
            admission_period: admissionPeriod,
            course_total_price: courseBasicInfo?.subscription_details?.course_total_price,
            course_original_price: minAmount,
        };

        setData(value);

        /* console.log(value.admission_period); */
    }, [admissionPeriod, minAmount]);

    /* englishToBengaliNumberFormat() */

    return (
        <div className="p-2">
            <div className="d-flex align-items-center w-100 mb-5">
                {
                    courseBasicInfo?.course_picture !== undefined &&
                    courseBasicInfo?.course_picture !== null ? (
                    <img src={`${courseBasicInfo?.course_image_path}${courseBasicInfo?.course_picture}`} alt="" width={74} height={74} className="me-3" style={{ borderRadius: "16px" }} />) 
                    : 
                    ( <img src={`${defaultImage}`} alt="" width={74} height={74} className="me-3" style={{ borderRadius: "16px" }}/>)
                }

                <div className="d-flex justify-content-between align-items-center w-100">
                    <div>
                        <p
                            className="result-exam-name mb-0"
                            style={{ fontSize: "24px" }}
                        >
                            {courseBasicInfo?.course_name}
                        </p>
                        <p
                            className="result-subject-name mb-0"
                            style={{ fontSize: "18px" }}
                        >
                            {courseBasicInfo?.batch_name}
                        </p>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="payment-subscript mb-3">সাবস্ক্রিপশন ফি</p>
                    <p className="payment-subscript mb-3 text-danger">
                        পেমেন্ট বাকি : ৳
                        <span className="balooda-medium">
                            {englishToBengaliNumberFormat(
                                courseBasicInfo?.subscription_details
                                    ?.due_amount
                            )}
                        </span>
                    </p>
                </div>

                <div className="d-flex justify-content-between align-items-center w-100 subscription-balance-section mb-5">
                    <div>
                        <p className="subscription-balance">
                            ৳
                            <span className="balooda-medium">
                                {courseBasicInfo?.subscription_details
                                    ?.due_amount <
                                courseBasicInfo?.subscription_details?.min_price
                                    ? englishToBengaliNumberFormat(
                                          courseBasicInfo?.subscription_details
                                              ?.due_amount
                                      )
                                    : englishToBengaliNumberFormat(
                                          courseBasicInfo?.subscription_details
                                              ?.min_price
                                      )}
                            </span>
                            /<span style={{ fontSize: "20px" }}>মাস</span>
                        </p>
                    </div>
                    <div className="">
                        <div className="d-flex justify-content-center align-items-center">
                            <button
                                className="btn-payment-control"
                                onClick={handelDecreaseMonth}
                            >
                                -
                            </button>
                            <p className="mb-0 payment-month text-center" style={{ width: "100px" }}>
                                <span className="balooda-medium">৳
                                    {courseBasicInfo?.subscription_details
                                        ?.due_amount <
                                    courseBasicInfo?.subscription_details
                                        ?.min_price
                                        ? englishToBengaliNumberFormat(
                                              dueAmount
                                          )
                                        : englishToBengaliNumberFormat(
                                              minAmount
                                          )}
                                </span>
                            </p>
                            <button
                                className="btn-payment-control"
                                onClick={handelIncreaseMonth}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="payment-subscript mb-3">বিস্তারিত পেমেন্ট</p>
                    <div className="mt-3 payment-subscript-details">
                        <div className="d-flex justify-content-between">
                            <p className="mb-0 subscription-duration">
                                সাবস্ক্রিপশন মেয়াদ
                            </p>
                            <p className="mb-0 subscription-duration">
                                <span className="balooda-regular" id="access_subscription">
                                    {englishToBengaliNumberFormat(getAccessDays(minDays,maxDays,monthPayment))}
                                </span>
                                দিন
                            </p>
                        </div>
                        <div className="divider-border my-3"></div>

                        <div className="d-flex justify-content-between">
                            <p className="mb-0 subscription-duration">
                                সাবস্ক্রিপশন ফি
                            </p>
                            <p className="mb-0 subscription-duration">
                                ৳{" "}
                                <span className="balooda-regular">
                                    {courseBasicInfo?.subscription_details
                                        ?.due_amount <
                                    courseBasicInfo?.subscription_details
                                        ?.min_price
                                        ? englishToBengaliNumberFormat(
                                              dueAmount
                                          )
                                        : englishToBengaliNumberFormat(
                                              minAmount
                                          )}
                                </span>
                            </p>
                        </div>
                        <div className="divider-border my-3"></div>

                        {/* <div className='d-flex justify-content-between'>
                                    <p className='mb-0 subscription-duration'>ভ্যাট</p>
                                    <p className='mb-0 subscription-duration'>৳ ৬২৪</p>
                                </div>
                                <div className='divider-border my-3'></div> */}

                        <div className="d-flex justify-content-between">
                            <p className="mb-0 subscription-duration">
                                ডিসকাউন্ট
                            </p>
                            <p className="mb-0 subscription-duration">৳ ০</p>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mt-3">
                        <p className="mb-0 subscription-duration-total">
                            সর্বমোট
                        </p>
                        <p className="mb-0 subscription-duration-total">
                            ৳{" "}
                            <span className="balooda-medium">
                                {courseBasicInfo?.subscription_details
                                    ?.due_amount <
                                courseBasicInfo?.subscription_details?.min_price
                                    ? englishToBengaliNumberFormat(dueAmount)
                                    : englishToBengaliNumberFormat(minAmount)}
                            </span>
                        </p>
                    </div>

                    {/* {
                                    isCouponUsed ? 
                                        <div>
                                            <div className='mt-1 mb-2 d-flex justify-content-between align-items-center px-4'>
                                                    <p className='mb-0 subscription-duration'>প্রোমো কোড ডিসকাউন্ট </p>
                                                    <p className='mb-0 subscription-duration'>৳<span className='balooda-regular'>{couponDetail.coupon_percentage_or_fixed == 'fixed' ? englishToBengaliNumberFormat(couponDetail.coupon_amount) : englishToBengaliNumberFormat(finalBalanceUsingCoupon)}</span></p>
                                            </div>
                                            <div className='admission-details-divider '></div>
                                            <div className='d-flex justify-content-between align-items-center '>
                                                <p className='mb-0 subscription-duration-total'>বর্তমান সর্বমোট</p>
                                                <p className='mb-0 subscription-duration-total'>৳ <span className='balooda-medium'>{englishToBengaliNumberFormat( (Math.round(days/minDaysPurchese) * courseBasicInfo?.subscription_details?.min_price) - finalBalanceUsingCoupon)}</span></p>
                                            </div>

                                        </div> : ""

                                } */}

                    {/* <form onSubmit={handelCoupon}  className='mt-5'>
                                    <div className='row gy-3'>
                                        <div className="col-lg-9 lg-pe-1">
                                            <input className='subscription-promo-code-field' name='promoCode' type="text" placeholder='প্রোমো কোড দিন'/>
                                        </div>
                                        <div className="col-lg-3 lg-ps-1">
                                            <button className='btn-subscription-apply'>Apply</button>
                                        </div>
                                    </div>
                                </form> */}

                    <div className="payment-bottom">
                        <button
                            className="btn-admit-course"
                            onClick={handleActivePaymentMethod}
                        >
                            সাবস্ক্রিপশন কিনুন
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentCalculation;
