import React from "react";
import "./CourseTeachers.css";
import dummyTeacher from '../../../../../../images/default_img/Default Person Avatar.png';

const CourseTeachers = ({course_teacher,teacher_length,index_count,teacher_profile_path,count}) => {
   
    return (
        <div>
            <div className="teacher-img">
            {(course_teacher?.teacher_profile_picture !== undefined && course_teacher?.teacher_profile_picture !== null) ? 
                <img src={`${teacher_profile_path}${course_teacher?.teacher_profile_picture}`} alt=""/>
                : 
                <img src={dummyTeacher} alt=""/>

            }
                {/* <img src={`${teacher_profile_path}${course_teacher?.teacher_profile_picture}`} alt=""/> */}
                <p className="teacher-name">{course_teacher?.teacher_name}</p>
                <p className="font-16 text-center teacher-details">
                    {course_teacher?.teacher_details}
                </p>
            </div>

            {
                teacher_length === index_count+1 ? '' : 
                <div className="teacher-divider" style={count === index_count+1? { display: "none" } : { display: "block" }}></div> 

            }
        </div>
    );
};

export default CourseTeachers;
