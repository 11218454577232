import React, { useEffect } from 'react';
import { useState } from 'react';
import parse from 'html-react-parser';

const MultipleAnswer = ({question, handelCheckBox, endSlice, index}) => {
    useEffect(()=>{
        handelCheckBox(null, question?.id, 0);
    },[])
    const handelRadio =(event) =>{
        const {value, checked} = event.target;
        if(checked){
            //console.log(value);
            handelCheckBox(value, question?.id, 1);
            
        }else{
            /* setMultipleCheckBox(multipleCheckBox.filter((event) => event !== value)); */
            handelCheckBox(value, question?.id, 2);
        }
    }

    const [checked, setChecked] = useState({});

    const handleCheckboxChange = (event) => {
        setChecked({ ...checked, [event.target.name]: event.target.checked });
    };
    return (
        <>
        <div className='d-flex justify-content-between mb-4 mt-5'>
                <p className='mb-0 ' style={{ fontWeight:'400' }}>Question No #{index ? index : endSlice}</p>
                <p className='mb-0' style={{ fontWeight:'400' }}>Question Type: 
                {/* <span className='single-question-type'>
                    {question?.question_type_id === 1 ? 'Multiple Choice Question': question?.question_type_id === 2 ? 'Multiple Answer': 'TrueFalse' }
                    </span> */}
                    <span className='single-question-type ms-1'>
                        {question?.question_type_id === 1 ? 'Multiple Choice Question': 
                        question?.question_type_id === 2 ? 'Multiple Answer': 
                        question?.question_type_id === 3 ? 'TrueFalse': 
                        question?.question_type_id === 4 ? 'Broad Question': ''}
                    </span>
                </p>
            </div>
        <div className='single-question text-center unselectable'>{parse(question?.title)}</div>
            <div className='d-flex justify-content-center mb-5'>
            <div className='option-width' onChange={handelRadio} style={{ marginBottom:'5rem' }}>
                {
                    question?.options?.map(option =>
                        <div key={option?.id} className="form-check option d-flex justify-content-between align-items-center ps-0">
                            <label className="form-check-label unselectable preference question-option-shadow" htmlFor={`multiple_answer_${option?.id}`}  style={{ border: checked[`multiple_answer_${option?.id}`] ? '1px solid #532d80' : '1px solid #fff', cursor:'pointer' }}>{option?.answer_details}</label>
                            <input className="form-check-input p-1 me-3" type="checkbox" name={`multiple_answer_${option?.id}`} id={`multiple_answer_${option?.id}`} value={`${option?.id}`} style={{ border:'2px solid #DEDEDE' }}  onChange={handleCheckboxChange}/>
                        </div>
                    )
                }
            </div>
        </div>
        </> 
    );
};

export default MultipleAnswer;