import React from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import courseLink from "../../../../../images/HomePageImage/Course.svg";
import { Link } from "react-router-dom";
import dot from "../../../../../images/HomePageImage/Dot.svg";
import { HiRefresh, HiVideoCamera } from "react-icons/hi";
import bnLogo from "../../../../../images/HomePageImage/bn_logo.svg";
import ukLogo from "../../../../../images/HomePageImage/UK.png";
import { useTranslation } from "react-i18next";

const MobileMenu = ({
  toggleSubMenu,
  openSubMenu,
  categories,
  handelCourse,
  showAllCourse,
  setIsOpen,
  courses,
  toggleLanguage,
  language,
}) => {
  const { t } = useTranslation();
  return (
    <div className="xl:tw-hidden tw-fixed tw-left-0 tw-top-[70px] tw-w-full tw-h-screen tw-bg-white tw-z-50 tw-overflow-auto">
      <div className="tw-px-4 tw-py-4 tw-space-y-1 tw-pb-3">
        <ul className="tw-p-0 tw-text-gray-700">
          <li className="tw-w-full tw-rounded-lg tw-bg-gray-100">
            <button
              className="tw-w-full tw-flex tw-justify-between tw-p-4"
              onClick={() => toggleSubMenu("allCourses")}
            >
              {openSubMenu === "allCourses" ? (
                <>
                  <p>{t("All courses")}</p>
                  <HiChevronUp />
                </>
              ) : (
                <>
                  <p>{t("All courses")}</p>
                  <HiChevronDown />
                </>
              )}
            </button>
            {openSubMenu === "allCourses" && (
              <ul className="tw-border-t tw-px-4 tw-py-2">
                {categories.map((category) => (
                  <>
                    <li
                      onClick={() => handelCourse(category)}
                      key={category?.course_category_id}
                      className="tw-flex tw-p-4"
                    >
                      <div className="tw-w-full tw-flex tw-items-start">
                        <img className="tw-mr-2" src={courseLink} alt="image" />
                        <div>
                          <p>{category?.course_sub_category_name}</p>
                          <p>{category?.courses_count} কোর্সেস </p>
                        </div>
                      </div>
                      <div>
                        {showAllCourse ===
                        category?.course_sub_category_name ? (
                          <HiChevronUp />
                        ) : (
                          <HiChevronDown />
                        )}
                      </div>
                    </li>

                    {showAllCourse === category?.course_sub_category_name &&
                      courses && (
                        <ul className="tw-ml-0 tw-py-1">
                          {courses.map((course) => (
                            <Link
                              onClick={() => setIsOpen(false)}
                              key={course?.id}
                              to={`/${course?.course_category_link}/${course?.course_slug}`}
                              state={{ course_id: `${course?.id}` }}
                            >
                              <div className="tw-flex tw-mt-1 tw-px-0 tw-py-1 tw-rounded-lg tw-bg-gray-100 tw-cursor-pointer">
                                <div className="tw-mr-4">
                                  <img
                                    className="tw-w-20 tw-h-auto"
                                    src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/courses/${course?.course_picture}`}
                                  />
                                </div>
                                <div>
                                  <p
                                    className="tw-font-semibold tw-text-gray-700"
                                    key={course?.id}
                                  >
                                    {course?.course_name}
                                  </p>
                                  <div className="tw-flex tw-mt-2">
                                    <div className="tw-flex tw-justify-center tw-items-center tw-bg-[#FEE2E2] tw-px-1 tw-rounded-3xl">
                                      <img
                                        className="tw-w-2 tw-h-2 tw-mr-2"
                                        src={dot}
                                        alt="logo"
                                      />
                                      <span className="tw-text-red-800">
                                        {course?.course_mode}
                                      </span>
                                    </div>
                                    <p className="tw-flex tw-items-center tw-mr-4 tw-text-gray-500 tw-ml-2">
                                      <HiVideoCamera className="tw-mr-1 tw-text-gray-400" />{" "}
                                      {course?.course_total_live_class} Live
                                      class
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </ul>
                      )}
                  </>
                ))}
              </ul>
            )}
          </li>
          <li className="tw-mt-2">
            <Link onClick={() => setIsOpen(false)} to="/live">
              <button
                className="tw-w-full tw-flex tw-justify-between tw-p-4 tw-rounded-lg tw-bg-gray-100"
                onClick={() => toggleSubMenu("liveCourses")}
              >
                <p>{t("Live Courses")}</p>
              </button>
            </Link>
          </li>
          <li className="tw-mt-2">
            <Link to="/record">
              <button
                className="tw-w-full tw-flex tw-justify-between tw-p-4 tw-rounded-lg tw-bg-gray-100"
                onClick={() => toggleSubMenu("freeCourses")}
              >
                <p>{t("Recorded Courses")}</p>
              </button>
            </Link>
          </li>
          <li className="tw-mt-2">
            <Link to="/free">
              <button
                className="tw-w-full tw-flex tw-justify-between tw-p-4 tw-rounded-lg tw-bg-gray-100"
                onClick={() => toggleSubMenu("freeCourses")}
              >
                <p>{t("Free Courses")}</p>
              </button>
            </Link>
          </li>
          <li className="tw-mt-2">
            <button
              className="tw-w-full tw-flex tw-justify-between tw-items-center tw-p-4 tw-rounded-lg tw-bg-gray-100"
              onClick={toggleLanguage}
            >
              <div className="tw-flex tw-items-center">
                {language === "en" ? (
                  <img className="tw-mr-2" src={bnLogo} alt="logo" />
                ) : (
                  <img
                    className="tw-w-4 tw-h-4 tw-mr-2"
                    src={ukLogo}
                    alt="logo"
                  />
                )}
                <span>Change to {language === "en" ? <>BN</> : <>EN</>}</span>
              </div>
              <div>
                <HiRefresh />
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
