import React from "react";
import { useEffect, useState } from "react";
/* import { Form } from "react-bootstrap"; */
import { englishToBengaliNumberFormat } from "../../../../../../utilities/utilities";
import "./CourseReview.css";
import Review from "./Review/Review";

const CourseReview = ({ course_id }) => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    async function fetchReview() {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/course-reviews?course_id=${course_id}`
      );
      response = await response.json();
      setReviews(response);
    }
    fetchReview();
  });

  /* console.log(reviews); */

  return (
    <div className="review-Section">
      <div id="show">
        লার্নারস রিভিউ (
        <span className="review-count balooda-medium">
          {englishToBengaliNumberFormat(reviews.total_reviews)}
        </span>
        )
      </div>
      <div>
        {reviews.total_reviews > 0 ? (
          reviews?.data?.map((review) => (
            <Review
              key={review.review_id}
              review={review}
              path={reviews.user_image_path}
            ></Review>
          ))
        ) : (
          <p
            className="text-center reviewer_name mt-5"
            style={{ fontSize: "26px" }}
          >
            এই কোর্সে এখনও কোন রিভিউ প্রদান করা হয়নি।
          </p>
        )}
      </div>
    </div>
  );
};

export default CourseReview;
