import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TagManager from "react-gtm-module";
import PageTitle from "../../../shared/PageTitle/PageTitle";
import bg from "../../../../../images/login/BG.png";
import "./Otp.css";
import successStudent from "../../../../../images/login/successStudent.png";
import SliderComponent from "../../SliderComponent/SliderComponent";
import image1 from "../../../../../images/HomePageImage/success/16x9a.jpg";
import image2 from "../../../../../images/HomePageImage/success/9x16a.jpg";
import image3 from "../../../../../images/HomePageImage/success/1a.jpg";
import {useTranslation} from "react-i18next";

const Otp = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get("page");
    const [otp, setOtp] = useState({ otp_1: "", otp_2: "", otp_3: "", otp_4: "" });
    const [btnLoading, setBtnLoading] = useState(false);
    const [isValidInput, setIsValidInput] = useState(false);
    const {t} = useTranslation();
    const from = location.state?.from || "";
    useEffect(() => {
        if (location.state?.message) {
            toast.success(location.state.message);
        }
    }, [location.state?.message]);

    const lang = sessionStorage.getItem("useLanguage");

    const handleOtpChange = (e) => {
        const { name, value } = e.target;
        if (value.length <= 1) {
            setOtp({ ...otp, [name]: value });
            if (value && parseInt(name.split("_")[1]) < 4) {
                const nextInput = document.querySelector(
                    `input[name=otp_${parseInt(name.split("_")[1]) + 1}]`
                );
                nextInput?.focus();
            }
        }
        setIsValidInput(Object.values({ ...otp, [name]: value }).every((v) => v !== ""));
    };

    const handleKeyDown = (e) => {
        const { name, value } = e.target;
        if (e.key === "Backspace" && !value && parseInt(name.split("_")[1]) > 1) {
            const prevInput = document.querySelector(
                `input[name=otp_${parseInt(name.split("_")[1]) - 1}]`
            );
            prevInput?.focus();
        }
    };

    const handleOtpSubmit = (e) => {
        e.preventDefault();
        setBtnLoading(true);

        const otp_number = Object.values(otp).join("");
        const otp_information = {
            mobile_or_email: location.state.mobile_or_email,
            user_id: location.state.user_id,
            otp: otp_number,
            fcm_token: localStorage.getItem("notification-token"),
            platform:"web"
        };

        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}verify-otp`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(otp_information),
        })
            .then((res) => res.json())
            .then((data) => {
                setBtnLoading(false);
                if (data.message_type === "success") {
                    if (page === "registration") {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: "new_registration",
                                pagePath: "otp",
                                PageTitle: "Otp verification",
                                mobile_or_email: location.state.mobile_or_email,
                            },
                        });
                    }
                    localStorage.setItem("auth_token", data.auth_token);
                    localStorage.setItem("auth_credential", data.auth_credential);
                    localStorage.setItem("user_id", location.state.user_id);
                    localStorage.setItem("mobile_or_email", location.state.mobile_or_email);
                    if (from === "login") {
                        navigate("/dashboard");
                    }else {
                        navigate("/user-registration")
                    }
                } else {
                    toast.error(data.message);
                }
            })
            .catch((error) => {
                setBtnLoading(false);
                toast.error("Something went wrong! Please try again.");
            });
    };

    return (
        <section className="tw-h-screen tw-flex tw-items-center tw-justify-center -tw-mt-[88px]">
            <PageTitle title="OTP Verification" />
            <div className="md:tw-flex tw-w-full tw-h-full">
                <div className="tw-w-full md:tw-w-1/2 tw-h-full tw-flex tw-flex-col tw-justify-center">
                    <div className="tw-w-[90%] md:tw-w-[50%] tw-mx-auto">
                        <h2 className="tw-text-2xl tw-font-bold">{t("OTP Verification")}</h2>
                        <p className="tw-mt-2 tw-text-gray-600">
                            {t("We Are Sending")} <span className={`${lang === 'en' ? "tw-hidden" : "tw-font-semibold tw-text-gray-900"}`}>+88 {location.state.mobile_or_email}</span> {t("A Verification Code To This Number")} <span className={`${lang === 'en' ? "tw-font-semibold tw-text-gray-900" : "tw-hidden"}`}>+88 {location.state.mobile_or_email}</span>
                        </p>
                        <form onSubmit={handleOtpSubmit} className="tw-mt-8">
                            <label
                                htmlFor="otp"
                                className="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
                            >
                                {t("Enter Your 4 Digit Code")}
                            </label>
                            <div className="tw-flex tw-space-x-2 tw-mt-4">
                                {[1, 2, 3, 4].map((i) => (
                                    <input
                                        key={i}
                                        name={`otp_${i}`}
                                        type="text"
                                        maxLength="1"
                                        value={otp[`otp_${i}`]}
                                        onChange={handleOtpChange}
                                        onKeyDown={handleKeyDown}
                                        className="tw-w-12 tw-h-12 tw-border tw-border-gray-300 tw-text-center tw-rounded-md focus:tw-ring-2 focus:tw-ring-purple-500"
                                        required
                                    />
                                ))}
                            </div>
                            <button
                                type="submit"
                                className={`tw-mt-6 tw-w-full tw-py-3 tw-rounded-3xl tw-text-white ${
                                    !isValidInput || btnLoading
                                        ? "tw-cursor-not-allowed tw-bg-[#D2BEF4]"
                                        : "tw-bg-[#532D80]"
                                }`}
                                disabled={!isValidInput || btnLoading}
                            >
                                {t("Submit")}
                            </button>
                        </form>
                    </div>
                </div>
                <div className="tw-hidden md:tw-block md:tw-w-1/2 tw-h-full tw-relative">
                    <img className="tw-w-full tw-h-full tw-object-cover" src={bg} alt="background image"/>
                    <div
                        className=" tw-absolute tw-top-[20%] tw-left-[10%] tw-w-[80%] tw-mx-auto  tw-bg-white tw-p-8 tw-rounded-3xl">
                        <div className="tw-flex tw-justify-between">
                            <img className="tw-w-[350px] tw-rounded-3xl" src={image1} alt="icon"/>
                            <img className="tw-w-[100px] tw-rounded-3xl" src={image2} alt="icon"/>
                            <img className="tw-w-[210px] tw-rounded-3xl" src={image3} alt="icon"/>
                        </div>
                        <div className="tw-overflow-hidden tw-mt-8">
                            <SliderComponent/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Otp;