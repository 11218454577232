import React, { useEffect, useState } from "react";
import CourseCard from "./CourseCard/CourseCard";
import {englishToBengaliNumberFormat} from "../../../../utilities/utilities";
import "./MyCourse.css";
import Skeleton from 'react-loading-skeleton';
import StudentPageTitle from "../../shared/StudentPageTitle/StudentPageTitle";
import { useTranslation } from "react-i18next";
import i18next from "i18next"

const MyCourse = () => {
    const [purchasedCourses, setPurchasedCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [language, setLanguage] = useState();
    const {t} = useTranslation();



    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}purchased-course-lists?user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            .then((res) => res.json())
            .then((data) => {
                setPurchasedCourses(data.data);
                setIsLoading(false);
            });

        const useLanguage = sessionStorage.getItem('useLanguage');
        if(!useLanguage){
            sessionStorage.setItem('useLanguage', 'bn');
            i18next.changeLanguage('bn');
            setLanguage('bn');
        }else{
            i18next.changeLanguage(useLanguage);
            setLanguage(useLanguage);
        }
    }, []);



    return (
        <div className="main-content">
            <StudentPageTitle title="My Course"></StudentPageTitle>
            <div className="page-content p-0">
                <div className="container-fluid">
                    <div className="my-course-section">
                        {/* New Design */}
                        {
                            isLoading && <>
                                <Skeleton className='mb-1' height={50} width={165}/>
                                <Skeleton className='mb-1' height={20} width={250}/>
                                <div className="row g-3">
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                </div>
                            </>
                        }
                        <p className="my-course-title mb-1">{t('All Courses')}</p>
                        {
                            purchasedCourses?.purchased_courses?.length > 0 && language === "bn" &&
                                <p style={{ fontWeight: "400" }}>
                                    সর্বমোট <span className="">{englishToBengaliNumberFormat( purchasedCourses?.purchased_courses?.length)}</span> টি কোর্সে অ্যাসাইন করা হয়েছে
                                </p>
                        }

                        <div className="row g-3">
                            {purchasedCourses?.purchased_courses?.length > 0 ? (
                                purchasedCourses?.purchased_courses?.map((purchasedCourse) => {
                                if (purchasedCourse?.course_card_hide === 0) {
                                    return (
                                    <CourseCard
                                        key={purchasedCourse.course_id}
                                        purchasedCourse={purchasedCourse}
                                        course_img_pathLink={purchasedCourses?.course_image_path}
                                        teacher_img_pathLink={purchasedCourses?.teacher_image_path}
                                    />
                                    );
                                } else {
                                    return null;
                                }
                                })
                            ) : (
                                <p className="text-center" style={{ fontWeight: "400", fontSize:'22px' }}>
                                {t('You have no course')}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyCourse;
