import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import watermarkLogo from "../../../../images/m_watermark.png";
import logo from "../../../../images/dashboard-logo.png";
import stem from "../../../../images/STEM.png";
import PageTitle from "../../shared/PageTitle/PageTitle";
import { BiPhoneCall } from "react-icons/bi";
import Footer from "../../shared/Footer/Footer";
import { Field, useFormik } from "formik";
import { cadetAdmissionFormValidation } from "../../components/Formik/FormikValidation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cadetIcon from "../../../../images/cadet.png"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Loader from "../../shared/Loader/Loader";
import calllCenter from "../../../../images/call-center-final.png";
import utils from "../../../../utils/index";

const CadetVivaRegistration = () => {

    const [loading, setLoading] = useState(false);

    const initialValues = {
        guardian_name: "",
        student_name: "",
        cadet_admission_index_number: "",
        mobile: "",
        whatsapp_number: "",
        existing_school_name: "",
        address: "",
        client_requirment: "",
        student_image: null,
        admitcard_image: null,
    };

    useEffect(()=>{
        const pixelData = {page: 'Cadet Viva Registration'};
        utils.addFacebookEvent('Registration', pixelData);
      },[])
    


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: cadetAdmissionFormValidation,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            // Disable the submit button to prevent multiple submissions

            setSubmitting(true);
            // Handle form submission here, e.g., send data to your Laravel API
            // You can use Axios or the fetch API to make API requests
            // Example:
            setLoading(true);
            const formData = new FormData();
            formData.append('guardian_name', values.guardian_name);
            formData.append('student_name', values.student_name);
            formData.append('cadet_admission_index_number', values.cadet_admission_index_number);
            formData.append('mobile', values.mobile);
            formData.append('whatsapp_number', values.whatsapp_number);
            formData.append('existing_school_name', values.existing_school_name);
            formData.append('address', values.address);
            formData.append('client_requirement', values.client_requirment);
            if (values.student_image) {
                formData.append('student_image_link', values.student_image);
            }
            if (values.admitcard_image) {
                formData.append('admit_card_image_link', values.admitcard_image);
            }
            fetch("https://new.mojaru.com/api/v1/registration-cadet-viva", {
                method: "POST",
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    setLoading(false);
                    resetForm();
                    toast.success("Welcome!!! Your registration is complete.", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                });
        },
    });

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }


    return (
        <div className="BkashLeadsPage">
            <div className="Social_sideIcon">
                <a
                    href="https://www.facebook.com/groups/mojaru.genius"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src="/images/fb.gif" alt="" />
                </a>
                <a
                    href="https://api.whatsapp.com/send/?phone=8801610075101&text&type=phone_number&app_absent=0"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src="/images/whs.gif" alt="" />
                </a>
                <a
                    href="https://www.youtube.com/@Mojarutech"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src="/images/youtube.gif" alt="" />
                </a>
            </div>
            <PageTitle title="Cadet Viva Registration"></PageTitle>
            <div className="BkashLeads">
                <Container>
                    <ToastContainer />
                    <Row>
                        <Col lg={12}>
                            <img
                                src={watermarkLogo}
                                alt="Watermark Logo"
                                className="watermark-logo"
                            />
                            {/* header */}
                            <div className="header">
                                <div className="left">
                                    <a style={{ display: "block" }} href='/' target='_blank'>  <img src={logo} alt="" className="img-fluid" /> </a>
                                    <img style={{ width: "100px", marginLeft: "20px" }} src={stem} alt="" className="img-fluid" />
                                </div>
                                <div className="right">
                                    <img style={{ width: "200px" }} src={cadetIcon} />
                                </div>
                            </div>

                            {/* Form */}
                            <div className="BkashLeadsForm">
                                <h3>ক্যাডেটে ভর্তিতে চূড়ান্ত সফলতা পেতে নিচের ফর্মটি পূরণ করুন</h3>
                                <form onSubmit={formik.handleSubmit}>
                                    {/* item */}
                                    <div className="custom_input">
                                        <label>
                                            শিক্ষার্থীর নাম <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="student_name"
                                            onChange={formik.handleChange}
                                            value={formik.values.student_name}
                                            placeholder="শিক্ষার্থীর নাম"
                                        />
                                        {formik.touched.student_name &&
                                            formik.errors.student_name ? (
                                            <div className="error">{formik.errors.student_name}</div>
                                        ) : null}
                                    </div>
                                    <div className="custom_input">
                                        <label>
                                            ক্যাডেট ভর্তি পরীক্ষার ইনডেক্স নাম্বার <span>*</span>
                                        </label>

                                        <input
                                            type="text"
                                            name="cadet_admission_index_number"
                                            onChange={formik.handleChange}
                                            value={formik.values.cadet_admission_index_number}
                                            placeholder="ক্যাডেট ভর্তি পরীক্ষার ইনডেক্স নাম্বার"
                                        />
                                        {formik.touched.cadet_admission_index_number &&
                                            formik.errors.cadet_admission_index_number ? (
                                            <div className="error">{formik.errors.cadet_admission_index_number}</div>
                                        ) : null}
                                    </div>

                                    {/* item */}
                                    <div className="custom_input">
                                        <label>
                                            অভিভাবকের  নাম <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="guardian_name"
                                            onChange={formik.handleChange}
                                            value={formik.values.guardian_name}
                                            placeholder="অভিভাবকের নাম"
                                        />
                                        {formik.touched.guardian_name &&
                                            formik.errors.guardian_name ? (
                                            <div className="error">{formik.errors.guardian_name}</div>
                                        ) : null}
                                    </div>
                                    {/* item */}
                                    <div className="custom_input">
                                        <label>
                                            অভিভাবকের মোবাইল নাম্বার  <span>*</span>
                                        </label>

                                        <PhoneInput
                                            country={"bd"}
                                            enableSearch={true}
                                            value={formik.values.mobile}
                                            onChange={(mobile) =>
                                                formik.setFieldValue("mobile", mobile)
                                            }
                                        />
                                        {formik.touched.mobile && formik.errors.mobile ? (
                                            <div className="error">{formik.errors.mobile}</div>
                                        ) : null}
                                    </div>
                                    {/* item */}
                                    <div className="custom_input">
                                        <label>
                                            অভিভাবকের হোয়াটসঅ্যাপ নাম্বার <span>*</span>
                                        </label>
                                        <PhoneInput
                                            country={"bd"}
                                            enableSearch={true}
                                            value={formik.values.whatsapp_number}
                                            onChange={(whatsapp_number) =>
                                                formik.setFieldValue("whatsapp_number", whatsapp_number)
                                            }
                                        />
                                        {formik.touched.whatsapp_number && formik.errors.whatsapp_number ? (
                                            <div className="error">{formik.errors.whatsapp_number}</div>
                                        ) : null}
                                    </div>
                                    {/* item */}
                                    <div className="custom_input">
                                        <label>
                                            বর্তমান স্কুলের নাম <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="existing_school_name"
                                            onChange={formik.handleChange}
                                            value={formik.values.existing_school_name}
                                            placeholder="বর্তমার স্কুলের নাম"
                                        />
                                        {formik.touched.age && formik.errors.existing_school_name ? (
                                            <div className="error">{formik.errors.existing_school_name}</div>
                                        ) : null}
                                    </div>
                                    <div className="custom_input">
                                        <label>
                                            ঠিকানা <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="address"
                                            onChange={formik.handleChange}
                                            value={formik.values.address}
                                            placeholder="ঠিকানা"
                                        />
                                        {formik.touched.age && formik.errors.address ? (
                                            <div className="error">{formik.errors.address}</div>
                                        ) : null}
                                    </div>
                                    <div className="custom_input">
                                        <label>আপনার মন্তব্য</label>
                                        <textarea
                                            name="client_requirment"
                                            onChange={formik.handleChange}
                                            value={formik.values.client_requirment}
                                            rows="1"
                                        ></textarea>
                                    </div>

                                    <div className="custom_input">
                                        <label htmlFor="student_image" for="formFile" className="form-label">শিক্ষার্থীর এক কপি ছবি আপলোড করুন (সেলফি গ্রহণযোগ্য নয়)</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            id="student_image"
                                            onChange={(event) => {
                                                formik.setFieldValue('student_image', event.currentTarget.files[0]);
                                            }}
                                        />
                                    </div>

                                    <div className="custom_input">
                                        <label htmlFor="admitcard_image">এডমিট কার্ডের ছবি আপলোড করুন</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            id="admitcard_image"
                                            onChange={(event) => {
                                                formik.setFieldValue('admitcard_image', event.currentTarget.files[0]);
                                            }}
                                        />
                                    </div>

                                    <div className="custom_input full_width button">
                                        <button type="submit">সংরক্ষণ করুন</button>
                                    </div>
                                </form>
                            </div>

                            {/* video */}
                            <div className="BkashLeads__video">
                                <iframe src="https://www.youtube.com/embed/AZDt7QppYlY?si=VZ1t5M9qOLsV1_Qo" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* about cadet */}

            <section className="py-5">
                <div className="container">
                    <h4>কী থাকছে এই কোর্সে</h4>
                    <ol>
                        <li>এক্স-ক্যাডেটদের তত্ত্বাবধানে বিষয়ভিত্তিক গ্রুমিং</li>
                        <li>সাবেক অধ্যক্ষ ও অবসরপ্রাপ্ত সামরিক কর্মকর্তাদের তত্ত্বাবধানে অফলাইন মক ভাইভা</li>
                        <li>এক্স-ক্যাডেট চিকিৎসকদের তত্ত্বাবধানে শারীরিক পরীক্ষা</li>
                        <li>ভাইভা ডাইজেস্ট বই</li>
                        <li>অভিভাবকদের গ্রুমিং ও ভাইভা</li>
                    </ol>
                </div>
                <div className="container mt-4">
                    <ul>
                        <li>এই ফরম পূরণ করার পর মজারু অফিস থেকে আপনার সাথে যোগাযোগ করা হবে।</li>
                        <li>লিখিত পরীক্ষার ফল প্রকাশের আগে ৩টি অনলাইন ক্লাস হবে, যেখানে ভাইভার প্রস্তুতি কীভাবে নেবে তা বুঝিয়ে দেওয়া হবে।</li>
                        <li>ফল প্রকাশের পর অনলাইনে ৭টি ক্লাস ও মক ভাইভা হবে</li>
                        <li>এরপর ঢাকায় ডাকা হবে সশরীরে মক ভাইভায় অংশগ্রহণের জন্য। যেখানে ক্যাডেট কলেজের সাবেক অধ্যক্ষ, অবসরপ্রাপ্ত সামরিক কর্মকর্তা ও এক্স-ক্যাডেটদের নিয়ে গঠিত ভাইভা বোর্ডে মক ভাইভা নেওয়া হবে।</li>
                    </ul>
                </div>
            </section>

            {/* faq */}
            <section className="faq">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <h3>ক্যাডেটে ভর্তিতে চূড়ান্ত সফলতা পেতে  অংশগ্রহণ করো মজারুর ভাইভা প্রস্তুতি কোর্সে ।</h3>
                            {/* tabs */}
                            <div className="faq__tabs">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>ভাইভাতে উপস্থিত হওয়ার পর শিক্ষার্থীর করণীয় কি?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                <span> উত্তর- </span> ভাইভা কক্ষের নিয়ম কানুন, প্রশ্নোত্তর পর্ব এবং উপস্থিত বুদ্ধিবৃত্তিক প্রচেষ্টায় পরিস্থিতিকে নিজের অনুকূলে আনার ব্যাপারে এক্স ক্যাডেট শিক্ষকদের তত্ত্বাবধানে মজারু আয়োজন করেছে  বিষয় ভিত্তিক গ্রুমিং।
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                        ভাইভা প্রস্তুতির ক্ষেত্রে নির্দিষ্ট কোন সিলেবাস থাকে? 
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                <span> উত্তর- </span> ভাইভা প্রস্তুতির সম্পূর্ণ মাস্টার প্ল্যান নিয়ে মজারু সাজিয়েছে চূড়ান্ত ক্যাডেট ভাইভা প্রস্তুতি কোর্স। যেখানে থাকছে ভাইভা সম্পর্কিত খুঁটিনাটির আপাদমস্তক সমাধান।
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        লিখিত পরীক্ষার কতদিন পর ভাইভা পরীক্ষা অনুষ্ঠিত হয় ?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                <span> উত্তর- </span> সাধারণত লিখিত পরীক্ষা সম্পন্ন হওয়ার ২৫ থেকে ৪০ দিনের মধ্যেই ভাইভা পরীক্ষা অনুষ্ঠিত হয় ।
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                        ভাইভা পরীক্ষা কি ক্যাডেট কলেজে ভর্তির চূড়ান্ত ধাপ ? 
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span> উত্তর- </span> না ,ভাইভা পরীক্ষার পাশাপাশি শারীরিক পরীক্ষাতে ও শিক্ষার্থীকে অংশ নিতে হয়। </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                        পরীক্ষার্থীর পাশাপাশি অভিভাবকদের ও কি ভাইভা পরীক্ষায় অংশগ্রহণ করতে হয়? 
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                <span> উত্তর- </span> জ্বি, ২০১৮ সাল থেকে অভিভাবকদের ভাইভা পরীক্ষা নেওয়ার প্রক্রিয়াটি চালু করা হয়েছে । 
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                        কাঙ্ক্ষিত সাফল্যের লক্ষ্যে মজারুর ক্যাডেট ভাইভা প্রোগ্রামটি কিভাবে সাজানো হয়েছে? 
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                <span> উত্তর- </span> এক্স-ক্যাডেট দের সমন্বয়ক্রমে  টানা নয় দিন অনলাইনে ভাইভা প্রস্তুতি ক্লাস অনুষ্ঠিত হবে। পাশাপাশি ক্যাডেট কলেজের সাবেক অধ্যক্ষ,  অবসরপ্রাপ্ত সামরিক কর্মকর্তা  এবং ফিজিশিয়ানদের নিবিড় পর্যবেক্ষণে  অফলাইনে ও একদিনের চূড়ান্ত প্রস্তুতি ও সম্পন্ন করা হবে।
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* CallUs */}
            <section className="CallUs">
                <Container>
                    <Row className="d_flex">
                        <Col lg={6}>
                            <div className="CallUs_content">
                                <h3>যেকোনো প্রয়োজনে এখনই কল করুন</h3>
                                <p>
                                    আপনার সোনামনির পড়ালেখা  সংক্রান্ত যেকোনো জিজ্ঞাসায় কল করুন, মজার শিক্ষা গুরু, মজায় শেখা শুরু

                                </p>
                                <h4>সকাল ৯ টা - রাত ১০ টা</h4>
                                <a href="tel:09610990880">
                                    <BiPhoneCall /> 09610990880
                                </a>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="CallUs_img">
                                <img src={calllCenter} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    );
};

export default CadetVivaRegistration;
