import React from 'react';
import './CourseDescription.css';
import parse from 'html-react-parser';
const CourseDescription = ({description}) => {
    /* const des = description; */
    return (
        <div>
            <div className='description-section'>
                {
                    description && parse(description)
                }
            </div>
        </div>
    );
};

export default CourseDescription;