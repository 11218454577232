import React from 'react';
import './Banner.css';
import banner_girl from "../../../../../images/Genius.png";

const Banner = () => {
    return (
        <div className="academic_background ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <div>
                            <p className="banner-title">
                                লিটিল জিনিয়াস,
                                <br />
                                তৈরি হবে এখানেই আজ
                            </p>
                            <p className="banner-text">
                                কিডস ফ্রেন্ডলি দেশি-বিদেশি এক্সপার্ট মেন্টর,
                                কুইজ, টেস্ট, ওয়ার্কশিট,
                                রিয়েল লাইফ এক্সামপল, গেম আর ফানে নতুন নতুন বিষয়
                                শিখে শিশুরা হয়ে উঠবে ক্ষুদে জিনিয়াস
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <img
                            src={banner_girl}
                            alt=""
                            className="img-fluid pb-5 mt-5"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;