import React, { useMemo, useState } from "react";



const Rating = ({ count, rating, color, onRating }) => {

    const [hoverRating, setHoverRating] = useState(0);

    const getColor = (index) => {
      if (hoverRating >= index) {
        return color.filled;
      } else if (!hoverRating && rating >= index) {
        return color.filled;
      }
  
      return color.unfilled;
    };

    const starRating = useMemo(() => {
        return Array(count)
          .fill(0)
          .map((_, i) => i + 1)
          .map((idx) => (
            <i key={idx} 
            className="fa-solid fa-star cursor-pointer fs-3" 
            onClick={() => onRating(idx)} 
            style={{ color: getColor(idx) }} 
            onMouseEnter={() => setHoverRating(idx)}
            onMouseLeave={() => setHoverRating(0)}
            ></i>
          ));
      }, [count, rating, hoverRating]);


    return (
        <div>
            {starRating}
        </div>
    );
};

/* Rating.propTypes = {
    count: PropTypes.number,
    rating: PropTypes.number,
    onChange: PropTypes.func,
    color: {
      filled: PropTypes.string,
      unfilled: PropTypes.string,
    },
  }; */
  
  Rating.defaultProps = {
    count: 5,
    rating: 0,
    color: { 
      filled: "#FB923C",
      unfilled: "#DCDCDC",
    },
  };
  

export default Rating;