import React, { useEffect, useState } from "react";
import { LuPhoneCall } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import DotLoader from "react-spinners/ClipLoader";

function ReveButton({ handleButton, spinnerAlreadyShown, showSpinner }) {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("bn");
  // Language switcher
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");
    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  // Toggle Language
  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem("useLanguage");

    if (getLanguage === "bn") {
      sessionStorage.setItem("useLanguage", "en");
      i18next.changeLanguage("en");
      setLanguage("en");
    } else {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    }
  }
  //Translation End
  return (
    <button
      className={`tw-bg-[#22c55e] tw-p-3 tw-rounded-full tw-fixed tw-bottom-[18px] ${
        spinnerAlreadyShown
          ? "tw-right-[20%] md:tw-right-[10%] lg:tw-right-[8%] xl:tw-right-[6%]  2xl:tw-right-[5%] tw-z-50"
          : "tw-right-[2%] md:tw-right-[2%] lg:tw-right-[2%] xl:tw-right-[2%]  2xl:tw-right-[2%] tw-z-50"
      }  `}
      onClick={handleButton}
    >
      <div className=" tw-flex tw-gap-2">
        <LuPhoneCall className="tw-text-white" size={25} />
        <span className="tw-hidden md:tw-flex md:tw-items-center md:tw-gap-2 tw-text-white">
          {t("Contact Us")}{" "}
          {showSpinner && <DotLoader color="white" margin={0} size={20} />}
        </span>
      </div>
    </button>
  );
}

export default ReveButton;
