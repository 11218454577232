import React, {useEffect} from 'react';
import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import morning from "../../../../../../images/course_details_icon/Morning.png";
import afternoon from "../../../../../../images/course_details_icon/Afternoon.png";
import evening from "../../../../../../images/course_details_icon/Eveninig.png";
import night from "../../../../../../images/course_details_icon/night.png";
import infoIcon from "../../../../../../images/course_details_icon/Information_circle.png";
import officeOff from "../../../../../../images/course_details_icon/offDay.png";
import { timeSlots } from "../../../../../../utils/Constant";
import {CalendarIcon} from "@heroicons/react/24/solid";
import {HiUser} from "react-icons/hi";
import PhoneInput from "react-phone-input-2";
import GreetingMsg from "../../../../components/AdmissionLanding/Shared/GreetingMsg/GreetingMsg";

const OneToOneTimeSchedule = ({courseNames}) => {
    const [value, onChange] = useState(new Date());
    const [tabSelected, setTabSelected] = useState("সকাল");
    const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
    const [anotherTime, setAnotherTime] = useState(false);
    const [isOffDay, setIsOffDay] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [userPreferenceTime, setUserPreferenceTime] = useState("");
    const [open, setOpen] = useState(false);

    const handleTabSelected = (value) => {
        setTabSelected(value);
    };

    const handleSubmit = () => {
        const preference_date = value.toISOString().split('T')[0];
        const guardian_name = name;
        const preference_time = selectedTimeSlot;
        const mobile = phone;
        const client_requirment = userPreferenceTime;
        const interested_courses = courseNames;
        const data = {
            preference_date,
            guardian_name,
            preference_time,
            mobile,
            client_requirment,
            interested_courses,
            type: "1-on-1-slot"
        }
        fetch(`${process.env.REACT_APP_BASE_URL}api/v1/registration-lead-store`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "secretKey": `${process.env.REACT_APP_API_URL_SECRET_KEY}`
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setOpen(true)
                setName("");
                setPhone("");
            })
    }

    const getSelectedTimeSlots = () => {
        switch (tabSelected) {
            case "সকাল":
                return timeSlots[0].morningTimes;
            case "দুপুর":
                return timeSlots[1].afterNoonTimes;
            case "বিকেল":
                return timeSlots[2].eveningTimes;
            case "সন্ধ্যা / রাত":
                return timeSlots[3].nightTimes;
            default:
                return [];
        }
    };

    const handleTimeSlot = (time) => {
        setSelectedTimeSlot(time);
    }

    useEffect(() => {
        async function fetchApi() {
            try {
                const formattedDate = value.toISOString().split('T')[0];
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v2/check-holiday?date=${formattedDate}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'secretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                });
                const data = await response.json();
                setIsOffDay(data.data.holiday);
            } catch (error) {
                console.error("Error fetching API:", error);
            }
        }
        fetchApi();
    }, [value]);


    return (
        <div className="tw-mt-8">
            <h2 className="tw-text-lg tw-font-bold">ওয়ান টু ওয়ান কাস্টমাইজড সময়</h2>
            <div className="tw-w-full tw-mt-4 tw-bg-white tw-rounded-3xl tw-p-6 ">
                <div className={`tw-flex ${isOffDay ? "" : "tw-justify-center"} tw-mb-8`}>
                    <div className="tw-w-[40%]">
                        <Calendar onChange={onChange} value={value} minDate={new Date()}/>
                    </div>
                    {
                        isOffDay ? (
                            <div className="tw-w-[60%] tw-flex tw-flex-col tw-justify-center tw-items-center">
                                <div>
                                    <img src={officeOff} alt="icon"/>
                                </div>
                                <div className="tw-mt-8">
                                    <h2 className="tw-text-3xl tw-font-bold tw-text-gray-900 tw-text-center">সাপ্তাহিক
                                        ছুটির দিন! </h2>
                                    <p className="tw-text-sm tw-text-gray-500 tw-text-center tw-mt-2">নির্ধারিত তারিখে
                                        কোন সময় পাওয়া যাই নি।<br/> অন্য তারিখ সিলেক্ট করো </p>
                                </div>
                            </div>
                        ) : (
                            <div className="tw-w-[60%] tw-ml-8">
                                <h2 className="tw-text-lg tw-font-bold">আপনার পছন্দের সময় বেছে নিন</h2>
                                <div className="tw-flex tw-justify-between tw-mt-6">
                                    <div
                                        onClick={() => handleTabSelected("সকাল")}
                                        className={`tw-flex tw-items-center tw-mr-4 tw-cursor-pointer ${
                                            tabSelected === "সকাল" ? "tw-border-[#FFCE39] tw-border-2 tw-p-2 tw-rounded-3xl tw-bg-[#FFFAE8]" : ""
                                        }`}
                                    >
                                        <img className="tw-mr-2" src={morning} alt="morning"/>
                                        <p className="tw-mt-1">সকাল</p>
                                    </div>
                                    <div
                                        onClick={() => handleTabSelected("দুপুর")}
                                        className={`tw-flex tw-items-center tw-mr-4 tw-cursor-pointer ${
                                            tabSelected === "দুপুর" ? "tw-border-[#FFCE39] tw-border-2 tw-p-2 tw-rounded-3xl tw-bg-[#FFFAE8]" : ""
                                        }`}
                                    >
                                        <img className="tw-mr-3" src={afternoon} alt="afternoon"/>
                                        <p className="tw-mt-0">দুপুর</p>
                                    </div>
                                    <div
                                        onClick={() => handleTabSelected("বিকেল")}
                                        className={`tw-flex tw-items-center tw-mr-4 tw-cursor-pointer ${
                                            tabSelected === "বিকেল" ? "tw-border-[#FFCE39] tw-border-2 tw-p-2 tw-rounded-3xl tw-bg-[#FFFAE8]" : ""
                                        }`}
                                    >
                                        <img className="tw-mr-2" src={evening} alt="evening"/>
                                        <p className="tw-mt-1">বিকেল</p>
                                    </div>
                                    <div
                                        onClick={() => handleTabSelected("সন্ধ্যা / রাত")}
                                        className={`tw-flex tw-items-center tw-mr-4 tw-cursor-pointer ${
                                            tabSelected === "সন্ধ্যা / রাত" ? "tw-border-[#FFCE39] tw-border-2 tw-p-2 tw-rounded-3xl tw-bg-[#FFFAE8]" : ""
                                        }`}
                                    >
                                        <img className="tw-mr-2" src={night} alt="night"/>
                                        <p className="">সন্ধ্যা / রাত</p>
                                    </div>
                                </div>
                                <div className="tw-mt-8">
                                    {getSelectedTimeSlots().map((timeSlot, idx) => (
                                        <div onClick={() => handleTimeSlot(timeSlot)} key={idx}
                                             className={`tw-inline-block tw-mr-4 ${getSelectedTimeSlots().length === idx ? "" : "tw-mb-6"}`}>
                                            <span
                                                className={`tw-p-2 ${selectedTimeSlot === timeSlot ? " tw-bg-[#532D80] tw-text-white" : " tw-border"} tw-rounded-3xl tw-cursor-pointer`}>{timeSlot}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    }
                </div>
                <hr className={`${anotherTime ? "tw-hidden" : "tw-bg-gray-400"}`}/>

                <div className="tw-w-full tw-flex tw-justify-between">
                    <div className="tw-mt-6 tw-w-1/2">
                        <label htmlFor="name"
                               className="tw-block tw-leading-6 tw-text-gray-900">
                            অভিভাবকের নাম <span className="tw-text-red-700">*</span>
                        </label>
                        <div className="tw-relative tw-mt-1">
                            <HiUser
                                className="tw-absolute tw-top-3 tw-left-3 tw-text-2xl tw-text-gray-500"/>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                autoComplete="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="অভিভাবকের নাম"
                                required
                                className="tw-pl-12 tw-block tw-w-full tw-rounded-xl tw-border-0 tw-py-3.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                            />
                        </div>
                    </div>
                    <div className="tw-mt-6 tw-ml-6 tw-w-1/2">
                        <label>
                            ফোন নাম্বার <span className="tw-text-red-700">*</span>
                        </label>
                        <div className="tw-mt-1">
                            <PhoneInput
                                country={"bd"}
                                value={phone}
                                onChange={mobile => setPhone(mobile)}
                                enableSearch={true}
                            />
                            {/*{phoneNumberError &&*/}
                            {/*    <p className="tw-mt-2 tw-text-sm tw-text-red-700">{phoneNumberError}</p>}*/}
                        </div>
                    </div>
                </div>
                <div className={`tw-flex tw-items-center tw-mt-6 ${anotherTime ? "tw-hidden" : ""}`}>
                    <div className="tw-w-[40%] tw-flex tw-items-start tw-bg-blue-50 tw-py-3 tw-px-4 tw-rounded-3xl">
                        <img className="tw-inline tw-mr-2 tw-mt-1" src={infoIcon} alt="info icon"/>
                        <span className="tw-inline tw-text-blue-800">তোমার পছন্দমত সময় বেছে নিয়ে ভর্তি হও অথবা তোমার সুবিধামত সময় বেছে নিতে অন্য সময় চাই বাটনে ক্লিক করে মেসেজ রিকোয়েস্ট করো</span>
                    </div>

                    <div className="tw-w-[60%]">
                        <div className="tw-flex tw-justify-center">
                            <button
                                onClick={() => setAnotherTime(true)}
                                className="tw-flex tw-items-center tw-border tw-border-[#532D80] tw-px-5 tw-py-3 tw-rounded-3xl">
                                <CalendarIcon className="tw-mr-2 tw-w-6 tw-h-6 tw-text-[#532D80]"/> <span
                                className="tw-text-[#532D80] tw-font-bold">অন্য সময় চাই</span></button>
                            <button
                                onClick={handleSubmit}
                                className="tw-px-16 tw-py-3 tw-bg-[#532D80] tw-rounded-3xl tw-text-white tw-font-bold tw-ml-6"> ভর্তি
                                হও
                            </button>
                        </div>
                    </div>
                </div>
                {
                    anotherTime && <div className="tw-w-full tw-mt-4">
                        <div>
                            <label htmlFor="comment"
                                   className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-500">
                                তোমার মেসেজ এইখানে লিখ
                            </label>
                            <div className="tw-mt-2">
                                <textarea
                                    id="comment"
                                    name="comment"
                                    placeholder="(যেমনঃ আমি শনিবার আর রবিবার বেছে নিতে চাই) "
                                    rows={4}
                                    onChange={(e) => setUserPreferenceTime(e.target.value)}
                                    className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-text-sm placeholder:tw-text-gray-400 placeholder:tw-text-sm tw-pl-3"
                                    defaultValue={''}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-justify-end tw-items-center tw-mt-8">
                            <div>
                                <button onClick={() => setAnotherTime(false)}
                                        className=" tw-font-bold tw-text-[#532D80] tw-px-16 tw-py-3 ">বাদ দাঁও
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={handleSubmit}
                                    className="tw-px-16 tw-py-3 tw-bg-[#532D80] tw-rounded-3xl tw-text-white tw-font-bold tw-ml-6">রিকোয়েস্ট
                                    দাঁও
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                <GreetingMsg open={open} setOpen={setOpen}/>
            }
        </div>
    );
};

export default OneToOneTimeSchedule;