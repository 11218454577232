import React from 'react';
import { dateConverterEnglish } from '../../../../../utilities/utilities';
import EditProfile from './EditProfile/EditProfile';
import './StudentInformation.css';

const StudentInformation = ({studentInfo, setRefetchData, refetchData}) => {
    /* const [studentInfo, setStudentInfo] = useState();
    const [refetchData, setRefetchData] = useState(0);
    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_BASE_URL_STUDENT}profile?user_id=${localStorage.getItem("user_id")}`)
            .then((res) => res.json())
            .then((data) => setStudentInfo(data.data));
    }, [refetchData]); */


    return (
        <>
            <div className='p-4 d-flex justify-content-between align-items-center'>
                <p className='mb-0' style={{ fontSize:"20px", fontWeight:'500' }}>Profile Update</p>
                <button className='btn-edit-profile' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"  aria-controls="offcanvasRight">Edit Profile</button>

            </div>

            <div className='total-assignment-divider'></div>
            <div className='mt-3 ps-4'>
                <table className="table table-borderless">
                    <tbody>
                        <tr>
                            <td className="info-title-area">Name</td>
                            <td>{studentInfo?.student_detail?.user_name ? studentInfo?.student_detail?.user_name : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Father Name</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.father_name ? studentInfo?.student_detail?.get_student_detail?.father_name : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Mother Name</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.mother_name ? studentInfo?.student_detail?.get_student_detail?.mother_name : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Phone Number</td>
                            <td>{studentInfo?.student_detail?.mobile_number ? studentInfo?.student_detail?.mobile_number : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Email</td>
                            <td>{studentInfo?.student_detail?.email ? studentInfo?.student_detail?.email : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Gender</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.gender ? studentInfo?.student_detail?.get_student_detail?.gender : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Guardian Mobile Number</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.guardian_mobile_number ? studentInfo?.student_detail?.get_student_detail?.guardian_mobile_number : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">WhatsApp Number</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.whats_app_number ? studentInfo?.student_detail?.get_student_detail?.whats_app_number : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Date Of Birth</td>
                            <td>{ studentInfo?.student_detail?.get_student_detail?.date_of_birth ? dateConverterEnglish(studentInfo?.student_detail?.get_student_detail?.date_of_birth) : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Religion</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.religion ? studentInfo?.student_detail?.get_student_detail?.religion : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Location</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.district_en_name ? `${studentInfo?.student_detail?.get_student_detail?.district_en_name}-${studentInfo?.student_detail?.get_student_detail?.district_bn_name}` : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">School</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.school ? studentInfo?.student_detail?.get_student_detail?.school : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Medium</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.medium ? studentInfo?.student_detail?.get_student_detail?.medium : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Class</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.class ? studentInfo?.student_detail?.get_student_detail?.class : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Present Address</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.full_address ? studentInfo?.student_detail?.get_student_detail?.full_address : 'N/A'}</td>
                        </tr>
                        <tr>
                            <td className="info-title-area">Currier Address</td>
                            <td>{studentInfo?.student_detail?.get_student_detail?.mailing_address ? studentInfo?.student_detail?.get_student_detail?.mailing_address : 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>



            <EditProfile studentInfo={studentInfo} setRefetchData= {setRefetchData} refetchData={refetchData}/>

        </>
    );
};

export default StudentInformation;