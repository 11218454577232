import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./StudentFeedback.css";
import iconImg from "../../../../images/feedback-icon.png";
import bnLogo from "../../../../images/HomePageImage/bn_logo.svg";
import ukLogo from "../../../../images/HomePageImage/UK.png";
const StudentFeedback = ({
  pendingFeedbackData,
  showModal,
  feedbackQuestion,
  feedbackDataDetails,
}) => {
  const userId = localStorage.getItem("user_id");
  const auth_token = localStorage.getItem("auth_token");
  const [data, setData] = useState({
    live_class_id: pendingFeedbackData?.live_class_id,
    attendance_id: pendingFeedbackData?.attendance_id,
    user_id: userId,
    is_class_good: 0,
    teacher_camera_on_whole_class: 0,
    teacher_talk_with_you: 0,
    teacher_give_hw_mark: 0,
    teacher_take_fulltime_class: 0,
    feedback_comment: "",
  });

  const [languageContent, setLanguageContent] = useState({
    topTitle: "ক্লাসের সম্পর্কে তোমার মতামত দাও!",
    topText:
      "আজকের ক্লাস কেমন লাগলো তোমার? জানিয়ে দাও এখনই! যাতে আমরা পরবর্তী ক্লাস আরো ভালো করতে পারি।",
    courseLabel: "কোর্সঃ",
    batchLabel: "ব্যাচঃ",
    subjectLabel: "বিষয়ঃ",
    commentBox: "আরো কিছু বলতে চাও?",
    submitBtn: "সাবমিট করো",
    questionOne: feedbackQuestion?.[0]?.question,
    questionTwo: feedbackQuestion?.[1]?.question,
    questionThree: feedbackQuestion?.[2]?.question,
    questionFour: feedbackQuestion?.[3]?.question,
    questionFive: feedbackQuestion?.[4]?.question,
    trueAnswerOption: "হ্যাঁ",
    falseAnswerOption: "না",
  });
  const [language, setLanguage] = useState("bn");
  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/student/v2/feedback-save/${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${auth_token}`,
            SecretKey: process.env.REACT_APP_API_URL_SECRET_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const apiResponse = await response.json();
      if (apiResponse.success === true) {
        window.location.reload();
      }
    } catch (error) {
      // console.error("Error fetching feedback data:", error);
    }
  };

  const languageToggle = async (e) => {
    if (language === "bn") {
      setLanguage("en");
      setLanguageContent({
        topTitle:
          "How was your experience in the class? Share your thoughts about it!",
        topText:
          "Your feedback will help us improve and make learning even more enjoyable at Mojaru. 😊",
        courseLabel: "Course:",
        batchLabel: "Batch:",
        subjectLabel: "Subject:",
        commentBox: "Want to say something more?",
        submitBtn: "Submit",
        questionOne: feedbackDataDetails?.feedback_questions_en?.[0]?.question,
        questionTwo: feedbackDataDetails?.feedback_questions_en?.[1]?.question,
        questionThree:
          feedbackDataDetails?.feedback_questions_en?.[2]?.question,
        questionFour: feedbackDataDetails?.feedback_questions_en?.[3]?.question,
        questionFive: feedbackDataDetails?.feedback_questions_en?.[4]?.question,
        trueAnswerOption: "Yes",
        falseAnswerOption: "No",
      });
    } else {
      setLanguage("bn");
      setLanguageContent({
        topTitle: "ক্লাসের সম্পর্কে তোমার মতামত দাও!",
        topText:
          "আজকের ক্লাস কেমন লাগলো তোমার? জানিয়ে দাও এখনই! যাতে আমরা পরবর্তী ক্লাস আরো ভালো করতে পারি।",
        courseLabel: "কোর্সঃ",
        batchLabel: "ব্যাচঃ",
        subjectLabel: "বিষয়ঃ",
        commentBox: "আরো কিছু বলতে চাও?",
        submitBtn: "সাবমিট করো",
        questionOne: feedbackQuestion?.[0]?.question,
        questionTwo: feedbackQuestion?.[1]?.question,
        questionThree: feedbackQuestion?.[2]?.question,
        questionFour: feedbackQuestion?.[3]?.question,
        questionFive: feedbackQuestion?.[4]?.question,
        trueAnswerOption: "হ্যাঁ",
        falseAnswerOption: "না",
      });
    }
  };
  return (
    <Modal className="tw-top-[10px] tw-left-[100]" show={showModal}>
      <Modal.Body className="tw-p-1 md:tw-p-1 lg:tw-p-[24px] tw-gap-[24px] tw-w-full">
        <div className="container">
          <div className="row">
            <div className="col-12 tw-my-1">
              <div className="tw-flex tw-justify-start lg:tw-justify-end xl:tw-justify-end">
                <button
                  onClick={languageToggle}
                  className="tw-flex tw-items-center tw-bg-gray-50 tw-rounded-3xl tw-px-4 tw-py-2 tw-shadow-md tw-shadow-gray-600"
                >
                  <img
                    className="tw-mr-2"
                    src={language === "bn" ? bnLogo : ukLogo}
                    alt="language logo"
                  />
                  <span className="tw-text-gray-600 tw-font-semibold">
                    {language === "bn" ? <>BN</> : <>EN</>}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 tw-p-[24px] tw-mb-5">
              <div className="d-flex tw-gap-[8px]">
                <img
                  src={iconImg}
                  alt="icon"
                  className="topImgIcon tw-w-[48px] tw-h-[48px]"
                />
                <div>
                  <h6 className="tw-mb-6 tw-text-[18px] tw-leading-[24px] tw-font-[600] tw-text-[#1F2937] tw-w-[403px] tw-h-[24px] topTitle">
                    {languageContent?.topTitle || ""}
                  </h6>
                  <p className="tw-w-[374px] tw-h-[39px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-text-[#6B7280] topDescription">
                    {languageContent?.topText || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="tw-card tw-w-3/4 md:tw-w-3/4 lg:tw-w-full tw-rounded-md tw-bg-gray-100 tw-p-2">
                <p className="tw-w-[300px] lg:tw-w-[374px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-text-[#6B7280]">
                  <span className="tw-font-[700] tw-mx-2">
                    {languageContent?.courseLabel || "কোর্সঃ"}
                  </span>{" "}
                  {pendingFeedbackData?.course_name} <br />
                  <span className="tw-font-[700] tw-mx-2">
                    {languageContent?.batchLabel || "ব্যাচঃ"}
                  </span>{" "}
                  {pendingFeedbackData?.batch_name} <br />
                  <span className="tw-font-[700] tw-mx-2">
                    {languageContent?.subjectLabel || "বিষয়ঃ"}
                  </span>{" "}
                  {pendingFeedbackData?.subject_name}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 tw-p-[24px]">
          <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px]">
                <div>
                  <p className="tw-h-[21px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-mb-[22px]">
                    {languageContent?.questionOne || ""}
                    <br />
                  </p>
                  <div className="tw-flex">
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackYes"
                        name="is_class_good"
                        value={1}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        {languageContent?.trueAnswerOption || "হ্যাঁ"}
                      </label>
                    </div>
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackNo"
                        name="is_class_good"
                        value={0}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        {languageContent?.falseAnswerOption || "না"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-p-[24px]">
                <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px]">
                <div>
                  <p className="tw-h-[21px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-mb-[22px]">
                    {languageContent?.questionTwo || ""}
                    <br />
                  </p>
                  <div className="tw-flex">
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackYes"
                        name="teacher_camera_on_whole_class"
                        value={1}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        {languageContent?.trueAnswerOption || "হ্যাঁ"}
                      </label>
                    </div>
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackNo"
                        name="teacher_camera_on_whole_class"
                        value={0}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        {languageContent?.falseAnswerOption || "না"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-p-[24px]">
                <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px]">
                <div>
                  <p className="tw-h-[21px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-mb-[22px]">
                    {languageContent?.questionThree || ""}
                    <br />
                  </p>
                  <div className="tw-flex">
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackYes"
                        name="teacher_talk_with_you"
                        value={1}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        {languageContent?.trueAnswerOption || "হ্যাঁ"}
                      </label>
                    </div>
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackNo"
                        name="teacher_talk_with_you"
                        value={0}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        {languageContent?.falseAnswerOption || "না"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-p-[24px]">
                <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px]">
                <div>
                  <p className="tw-h-[21px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-mb-[22px]">
                    {languageContent?.questionFour || ""}
                    <br />
                  </p>
                  <div className="tw-flex">
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackYes"
                        name="teacher_give_hw_mark"
                        value={1}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        {languageContent?.trueAnswerOption || "হ্যাঁ"}
                      </label>
                    </div>
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackNo"
                        name="teacher_give_hw_mark"
                        value={0}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        {languageContent?.falseAnswerOption || "না"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-p-[24px]">
                <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px] tw-pb-[15px]">
                <div>
                  <p className="tw-h-[21px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-mb-9">
                    {languageContent?.questionFive || ""}
                  </p>
                  <div className="tw-flex">
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackYes"
                        name="teacher_take_fulltime_class"
                        value={1}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        {languageContent?.trueAnswerOption || "হ্যাঁ"}
                      </label>
                    </div>
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackNo"
                        name="teacher_take_fulltime_class"
                        value={0}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        {languageContent?.falseAnswerOption || "না"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-p-[24px]">
                <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px]">
                <div className="tw-mb-3 tw-w-3/4 lg:tw-w-full">
                  <textarea
                    className="form-control tw-font-[400] tw-placeholder-opacity-15 tw-text-sm"
                    name="feedback_comment"
                    id="feedback_comment"
                    placeholder={
                      languageContent?.commentBox || "আরো কিছু বলতে চাও?"
                    }
                    onChange={handleChange}
                    value={data.feedback_comment}
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-relative lg:tw-flex lg:tw-justify-center lg:tw-items-center">
                <button
                  type="submit"
                  className=" tw-bg-[#532D80] tw-w-3/4 lg:tw-w-[425px] tw-h-[38px] tw-px-[4px] lg:tw-px-[17px] tw-py-[9px] tw-rounded-[6px] submitButton tw-mt-8"
                >
                  <span className="tw-text-white">
                    {languageContent?.submitBtn || "সাবমিট করো"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default StudentFeedback;
