import React, {useState} from 'react';
import './AssignmentQuestionShow.css';
/* import questionClock from '../../../../../../images/question-time-clock-fill.png' */
import questionClockGray from '../../../../../../images/due-clock-fill_gray.png'
import questionCircle from '../../../../../../images/question-circle-fill.png'
import {useEffect} from 'react';
import MultipleChoice from './MultipleChoice/MultipleChoice';
import MultipleAnswer from './MultipleAnswer/MultipleAnswer';
import TrueFalse from './TrueFalse/TrueFalse';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useLocation, useNavigate} from 'react-router-dom';
import BroadQuestion from './BroadQuestion/BroadQuestion';
import FileSubmission from './FileSubmission/FileSubmission';
import StudentPageTitle from '../../../../shared/StudentPageTitle/StudentPageTitle';
import {FaCalendarAlt} from "react-icons/fa";
import certificateIcon from "../../../../../../images/course_play_list/certificate-icon 1.png";
import CoursePlaylist from "../../Syllabus/CoursePlaylist/CoursePlaylist";
import Dashboard from "../../../../Dashboard/Dashboard";
import {englishToBengaliNumberFormat} from "../../../../../../utilities/utilities";


const AssignmentQuestionShow = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [initialSclice, setInitialSclice] = useState(true);
    const [startSlice, setStartSlice] = useState(0);
    const [endSlice, setEndSlice] = useState(1);
    const [stopEndSlice, setStopEndSlice] = useState(true);
    let [totalAnswer, setTotalAnswer] = useState([]);
    const [show, setShow] = useState(false);
    const [showAttemptOver, setShowAttemptOver] = useState(false);
    const [startTime, setStartTime] = useState(true);

    const assignment_id = location.state.assignment_id;
    let lessons = location?.state?.lessons;
    let topic = location?.state?.topic;
    let lesion_name = location?.state?.lesion_name;
    let course_id = location?.state?.course_id;
    let batch_id = location?.state?.batch_id;
    let index = location?.state?.index;
    let progressPercent = location?.state?.progressPercent;

    useEffect(() => {
        async function fetchQuestionApi() {
            let response = await fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}assignment-questions/v2?assignment_id=${assignment_id}&user_id=${localStorage.getItem("user_id")}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
                    'SecretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                },
            })
            response = await response.json();
            setQuestions(response.data);
            if (response.data.attempt_over === true) {
                setShowAttemptOver(true);
            }
        }

        fetchQuestionApi();

        const handleTabClose = event => {
            event.preventDefault();
            return (event.returnValue = 'Are you sure you want to exit?');
        };

        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };

    }, [assignment_id]);


    /* Assignment timer */
    if (questions?.assignment_duration && startTime) {
        setStartTime(false);
        let sec;
        if (questions?.remaining_time_in_sec !== null) {
            sec = questions?.remaining_time_in_sec;
        } else {
            sec = questions?.assignment_duration_in_sec - 2;
        }

        //let sec = 10;

        let countDiv = document.getElementById("remain-time");
        let countDown = setInterval(function () {
            perSecond();
        }, 1000);

        function perSecond() {
            let min = Math.floor(sec / 60),
                remSec = sec % 60;
            if (remSec < 10) {
                remSec = '0' + remSec;
            }
            if (min < 10) {
                min = '0' + min;
            }
            countDiv.innerHTML = min + ":" + remSec;

            if (sec > 0) {
                sec = sec - 1;
            } else {
                setShow(true);
                clearInterval(countDown);

                countDiv.innerHTML = '00:00';

            }
        }

    }

    let answer; //get answer from student variable


    if (questions?.already_submitted_questions?.length > 0 && initialSclice) {
        setStartSlice(questions?.already_submitted_questions?.length);
        setEndSlice(questions?.already_submitted_questions?.length + 1);
        setInitialSclice(false);
    }

    //progress bar 
    function progressBar(endSlice) {
        let element = document.getElementById("myprogressBar");
        if (questions?.questions?.length) {
            let complete = (100 * endSlice) / questions?.questions?.length;
            element.style.width = complete + '%';
        }
    }


    /*    let answer; //get answer from student variable */

    /* show question one by one */
    const handelSlice = () => {
        setStartSlice(startSlice + 1); // +1 for index to serial
        setEndSlice(endSlice + 1);
        if (totalAnswer?.length === 0)
            totalAnswer.push(answer);


        const totalAnswers = {
            attempt_id: questions?.attempt_id,
            assignment_id: assignment_id,
            question_answer: totalAnswer,
            is_running: endSlice + 1 > questions?.questions?.length ? '0' : 1
        }

        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}save-assignment/v2`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(totalAnswers),
        })
            .then(res => res.json())
            .then(data => {
                if (endSlice + 1 > questions?.questions?.length) {
                    if (questions?.result_display === 2) {
                        navigate("/submit-assignment", {
                            state: {
                                assignment_name: questions?.assignment_name,
                                course_id: questions?.course_id,
                                batch_id: questions?.batch_id
                            }
                        })
                    } else if (questions?.result_display === 3) {
                        let wrong_answer = 0;
                        let correct_answer = 0;
                        let get_marks = 0;
                        let wrong_question_marks = 0;
                        let question_marks;
                        let arr = [];

                        data?.data?.questions?.forEach(question => {
                            let initial_wrong_answer = 0;
                            question?.options.forEach(option => {
                                let initial_right_answer = 0;
                                data?.data?.already_submitted_questions?.forEach(answer => {
                                    if (parseInt(answer.answer_id) === option.id && answer.question_id === option.question_id) {
                                        initial_right_answer = 1;
                                        if (option.is_correct === 1) {
                                            return;
                                        } else {
                                            initial_wrong_answer = 1;
                                            return;
                                        }
                                    }
                                });
                                if ((initial_right_answer === 0) && (option.is_correct === 1)) {
                                    initial_wrong_answer = 1;
                                }
                            });
                            if (initial_wrong_answer === 1) {
                                wrong_answer++;
                                wrong_question_marks = wrong_question_marks + parseInt(question.marks);
                                question_marks = {
                                    "question_id": question.id,
                                    "marks": 0
                                };
                                arr.push(question_marks);
                            } else {
                                correct_answer++;
                                get_marks = get_marks + parseInt(question.marks);
                                question_marks = {
                                    "question_id": question.id,
                                    "marks": question.marks
                                };
                                arr.push(question_marks);
                            }
                        });
                        const student_marks_detail = {
                            attempt_id: questions?.attempt_id,
                            obtained_marks: get_marks,
                            question_obtained_marks: arr
                        }

                        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}save-assignment-marking`, {
                            method: 'POST',
                            headers: {
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify(student_marks_detail),
                        })
                            .then(res => res.json())
                            .then(data => console.log(''))

                        navigate("/submit-assignment-with-marks", {
                            state: {
                                assignment_name: questions?.assignment_name,
                                wrong_answer: wrong_answer,
                                correct_answer: correct_answer,
                                get_marks: get_marks,
                                total_marks: get_marks + wrong_question_marks,
                                course_id: questions?.course_id,
                                batch_id: questions?.batch_id,
                                lessons: lessons,
                                topic: topic,
                                index: index,
                                lesion_name: lesion_name,
                                progressPercent: progressPercent,
                            }
                        })
                    } else {
                        navigate("/submit-assignment", {
                            state: {
                                assignment_name: questions?.assignment_name,
                                course_id: questions?.course_id,
                                batch_id: questions?.batch_id
                            }
                        })
                    }
                }
            })

        answer = ''
        setTotalAnswer([]);
        progressBar(endSlice);

    }

    // initial when same attempt rejoin
    if (endSlice > 1 && stopEndSlice && questions?.questions?.length) {
        progressBar(endSlice - 1);
        setStopEndSlice(false);

    }

    /* get answer from MCQ and TF */
    const handelRadioButton = (answer_id, question_id) => {
        answer = {
            "question_id": question_id,
            "answer_id": answer_id
        };
    }
    /* get answer from broad */
    const handelBroadQuestion = (broad_ans, question_id) => {
        answer = {
            "question_id": question_id,
            "broad_ans": broad_ans,
            "answer_id": 0
        };
    }
    /* get answer from filesubmission */
    const handelFileSubmission = (answerImage, question_id) => {
        answer = {
            "question_id": question_id,
            "files": answerImage,
            "answer_id": 0
        };
    }

    /* get answer from MA*/
    const handelCheckBox = (answer_id, question_id, addOrRemove) => {
        if (addOrRemove === 1) {
            if (totalAnswer[0]?.answer_id == null)
                totalAnswer = [];
            answer = {
                "question_id": question_id,
                "answer_id": answer_id
            };
            if (answer) {
                totalAnswer.push(answer);
            }
            answer = ''
        } else if (addOrRemove === 2) {
            setTotalAnswer(totalAnswer.filter((item) => item.answer_id !== answer_id));
            if (totalAnswer.filter((item) => item.answer_id !== answer_id).length === 0) {
                answer = {
                    "question_id": question_id,
                    "answer_id": null
                };
            }
        } else {
            answer = {
                "question_id": question_id,
                "answer_id": answer_id
            };
        }
    }


    /* save assignment time out*/
    const handelSubmitAssignment = () => {

        if (answer !== undefined)
            totalAnswer.push(answer);


        const totalAnswers = {
            attempt_id: questions?.attempt_id,
            assignment_id: assignment_id,
            question_answer: totalAnswer,
            is_running: '0'
        }


        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}save-assignment/v2`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(totalAnswers),
        })
            .then(res => res.json())
            .then(data => {
                    if (questions?.result_display === 2) {
                        navigate("/submit-assignment", {
                            state: {
                                assignment_name: questions?.assignment_name,
                                course_id: questions?.course_id,
                                batch_id: questions?.batch_id
                            }
                        })
                    } else if (questions?.result_display === 3) {
                        let wrong_answer = 0;
                        let correct_answer = 0;
                        let get_marks = 0;
                        let wrong_question_marks = 0;
                        let question_marks;
                        let arr = [];

                        data?.data?.questions?.forEach(question => {
                            let initial_wrong_answer = 0;
                            question?.options.forEach(option => {
                                let initial_right_answer = 0;
                                data?.data?.already_submitted_questions?.forEach(answer => {
                                    if (parseInt(answer.answer_id) === option.id && answer.question_id === option.question_id) {
                                        initial_right_answer = 1;
                                        if (option.is_correct === 1) {
                                            return;
                                        } else {
                                            initial_wrong_answer = 1;
                                            return;
                                        }
                                    }
                                });
                                if ((initial_right_answer === 0) && (option.is_correct === 1)) {
                                    initial_wrong_answer = 1;
                                }
                            });
                            if (initial_wrong_answer === 1) {
                                wrong_answer++;
                                wrong_question_marks = wrong_question_marks + parseInt(question.marks);
                                question_marks = {
                                    "question_id": question.id,
                                    "marks": 0
                                };
                                arr.push(question_marks);
                            } else {
                                correct_answer++;
                                get_marks = get_marks + parseInt(question.marks);
                                question_marks = {
                                    "question_id": question.id,
                                    "marks": question.marks
                                };
                                arr.push(question_marks);
                            }
                        });
                        const student_marks_detail = {
                            attempt_id: questions?.attempt_id,
                            obtained_marks: get_marks,
                            question_obtained_marks: arr
                        }

                        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}save-assignment-marking`, {
                            method: 'POST',
                            headers: {
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify(student_marks_detail),
                        })
                            .then(res => res.json())
                            .then(data => console.log(''))

                        navigate("/submit-assignment-with-marks", {
                            state: {
                                assignment_name: questions?.assignment_name,
                                wrong_answer: wrong_answer,
                                correct_answer: correct_answer,
                                get_marks: get_marks,
                                total_marks: get_marks + wrong_question_marks,
                                course_id: questions?.course_id,
                                batch_id: questions?.batch_id

                            }
                        })
                    } else {
                        navigate("/submit-assignment", {
                            state: {
                                assignment_name: questions?.assignment_name,
                                course_id: questions?.course_id,
                                batch_id: questions?.batch_id
                            }
                        })
                    }
                }
            )
    }

    const handelAttemptOver = () => {
        navigate("/my-course/lms", {
            state: {
                course_id: questions?.course_id,
                batch_id: questions?.batch_id,
                class_content: 'assignment'
            },
        });
    }

    /*     const handleShowModal = () => setShowModal(true);
        const handleCloseModal = () => setShowModal(false); */


    return (
        <>
            {
                lessons ? (
                    <div>
                        <div>
                            <div className="main-content">
                                <StudentPageTitle title="View Smart Note"></StudentPageTitle>
                                <div className="page-content p-0">
                                    <div className="container-fluid">
                                        <div className="my-course-section">

                                            <h2 className="tw-px-16 tw-mt-8 tw-mb-8">ক্লাস : {lesion_name}</h2>
                                            <div className="md:tw-flex md:tw-justify-between tw-px-16">
                                                <div className="tw-flex">
                                                    {/*<img className="tw-w-10 tw-h-10 tw-mr-4 tw-rounded-lg"*/}
                                                    {/*     src={`${lectureDetail?.video_lecture?.teacher_image_path}${lectureDetail?.video_lecture?.teacher_image}`}*/}
                                                    {/*     alt="profile image"/>*/}
                                                    <div>
                                                        <p className="tw-text-[#6B7280] tw-text-sm">Published by</p>
                                                        {/*<p className="tw-text-gray-700">{lectureDetail?.video_lecture?.teacher_name}</p>*/}
                                                    </div>
                                                </div>
                                                <div className="tw-flex">
                                                    <FaCalendarAlt className="tw-text-4xl tw-mr-4 tw-text-gray-500"/>
                                                    <div>
                                                        <p className="tw-text-[#6B7280] tw-text-sm">Published on</p>
                                                        <p className="tw-text-gray-700">২১ এপ্রিল, ২০২৩</p>
                                                    </div>
                                                </div>
                                                <div className="tw-flex tw-bg-white tw-px-5 tw-py-3 tw-rounded-lg">
                                                    <div
                                                        className="tw-mr-4 tw-border-r-2 tw-border-gray-300 tw-border-sm tw-px-7">
                                                        <p className="tw-text-2xl tw-font-semibold">কোর্স প্রোগ্রেস {englishToBengaliNumberFormat(index+1)}/ {englishToBengaliNumberFormat(lessons.length)}
                                                        </p>
                                                        <div
                                                            className="tw-w-[200px] tw-bg-gray-200 tw-rounded-full h-4 tw-text-white tw-text-sm tw-text-end">
                                                            <div
                                                                className="tw-bg-green-400 tw-h-4 tw-rounded-full"
                                                                style={{width: `${progressPercent}%`}}
                                                            >{progressPercent}%
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img className="tw-w-10 tw-h-10" src={certificateIcon} alt="image"/>
                                                </div>
                                            </div>


                                            <div className="md:tw-flex tw-justify-between tw-p-10">
                                                <div className="tw-w-[65%]">
                                                    {/* <iframe className='lecture-video-section' src="https://www.youtube.com/embed/eI4an8aSsgw" allowFullScreen></iframe> */}
                                                    <div
                                                        className='d-flex justify-content-center align-items-center p-4'>
                                                        <StudentPageTitle
                                                            title="Assignment Question"></StudentPageTitle>
                                                        {/* <div className='btn-assignment-submit-and-close'>
                                                                <button type="button" className="btn-close" aria-label="Close"></button>
                                                            </div> */}
                                                        <p className='text-center mb-0 show-assignment-name'>{questions?.assignment_name}</p>
                                                        {/* <div></div> */}
                                                    </div>
                                                    <div className='total-assignment-divider'></div>


                                                    <div className='container mt-3'>

                                                        <div className='d-flex justify-content-between mb-3'>
                                                            <span className='question-count px-2 py-1 d-flex align-items-center' style={{width: '16rem'}}>
                                                                <div><img src={questionClockGray} className='img-fluid' alt=""/></div>
                                                                <p className='remain-time ms-1 mb-0'>Assignment Timer <span
                                                                    style={{fontSize: '22px', fontWeight: '600'}}
                                                                    id='remain-time'>{questions?.assignment_duration}:00</span></p>
                                                            </span>
                                                            <span
                                                                className='question-count px-2 py-1 d-flex align-items-center'
                                                                style={{width: '10rem'}}>
                                                                <div><img src={questionCircle} className='img-fluid' alt=""/></div>
                                                                {/* <p className='remain-question ms-1 mb-0'>{endSlice > questions?.questions?.length ?  questions?.questions?.length : endSlice}/{questions?.questions?.length}</p> */}
                                                                <p className='remain-question ms-1 mb-0'><span style={{
                                                                    fontSize: '22px',
                                                                    fontWeight: '600'
                                                                }}>{questions?.questions?.length}</span> Questions</p>
                                                            </span>
                                                        </div>

                                                        <div className="progress mt-1 mb-3"
                                                             style={{borderRadius: '15px'}}>
                                                            <div className="progress-bar" role="progressbar"
                                                                 id="myprogressBar"
                                                                 style={{
                                                                     width: '0%',
                                                                     borderRadius: '15px',
                                                                     backgroundColor: '#46C35F'
                                                                 }}
                                                                 aria-valuenow="5" aria-valuemin="0"
                                                                 aria-valuemax="100"></div>
                                                        </div>
                                                        <form>
                                                            {
                                                                questions?.questions?.slice(startSlice, endSlice).map((question, index) =>
                                                                    (
                                                                        question?.question_type_id === 1 ?
                                                                            <MultipleChoice question={question}
                                                                                            key={question?.id}
                                                                                            handelRadioButton={handelRadioButton}
                                                                                            endSlice={endSlice}/> :

                                                                            question?.question_type_id === 2 ?
                                                                                <MultipleAnswer question={question}
                                                                                                key={question?.id}
                                                                                                handelCheckBox={handelCheckBox}
                                                                                                endSlice={endSlice}/> :

                                                                                question?.question_type_id === 3 ?
                                                                                    <TrueFalse question={question}
                                                                                               key={question?.id}
                                                                                               handelRadioButton={handelRadioButton}
                                                                                               endSlice={endSlice}/> :

                                                                                    question?.question_type_id === 4 ?
                                                                                        <BroadQuestion
                                                                                            question={question}
                                                                                            key={question?.id}
                                                                                            handelBroadQuestion={handelBroadQuestion}
                                                                                            endSlice={endSlice}/> :

                                                                                        <FileSubmission
                                                                                            question={question}
                                                                                            key={question?.id}
                                                                                            handelFileSubmission={handelFileSubmission}
                                                                                            endSlice={endSlice}/>
                                                                    )
                                                                )
                                                            }
                                                        </form>


                                                        {/* time out modal */}
                                                        <Modal show={show} backdrop="static" keyboard={false} centered>
                                                            <Modal.Header style={{border: 'none'}}>
                                                                <Modal.Title>সময় শেষ</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                তোমার পরীক্ষার সময় শেষ। তোমার উত্তরপত্র জমা দাও।
                                                            </Modal.Body>
                                                            <Modal.Footer style={{border: 'none'}}>
                                                                <Button
                                                                    variant="mb-0 btn-next-question text-center text-white p-2"
                                                                    style={{backgroundColor: '#532d80'}}
                                                                    onClick={handelSubmitAssignment}>জমা
                                                                    দাও</Button>
                                                            </Modal.Footer>
                                                        </Modal>

                                                        {/* attempt over modal */}
                                                        <Modal show={showAttemptOver} backdrop="static" keyboard={false}
                                                               centered>
                                                            <Modal.Header style={{border: 'none'}}>
                                                                <Modal.Title>সাবমিশন</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                তোমার সাবমিশন শেষ। এসাইনমেন্ট লিস্টে যাও।
                                                            </Modal.Body>
                                                            <Modal.Footer style={{border: 'none'}}>
                                                                <Button
                                                                    variant="mb-0 btn-next-question text-center text-white p-2"
                                                                    style={{backgroundColor: '#532d80'}}
                                                                    onClick={handelAttemptOver}>ঠিক
                                                                    আছে</Button>
                                                            </Modal.Footer>
                                                        </Modal>

                                                        <div className="">
                                                            <div className=''></div>
                                                            <div
                                                                className='container pt-2 pb-4 d-flex justify-content-between bg-white'>
                                                                <span></span>

                                                                <span className=' btn-next-question text-center'
                                                                      style={{cursor: 'pointer'}}
                                                                      onClick={handelSlice}>{endSlice + 1 > questions?.questions?.length ? 'জমা দাও' : 'পরবর্তী'}</span>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="md:tw-w-[30%]">
                                                    <CoursePlaylist lessons={lessons} topic={topic} courseId={course_id}
                                                                    batchId={batch_id} index={index}/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='d-flex justify-content-center align-items-center p-4'>
                            <StudentPageTitle title="Assignment Question"></StudentPageTitle>
                            {/* <div className='btn-assignment-submit-and-close'>
                    <button type="button" className="btn-close" aria-label="Close"></button>
                </div> */}
                            <p className='text-center mb-0 show-assignment-name'>{questions?.assignment_name}</p>
                            {/* <div></div> */}
                        </div>
                        <div className='total-assignment-divider'></div>


                        <div className='container mt-3'>

                            <div className='d-flex justify-content-between mb-3'>
                    <span className='question-count px-2 py-1 d-flex align-items-center' style={{width: '16rem'}}>
                        <div><img src={questionClockGray} className='img-fluid' alt=""/></div>
                        <p className='remain-time ms-1 mb-0'>Assignment Timer <span
                            style={{fontSize: '22px', fontWeight: '600'}}
                            id='remain-time'>{questions?.assignment_duration}:00</span></p>
                    </span>
                                <span className='question-count px-2 py-1 d-flex align-items-center'
                                      style={{width: '10rem'}}>
                        <div><img src={questionCircle} className='img-fluid' alt=""/></div>
                                    {/* <p className='remain-question ms-1 mb-0'>{endSlice > questions?.questions?.length ?  questions?.questions?.length : endSlice}/{questions?.questions?.length}</p> */}
                                    <p className='remain-question ms-1 mb-0'><span style={{
                                        fontSize: '22px',
                                        fontWeight: '600'
                                    }}>{questions?.questions?.length}</span> Questions</p>
                    </span>
                            </div>

                            <div className="progress mt-1 mb-3" style={{borderRadius: '15px'}}>
                                <div className="progress-bar" role="progressbar" id="myprogressBar"
                                     style={{width: '0%', borderRadius: '15px', backgroundColor: '#46C35F'}}
                                     aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <form>
                                {
                                    questions?.questions?.slice(startSlice, endSlice).map((question, index) =>
                                        (
                                            question?.question_type_id === 1 ?
                                                <MultipleChoice question={question} key={question?.id}
                                                                handelRadioButton={handelRadioButton}
                                                                endSlice={endSlice}/> :

                                                question?.question_type_id === 2 ?
                                                    <MultipleAnswer question={question} key={question?.id}
                                                                    handelCheckBox={handelCheckBox}
                                                                    endSlice={endSlice}/> :

                                                    question?.question_type_id === 3 ?
                                                        <TrueFalse question={question} key={question?.id}
                                                                   handelRadioButton={handelRadioButton}
                                                                   endSlice={endSlice}/> :

                                                        question?.question_type_id === 4 ?
                                                            <BroadQuestion question={question} key={question?.id}
                                                                           handelBroadQuestion={handelBroadQuestion}
                                                                           endSlice={endSlice}/> :

                                                            <FileSubmission question={question} key={question?.id}
                                                                            handelFileSubmission={handelFileSubmission}
                                                                            endSlice={endSlice}/>
                                        )
                                    )
                                }
                            </form>


                            {/* time out modal */}
                            <Modal show={show} backdrop="static" keyboard={false} centered>
                                <Modal.Header style={{border: 'none'}}>
                                    <Modal.Title>সময় শেষ</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    তোমার পরীক্ষার সময় শেষ। তোমার উত্তরপত্র জমা দাও।
                                </Modal.Body>
                                <Modal.Footer style={{border: 'none'}}>
                                    <Button variant="mb-0 btn-next-question text-center text-white p-2"
                                            style={{backgroundColor: '#532d80'}} onClick={handelSubmitAssignment}>জমা
                                        দাও</Button>
                                </Modal.Footer>
                            </Modal>

                            {/* attempt over modal */}
                            <Modal show={showAttemptOver} backdrop="static" keyboard={false} centered>
                                <Modal.Header style={{border: 'none'}}>
                                    <Modal.Title>সাবমিশন</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    তোমার সাবমিশন শেষ। এসাইনমেন্ট লিস্টে যাও।
                                </Modal.Body>
                                <Modal.Footer style={{border: 'none'}}>
                                    <Button variant="mb-0 btn-next-question text-center text-white p-2"
                                            style={{backgroundColor: '#532d80'}} onClick={handelAttemptOver}>ঠিক
                                        আছে</Button>
                                </Modal.Footer>
                            </Modal>

                            <div className="fixed-bottom">
                                <div className='total-assignment-divider'></div>
                                <div className='container pt-2 pb-4 d-flex justify-content-between bg-white'>
                                    <span></span>

                                    <span className='mb-0 btn-next-question text-center' style={{cursor: 'pointer'}}
                                          onClick={handelSlice}>{endSlice + 1 > questions?.questions?.length ? 'জমা দাও' : 'পরবর্তী'}</span>

                                </div>
                            </div>


                        </div>
                    </div>
                )
            }
        </>
    );
};

export default AssignmentQuestionShow;