import React, { useState, useEffect } from "react";
import logo from "../../../../../images/logo.png";
import success from "../../../../../images/eft/success.png";
import registred from "../../../../../images/eft/registered.png";
import banner from "../../../../../images/eft/EFTWebbanner.png";
import { Link } from "react-router-dom";

const Eft = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [childAge, setChildAge] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [childAgeError, setChildAgeError] = useState("");
  const [timePreference, setTimePreference] = useState("");
  const [wlcMgs, setWlcMgs] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [timeSlot, setTimeSlot] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [name, setName] = useState("");
  const [className, setClassName] = useState("");
  const [medium, setMedium] = useState("");

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
  };

  const handleChildAgeChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value !== "") {
      setChildAge(value);
      setChildAgeError("");
    } else {
      setChildAgeError("বয়স অবশ্যই সংখ্যা হতে হবে");
    }
  };

  const handleTimePreferenceChange = (time) => {
    setTimePreference(time);
  };

  const validatePhoneNumber = (number) => {
    const phoneNumberPattern = /^(01[3-9]\d{8})$/;
    if (phoneNumberPattern.test(number)) {
      setPhoneNumberError("");
      return true;
    } else {
      setPhoneNumberError(
        "Invalid phone number! Please enter a valid phone number."
      );
      return false;
    }
  };

  useEffect(() => {
    if (
      phoneNumber &&
      childAge &&
      timePreference &&
      name &&
      className &&
      medium
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [phoneNumber, phoneNumberError, childAge, childAgeError, timePreference]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const number = phoneNumber;
    const dob = childAge;
    const className = e.target.class.value;
    const medium = e.target.medium.value;

    if (!validatePhoneNumber(number) || !dob) {
      return;
    }

    const formData = {
      name,
      mobile_number: number,
      age: dob,
      class: className,
      medium,
      time_preference: timePreference,
      type: "EFT",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v1/skill-test-schedule`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            secretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();


      if (data?.message === "Thank you. You have already booked a schedule.") {

        setAlreadyRegistered(true);
        setTimeSlot(data.data);
      } else {
        setWlcMgs(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {wlcMgs || alreadyRegistered ? (
        <>
          {wlcMgs && (
            <div className="tw-max-w-[450px] tw-mx-auto tw-border tw-mt-20 tw-rounded-lg">
              <div className="tw-p-8 tw-flex tw-flex-col tw-justify-center tw-items-center">
                <img className="tw-w-[150px]" src={success} alt="success" />
                <h1 className="tw-text-2xl tw-font-semibold tw-mt-8 tw-text-gray-800">
                  ধন্যবাদ রেজিস্ট্রেশন করার জন্য!{" "}
                </h1>
                <p className="tw-mt-3 tw-text-center tw-text-gray-500">
                  আমাদের একজন প্রতিনিধি খুব শিগগিরই আপনার সাথে যোগাযোগ করবেন।
                </p>
                <button className="tw-bg-purple-500 tw-w-full tw-p-2 tw-text-white tw-rounded-lg tw-mt-8">
                  <Link to="/"> হোমে ফিরে যান </Link>
                </button>
                <p className="tw-mt-8 tw-text-gray-500">
                  মজারুর কোর্স সম্পর্কে জানতে
                </p>
                <div className="tw-w-full md:rttw-flex tw-justify-between tw-mt-2">
                  <div className="tw-flex tw-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.76637 7.20001H5.32291C5.39427 5.96289 5.62983 4.82326 5.99316 3.90583C4.83402 4.61708 4.00011 5.80679 3.76637 7.20001ZM8.50001 1.60001C4.96538 1.60001 2.10001 4.46538 2.10001 8.00001C2.10001 11.5346 4.96538 14.4 8.50001 14.4C12.0346 14.4 14.9 11.5346 14.9 8.00001C14.9 4.46538 12.0346 1.60001 8.50001 1.60001ZM8.50001 3.20001C8.43917 3.20001 8.3143 3.22539 8.12777 3.40948C7.93779 3.59697 7.73001 3.90793 7.53827 4.35534C7.22732 5.08089 6.99993 6.06847 6.9259 7.20001H10.0741C10.0001 6.06847 9.77269 5.08089 9.46175 4.35534C9.27 3.90793 9.06222 3.59697 8.87225 3.40948C8.68571 3.22539 8.56085 3.20001 8.50001 3.20001ZM11.6771 7.20001C11.6057 5.96289 11.3702 4.82326 11.0069 3.90583C12.166 4.61708 12.9999 5.80679 13.2336 7.20001H11.6771ZM10.0741 8.80001H6.9259C6.99993 9.93154 7.22732 10.9191 7.53827 11.6447C7.73001 12.0921 7.93779 12.403 8.12777 12.5905C8.3143 12.7746 8.43917 12.8 8.50001 12.8C8.56085 12.8 8.68571 12.7746 8.87225 12.5905C9.06222 12.403 9.27 12.0921 9.46175 11.6447C9.77269 10.9191 10.0001 9.93154 10.0741 8.80001ZM11.0069 12.0942C11.3702 11.1767 11.6057 10.0371 11.6771 8.80001H13.2336C12.9999 10.1932 12.166 11.3829 11.0069 12.0942ZM5.99316 12.0942C5.62983 11.1767 5.39427 10.0371 5.32291 8.80001H3.76637C4.00011 10.1932 4.83402 11.3829 5.99316 12.0942Z"
                        fill="#3B82F6"
                      />
                    </svg>
                    <a
                      className="tw-block tw-mr-2 tw-text-blue-500 "
                      href="https://mojaru.com/"
                    >
                      https://mojaru.com
                    </a>
                  </div>
                  <p className="tw-text-gray-500">অথবা</p>
                  <div className="tw-flex tw-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.1 2.3C12.6 0.8 10.6 0 8.5 0C4.1 0 0.5 3.6 0.5 8C0.5 9.4 0.900006 10.8 1.60001 12L0.5 16L4.70001 14.9C5.90001 15.5 7.2 15.9 8.5 15.9C12.9 15.9 16.5 12.3 16.5 7.9C16.5 5.8 15.6 3.8 14.1 2.3ZM8.5 14.6C7.3 14.6 6.10001 14.3 5.10001 13.7L4.89999 13.6L2.39999 14.3L3.10001 11.9L2.89999 11.6C2.19999 10.5 1.89999 9.3 1.89999 8.1C1.89999 4.5 4.9 1.5 8.5 1.5C10.3 1.5 11.9 2.2 13.2 3.4C14.5 4.7 15.1 6.3 15.1 8.1C15.1 11.6 12.2 14.6 8.5 14.6ZM12.1 9.6C11.9 9.5 10.9 9 10.7 9C10.5 8.9 10.4 8.9 10.3 9.1C10.2 9.3 9.80001 9.7 9.70001 9.9C9.60001 10 9.49999 10 9.29999 10C9.09999 9.9 8.50001 9.7 7.70001 9C7.10001 8.5 6.70001 7.8 6.60001 7.6C6.50001 7.4 6.60001 7.3 6.70001 7.2C6.80001 7.1 6.9 7 7 6.9C7.1 6.8 7.10001 6.7 7.20001 6.6C7.30001 6.5 7.20001 6.4 7.20001 6.3C7.20001 6.2 6.80001 5.2 6.60001 4.8C6.50001 4.5 6.30001 4.5 6.20001 4.5C6.10001 4.5 5.99999 4.5 5.79999 4.5C5.69999 4.5 5.49999 4.5 5.29999 4.7C5.09999 4.9 4.60001 5.4 4.60001 6.4C4.60001 7.4 5.29999 8.3 5.39999 8.5C5.49999 8.6 6.79999 10.7 8.79999 11.5C10.5 12.2 10.8 12 11.2 12C11.6 12 12.4 11.5 12.5 11.1C12.7 10.6 12.7 10.2 12.6 10.2C12.5 9.7 12.3 9.7 12.1 9.6Z"
                        fill="#25D366"
                      />
                    </svg>
                    <a
                      href="https://api.whatsapp.com/send/?phone=8801610075103&text&type=phone_number&app_absent=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="tw-text-green-500">
                        +880 1610-075103
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {alreadyRegistered && (
            <div className="tw-max-w-[450px] tw-mx-auto tw-border tw-mt-20 tw-rounded-lg">
              <div className="tw-p-8 tw-flex tw-flex-col tw-justify-center tw-items-center">
                <img className="tw-w-[150px]" src={registred} alt="success" />
                <h1 className="tw-text-2xl tw-font-semibold tw-mt-8 tw-text-gray-800">
                  আপনি অলরেডি রেজিস্ট্রেশন করেছেন!
                </h1>
                <p className="tw-mt-3 tw-text-center tw-text-gray-500">
                  আপনার English Fluency Test এর টাইম স্লট হচ্ছে{" "}
                  <span className="tw-font-semibold">
                    {timeSlot?.time_preference}
                  </span>
                </p>
                <button className="tw-bg-purple-500 tw-w-full tw-p-2 tw-text-white tw-rounded-lg tw-mt-8">
                  <Link to="/"> হোমে ফিরে যান </Link>
                </button>
                <p className="tw-mt-8 tw-text-gray-500">
                  মজারুর কোর্স সম্পর্কে জানতে
                </p>
                <div className="tw-w-full md:tw-flex tw-justify-between tw-mt-2">
                  <div className="tw-flex tw-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.76637 7.20001H5.32291C5.39427 5.96289 5.62983 4.82326 5.99316 3.90583C4.83402 4.61708 4.00011 5.80679 3.76637 7.20001ZM8.50001 1.60001C4.96538 1.60001 2.10001 4.46538 2.10001 8.00001C2.10001 11.5346 4.96538 14.4 8.50001 14.4C12.0346 14.4 14.9 11.5346 14.9 8.00001C14.9 4.46538 12.0346 1.60001 8.50001 1.60001ZM8.50001 3.20001C8.43917 3.20001 8.3143 3.22539 8.12777 3.40948C7.93779 3.59697 7.73001 3.90793 7.53827 4.35534C7.22732 5.08089 6.99993 6.06847 6.9259 7.20001H10.0741C10.0001 6.06847 9.77269 5.08089 9.46175 4.35534C9.27 3.90793 9.06222 3.59697 8.87225 3.40948C8.68571 3.22539 8.56085 3.20001 8.50001 3.20001ZM11.6771 7.20001C11.6057 5.96289 11.3702 4.82326 11.0069 3.90583C12.166 4.61708 12.9999 5.80679 13.2336 7.20001H11.6771ZM10.0741 8.80001H6.9259C6.99993 9.93154 7.22732 10.9191 7.53827 11.6447C7.73001 12.0921 7.93779 12.403 8.12777 12.5905C8.3143 12.7746 8.43917 12.8 8.50001 12.8C8.56085 12.8 8.68571 12.7746 8.87225 12.5905C9.06222 12.403 9.27 12.0921 9.46175 11.6447C9.77269 10.9191 10.0001 9.93154 10.0741 8.80001ZM11.0069 12.0942C11.3702 11.1767 11.6057 10.0371 11.6771 8.80001H13.2336C12.9999 10.1932 12.166 11.3829 11.0069 12.0942ZM5.99316 12.0942C5.62983 11.1767 5.39427 10.0371 5.32291 8.80001H3.76637C4.00011 10.1932 4.83402 11.3829 5.99316 12.0942Z"
                        fill="#3B82F6"
                      />
                    </svg>
                    <a
                      className="tw-block tw-mr-2 tw-text-blue-500 "
                      href="https://mojaru.com/"
                    >
                      https://mojaru.com
                    </a>
                  </div>
                  <p className="tw-text-gray-500">অথবা</p>
                  <div className="tw-flex tw-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.1 2.3C12.6 0.8 10.6 0 8.5 0C4.1 0 0.5 3.6 0.5 8C0.5 9.4 0.900006 10.8 1.60001 12L0.5 16L4.70001 14.9C5.90001 15.5 7.2 15.9 8.5 15.9C12.9 15.9 16.5 12.3 16.5 7.9C16.5 5.8 15.6 3.8 14.1 2.3ZM8.5 14.6C7.3 14.6 6.10001 14.3 5.10001 13.7L4.89999 13.6L2.39999 14.3L3.10001 11.9L2.89999 11.6C2.19999 10.5 1.89999 9.3 1.89999 8.1C1.89999 4.5 4.9 1.5 8.5 1.5C10.3 1.5 11.9 2.2 13.2 3.4C14.5 4.7 15.1 6.3 15.1 8.1C15.1 11.6 12.2 14.6 8.5 14.6ZM12.1 9.6C11.9 9.5 10.9 9 10.7 9C10.5 8.9 10.4 8.9 10.3 9.1C10.2 9.3 9.80001 9.7 9.70001 9.9C9.60001 10 9.49999 10 9.29999 10C9.09999 9.9 8.50001 9.7 7.70001 9C7.10001 8.5 6.70001 7.8 6.60001 7.6C6.50001 7.4 6.60001 7.3 6.70001 7.2C6.80001 7.1 6.9 7 7 6.9C7.1 6.8 7.10001 6.7 7.20001 6.6C7.30001 6.5 7.20001 6.4 7.20001 6.3C7.20001 6.2 6.80001 5.2 6.60001 4.8C6.50001 4.5 6.30001 4.5 6.20001 4.5C6.10001 4.5 5.99999 4.5 5.79999 4.5C5.69999 4.5 5.49999 4.5 5.29999 4.7C5.09999 4.9 4.60001 5.4 4.60001 6.4C4.60001 7.4 5.29999 8.3 5.39999 8.5C5.49999 8.6 6.79999 10.7 8.79999 11.5C10.5 12.2 10.8 12 11.2 12C11.6 12 12.4 11.5 12.5 11.1C12.7 10.6 12.7 10.2 12.6 10.2C12.5 9.7 12.3 9.7 12.1 9.6Z"
                        fill="#25D366"
                      />
                    </svg>
                    <a
                      href="https://api.whatsapp.com/send/?phone=8801610075103&text&type=phone_number&app_absent=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="tw-text-green-500">
                        +880 1610-075103
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        // Container
        <div className="tw-max-w-screen-xl tw-mx-auto tw-mt-8 md:tw-mt-16">
          <div>
            <div className="tw-flex tw-min-h-full tw-flex-1">
              <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-px-4 sm:tw-px-6 lg:tw-flex-none lg:tw-px-20 xl:tw-px-24">
                <div className="tw-mx-auto tw-w-full tw-max-w-sm lg:tw-w-96">
                  <div>
                    <Link to="/">
                      <img
                        className="tw-h-8 tw-w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                    </Link>
                    <h2 className="tw-mt-4 tw-text-xl tw-font-semibold tw-leading-9 tw-tracking-tight tw-text-gray-900">
                      Register for your English Fluency Test
                    </h2>
                    <h2 className="tw-mt-2 tw-text-lg tw-font-semibold tw-leading-9 tw-tracking-tight tw-text-gray-500">
                      Your Information
                    </h2>
                  </div>
                  <div className="tw-mt-4">
                    <form onSubmit={submitHandler} className="">
                      <div>
                        <label
                          htmlFor="name"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Your name
                        </label>
                        <div className="tw-mt-1">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            autoComplete="name"
                            onChange={(e) => setName(e.target.value)}
                            required
                            className="tw-pl-4 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                          />
                        </div>
                      </div>
                      <div className="tw-mt-4">
                        <label
                          htmlFor="phoneNumber"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Mobile Number
                        </label>
                        <div className="tw-mt-1">
                          <input
                            id="phoneNumber"
                            name="phoneNumber"
                            type="text"
                            autoComplete="current-number"
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            placeholder="e.g. 01332555341"
                            required
                            className="tw-pl-4 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                          />
                          {phoneNumberError && (
                            <p className="tw-mt-2 tw-text-sm tw-text-red-700">
                              {phoneNumberError}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="tw-mt-4">
                        <label
                          htmlFor="dob"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Age
                        </label>
                        <div className="tw-mt-1">
                          <input
                            id="dob"
                            name="dob"
                            type="text"
                            value={childAge}
                            onChange={handleChildAgeChange}
                            required
                            className="tw-pl-4 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                          />
                          {childAgeError && (
                            <p className="tw-mt-2 tw-text-sm tw-text-red-700">
                              {childAgeError}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="tw-mt-4">
                        <label
                          htmlFor="class"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Class
                        </label>
                        <select
                          id="class"
                          name="class"
                          onChange={(e) => setClassName(e.target.value)}
                          className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                          defaultValue="ক্লাস নির্বাচন করুন"
                        >
                          <option value="" label="ক্লাস নির্বাচন করুন"></option>
                          <option
                            value="Not Admitted"
                            label="Not Admitted - ভর্তি করা হয়নি"
                          ></option>
                          <option value="Play" label="Play - প্লে"></option>
                          <option
                            value="Nursery"
                            label="Nursery - নার্সারী"
                          ></option>
                          <option value="KG" label="KG - কেজি"></option>
                          <option
                            value="Class 1"
                            label="Class 1 - শ্রেণী ১"
                          ></option>
                          <option
                            value="Class 2"
                            label="Class 2 - শ্রেণী ২"
                          ></option>
                          <option
                            value="Class 3"
                            label="Class 3 - শ্রেণী ৩"
                          ></option>
                          <option
                            value="Class 4"
                            label="Class 4 - শ্রেণী ৪"
                          ></option>
                          <option
                            value="Class 5"
                            label="Class 5 - শ্রেণী ৫"
                          ></option>
                          <option
                            value="Class 6"
                            label="Class 6 - শ্রেণী ৬"
                          ></option>
                          <option
                            value="Class 7"
                            label="Class 7 - শ্রেণী ৭"
                          ></option>
                          <option
                            value="Class 8"
                            label="Class 8 - শ্রেণী ৮"
                          ></option>
                          <option
                            value="Class 9"
                            label="Class 9 - শ্রেণী ৯"
                          ></option>
                          <option
                            value="Class 10"
                            label="Class 10 - শ্রেণী ১০"
                          ></option>
                          <option
                            value="Class 11"
                            label="Class 11 - শ্রেণী ১১"
                          ></option>
                          <option
                            value="Class 12"
                            label="Class 12 - শ্রেণী ১২"
                          ></option>
                          <option
                            value="Other"
                            label="Other - অন্যান্য"
                          ></option>
                        </select>
                      </div>
                      <div className="tw-mt-4">
                        <label
                          htmlFor="medium"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Version/Medium
                        </label>
                        <select
                          id="medium"
                          name="medium"
                          onChange={(e) => setMedium(e.target.value)}
                          className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-indigo-600 tw-sm:text-sm tw-sm:leading-6"
                          defaultValue="ভার্সন নির্বাচন করুন"
                        >
                          <option
                            value=""
                            label="ভার্সন নির্বাচন করুন"
                          ></option>
                          <option
                            value="Bangla version"
                            label="Bangla version"
                          ></option>
                          <option
                            value="English version"
                            label="English version"
                          ></option>
                          <option
                            value="English medium"
                            label="English medium"
                          ></option>
                        </select>
                      </div>
                      <div className="tw-mt-4">
                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                          Time preference for test
                        </label>
                        <div className="tw-mt-2 tw-flex tw-space-x-4">
                          {["3.00 PM", "5.00 PM", "7.00 PM"].map((time) => (
                            <button
                              key={time}
                              type="button"
                              onClick={() => handleTimePreferenceChange(time)}
                              className={`tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900 tw-border tw-rounded-md ${
                                timePreference === time
                                  ? "tw-bg-indigo-600 tw-text-white"
                                  : "tw-bg-gray-200"
                              }`}
                            >
                              {time}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="tw-mt-8">
                        <button
                          type="submit"
                          className={`tw-flex tw-w-full tw-justify-center tw-rounded-md tw-px-3 tw-py-1.5 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white  ${
                            isFormValid
                              ? "tw-bg-indigo-600 tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                              : "tw-bg-gray-400"
                          } `}
                          disabled={!isFormValid}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="tw-relative tw-hidden tw-w-0 tw-flex-1 lg:tw-block">
                <img
                  className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-rounded-2xl"
                  src={banner}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Eft;
