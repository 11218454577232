import React from 'react';
import {HiPresentationChartLine} from "react-icons/hi2";
import {Link} from "react-router-dom";
import {HiArrowRight, HiUsers, HiVideoCamera} from "react-icons/hi";
import dot from "../../../../../images/HomePageImage/Dot.svg";
import appStore from "../../../../../images/HomePageImage/AppPlatform/AppStore.png";
import playStore from "../../../../../images/HomePageImage/AppPlatform/Google-Play.png";

const CourseCategory = ({categories, handleSubCategoryCourse, selectedCategory, setAllCourse, courses}) => {
    return (
        <div>
            <div className="tw-flex">
                <div className="tw-w-[60%] ">
                    <h2 className="tw-text-lg tw-font-semibold tw-mt-6">কোর্স ক্যাটাগরি</h2>
                    <div className="tw-grid tw-grid-cols-2 tw-gap-3">
                        {
                            categories.map((category) => (
                                <div onClick={() => handleSubCategoryCourse(category)}
                                     className={`tw-pl-4 tw-py-2 tw-mt-4 tw-rounded-lg tw-cursor-pointer ${selectedCategory === category?.course_sub_category_name ? "tw-bg-[#FFFAE8] tw-text-gray-700 tw-border-2 tw-border-[#FFCE39]" : "hover:tw-bg-gray-200 "} tw-bg-gray-50`}
                                     key={category?.id}>
                                    <div className="tw-flex">
                                        <div className="">
                                            {
                                                category?.course_sub_category_photo ?
                                                    <img
                                                        className={`tw-w-8 tw-h-8 tw-mr-4 tw-mt-1 ${selectedCategory === category?.course_sub_category_name ? " tw-bg-[#FFE789] tw-p-1 tw-rounded-md" : " tw-p-1 "} `}
                                                        src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/${category?.course_sub_category_photo}`}/> :
                                                    <HiPresentationChartLine
                                                        className={`tw-w-8 tw-h-8 tw-mr-4 tw-mt-2 ${selectedCategory === category?.course_sub_category_name ? " tw-bg-[#FFE789] tw-p-1 tw-rounded-md" : " tw-p-1 "} `}/>
                                            }

                                        </div>
                                        <div>
                                            <p className="tw-text-lg tw-font-semibold">{category?.course_sub_category_name}</p>
                                            <p className="">{category?.courses_count} কোর্স</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="tw-mt-6 tw-mb-6">
                        <Link onClick={() => setAllCourse(false)} to="/all">
                            <button
                                className="tw-w-full tw-text-white tw-bg-[#532D80] tw-py-3 tw-flex tw-justify-center tw-items-center tw-rounded-3xl">
                                <sapn className="tw-font-semibold tw-mr-2">সকল কোর্সসমূহ
                                </sapn>
                                <HiArrowRight/></button>
                        </Link>
                    </div>
                </div>
                <div className="tw-w-[40%] tw-ml-6 tw-rounded-br-3xl tw-overflow-hidden">
                    <div className="tw-flex tw-justify-between tw-px-6 tw-mt-6 tw-mb-4">
                        <p className=" tw-text-lg tw-font-semibold">কোর্স সমূহ</p>
                        <Link onClick={() => setAllCourse(false)} to="/all">
                            <button
                                className="tw-bg-gray-100 tw-px-2 tw-py-1 tw-rounded-lg tw-text-sm tw-font-semibold tw-text-gray-700">সব
                                গুলো কোর্স দেখুন
                            </button>
                        </Link>
                    </div>
                    <div
                        className="tw-px-3 tw-max-h-[430px] tw-overflow-y-scroll scrollbar tw-bg-yellow-50">
                        {
                            courses.map((course) => (
                                <Link onClick={() => setAllCourse(false)} key={course?.id}
                                      to={`/${course?.course_category_link}/${course?.course_slug}`}
                                      state={{course_id: `${course?.id}`}}>
                                    <div
                                        className="tw-flex tw-mt-4 tw-rounded-lg tw-bg-gray-100 tw-py-3 tw-cursor-pointer hover:tw-scale-[1.05] tw-transition-all">
                                        <div className="">
                                            <img
                                                className="tw-w-[140px] tw-h-auto tw-p-3 tw-rounded-2xl"
                                                src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/courses/${course?.course_picture}`}/>
                                        </div>
                                        <div>
                                            <p className="tw-font-semibold tw-text-gray-700"
                                               key={course?.id}>{course?.course_name}</p>
                                            <div className="tw-flex tw-mt-3">
                                                <div
                                                    className="tw-flex tw-justify-center tw-items-center tw-bg-[#FEE2E2] tw-px-4 tw-rounded-3xl">
                                                    <img className="tw-w-2 tw-h-2 tw-mr-2"
                                                         src={dot}
                                                         alt="logo"/>
                                                    <span
                                                        className="tw-text-red-800">{course?.course_mode}</span>
                                                </div>
                                                <p className="tw-flex tw-items-center tw-mr-4 tw-text-gray-500 tw-ml-4">
                                                    <HiVideoCamera
                                                        className="tw-mr-2 tw-text-gray-400"/> {course?.course_total_live_class} Live
                                                    class</p>
                                                <p className="tw-flex tw-items-center tw-text-gray-500 tw-ml-4">
                                                    <HiUsers
                                                        className="tw-mr-2 tw-text-gray-400"/> {course?.students_count} Students
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-justify-between tw-items-center tw-border-t tw-mb-5">
                <div className="tw-flex tw-mt-6">
                    <a className="tw-mr-4" href="https://apps.apple.com/us/app/mojaru/id6657991877"
                       target="_blank" rel="noreferrer">
                        <img className="tw-mr-4" src={appStore} alt="app store"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.aitl.mojaru"
                       target="_blank" rel="noreferrer">
                        <img src={playStore} alt="play store"/>
                    </a>
                </div>
                <div className="tw-mt-6">
                    <Link onClick={() => setAllCourse(false)} to="/free">
                        <button
                            className="tw-bg-gray-100 tw-px-4 tw-py-2 tw-font-semibold tw-rounded-lg tw-text-gray-700">ফ্রি
                            কোর্সসমূহ
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CourseCategory;