import React from 'react';
import { HomeIcon } from '@heroicons/react/20/solid'
import {Link} from "react-router-dom";
const pages = [
    { name: 'কোর্স', href: '#', current: false },
    { name: 'চেকআউট ', href: '#', current: true },
]
const Breadcrumbs = () => {
    return (
        <div>
            <nav className="tw-flex" aria-label="Breadcrumb">
                <ol role="list" className="tw-flex tw-items-center tw-space-x-4">
                    <li>
                        <div>
                            <Link to="/" className="tw-text-gray-400 tw-hover:text-gray-500">
                                <HomeIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0" aria-hidden="true"/>
                                <span className="tw-sr-only">Home</span>
                            </Link>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name}>
                            <div className="tw-flex tw-items-center">
                                <svg
                                    className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-300"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                >
                                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"/>
                                </svg>
                                <a
                                    href={page.href}
                                    className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-500 tw-hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </a>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    );
};

export default Breadcrumbs;