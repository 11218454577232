// api.js
const BASE_URL = process.env.REACT_APP_BASE_URL_V2;
const AUTH_TOKEN = localStorage.getItem("auth_token");
const USER_ID = localStorage.getItem("user_id");
const SECRET_KEY = process.env.REACT_APP_API_URL_SECRET_KEY;

const headers = {
  Authorization: `Bearer ${AUTH_TOKEN}`,
  SecretKey: SECRET_KEY,
  user_id: USER_ID,
};

// Fetch Avatar List

export const fetchAvatarList = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}student/avatar-list?user_id=${USER_ID}`,
      {
        method: "GET",
        headers,
      }
    );
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    return response.json();
  } catch (error) {
    console.error("Error fetching avatar list:", error);
    throw error;
  }
};

// Generate Avatar API
// export const generateAvatarRequest = async (formData) => {
//   console.log(formData);

//   try {
//     const response = await fetch(`${BASE_URL}student/avatar-generate-request`, {
//       method: "POST",
//       headers: { ...headers, "Content-Type": "multipart/form-data" },
//       body: formData,
//     });
//     return response.json();
//   } catch (error) {
//     console.error("Error generating avatar:", error);
//     throw error;
//   }
// };

export const generateAvatarRequest = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}student/avatar-generate-request`, {
      method: "POST",
      headers: {
        ...headers, // Include any custom headers except Content-Type
      },
      body: formData, // The browser will automatically set the Content-Type
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`API Error: ${errorData.message || "Unknown error"}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error generating avatar:", error);
    throw error;
  }
};

//Save Image API
export const saveGeneratedImage = async (orderId) => {
  try {
    const response = await fetch(`${BASE_URL}student/avatar-image-save`, {
      method: "POST",
      headers: { ...headers, "Content-Type": "application/json" },
      body: JSON.stringify({ order_id: orderId }),
    });
    return response.json();
  } catch (error) {
    console.error("Error saving generated image:", error);
    throw error;
  }
};
