import React from "react";
import { useState } from "react";
import noPerson from "../../../../../../images/blank.png";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./EditProfile.css";
import { eventDate } from "../../../../../../utilities/utilities";
import { toast } from "react-toastify";

const EditProfile = ({ studentInfo, setRefetchData, refetchData }) => {
  const [file, setFile] = useState();
  const [image, setImage] = useState("");
  const [isChangeImage, setIsChangeImage] = useState(false);
  const [startDate, setStartDate] = useState();
  const [dateOfBirth, setDateOfBirth] = useState(true);
  const [districtValue, setDistrictValue] = useState(true);
  const [classListValue, setClassListValue] = useState(true);
  const [religious, setReligiousValue] = useState(true);
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [selectedClassList, setSelectedClassList] = useState();
  const [selectedReligious, setSelectedReligious] = useState();
  /* const [studentInfo, setStudentInfo] = useState();
    /* useEffect(() => {
        fetch(
            `${process.env.REACT_APP_BASE_URL_STUDENT}profile?user_id=${localStorage.getItem("user_id")}`)
            .then((res) => res.json())
            .then((data) => setStudentInfo(data.data));
    }, []); */

  const handleChange = (e) => {
    setImage(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    setIsChangeImage(true);
  };

  if (
    dateOfBirth &&
    studentInfo?.student_detail?.get_student_detail?.date_of_birth
  ) {
    setStartDate(
      new Date(studentInfo?.student_detail?.get_student_detail?.date_of_birth)
    );
    //console.log(studentInfo?.student_detail?.get_student_detail?.date_of_birth);
    setDateOfBirth(false);
  }

  const handleDistrict = (event) => {
    setSelectedDistrict(event.target.value);
  };
  const handleClassList = (event) => {
    setSelectedClassList(event.target.value);
  };
  const handleReligious = (event) => {
    setSelectedReligious(event.target.value);
  };

  const handelStudentInfo = (e) => {
    e.preventDefault();
    let data = new FormData();

    isChangeImage && data.append("profile_picture", image);

    data.append("user_name", e.target.student_name.value);
    if (
      studentInfo?.student_detail?.mobile_number !==
      e.target.student_phone_number.value
    ) {
      toast.error("You can't change your mobile number");
      return;
    }

    // console.log(studentInfo?.student_detail?.email, e.target.email.value)
    if (
      studentInfo?.student_detail?.email &&
      studentInfo?.student_detail?.email !== e.target.email.value
    ) {
      toast.error("You can't change your email");
      return;
    } else {
      data.append("email", e.target.email.value);
    }
    data.append("father_name", e.target.father_name.value);
    data.append("mother_name", e.target.mother_name.value);
    data.append("full_address", e.target.full_address.value);
    data.append("gender", e.target.gender.value);
    data.append("mailing_address", e.target.mailing_address.value);
    data.append(
      "guardian_mobile_number",
      e.target.guardian_mobile_number.value
    );
    data.append("whats_app_number", e.target.student_whats_app_number.value);
    /* data.append('email', e.target.email.value); */
    data.append("date_of_birth", eventDate(startDate));
    data.append("location", e.target.district.value);
    data.append("school", e.target.school_name.value);
    data.append("medium", e.target.medium.value);
    data.append("class", e.target.class_name.value);
    data.append("religion", e.target.religion.value);

    /* console.log(e.target.student_name.value); */

    fetch(
      `${
        process.env.REACT_APP_BASE_URL_STUDENT
      }update-profile?user_id=${localStorage.getItem("user_id")}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
        },
        body: data,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message_type === "success") {
          toast.success(data.message);
          setRefetchData(refetchData + 1);
          setIsChangeImage(false);
          let closeCanvas = document.querySelector(
            '[data-bs-dismiss="offcanvas"]'
          );
          closeCanvas.click();
        } else {
          toast.error(data.message);
        }
      });
  };

  if (
    studentInfo?.student_detail?.get_student_detail?.location &&
    districtValue
  ) {
    setSelectedDistrict(
      studentInfo?.student_detail?.get_student_detail?.location
    );
    setDistrictValue(false);
  }
  if (
    studentInfo?.student_detail?.get_student_detail?.class &&
    classListValue
  ) {
    setSelectedClassList(
      studentInfo?.student_detail?.get_student_detail?.class
    );
    setClassListValue(false);
  }
  if (studentInfo?.student_detail?.get_student_detail?.religion && religious) {
    setSelectedReligious(
      studentInfo?.student_detail?.get_student_detail?.religion
    );
    setReligiousValue(false);
  }

  return (
    <div
      className="offcanvas result-details offcanvas-end"
      tabIndex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
      style={{ overflowY: "auto" }}
    >
      <div className="offcanvas-header student-profile">
        <h5 id="offcanvasRightLabel">প্রোফাইল আপডেট</h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="total-assignment-divider"></div>
      <div className="offcanvas-body px-4">
        <p className="mb-3 font-400">তোমার ছবি</p>
        <div className="avatar-upload">
          <div className="avatar-edit">
            <form action="" method="">
              <input
                type="file"
                id="imageUpload"
                accept=".png, .jpg, .jpeg"
                onChange={handleChange}
              />
              <label htmlFor="imageUpload"></label>
            </form>
          </div>
          <div className="avatar-preview">
            {file ? (
              <img
                className="profile-user-img img-responsive"
                id="imagePreview"
                src={file}
                alt="User profile"
              />
            ) : studentInfo?.student_detail?.profile_picture ? (
              <img
                className="profile-user-img img-responsive"
                id="imagePreview"
                src={`${studentInfo?.student_profile_image}${studentInfo?.student_detail?.profile_picture}`}
                alt="User profile"
              />
            ) : (
              <img
                className="profile-user-img img-responsive"
                id="imagePreview"
                src={noPerson}
                alt="User profile"
              />
            )}
          </div>
        </div>

        <form onSubmit={handelStudentInfo}>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">
                নাম <span className="text-danger">*</span>
              </p>
              <input
                className="form-control form-control-lg student-profile-info"
                name="student_name"
                type="text"
                placeholder="Write Your Full Name"
                defaultValue={
                  studentInfo?.student_detail?.user_name &&
                  studentInfo?.student_detail?.user_name
                }
                required
              />
            </div>

            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">
                ফোন নাম্বার <span className="text-danger">*</span>
              </p>
              <input
                className="form-control form-control-lg student-profile-info"
                name="student_phone_number"
                type="text"
                pattern="[0-1]{2}[3-9]{1}[0-9]{8}"
                placeholder="Write Your Phone Number"
                readOnly={studentInfo?.student_detail?.mobile_number}
                defaultValue={
                  studentInfo?.student_detail?.mobile_number &&
                  studentInfo?.student_detail?.mobile_number
                }
                required
              />
            </div>

            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">
                বাবার নাম<span className="text-danger">*</span>
              </p>
              <input
                className="form-control form-control-lg student-profile-info"
                name="father_name"
                type="text"
                defaultValue={
                  studentInfo?.student_detail?.get_student_detail
                    ?.father_name &&
                  studentInfo?.student_detail?.get_student_detail?.father_name
                }
                placeholder="তোমার বাবার নাম লিখ"
                required
              />
            </div>
            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">
                মায়ের নাম<span className="text-danger">*</span>
              </p>
              <input
                className="form-control form-control-lg student-profile-info"
                name="mother_name"
                type="text"
                defaultValue={
                  studentInfo?.student_detail?.get_student_detail
                    ?.mother_name &&
                  studentInfo?.student_detail?.get_student_detail?.mother_name
                }
                placeholder="তোমার মায়ের নাম লিখ "
                required
              />
            </div>
            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">
                অভিভাবকের ফোন নাম্বার<span className="text-danger">*</span>
              </p>
              <input
                className="form-control form-control-lg student-profile-info"
                name="guardian_mobile_number"
                type="text"
                pattern="[0-1]{2}[3-9]{1}[0-9]{8}"
                placeholder="Write Your Guardian Mobile Number"
                defaultValue={
                  studentInfo?.student_detail?.get_student_detail
                    ?.guardian_mobile_number &&
                  studentInfo?.student_detail?.get_student_detail
                    ?.guardian_mobile_number
                }
                required
              />
            </div>

            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">হোয়াটস অ্যাপ নাম্বার</p>
              <input
                className="form-control form-control-lg student-profile-info"
                name="student_whats_app_number"
                type="text"
                placeholder="Write Your WhatsApp Number"
                defaultValue={
                  studentInfo?.student_detail?.get_student_detail
                    ?.whats_app_number &&
                  studentInfo?.student_detail?.get_student_detail
                    ?.whats_app_number
                }
              />
            </div>

            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">ইমেইল</p>
              <input
                className="form-control form-control-lg student-profile-info"
                name="email"
                type="email"
                placeholder="Write Your Email"
                readOnly={studentInfo?.student_detail?.email}
                defaultValue={
                  studentInfo?.student_detail?.email &&
                  studentInfo?.student_detail?.email
                }
              />
            </div>

            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">
                জন্ম তারিখ <span className="text-danger">*</span>
              </p>
              <DatePicker
                showYearDropdown
                yearDropdownItemNumber={40}
                scrollableYearDropdown
                className="form-control form-control-lg student-profile-info"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText={"Select Your Date of Birth"}
                required
              />
            </div>

            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">জেলা</p>
              <select
                name="district"
                value={selectedDistrict}
                onChange={handleDistrict}
                className="form-select form-control form-control-lg student-profile-info"
                id="select_box"
              >
                {/* <option value="">Select District</option> */}
                {studentInfo?.districts.map((district) => (
                  <option
                    key={district.district_id}
                    value={district.district_id}
                  >
                    {district.district_en_name} - {district.district_bn_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">
                ক্লাস <span className="text-danger">*</span>
              </p>
              <select
                name="class_name"
                value={selectedClassList}
                onChange={handleClassList}
                className="form-select form-control form-control-lg student-profile-info"
                id="select_box"
                required
              >
                {studentInfo?.class_lists.map((class_list) => (
                  <option
                    key={class_list.class_id}
                    value={class_list.class_name}
                  >
                    {class_list.class_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">
                স্কুল <span className="text-danger">*</span>
              </p>
              <input
                className="form-control form-control-lg student-profile-info"
                name="school_name"
                type="text"
                placeholder="Write Your School Name"
                defaultValue={
                  studentInfo?.student_detail?.get_student_detail?.school &&
                  studentInfo?.student_detail?.get_student_detail?.school
                }
                required
              />
            </div>
            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">
                কুরিয়ার ঠিকানা<span className="text-danger">*</span>
              </p>
              <input
                className="form-control form-control-lg student-profile-info"
                name="mailing_address"
                type="text"
                placeholder="তোমার মেইলিং এড্রেস লিখ"
                defaultValue={
                  studentInfo?.student_detail?.get_student_detail
                    ?.mailing_address &&
                  studentInfo?.student_detail?.get_student_detail
                    ?.mailing_address
                }
                required
              />
            </div>

            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">মিডিয়াম</p>
              <div className="align-items-center">
                <div className="radio">
                  <label style={{ fontWeight: "500", cursor: "pointer" }}>
                    <input
                      type="radio"
                      name="medium"
                      id="bangla"
                      value="Bangla"
                      defaultChecked={
                        studentInfo?.student_detail?.get_student_detail
                          ?.medium &&
                        studentInfo?.student_detail?.get_student_detail
                          ?.medium === "Bangla"
                      }
                    />
                    &nbsp;&nbsp;Bangla
                  </label>
                  <br />
                  <label style={{ fontWeight: "500", cursor: "pointer" }}>
                    <input
                      type="radio"
                      name="medium"
                      id="english"
                      value="English"
                      defaultChecked={
                        studentInfo?.student_detail?.get_student_detail
                          ?.medium &&
                        studentInfo?.student_detail?.get_student_detail
                          ?.medium === "English"
                      }
                    />
                    &nbsp;&nbsp;English
                  </label>
                  <br />
                  <label style={{ fontWeight: "500", cursor: "pointer" }}>
                    <input
                      type="radio"
                      name="medium"
                      id="madrasa"
                      value="Madrasa"
                      defaultChecked={
                        studentInfo?.student_detail?.get_student_detail
                          ?.medium &&
                        studentInfo?.student_detail?.get_student_detail
                          ?.medium === "Madrasa"
                      }
                    />
                    &nbsp;&nbsp;Madrasa
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <p className="mb-2 font-400">
                লিঙ্গ<span className="text-danger">*</span>
              </p>
              <div className="align-items-center">
                <div className="radio">
                  <label style={{ fontWeight: "500", cursor: "pointer" }}>
                    <input
                      type="radio"
                      name="gender"
                      id="boy"
                      value="Male"
                      defaultChecked={
                        studentInfo?.student_detail?.get_student_detail
                          ?.gender &&
                        studentInfo?.student_detail?.get_student_detail
                          ?.gender === "Male"
                      }
                    />
                    &nbsp;&nbsp;Male
                  </label>
                  <br />
                  <label style={{ fontWeight: "500", cursor: "pointer" }}>
                    <input
                      type="radio"
                      name="gender"
                      id="girl"
                      value="Female"
                      defaultChecked={
                        studentInfo?.student_detail?.get_student_detail
                          ?.gender &&
                        studentInfo?.student_detail?.get_student_detail
                          ?.gender === "Female"
                      }
                    />
                    &nbsp;&nbsp;Female
                  </label>
                  <br />
                  <label style={{ fontWeight: "500", cursor: "pointer" }}>
                    <input
                      type="radio"
                      name="gender"
                      id="other"
                      value="Other"
                      defaultChecked={
                        studentInfo?.student_detail?.get_student_detail
                          ?.gender &&
                        studentInfo?.student_detail?.get_student_detail
                          ?.gender === "Other"
                      }
                    />
                    &nbsp;&nbsp;Other
                  </label>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mb-4">
              <p className="mb-2 font-400">
                ধর্ম<span className="text-danger">*</span>
              </p>
              <select
                name="religion"
                value={selectedReligious}
                onChange={handleReligious}
                className="form-select form-control form-control-lg student-profile-info"
                id="select_box"
                required
              >
                <option value="">তোমার ধর্ম বাছাই করো</option>
                <option value="Muslim">ইসলাম ধর্মাবলম্বী</option>
                <option value="Hindu">হিন্দু ধর্মাবলম্বী</option>
                <option value="Cristian">ক্রিস্টিয়ান ধর্মাবলম্বী</option>
                <option value="Buddhist">বৌদ্ধ ধর্মাবলম্বী</option>
                <option value="Other">অন্যান্য</option>
              </select>
            </div>
            <div className="col-lg-12 mb-4">
              <p className="mb-2 font-400">
                বর্তমান ঠিকানা<span className="text-danger">*</span>
              </p>
              <input
                className="form-control form-control-lg student-profile-info"
                name="full_address"
                defaultValue={
                  studentInfo?.student_detail?.get_student_detail
                    ?.full_address &&
                  studentInfo?.student_detail?.get_student_detail?.full_address
                }
                type="text"
                placeholder="তোমার বর্তমান ঠিকানা লিখ"
                required
              />
            </div>

            <div className="total-assignment-divider"></div>
            <input
              className="btn text-white mt-4 py-3"
              style={{ backgroundColor: "#532D80", fontSize: "22" }}
              type="submit"
              value="আপডেট করুন"
            ></input>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
