import React from 'react';
import Question from './Question/Question';


const Questions = ({faqs}) => {
  
    return (
      <section >
            <div className="faq_question">
                <div
                    className="container"
                    data-aos="fade-up"
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="accordion accordion-flush mt-4" id="faqlist3">
                                {
                                  faqs.map(faq =><Question key={faq.faq_id} faq={faq}></Question>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </section>
        
    );
};

export default Questions;