import React from 'react';
import banner_girl from "../../../../../images/Ebook.png";

const Banner = () => {
    return (
        <div className="academic_background ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <div>
                            <p className="banner-title">
                                হাতের মুঠোয় লাইব্রেরি
                                <br />
                                জ্ঞান বাড়াতে বই পড়ি
                            </p>
                            <p className="banner-text">
                                বিখ্যাত লেখক, নানান ক্যাটাগরির বই, ম্যাগাজিন- সব
                                মিলবে এখানেই।
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <img
                            src={banner_girl}
                            alt=""
                            className="img-fluid pb-5 mt-5"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;