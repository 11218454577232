import React from 'react';
import { Link } from 'react-router-dom';
import noBookImgFound from "../../../../../images/default_book_image.jpg";
import writerIcon from '../../../../../images/ebook_writer.png';
import languageIcon from '../../../../../images/globe.png';
import {encode as base64_encode} from 'base-64';
import { englishToBengaliNumberFormat } from '../../../../../utilities/utilities';

const Book = ({ebook, path_link}) => {
    let encoded = base64_encode(`${ebook.e_book_id}`);
    return (
      <div className="col-lg-3 col-md-6 px-2">
        <Link to={`/e-book/detail?e_book_id=${encoded}`} className="card-text-decoration">
        <div className="p-3 card-hover-effect" style={{ backgroundColor:'#ECECEC', borderRadius:'15px' }}>
          <div className="row">
            <div className="col-lg-12">
                <img src="" alt="" className="img-fluid" style={{ borderRadius:'15px' }}/>
                {(ebook.book_image !== undefined && ebook.book_image !== null) ? 
                  <img src={`${path_link}${ebook.book_image}`} className="img-fluid" style={{ borderRadius:'15px' }} id="course-img" alt='book_img'/>
                  : 
                  <img src={noBookImgFound} className="img-fluid" style={{ borderRadius:'15px' }} id="course-img" alt='book_dummy_image'/>

                }
            </div>
            <div className="col-lg-12">
              <div style={{ height:'6.5rem' }}>
                <p className='course_book_name_size'>{ebook.book_title.length > 55 ? ebook.book_title.slice(0,55)+'...': ebook.book_title}</p>
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <img src={writerIcon} alt="" className="img-fluid"/><span className="ps-1" style={{ color:'#7C7C7C' }}>{ebook.writter_name}</span>
                </div>
                <div className="d-flex align-items-center">
                  <img src={languageIcon} alt="" className="img-fluid"/><span className="ps-1" style={{ color:'#7C7C7C' }}>{ebook.book_language}</span>
                </div>
              </div>
              <div style={{ height: '1px', width:'100%', backgroundColor: '#AAAAAA', margin: '13px 0' }}></div>

              <div className='d-flex justify-content-between align-items-center'>
                  <div className="d-flex flex-column flex-lg-row flex-md-row">
                          <p className="text_color_blue balance_symbol balooda-medium" style={{ fontSize: '25px' }}>৳<span>{englishToBengaliNumberFormat(ebook.book_special_price) }</span></p>
                          <del className="ms-lg-2  text_color_blue d-flex align-items-center balooda-medium" style={{ fontSize: '16px', fontWeight:'600'}}><span style={{color:'#848484' }}>৳{englishToBengaliNumberFormat(ebook.book_original_price)}</span></del>
                  </div>
                  
                  
                  <div>
                  <p style={{ backgroundColor: '#f27321', color: '#ffffff' }} className="px-3 rounded-3 text_color_org a_tag_decoration button_border">আরো দেখুন</p>

                  </div>
              </div>
  
            </div>
            
          </div>
        </div>

        </Link>
      </div>
                
    );
};

export default Book;