import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../../images/logo.png";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "./Header.css";

const Header = ({ location }) => {
  const [notices, setNotices] = useState([]);
  const [settings, setSettings] = useState([]);
  const [navlinks, setNavlinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [studentInfo, setStudentInfo] = useState();
  const [language, setLanguage] = useState("");
  const { t } = useTranslation();

  let user = localStorage.getItem("user_id");
  useEffect(() => {
    async function fetchWebsiteSetting() {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/settings`
      );
      response = await response.json();
      setSettings(response.data.settings);
    }

    async function fetchMyAPI() {
      let response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL_STUDENT
        }profile?user_id=${localStorage.getItem("user_id")}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
        }
      );
      response = await response.json();
      setStudentInfo(response?.data?.student_detail);
    }
    if (localStorage.getItem("user_id")) fetchMyAPI();

    fetchWebsiteSetting();
  }, [user]);

  //login and logout button switch
  let AuthButton = "";
  if (!localStorage.getItem("auth_token")) {
    AuthButton = (
      <Link
        to="/login"
        style={{ border: "none" }}
        className="nav-item px-4 ms-2 py-2 rounded-3 text-white bg_color_blue a_tag_decoration"
      >
        {t("LogIn")}
      </Link>
    );
  } else {
    AuthButton = (
      <Link
        to="/dashboard"
        style={{ border: "none" }}
        className="nav-item px-4 ms-2 py-2 rounded-3 text-white bg_color_blue a_tag_decoration border-none"
      >
        {/* ড্যাশবোর্ড */}
        {studentInfo?.user_name ? studentInfo?.user_name : "Student Name"}
      </Link>
    );
  }

  //get navlink
  useEffect(() => {
    async function fetchNotice() {
      let data = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/course-categories`
      );
      data = await data.json();
      setNavlinks(data?.data?.course_categories);
      setIsLoading(false);
    }
    fetchNotice();
  }, []);
  //get notice
  useEffect(() => {
    async function fetchNotice() {
      let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/notices`);
      data = await data.json();
      setNotices(data?.data);
      setIsLoading(false);
    }
    fetchNotice();
  }, []);

  //Language Change button
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");

    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem("useLanguage");

    if (getLanguage === "bn") {
      sessionStorage.setItem("useLanguage", "en");
      i18next.changeLanguage("en");
      setLanguage("en");
    } else {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    }
  }

  if (isLoading) {
    return (
      <div className="pos-center">
        <Loader></Loader>
      </div>
    );
  }

  return (
    <section
      className="sticky-top"
      style={
        location === "/login" ||
        location === "/registration" ||
        location === "/otp"
          ? { display: "none" }
          : { display: "block" }
      }
    >
      <header className="bottom-border">
        <nav className="navbar navbar-expand-lg navbar-light top-header">
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img className="tw-w-[160px]" src={logo} height="35" alt="" />
            </Link>
            <Button
              type="button"
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              style={{ backgroundColor: "white", border: "none" }}
            >
              <span className="navbar-toggler-icon"></span>
            </Button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div
                className="navbar-nav ms-auto menu5"
                style={{ fontSize: "20px" }}
              >
                <NavLink to="/" className="nav-item nav-link me-1 custom">
                  <span
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    {t("Home")}
                  </span>
                </NavLink>

                {navlinks.map((navlink, index) => (
                  <NavLink
                    to={`/${navlink.course_category_link}`}
                    state={{
                      from: `${navlink.course_category_link}`,
                      enable: `${navlink.is_enabled}`,
                    }}
                    key={index}
                    className="nav-item nav-link me-1 custom"
                  >
                    <span
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      {sessionStorage.getItem("useLanguage") == "en"
                        ? navlink.course_category_en_name
                        : navlink.course_category_name}
                    </span>
                  </NavLink>
                ))}
                <NavLink to="/e-book" className="nav-item nav-link me-1 custom">
                  <span
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    {t("E-Book")}
                  </span>
                </NavLink>
              </div>

              <div id="btn-login" className="pt-2">
                {AuthButton}
              </div>
              <div className="d-none d-xl-block">
                <div className="switch">
                  <div className="switch">
                    <input
                      id="language-toggle"
                      className="check-toggle check-toggle-round-flat"
                      type="checkbox"
                      checked={language === "en"}
                      onChange={toggleLanguage}
                    />
                    <label htmlFor="language-toggle"></label>
                    <span className="on" value="bn">
                      BN
                    </span>
                    <span className="off" value="en">
                      EN
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      {/* <div className="top-yellow-line"></div> */}
    </section>
  );
};

export default Header;
