import {create} from "zustand";

export const userInfoStore = create((set) => ({
    name: '',
    phone: '',
    dob: '',
    mark: '',
    setName: (data) => set({name: data}),
    setPhone: (data) => set({phone: data}),
    setDob: (data) => set({dob: data}),
    setMark: (data) => set({mark: data}),
}));