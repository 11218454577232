
import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './Certificate.css';
import logo from '../../../../images/dashboard-logo.png';
import certificateMiddle from '../../../../images/certificate-middle.png';
import certificateTop from '../../../../images/certificate-top.png';
import certificateBottom from '../../../../images/certificate-bottom.png';
import certificateRight from '../../../../images/certificat-right-logo.png';
import signature from '../../../../images/ceo-signature.png';
import { useLocation } from 'react-router-dom';
import { certificateDate } from '../../../../utilities/utilities';
import StudentPageTitle from '../../shared/StudentPageTitle/StudentPageTitle';

const MyComponent = () => {
  const myRef = useRef(null);

  const handleDownloadPDF = async () => {
    /* const pdf = new jsPDF('landscape');
    const printContents = myRef.current;
    const canvas = await html2canvas(printContents);
    const imgData = canvas.toDataURL('image/png');
    pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight()); */

    const pdf = new jsPDF('landscape');
    const printContents = myRef.current;
    const canvas = await html2canvas(printContents);
    const imgData = canvas.toDataURL('image/png');
    const imgWidth = pdf.internal.pageSize.getWidth() ;
    const imgHeight = imgWidth * canvas.height / canvas.width;
    const x = pdf.internal.pageSize.getWidth() / 3 - imgWidth / 3;
    const y = pdf.internal.pageSize.getHeight() / 3 - imgHeight / 3;
    pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);


    pdf.save('my-certificate.pdf');
  };

  const location = useLocation();
        const course_name = location?.state?.course_name;
        const user_name = location?.state?.user_name;

  return (
     <div className={` ${course_name ? '' : 'pt-5 pb-5'}`} style={{ backgroundColor:'#eee' }} >
        <StudentPageTitle title='Mojaru Certificate'></StudentPageTitle>
            <div className={`container position-relative px-0`}  style={{ backgroundColor: "#fff", zIndex: 1 }} ref={myRef} id="printableArea">
                <div className="position-absolute top-0 end-0 question-bg-top-img">
                    <img src={certificateTop} alt="" className='img-fluid'/>
                </div>

                <div className="position-absolute" style={{ zIndex: -1, top:'25%', left:'35%'}}>
                    <img src={certificateMiddle} alt="" className='center-logo'/>
                </div>
                
                <div className="row m-0 p-4 h-100">
                    <div className='col-12 h-100 px-0'>
                        <div className='certificate-middle-section h-100 p-5'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                    <div className='d-flex justify-content-center'>
                                        <img src={logo} alt="" className='img-fluid'/>
                                    </div>
                                        {/* <div className="position-absolute ms-5" style={{ zIndex: -1}}>
                                            <img src={certificateMiddle} alt="" className='center-logo'/>
                                        </div> */}
                                    <p className='certificate-title text-center mb-0'>CERTIFICATE</p>
                                    <p className='certificate-achievement text-center mb-0'>OF ACHIEVEMENT</p>
                                    <div className='certificate-line'></div>
                                    <p className='certificate-student-name'>{user_name ? user_name : 'STUDENT NAME'}</p>
                                    <p className='certificate-des'><strong>Congratulations!</strong> We are proud of your achievements and know that you will continue to do great things in the future for the country.</p>
                                    <p className='certificate-course-name'>{course_name ? course_name : 'Course'}</p>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-6'>
                                    <div className='d-flex justify-content-center align-items-center h-100 p-lg-5'>
                                        <img src={certificateRight} alt="" className='img-fluid'/>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex justify-content-center' style={{ flexDirection:'column' }}>
                                    <div className='w-100 text-center mb-2'>
                                        
                                    <img src={signature} alt="" style={{ height:'50px', width:'100px' }}/>
                                    </div>
                                    <strong className='ceo-name mb-0'>Alauddin Faruqi Prince</strong>
                                    <p className='ceo-title text-center mb-0'>CEO, Mojaru</p>
                                </div>
                                <div>
                                {
                                    user_name && course_name &&
                                    <p className='certificate-date mb-0 text-center'>{certificateDate(new Date())}</p>
                                }
                                    <div className='date-divider'>
                                        <strong className='certificate-date-title text-center mb-2 w-100 d-block mb-0'>Date</strong>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>

                <div className="position-absolute bottom-0 start-0" style={{ zIndex: -1, paddingLeft: 0 }}>
                    <img src={certificateBottom} alt="" className='img-fluid'/>
                </div>
                
            </div>

            {
                user_name && course_name &&
                <div className='text-center mt-3 pb-5' id='btn-download'>
                    <input type="button" className='certificate-download-btn' onClick={handleDownloadPDF} value="Download Certificate" />
                </div>
            }
            
        </div>
        
      
    
  );
};

export default MyComponent;
