import React, { useEffect, useRef, useState } from "react";
import {
  HiMiniBars3,
  HiBell,
  HiXMark,
  HiPlus,
  HiChevronDown,
  HiChevronUp,
  HiPresentationChartLine,
} from "react-icons/hi2";
import {
  HiRefresh,
  HiSearch,
  HiUser,
  HiUsers,
  HiVideoCamera,
  HiTrendingUp,
  HiArrowRight,
  HiSearchCircle,
} from "react-icons/hi";
import logo from "../../../../images/HomePageImage/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import bnLogo from "../../../../images/HomePageImage/bn_logo.svg";
import ukLogo from "../../../../images/HomePageImage/UK.png";
import courseLink from "../../../../images/HomePageImage/Course.svg";
import profilePic from "../../../../images/Navbar/Profile placeholder.svg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import dot from "../../../../images/HomePageImage/Dot.svg";
import appStore from "../../../../images/HomePageImage/AppPlatform/AppStore.png";
import playStore from "../../../../images/HomePageImage/AppPlatform/Google-Play.png";
import CourseCategory from "./components/CourseCategory";
import MobileMenu from "./components/MobileMenu";
import MobileSearch from "./MobileSearch";
import "./style.css";

const Navbar = ({ location }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState("bn");
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [navLinks, setNavLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [showAllCourse, setShowAllCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [studentInfo, setStudentInfo] = useState();
  const [settings, setSettings] = useState([]);
  const { t } = useTranslation();
  const [allCourse, setAllCourse] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const dropdownRef = useRef(null);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [suggestedCourse, setSuggestedCourse] = useState("");

  const [isInputOpen, setIsInputOpen] = useState(false);

  const navigate = useNavigate();

  const toggleDropdown = () => setAllCourse(!allCourse);

  const handleInputField = () => {
    setIsInputOpen(true);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setAllCourse(false);
      setIsInputOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSubMenu = (menu) => {
    setOpenSubMenu(openSubMenu === menu ? null : menu);
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  const handleSuggestionsClick = (suggestion) => {
    setSearchValue(suggestion);
    handleSearch(suggestion);
  };

  const handleOnChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSearchValue(value);
    setSearchInputValue(value);
    if (value.length >= 2) {
      debounceHandleSuggest(value);
      setIsInputOpen(true);
    } else {
      setSuggestedCourse([]);
    }
  };

  const handleKeyPress = (event) => {
    const key = event.key;
    if (key === "Enter") {
      handleSearch(searchValue);
    } else if (/^[a-zA-Z]$/.test(key)) {
    }
  };

  const debounceHandleSuggest = debounce((value) => {
    handleSuggest(value);
  }, 300);

  const handleSearch = async (searchQuery) => {
    setIsLoading(true);
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/search?q=${searchQuery}`
      );
      response = await response.json();
      setIsInputOpen(false);
      navigate("/search", { state: { searchResults: response } });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuggest = async (suggestQuery) => {
    setIsLoading(true);
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/suggest?q=${suggestQuery}`
      );
      response = await response.json();
      if (response?.data?.courses) {
        setSuggestedCourse(response?.data?.courses);
      } else {
        setSuggestedCourse([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchSearch() {
      let data = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/course-sub-categories`
      );
      data = await data.json();
      setCategories(data?.data?.course_sub_categories);
      setIsLoading(false);
    }
    fetchSearch();
  }, []);

  const fetchApi = async (categoryId) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/sub-categories/${categoryId}/courses`
      );
      response = await response.json();
      setCourses(response.data.courses);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handelCourse = (category) => {
    setShowAllCourse((prev) =>
      prev === category?.course_sub_category_name
        ? null
        : category?.course_sub_category_name
    );

    if (showAllCourse !== category?.course_sub_category_name) {
      fetchApi(category?.id);
    }
  };

  const handleSubCategoryCourse = (category) => {
    setSelectedCategory(category?.course_sub_category_name);
    fetchApi(category?.id);
  };

  let user = localStorage.getItem("user_id");

  useEffect(() => {
    async function fetchWebsiteSetting() {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/settings`
      );
      response = await response.json();
      setSettings(response.data.settings);
    }

    async function fetchMyAPI() {
      let response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL_STUDENT
        }profile?user_id=${localStorage.getItem("user_id")}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
        }
      );
      response = await response.json();
      setStudentInfo(response?.data?.student_detail);
    }
    if (localStorage.getItem("user_id")) fetchMyAPI();

    fetchWebsiteSetting();
  }, [user]);

  const isUserExist = localStorage.getItem("auth_token");

  let AuthButton = "";

  // Previous Auth design Start *****
  // --------------------------------

  if (!localStorage.getItem("auth_token")) {
    AuthButton = (
      <Link to="/login">
        <button className="tw-hidden md:tw-flex tw-items-center tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-gray-700 tw-font-semibold">
          <HiUser className="tw-mr-2 tw-text-xl tw-font-semibold" />
          <p>{t("LogIn")}</p>
        </button>
        <button className="md:tw-hidden tw-flex tw-items-center tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl">
          <HiUser className="tw-mr-2 tw-text-lg" />
          <p>{t("LogIn")}</p>
        </button>
      </Link>
    );
  } else {
    AuthButton = (
      <Link to="/dashboard">
        <img
          className="tw-rounded-full tw-h-9 tw-w-9"
          src={
            studentInfo?.profile_picture
              ? `https://mojaru-prod.s3.ap-southeast-1.amazonaws.com${studentInfo.profile_picture}`
              : profilePic
          }
          alt="img"
        />
      </Link>
    );
  }
  // --------------------------------

  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");
    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem("useLanguage");

    if (getLanguage === "bn") {
      sessionStorage.setItem("useLanguage", "en");
      i18next.changeLanguage("en");
      setLanguage("en");
    } else {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    }
  }

  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // -------------------Mobile search-------------------------
  const handleSearchOpen = (e, value) => {
    e.preventDefault();
    setIsSearchVisible(value);
  };

  // useEffect to handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsSearchVisible(false); // Hide MobileSearch on lg screen and above
      }
    };

    // Attach resize listener
    window.addEventListener("resize", handleResize);

    // Initial check in case the component mounts on a larger screen
    handleResize();

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="tw-relative"
      style={
        ["/one-to-one", "/batch", "/eft", "/book-demo-class", "/ela"].includes(
          location.pathname
        )
          ? { display: "none" }
          : { display: "block" }
      }
    >
      <div className="tw-fixed tw-top-0 tw-bg-[#532D80] tw-h-[70px] md:tw-h-[88px] tw-px-4 xl:tw-px-6 tw-py-4 md:tw-py-6 tw-shadow tw-w-full tw-z-50">
        <div className="tw-w-full tw-max-w-[1440px] tw-mx-auto tw-relative ">
          <div className=" tw-flex tw-items-center tw-justify-between tw-flex-wrap">
            <div className="tw-flex tw-justify-between tw-items-center">
              {/* Mobile Menu Button */}
              <div className="tw-flex tw-items-center xl:tw-hidden">
                <button
                  className="tw-group tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-px-2 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 tw-mr-3"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span className="tw-sr-only">Open main menu</span>
                  {isOpen ? (
                    <HiXMark aria-hidden="true" className="tw-h-6 tw-w-6" />
                  ) : (
                    <HiMiniBars3 aria-hidden="true" className="tw-h-6 tw-w-6" />
                  )}
                </button>
              </div>

              {/* Logo */}
              <Link
                to="/"
                className={`${
                  isInputOpen ? "tw-hidden md:tw-block" : "tw-block"
                } md:tw-flex tw-items-center`}
              >
                <img
                  alt="mojaru"
                  src={logo}
                  className="tw-w-[120px] md:tw-w-[133px] md:tw-h-8 tw-flex-shrink-0"
                />
              </Link>

              {/* Course Dropdown (hidden on mobile) */}
              <button
                onClick={toggleDropdown}
                className="tw-hidden xl:tw-inline-flex tw-px-4 tw-py-2 tw-rounded-3xl tw-bg-white tw-items-center tw-text-left tw-ml-6 tw-relative"
              >
                <span className="tw-mr-2">{t("All courses")}</span>
                {allCourse ? <HiChevronUp /> : <HiChevronDown />}
              </button>

              {allCourse && (
                <div
                  ref={dropdownRef}
                  className="tw-hidden xl:tw-block tw-bg-white tw-absolute tw-top-16 tw-left-0 tw-rounded-bl-3xl tw-rounded-br-3xl tw-shadow-lg tw-px-6"
                >
                  <CourseCategory
                    categories={categories}
                    handleSubCategoryCourse={handleSubCategoryCourse}
                    selectedCategory={selectedCategory}
                    setAllCourse={setAllCourse}
                    courses={courses}
                  />
                </div>
              )}

              {/* Search Input and Suggestions */}
              <div
                className={`tw-ml-2 tw-hidden xl:tw-inline-block ${
                  isInputOpen
                    ? "tw-w-[270px]  xl:tw-w-[300px]"
                    : "tw-w-8 md:tw-w-[270px] xl:tw-w-[300px]"
                } tw-relative tw-flex tw-items-center tw-px-2`}
                onClick={handleInputField}
              >
                <div className="tw-w-full">
                  <label htmlFor="search" className="tw-sr-only">
                    Search
                  </label>

                  <div className="tw-relative">
                    <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
                      <HiSearch
                        aria-hidden="true"
                        className="tw-h-5 tw-w-5 tw-text-gray-400"
                      />
                    </div>

                    <input
                      id="search"
                      name="search"
                      type="search"
                      placeholder={t("Search you favourite courses")}
                      value={searchValue}
                      onChange={handleOnChange}
                      onKeyDown={handleKeyPress}
                      className={`tw-w-full tw-pl-10 tw-pr-3 tw-bg-white tw-text-gray-900 tw-ring-1 tw-ring-gray-300 tw-py-2 tw-placeholder:text-gray-400 tw-border-0 tw-focus:ring-2 tw-focus:ring-indigo-600 sm:tw-text-sm ${
                        isInputOpen
                          ? "tw-rounded-tl-3xl tw-rounded-tr-3xl"
                          : "tw-rounded-3xl"
                      }`}
                    />
                  </div>
                </div>

                {/* Suggestions Dropdown */}
                {isInputOpen && (
                  <div
                    ref={dropdownRef}
                    // className="tw-absolute tw-top-[100%] tw-w-[285px] md:tw-w-[285px] tw-bg-white tw-rounded-bl-3xl tw-rounded-br-3xl tw-shadow-lg tw-py-2 tw-z-50"
                    className="tw-absolute tw-top-[100%] tw-w-[255px]  xl:tw-w-[285px] tw-bg-white tw-rounded-bl-3xl tw-rounded-br-3xl tw-shadow-lg tw-py-2 tw-z-50"
                  >
                    <div className="tw-px-4">
                      {suggestedCourse.length > 0 ? (
                        suggestedCourse.map((course, idx) => (
                          <Link
                            onClick={() => setIsInputOpen(false)}
                            to={`${course?.course_category_link}/${course?.course_slug}`}
                            key={idx}
                            className="tw-flex tw-justify-between tw-items-center tw-py-1 tw-text-gray-500 tw-cursor-pointer"
                          >
                            <p>{course?.course_name}</p>
                            <HiTrendingUp className="tw-text-gray-500" />
                          </Link>
                        ))
                      ) : (
                        <>
                          <p className="tw-py-1 tw-text-gray-800">
                            জনপ্রিয় সার্চ
                          </p>
                          {[
                            "ছোটদের কুরআন শিক্ষা",
                            "Abacus Mind Math Buds",
                            "Smart English for Kids",
                            "মজার গণিত ৫",
                          ].map((text, idx) => (
                            <div
                              onClick={() => handleSuggestionsClick(text)}
                              key={idx}
                              className="tw-flex tw-justify-between tw-items-center tw-py-1 tw-text-gray-500 tw-cursor-pointer"
                            >
                              <p>{text}</p>
                              <HiTrendingUp />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Desktop Links */}
            <div className="tw-flex tw-justify-between tw-items-center">
              {/* Desktop Links */}
              <div className="tw-hidden lg:tw-flex tw-space-x-4 tw-text-white tw-mr-6">
                <Link
                  to="/live"
                  className="tw-text-base tw-font-medium tw-text-white hover:tw-text-white"
                >
                  {t("Live Courses")}
                </Link>
                <Link
                  to="/record"
                  className="tw-text-base tw-font-medium tw-text-white hover:tw-text-white"
                >
                  {t("Recorded Courses")}
                </Link>
                <Link
                  to="/free"
                  className="tw-text-base tw-font-medium tw-text-white hover:tw-text-white"
                >
                  {t("Free Courses")}
                </Link>
              </div>

              {/* Search icon  */}
              <div className="tw-mr-2 md:tw-mr-4 tw-flex lg:tw-hidden">
                <HiSearchCircle
                  className=" tw-text-white"
                  size={40}
                  onClick={(e) => {
                    handleSearchOpen(e, true);
                  }}
                />
              </div>

              {/* Language and Auth (Desktop) */}
              <div className="tw-hidden md:tw-flex tw-items-center tw-space-x-3">
                <button
                  onClick={toggleLanguage}
                  className="tw-flex tw-items-center tw-bg-white tw-rounded-3xl tw-px-4 tw-py-2"
                >
                  <img
                    className="tw-mr-2"
                    src={language === "bn" ? bnLogo : ukLogo}
                    alt="bn logo"
                  />
                  <span className="tw-text-gray-600 tw-font-semibold">
                    {language === "bn" ? <>BN</> : <>EN</>}
                  </span>
                </button>
                {AuthButton}
              </div>

              {/* Mobile Auth and Language */}
              <div
                className={`${
                  isInputOpen ? "tw-hidden" : "tw-block"
                } tw-flex md:tw-hidden tw-items-center`}
              >
                {isUserExist ? (
                  <Link to="/dashboard">
                    <img
                      className="tw-rounded-full tw-h-8 tw-w-8"
                      src={
                        studentInfo?.profile_picture
                          ? `https://mojaru-prod.s3.ap-southeast-1.amazonaws.com${studentInfo.profile_picture}`
                          : profilePic
                      }
                      alt="img"
                    />
                  </Link>
                ) : (
                  <Link to="/login">
                    <HiUser className="tw-text-4xl tw-text-white" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Search  */}

        {isSearchVisible && (
          <div className="tw-absolute tw-w-full -tw-ml-4 md:-tw-ml-2 animate-slideDown">
            <MobileSearch
              handleSearchOpen={(e, value) => handleSearchOpen(e, value)}
            />
          </div>
        )}
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <MobileMenu
          toggleSubMenu={toggleSubMenu}
          openSubMenu={openSubMenu}
          categories={categories}
          handelCourse={handelCourse}
          showAllCourse={showAllCourse}
          courses={courses}
          setIsOpen={setIsOpen}
          toggleLanguage={toggleLanguage}
          language={language}
        />
      )}
    </div>
  );
};
export default Navbar;

// Previous Auth design Start *****

// if (!localStorage.getItem("auth_token")) {
//   AuthButton = (
//     <Link to="/login">
//       <button className="tw-hidden md:tw-flex tw-items-center tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-gray-700 tw-font-semibold">
//         <HiUser className="tw-mr-2 tw-text-xl tw-font-semibold" />
//         <p>{t("LogIn")}</p>
//       </button>
//       <button className="md:tw-hidden tw-flex tw-items-center tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl">
//         <HiUser className="tw-mr-2 tw-text-lg" />
//         <p>{t("LogIn")}</p>
//       </button>
//     </Link>
//   );
// } else {
//   AuthButton = (
//     <Link to="/dashboard">
//       <button className="tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-gray-700 tw-font-semibold tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
//         <p>
//           {studentInfo?.user_name
//             ? studentInfo.user_name.split(" ").slice(0, 4).join(" ")
//             : "Student Name"}
//         </p>
//       </button>
//     </Link>
//   );
// }

// Previous Auth design End *****

// --------------------------------

// import React, { useEffect, useRef, useState } from "react";
// import {
//   HiMiniBars3,
//   HiBell,
//   HiXMark,
//   HiPlus,
//   HiChevronDown,
//   HiChevronUp,
//   HiPresentationChartLine,
// } from "react-icons/hi2";
// import {
//   HiRefresh,
//   HiSearch,
//   HiUser,
//   HiUsers,
//   HiVideoCamera,
//   HiTrendingUp,
//   HiArrowRight,
//   HiSearchCircle,
// } from "react-icons/hi";
// import logo from "../../../../images/HomePageImage/Logo.png";
// import { Link, useNavigate } from "react-router-dom";
// import bnLogo from "../../../../images/HomePageImage/bn_logo.svg";
// import ukLogo from "../../../../images/HomePageImage/UK.png";
// import courseLink from "../../../../images/HomePageImage/Course.svg";
// import i18next from "i18next";
// import { useTranslation } from "react-i18next";
// import dot from "../../../../images/HomePageImage/Dot.svg";
// import appStore from "../../../../images/HomePageImage/AppPlatform/AppStore.png";
// import playStore from "../../../../images/HomePageImage/AppPlatform/Google-Play.png";
// import CourseCategory from "./components/CourseCategory";
// import MobileMenu from "./components/MobileMenu";
// import MobileSearch from "./MobileSearch";
// import "./style.css";

// const Navbar = ({ location }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [language, setLanguage] = useState("bn");
//   const [openSubMenu, setOpenSubMenu] = useState(null);
//   const [navLinks, setNavLinks] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [categories, setCategories] = useState([]);
//   const [showAllCourse, setShowAllCourse] = useState(null);
//   const [courses, setCourses] = useState([]);
//   const [studentInfo, setStudentInfo] = useState();
//   const [settings, setSettings] = useState([]);
//   const { t } = useTranslation();
//   const [allCourse, setAllCourse] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [searchValue, setSearchValue] = useState("");
//   const dropdownRef = useRef(null);
//   const [searchInputValue, setSearchInputValue] = useState("");
//   const [suggestedCourse, setSuggestedCourse] = useState("");

//   const [isInputOpen, setIsInputOpen] = useState(false);

//   const navigate = useNavigate();

//   const toggleDropdown = () => setAllCourse(!allCourse);

//   const handleInputField = () => {
//     setIsInputOpen(true);
//   };

//   const handleClickOutside = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//       setAllCourse(false);
//       setIsInputOpen(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const toggleSubMenu = (menu) => {
//     setOpenSubMenu(openSubMenu === menu ? null : menu);
//   };

//   const debounce = (func, delay) => {
//     let debounceTimer;
//     return (...args) => {
//       clearTimeout(debounceTimer);
//       debounceTimer = setTimeout(() => func(...args), delay);
//     };
//   };

//   const handleSuggestionsClick = (suggestion) => {
//     setSearchValue(suggestion);
//     handleSearch(suggestion);
//   };

//   const handleOnChange = (event) => {
//     event.preventDefault();
//     const value = event.target.value;
//     setSearchValue(value);
//     setSearchInputValue(value);
//     if (value.length >= 2) {
//       debounceHandleSuggest(value);
//       setIsInputOpen(true);
//     } else {
//       setSuggestedCourse([]);
//     }
//   };

//   const handleKeyPress = (event) => {
//     const key = event.key;
//     if (key === "Enter") {
//       handleSearch(searchValue);
//     } else if (/^[a-zA-Z]$/.test(key)) {
//     }
//   };

//   const debounceHandleSuggest = debounce((value) => {
//     handleSuggest(value);
//   }, 300);

//   const handleSearch = async (searchQuery) => {
//     setIsLoading(true);
//     try {
//       let response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}api/v2/search?q=${searchQuery}`
//       );
//       response = await response.json();
//       setIsInputOpen(false);
//       navigate("/search", { state: { searchResults: response } });
//     } catch (err) {
//       console.error(err);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleSuggest = async (suggestQuery) => {
//     setIsLoading(true);
//     try {
//       let response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}api/v2/suggest?q=${suggestQuery}`
//       );
//       response = await response.json();
//       if (response?.data?.courses) {
//         setSuggestedCourse(response?.data?.courses);
//       } else {
//         setSuggestedCourse([]);
//       }
//     } catch (err) {
//       console.error(err);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     async function fetchSearch() {
//       let data = await fetch(
//         `${process.env.REACT_APP_BASE_URL}api/v2/course-sub-categories`
//       );
//       data = await data.json();
//       setCategories(data?.data?.course_sub_categories);
//       setIsLoading(false);
//     }
//     fetchSearch();
//   }, []);

//   const fetchApi = async (categoryId) => {
//     try {
//       setIsLoading(true);
//       let response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}api/v2/sub-categories/${categoryId}/courses`
//       );
//       response = await response.json();
//       setCourses(response.data.courses);
//     } catch (err) {
//       console.log(err);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handelCourse = (category) => {
//     setShowAllCourse((prev) =>
//       prev === category?.course_sub_category_name
//         ? null
//         : category?.course_sub_category_name
//     );

//     if (showAllCourse !== category?.course_sub_category_name) {
//       fetchApi(category?.id);
//     }
//   };

//   const handleSubCategoryCourse = (category) => {
//     setSelectedCategory(category?.course_sub_category_name);
//     fetchApi(category?.id);
//   };

//   let user = localStorage.getItem("user_id");
//   useEffect(() => {
//     async function fetchWebsiteSetting() {
//       let response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}api/settings`
//       );
//       response = await response.json();
//       setSettings(response.data.settings);
//     }

//     async function fetchMyAPI() {
//       let response = await fetch(
//         `${
//           process.env.REACT_APP_BASE_URL_STUDENT
//         }profile?user_id=${localStorage.getItem("user_id")}`,
//         {
//           method: "GET",
//           headers: {
//             "content-type": "application/json",
//             accept: "application/json",
//             Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
//             SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
//           },
//         }
//       );
//       response = await response.json();
//       setStudentInfo(response?.data?.student_detail);
//     }
//     if (localStorage.getItem("user_id")) fetchMyAPI();

//     fetchWebsiteSetting();
//   }, [user]);

//   const isUserExist = localStorage.getItem("auth_token");

//   let AuthButton = "";

//   // Previous Auth design Start *****

//   // if (!localStorage.getItem("auth_token")) {
//   //   AuthButton = (
//   //     <Link to="/login">
//   //       <button className="tw-hidden md:tw-flex tw-items-center tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-gray-700 tw-font-semibold">
//   //         <HiUser className="tw-mr-2 tw-text-xl tw-font-semibold" />
//   //         <p>{t("LogIn")}</p>
//   //       </button>
//   //       <button className="md:tw-hidden tw-flex tw-items-center tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl">
//   //         <HiUser className="tw-mr-2 tw-text-lg" />
//   //         <p>{t("LogIn")}</p>
//   //       </button>
//   //     </Link>
//   //   );
//   // } else {
//   //   AuthButton = (
//   //     <Link to="/dashboard">
//   //       <button className="tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-gray-700 tw-font-semibold tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
//   //         <p>
//   //           {studentInfo?.user_name
//   //             ? studentInfo.user_name.split(" ").slice(0, 4).join(" ")
//   //             : "Student Name"}
//   //         </p>
//   //       </button>
//   //     </Link>
//   //   );
//   // }

//   // Previous Auth design End *****

//   // --------------------------------
//   if (!localStorage.getItem("auth_token")) {
//     AuthButton = (
//       <Link to="/login">
//         <button className="tw-hidden md:tw-flex tw-items-center tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-gray-700 tw-font-semibold">
//           <HiUser className="tw-mr-2 tw-text-xl tw-font-semibold" />
//           <p>{t("LogIn")}</p>
//         </button>
//         <button className="md:tw-hidden tw-flex tw-items-center tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl">
//           <HiUser className="tw-mr-2 tw-text-lg" />
//           <p>{t("LogIn")}</p>
//         </button>
//       </Link>
//     );
//   } else {
//     AuthButton = (
//       <Link to="/dashboard">
//         <button className="tw-text-base tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-3xl tw-text-gray-700 tw-font-semibold tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
//           <p>
//             {studentInfo && studentInfo?.user_name
//               ? studentInfo?.user_name.slice(0, 4) + ".." // Get only the first word
//               : "Student Name"}
//           </p>
//         </button>
//       </Link>
//     );
//   }
//   // --------------------------------

//   useEffect(() => {
//     const useLanguage = sessionStorage.getItem("useLanguage");
//     if (!useLanguage) {
//       sessionStorage.setItem("useLanguage", "bn");
//       i18next.changeLanguage("bn");
//       setLanguage("bn");
//     } else {
//       i18next.changeLanguage(useLanguage);
//       setLanguage(useLanguage);
//     }
//   }, []);

//   function toggleLanguage() {
//     const getLanguage = sessionStorage.getItem("useLanguage");

//     if (getLanguage === "bn") {
//       sessionStorage.setItem("useLanguage", "en");
//       i18next.changeLanguage("en");
//       setLanguage("en");
//     } else {
//       sessionStorage.setItem("useLanguage", "bn");
//       i18next.changeLanguage("bn");
//       setLanguage("bn");
//     }
//   }

//   const [isSearchVisible, setIsSearchVisible] = useState(false);

//   // -------------------Mobile search-------------------------
//   const handleSearchOpen = (e, value) => {
//     e.preventDefault();
//     setIsSearchVisible(value);
//   };

//   // useEffect to handle screen resizing
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 1024) {
//         setIsSearchVisible(false); // Hide MobileSearch on lg screen and above
//       }
//     };

//     // Attach resize listener
//     window.addEventListener("resize", handleResize);

//     // Initial check in case the component mounts on a larger screen
//     handleResize();

//     // Cleanup listener on component unmount
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return (
//     <div
//       className="tw-relative"
//       style={
//         ["/one-to-one", "/batch", "/eft", "/book-demo-class", "/ela"].includes(
//           location.pathname
//         )
//           ? { display: "none" }
//           : { display: "block" }
//       }
//     >
//       <div className="tw-fixed tw-top-0 tw-bg-[#532D80] tw-h-[70px] md:tw-h-[88px] tw-px-4 xl:tw-px-6 tw-py-4 md:tw-py-6 tw-shadow tw-w-full tw-z-50">
//         <div className="tw-w-full tw-max-w-[1440px] tw-mx-auto tw-relative ">
//           <div className=" tw-flex tw-items-center tw-justify-between tw-flex-wrap">
//             <div className="tw-flex tw-justify-between tw-items-center">
//               {/* Mobile Menu Button */}
//               <div className="tw-flex tw-items-center xl:tw-hidden">
//                 <button
//                   className="tw-group tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-px-2 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 tw-mr-3"
//                   onClick={() => setIsOpen(!isOpen)}
//                 >
//                   <span className="tw-sr-only">Open main menu</span>
//                   {isOpen ? (
//                     <HiXMark aria-hidden="true" className="tw-h-6 tw-w-6" />
//                   ) : (
//                     <HiMiniBars3 aria-hidden="true" className="tw-h-6 tw-w-6" />
//                   )}
//                 </button>
//               </div>

//               {/* Logo */}
//               <Link
//                 to="/"
//                 className={`${
//                   isInputOpen ? "tw-hidden md:tw-block" : "tw-block"
//                 } md:tw-flex tw-items-center`}
//               >
//                 <img
//                   alt="mojaru"
//                   src={logo}
//                   className="tw-w-[120px] md:tw-w-[133px] md:tw-h-8 tw-flex-shrink-0"
//                 />
//               </Link>

//               {/* Course Dropdown (hidden on mobile) */}
//               <button
//                 onClick={toggleDropdown}
//                 className="tw-hidden xl:tw-inline-flex tw-px-4 tw-py-2 tw-rounded-3xl tw-bg-white tw-items-center tw-text-left tw-ml-6 tw-relative"
//               >
//                 <span className="tw-mr-2">{t("All courses")}</span>
//                 {allCourse ? <HiChevronUp /> : <HiChevronDown />}
//               </button>

//               {allCourse && (
//                 <div
//                   ref={dropdownRef}
//                   className="tw-hidden xl:tw-block tw-bg-white tw-absolute tw-top-16 tw-left-0 tw-rounded-bl-3xl tw-rounded-br-3xl tw-shadow-lg tw-px-6"
//                 >
//                   <CourseCategory
//                     categories={categories}
//                     handleSubCategoryCourse={handleSubCategoryCourse}
//                     selectedCategory={selectedCategory}
//                     setAllCourse={setAllCourse}
//                     courses={courses}
//                   />
//                 </div>
//               )}

//               {/* Search Input and Suggestions */}
//               <div
//                 className={`tw-ml-2 tw-hidden lg:tw-inline-block ${
//                   isInputOpen
//                     ? "tw-w-[300px] md:tw-w-[300px]"
//                     : "tw-w-8 md:tw-w-[300px]"
//                 } tw-relative tw-flex tw-items-center tw-px-2`}
//                 onClick={handleInputField}
//               >
//                 <div className="tw-w-full">
//                   <label htmlFor="search" className="tw-sr-only">
//                     Search
//                   </label>

//                   <div className="tw-relative">
//                     <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
//                       <HiSearch
//                         aria-hidden="true"
//                         className="tw-h-5 tw-w-5 tw-text-gray-400"
//                       />
//                     </div>

//                     <input
//                       id="search"
//                       name="search"
//                       type="search"
//                       placeholder={t("Search you favourite courses")}
//                       value={searchValue}
//                       onChange={handleOnChange}
//                       onKeyDown={handleKeyPress}
//                       className={`tw-w-full tw-pl-10 tw-pr-3 tw-bg-white tw-text-gray-900 tw-ring-1 tw-ring-gray-300 tw-py-2 tw-placeholder:text-gray-400 tw-border-0 tw-focus:ring-2 tw-focus:ring-indigo-600 sm:tw-text-sm ${
//                         isInputOpen
//                           ? "tw-rounded-tl-3xl tw-rounded-tr-3xl"
//                           : "tw-rounded-3xl"
//                       }`}
//                     />
//                   </div>
//                 </div>

//                 {/* Suggestions Dropdown */}
//                 {isInputOpen && (
//                   <div
//                     ref={dropdownRef}
//                     className="tw-absolute tw-top-[100%] tw-w-[285px] md:tw-w-[285px] tw-bg-white tw-rounded-bl-3xl tw-rounded-br-3xl tw-shadow-lg tw-py-2 tw-z-50"
//                   >
//                     <div className="tw-px-4">
//                       {suggestedCourse.length > 0 ? (
//                         suggestedCourse.map((course, idx) => (
//                           <Link
//                             onClick={() => setIsInputOpen(false)}
//                             to={`${course?.course_category_link}/${course?.course_slug}`}
//                             key={idx}
//                             className="tw-flex tw-justify-between tw-items-center tw-py-1 tw-text-gray-500 tw-cursor-pointer"
//                           >
//                             <p>{course?.course_name}</p>
//                             <HiTrendingUp className="tw-text-gray-500" />
//                           </Link>
//                         ))
//                       ) : (
//                         <>
//                           <p className="tw-py-1 tw-text-gray-800">
//                             জনপ্রিয় সার্চ
//                           </p>
//                           {[
//                             "ছোটদের কুরআন শিক্ষা",
//                             "Abacus Mind Math Buds",
//                             "Smart English for Kids",
//                             "মজার গণিত ৫",
//                           ].map((text, idx) => (
//                             <div
//                               onClick={() => handleSuggestionsClick(text)}
//                               key={idx}
//                               className="tw-flex tw-justify-between tw-items-center tw-py-1 tw-text-gray-500 tw-cursor-pointer"
//                             >
//                               <p>{text}</p>
//                               <HiTrendingUp />
//                             </div>
//                           ))}
//                         </>
//                       )}
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>

//             {/* Desktop Links */}
//             <div className="tw-flex tw-justify-between tw-items-center">
//               {/* Desktop Links */}
//               <div className="tw-hidden xl:tw-flex tw-space-x-4 tw-text-white tw-mr-6">
//                 <Link
//                   to="/live"
//                   className="tw-text-base tw-font-medium tw-text-white hover:tw-text-white"
//                 >
//                   {t("Live Courses")}
//                 </Link>
//                 <Link
//                   to="/record"
//                   className="tw-text-base tw-font-medium tw-text-white hover:tw-text-white"
//                 >
//                   {t("Recorded Courses")}
//                 </Link>
//                 <Link
//                   to="/free"
//                   className="tw-text-base tw-font-medium tw-text-white hover:tw-text-white"
//                 >
//                   {t("Free Courses")}
//                 </Link>
//               </div>

//               {/* Search icon  */}
//               <div className="tw-mr-2 md:tw-mr-4 tw-flex lg:tw-hidden">
//                 <HiSearchCircle
//                   className=" tw-text-white"
//                   size={40}
//                   onClick={(e) => {
//                     handleSearchOpen(e, true);
//                   }}
//                 />
//               </div>

//               {/* Language and Auth (Desktop) */}
//               <div className="tw-hidden md:tw-flex tw-items-center tw-space-x-6">
//                 <button
//                   onClick={toggleLanguage}
//                   className="tw-flex tw-items-center tw-bg-white tw-rounded-3xl tw-px-4 tw-py-2"
//                 >
//                   <img
//                     className="tw-mr-2"
//                     src={language === "bn" ? bnLogo : ukLogo}
//                     alt="bn logo"
//                   />
//                   <span className="tw-text-gray-600 tw-font-semibold">
//                     {language === "bn" ? <>BN</> : <>EN</>}
//                   </span>
//                 </button>
//                 {AuthButton}
//               </div>

//               {/* Mobile Auth and Language */}
//               <div
//                 className={`${
//                   isInputOpen ? "tw-hidden" : "tw-block"
//                 } tw-flex md:tw-hidden tw-items-center`}
//               >
//                 {isUserExist ? (
//                   <Link to="/dashboard">
//                     <img
//                       className="tw-w-8 tw-rounded-full"
//                       src={`https://mojaru-prod.s3.ap-southeast-1.amazonaws.com${studentInfo?.profile_picture}`}
//                       alt="profile image"
//                     />
//                   </Link>
//                 ) : (
//                   <Link to="/login">
//                     <HiUser className="tw-text-4xl tw-text-white" />
//                   </Link>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Mobile Search  */}

//         {isSearchVisible && (
//           <div className="tw-absolute tw-w-full -tw-ml-4 md:-tw-ml-2 animate-slideDown">
//             <MobileSearch
//               handleSearchOpen={(e, value) => handleSearchOpen(e, value)}
//             />
//           </div>
//         )}
//       </div>

//       {/* Mobile Menu */}
//       {isOpen && (
//         <MobileMenu
//           toggleSubMenu={toggleSubMenu}
//           openSubMenu={openSubMenu}
//           categories={categories}
//           handelCourse={handelCourse}
//           showAllCourse={showAllCourse}
//           courses={courses}
//           setIsOpen={setIsOpen}
//           toggleLanguage={toggleLanguage}
//           language={language}
//         />
//       )}
//     </div>
//   );
// };
// export default Navbar;
