import React from 'react';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from '../../../../images/NotFound/NotFound.jpg';

const CourseNotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-text-center tw-p-6">
            <img src={NotFoundImage} alt="Course Not Found" className="tw-w-64 tw-h-64 tw-mb-4" />
            <h2 className="tw-text-3xl tw-font-bold tw-text-gray-700">Oops! No Courses Found</h2>
            <p className="tw-text-lg tw-text-gray-500 tw-mt-2">
                <p>It seems we don't have any courses available for you right now.</p>
                <p>Don't worry, new courses are coming soon!</p>
            </p>
            <button
                onClick={() => navigate('/all')}
                className="tw-mt-6 tw-bg-[#532D80] tw-text-white tw-py-2 tw-px-6 tw-rounded-full tw-text-lg tw-font-semibold tw-shadow-md tw-transition-all tw-duration-300 hover:tw-bg-purple-600"
            >
                Try Again
            </button>
        </div>
    );
};

export default CourseNotFound;
