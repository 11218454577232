import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Banner from './Banner/Banner';
import Card from './Card/Card';
import PageTitle from '../../shared/PageTitle/PageTitle';
import Loader from '../../shared/Loader/Loader';
import FreeDemoClass from '../../shared/FreeDemoClass/FreeDemoClass';
import ReactGA from "react-ga4";
import mixpanel from 'mixpanel-browser';
import TagManager from "react-gtm-module";
import utils from "../../../../utils/index";

const Academic = () => {
  const [categories, setCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let location = useLocation();
  let from = location?.state?.from;


  useEffect(()=>{
    TagManager.dataLayer({
        dataLayer:{
            event: 'pageView',
            pagePath:location.pathname,
            PageTitle: 'Academic' 
        }
    })
},[location])


  useEffect(() => {
    async function fetchMyAPI() {
        let response = await fetch(
            `${process.env.REACT_APP_BASE_URL}api/course-category-wings/${from}`
        );
        response = await response.json();
        setCategories(response.data);
        setIsLoading(false);
    }
    fetchMyAPI();
    ReactGA.event({
      category: `${from}`,
      action: `${from}-action`,
      label: `${from}-level`, // optional
      value: localStorage.getItem('mobile_or_email') ? Number(localStorage.getItem('mobile_or_email')) : undefined, // optional, must be a number
  });
  let advancedMatching = {};
  if(localStorage.getItem('mobile_or_email')){
    advancedMatching =  { 
      ph : Number(localStorage.getItem('mobile_or_email')),
   external_id: localStorage.getItem('user_id')
  };
}

  const pixelData = {page: 'Academic'};
  utils.addFacebookEvent('Page View', pixelData, advancedMatching);


  mixpanel.track(`${from}_visit`, {
    number : localStorage.getItem('mobile_or_email') ? Number(localStorage.getItem('mobile_or_email')) : 'Not Registered User'
});
},[from]);



if(isLoading){
        return (<Loader/>);
    }


    return (
        <div style={{ paddingBottom: "20rem" }}>
          <PageTitle title='Academic'></PageTitle>
            <FreeDemoClass/>
            <Banner></Banner>
                <ul className="nav nav-pills mb-3 mt-5 d-flex justify-content-center" id="pills-tab" role="tablist">
                {
                      categories?.course_categories?.map((category) =>{
                        return(category?.course_sub_categories?.map((subCategory, index)=> (
                          <li className="nav-item mx-2 py-1" role="presentation" key={index}>
                          <button className={`nav-link tabpen-links category-name py-1 ${index === 0 ? "active" : ""}`}id={`pills-category_${subCategory.course_sub_category_id}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-category_${subCategory.course_sub_category_id}`} type="button" role="tab" aria-controls={`pills-category_${subCategory.course_sub_category_id}`} aria-selected="true">{subCategory.course_sub_category_name}</button>
                        </li>
                        )))
                      })
                    }
                </ul>

                <div className="tab-content mt-5 mb-5" id="pills-tabContent">
              {
                categories?.course_categories?.map((category) =>{
                  return(category?.course_sub_categories?.map((subCategory, index)=> {
                    return(
                      <div className={`tab-pane fade ${index === 0  ? "active show" : ""}`} id={`pills-category_${subCategory.course_sub_category_id}`} role="tabpanel" aria-labelledby={`pills-category_${subCategory.course_sub_category_id}-tab`} key={subCategory.course_sub_category_id}>
                      <div className="container mt-5 mb-5">
                              <div className="row gy-4">
                                 {
                                    (categories?.courses?.map((course,index) =>{
                                      if(subCategory?.course_sub_category_id === course?.course_sub_category_id) {
                                        return <Card key={index} course={course} path_link={categories?.course_image_path} from={from}></Card>
                                      }
                                      return null;
                                    }))
                                  }
                              </div>
                          </div>
                          <p className='text-center mb-4' style={{ color: '#BDBCBE' }}>আপনি তালিকার শেষে পৌঁছে গিয়েছেন</p>
                        </div>
                    )
                  }))
                })
              }
            </div>

            {/* <div className='container'>

              {
                isLoading && <CourseCardLoader/>
              }
              {
                categories?.course_sub_categories?.map(subcategory => subcategory?.get_courses?.length == 0 ? 
                  ''
                  :
                  <div key={subcategory?.course_sub_category_name}>
                    <h4 className='mt-5 mb-3' style={{ color:'#532d80' }}>{subcategory?.course_sub_category_name}</h4>
                    <div className="row gy-2">
                      { 
                        subcategory?.get_courses?.map((course, index)=>
                        {
                          return <Card key={index} course={course} path_link={categories?.course_image_path}></Card>
                        }
                      )}
                    </div>
                  </div>
                )}

            </div>
        <MessengerCustomerChat themeColor="#532D80" pageId="101066028601535" appId="1272114613611586"/> */}
        </div>
    );
};

export default Academic;