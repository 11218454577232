import React, { useState } from 'react';
import logo from '../../../../../../images/logo.png';
import { userInfoStore } from "../../../../../../store/userInfoStore";
import iq from "../../../../../../images/quizImage/Mojaru-UI-Feature.png";

const CampaignForm = () => {
    const { setName, setPhone, setDob } = userInfoStore();
    const [existingUser, setExistingUser] = useState({});
    const [existingNumber, setExistingNumber] = useState(null);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [childAge, setChildAge] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [childAgeError, setChildAgeError] = useState('');

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
    };

    const handleChildAgeChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value !== '') {
            setChildAge(value);
            setChildAgeError('');
        } else {
            setChildAgeError('বয়স অবশ্যই সংখ্যা হতে হবে');
        }
    };

    const validatePhoneNumber = (number) => {
        const phoneNumberPattern = /^(01[3-9]\d{8})$/;
        if (phoneNumberPattern.test(number)) {
            setPhoneNumberError('');
            return true;
        } else {
            setPhoneNumberError('Invalid phone number! Please enter a valid phone number.');
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const number = phoneNumber;
        const dob = childAge;

        if (!validatePhoneNumber(number)) {
            return;
        }

        try {
            const params = new URLSearchParams({
                age: dob,
                phone_number: number,
                student_name: name,
                type: "free",
            });

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/free-exam-done?${params.toString()}`, {
                method: 'GET'
            });

            const data = await response.json();
            setExistingUser(data?.data);
            const existingNumber = data?.data?.phone_number;

            if (existingNumber) {
                setExistingNumber(existingNumber);
            } else {
                setName(name);
                setPhone(phoneNumber);
                setDob(dob);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    if (existingNumber) {
        return (
            <div className="min-h-screen bg-gray-100 flex items-center justify-center">
                <div className="bg-white p-8 rounded-md shadow-md">
                    <h2 className="text-xl font-bold mb-4">Name: {existingUser?.student_name}</h2>
                    <p className="text-gray-700 mb-2">Phone Number: {existingUser?.phone_number}</p>
                    <p className="text-gray-700 mb-2">Total Mark: {existingUser?.total_mark}</p>
                    <p className="text-gray-700 mb-2">Obtain Mark: {existingUser?.obtain_mark}</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="tw-flex tw-min-h-full tw-flex-1">
                <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-px-4 tw-py-12 sm:tw-px-6 lg:tw-flex-none lg:tw-px-20 xl:tw-px-24">
                    <div className="tw-mx-auto tw-w-full tw-max-w-sm lg:tw-w-96">
                        <div>
                            <img className="tw-h-10 tw-w-auto" src={logo} alt="Your Company" />
                            <h2 className="tw-mt-8 tw-text-2xl tw-font-bold tw-leading-9 tw-tracking-tight tw-text-gray-900">
                                প্রশ্নের উত্তর দাও
                            </h2>
                            <p className="tw-mt-2 tw-text-lg tw-leading-6 tw-text-gray-700 tw-font-semibold">
                                ব্রেইনের সক্ষমতা যাচাই করে নাও
                            </p>
                            <h2 className="tw-mt-8 tw-text-2xl tw-font-bold tw-leading-9 tw-tracking-tight tw-text-gray-900">
                                Your Information
                            </h2>
                        </div>

                        <div className="tw-mt-10">
                            <div>
                                <form onSubmit={handleSubmit} className="tw-space-y-6">
                                    <div>
                                        <label htmlFor="name" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Your name
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                                id="name"
                                                name="name"
                                                type="text"
                                                autoComplete="name"
                                                required
                                                className="tw-pl-4 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="phoneNumber" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Mobile Number
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                type="text"
                                                autoComplete="current-number"
                                                onChange={handlePhoneNumberChange}
                                                value={phoneNumber}
                                                placeholder="e.g. 01332555341"
                                                required
                                                className="tw-pl-4 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                            />
                                            <p className="tw-mt-2 tw-text-sm tw-text-red-700">{phoneNumberError}</p>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="dob" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Age
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                                id="dob"
                                                name="dob"
                                                value={childAge}
                                                type="text"
                                                autoComplete="current-number"
                                                onChange={handleChildAgeChange}
                                                required
                                                className="tw-pl-4 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                            />
                                            <p className="tw-mt-2 tw-text-sm tw-text-red-700">{childAgeError}</p>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="tw-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-1.5 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tw-relative tw-hidden tw-w-0 tw-flex-1 lg:tw-block">
                    <img className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-rounded-2xl" src={iq} alt="" />
                </div>
            </div>
        </div>
    );
};

export default CampaignForm;
