import React from 'react';
import image1 from '../../../../images/HomePageImage/success/1a.jpg';
import image2 from '../../../../images/HomePageImage/success/2a.jpg';
import image3 from '../../../../images/HomePageImage/success/3a.jpg';
import image4 from '../../../../images/HomePageImage/success/4a.jpg';
import image5 from '../../../../images/HomePageImage/success/5a.jpg';
import image6 from '../../../../images/HomePageImage/success/6a.jpg';
import image7 from '../../../../images/HomePageImage/success/7a.jpg';
import image8 from '../../../../images/HomePageImage/success/8a.jpg';
import image9 from '../../../../images/HomePageImage/success/9a.jpg';
import './SliderComponent.css';
const SliderComponent = () => {
    const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9];
    return (
        <div className="">
            <div className="">
                <div className="tw-flex tw-space-x-6 scroll-row">
                    {[...images, ...images].map((image, index) => (
                        <div key={index} className="tw-w-[338px] tw-flex-shrink-0">
                            <img src={image} alt={`slide-${index}`}
                                 className="tw-rounded-3xl tw-shadow-lg tw-w-full tw-h-[247px]"/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SliderComponent;
