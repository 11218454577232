import React from 'react';
import './ResultDetails.css';

const ResultDetails = ({index, studentName, profilePath, studentProfile, resultPercentage, examComplete, totalExam, exams}) => {


    let minMarkPercentage = parseInt(exams[0]?.average_marks_percentage);
    for (let i = 1; i < exams.length; ++i) {
        let averageMarksPercentage = parseInt(exams[i]?.average_marks_percentage);
        if (averageMarksPercentage < minMarkPercentage) {
            minMarkPercentage = averageMarksPercentage
        }
    }
    let maxMarkPercentage = parseInt(exams[0]?.average_marks_percentage);
    for (let i = 1; i < exams.length; ++i) {
        let averageMarksPercentage = parseInt(exams[i]?.average_marks_percentage);
        if (averageMarksPercentage > maxMarkPercentage) {
            maxMarkPercentage = averageMarksPercentage;
        }
    }

    return (
        <div className="offcanvas offcanvas-end result-details" tabIndex="-1" id={`offcanvasStudentResult_${index}`} aria-labelledby={`offcanvasStudentResultLabel_${index}`} style={{ overflowY:'auto' }}>
            <div className="offcanvas-header student-profile">
                    <h5 id="offcanvasRightLabel">My Result</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close">
                    </button>
                </div>
                <div className='total-assignment-divider'></div>
                <div className="offcanvas-body">
                    <div className='p-4'>
                        <div className='result-offcanvas-section mb-4'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <div className='d-flex align-items-center'>
                                    <img src={`${profilePath}${studentProfile}`} alt="" className='img-fluid' width={56} height={56} style={{ borderRadius:'50%' }}/>
                                    <p className='mb-0 ms-2 result-student-name'>{studentName}</p>
                                </div>
                                <p className='mb-0 total-percentage'>{resultPercentage ? resultPercentage : 0}%</p>
                            </div>

                            <div className='d-flex justify-content-around align-items-center'>
                                <div>
                                    <p className='mb-1 result-summary' style={{ color:'#918F92' }}>Exam Complete</p>
                                    <p className='mb-0 total-mark-percentage text-center balooda-medium'>{examComplete}/{totalExam}</p>
                                </div>
                                <div className='result-top-divider'></div>
                                <div>
                                    <p className='mb-1 result-summary' style={{ color:'#918F92' }}>Highest</p>
                                    <p className='mb-0 total-attend-assignment text-center balooda-medium'>{!isNaN(maxMarkPercentage) ? maxMarkPercentage : 0}%</p>
                                </div>
                                <div className='result-top-divider'></div>
                                <div>
                                    <p className='mb-1 result-summary' style={{ color:'#918F92' }}>Lowest</p>
                                    <p className='mb-0 total-attend-assignment text-center balooda-medium' style={{ color:'#D0202F' }}>{!isNaN(minMarkPercentage) ? minMarkPercentage : 0}%</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <p className='font-400'>List of Exams</p>
                            <div style={{ overflowY:'auto' }}>

                            {
                                exams.map((exam, index) =>

                                <div key={index}>
                                    <div className='d-flex align-items-center w-100'>
                                        <p className='mb-0 result-exam-count pe-4 balooda-medium'>#{index+1}</p>

                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <div>
                                                <p className='result-exam-name mb-1'>{exam?.assignment_name}</p>
                                                <p className='result-subject-name mb-0'>{exam?.subject_name}</p>
                                            </div>
                                            <div>
                                                <p className='result-marks mb-0 balooda-medium'>{Math.floor(exam?.average_marks_percentage)}%</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='divider-border my-3'></div> 
                                </div>
                                
                                )
                            }

                            </div>

                        
                            

                            {/* <div className='d-flex align-items-center w-100'>
                                <p className='mb-0 result-exam-count pe-4'>#1</p>

                                <div className='d-flex justify-content-between align-items-center w-100'>
                                    <div>
                                        <p className='result-exam-name mb-1'>Exam 101</p>
                                        <p className='result-subject-name mb-0'>Physics</p>
                                    </div>
                                    <div>
                                        <p className='result-marks mb-0'>80</p>
                                    </div>
                                </div>
                            </div>

                            <div className='divider-border my-3'></div>

                            <div className='d-flex align-items-center w-100'>
                                <p className='mb-0 result-exam-count pe-4'>#1</p>

                                <div className='d-flex justify-content-between align-items-center w-100'>
                                    <div>
                                        <p className='result-exam-name mb-1'>Exam 101</p>
                                        <p className='result-subject-name mb-0'>Physics</p>
                                    </div>
                                    <div>
                                        <p className='result-marks mb-0'>80</p>
                                    </div>
                                </div>
                            </div>

                            <div className='divider-border my-3'></div>

                            <div className='d-flex align-items-center w-100'>
                                <p className='mb-0 result-exam-count pe-4'>#1</p>

                                <div className='d-flex justify-content-between align-items-center w-100'>
                                    <div>
                                        <p className='result-exam-name mb-1'>Exam 101</p>
                                        <p className='result-subject-name mb-0'>Physics</p>
                                    </div>
                                    <div>
                                        <p className='result-marks mb-0'>80</p>
                                    </div>
                                </div>
                            </div> */}




                        </div>

                        
                    </div>
                </div>
        </div>
    );
};

export default ResultDetails;