import React, { useState } from "react";
import { englishToBengaliNumberFormat } from "../../../../../utilities/utilities";
import CourseEnroll from "./CourseEnroll/CourseEnroll";
import CourseFeatures from "./CourseFeatures/CourseFeatures";
import CourseTeachers from "./CourseTeachers/CourseTeachers";

const CourseShortInformation = ({
    courseDetail,
    course_features,
    course_teachers,
    courseSubjectCount,
    freeClassRegistration,
    isCoursePurchase,
    id,
    affiliate_id,
    open,
    handleTimeScheduleRef,
    isOneToOneCourse,
    setIsSelectOneToOne,
}) => {
    const [count, setCount] = useState(3);
    const handelLoadBook = () => {
        let addCount = parseInt(count) + 1;
        setCount(addCount);
    };
    const feature_length = course_features?.length;
    const teacher_length = course_teachers?.length;
    const remainTeacher = teacher_length - count;
    return (
        <div>{
            freeClassRegistration ? null : <CourseEnroll courseSubjectCount={courseSubjectCount} courseDetail={courseDetail} id={id} isCoursePurchase={isCoursePurchase} affiliate_id={affiliate_id} open={open} isOneToOneCourse={isOneToOneCourse} handleTimeScheduleRef={handleTimeScheduleRef} setIsSelectOneToOne={setIsSelectOneToOne} course_features={course_features}></CourseEnroll>
        }
            
            {/*<div className="course-feature-border">*/}
            {/*    <div className="course-feature">*/}
            {/*        <p className="course-feature-title">কোর্সের বৈশিষ্ট্য</p>*/}
            {/*        {course_features?.map((course_feature, index) => (*/}
            {/*            <CourseFeatures*/}
            {/*                key={index}*/}
            {/*                count={index}*/}
            {/*                course_feature={course_feature}*/}
            {/*                feature_length={feature_length}*/}
            {/*            ></CourseFeatures>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<p className="text-center teacher-title">শিক্ষকবৃন্দ</p>*/}
            {/*<div className="course-teacher">*/}
            {/*    {course_teachers?.slice(0,count).map((course_teacher, index) => (*/}
            {/*        <CourseTeachers*/}
            {/*            key={index}*/}
            {/*            index_count={index}*/}
            {/*            count = {count}*/}
            {/*            course_teacher={course_teacher}*/}
            {/*            teacher_length={teacher_length}*/}
            {/*            teacher_profile_path={teacher_profile_path}*/}
            {/*        ></CourseTeachers>*/}
            {/*    ))}*/}
            {/*</div>*/}
            {/*<div className="d-flex justify-content-center mt-5">*/}
            {/*    <button className=" btn-seeMore px-4 py-2" style={teacher_length > 0 && count < teacher_length ? { display: "block" } : { display: "none" }} onClick={handelLoadBook}>*/}
            {/*        আরো শিক্ষক ({englishToBengaliNumberFormat(remainTeacher)}) */}
            {/*    </button>*/}
            {/*</div>*/}
        </div>
    );
};

export default CourseShortInformation;
