import React from 'react';
import PageTitle from '../PageTitle/PageTitle';

const NotFoundPage = () => {
    return (
        <div className='' style={{ height: '100vh' }}>
        <PageTitle title="No pages"></PageTitle>
            {/* <div className='d-flex justify-content-center align-items-center h-100' >
                <div>
                    <img src={notfoundpage} alt="" style={{ marginTop:'-15rem', maxHeight:'500px', minWidth:'550px' }} />
                    
                    <div className='d-flex justify-content-center'>
                        <button style={{ backgroundColor: '#F27321', color: '#ffffff', fontSize: '20px', fontWeight: '600', border: 'none', padding: '12px 30px', borderRadius: '12px'}}><img src={backHome} alt="" /> <span className="ps-3">Back to Home</span></button>
                    </div>
                </div>
            </div> */}
            <h1 className='d-flex justify-content-center align-items-center h-100' style={{ marginTop:'-10rem'}}>Opps! Page Not Found</h1>
        </div>
    );
};

export default NotFoundPage;