import { create } from 'zustand'


export const useStoreCoupon = create((set) => ({
    totalPrice: '',
    addPromoCode: '',
    finalPriceAfterUsingCoupon: '',
    couponDetail: {},
    setTotalPrice:(data) => set({totalPrice:data}),
    setAddPromoCode:(data) => set({addPromoCode:data}),
    setFinalPriceAfterUsingCoupon:(data) => set({finalPriceAfterUsingCoupon:data}),
    setCouponDetail:(data) => set({couponDetail:data})
}))
