import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import './InstructionModal.css';
import instructionIcon from '../../../../../../../images/assignment-instraction-icon.png';
import { englishToBengaliNumberFormat } from "../../../../../../../utilities/utilities";

const InstructionModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton style={{ borderBottom:'1px solid #F4F4F4' }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    নির্দেশাবলী
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={instructionIcon} alt=""  />
                <div className="mt-3">

                <table className='table table-borderless'>

                        <tbody>
                            <tr>
                                <td className='instruction-title ps-0'>সময়</td>
                                <td className='assignment-instruction-details'>: &nbsp;&nbsp; আপনাকে <span className="balooda-medium">{englishToBengaliNumberFormat(props.assignment_time)}</span> মিনিটের মধ্যে অ্যাসাইনমেন্টটি সম্পূর্ণ করতে হবে৷</td>
                            </tr>
                            <tr>
                                <td className='instruction-title ps-0'>সময় সেটিংস</td>
                                <td className='assignment-instruction-details'>: &nbsp;&nbsp; সময় শেষ হয়ে গেলে এই অ্যাসাইনমেন্টটি স্বয়ংক্রিয়ভাবে সংরক্ষণ করা হবে৷</td>
                            </tr>
                            <tr>
                                <td className='instruction-title ps-0'>অংশগ্রহণ</td>
                                <td className='assignment-instruction-details'>: &nbsp;&nbsp; অ্যাসাইনমেন্টটিতে সর্বোচ্চ <span className="balooda-medium">{englishToBengaliNumberFormat(props.assignment_attempt)}</span> বার অংশগ্রহণ করতে পারবে।</td>
                            </tr>
                            <tr>
                                <td className='instruction-title ps-0'>সংরক্ষণ</td>
                                <td className='assignment-instruction-details'>: &nbsp;&nbsp; ব্রাউজার কেটে দিলে অথবা ব্রাউজারের ব্যাক বাটনে ক্লিক করলে অ্যাসাইনমেন্টটি জমা হবে না কিন্তু সাবমিশন কাউন্ট হবে৷ অ্যাসাইনমেন্টটি সংরক্ষণ করতে সাবমিট বাটনে ক্লিক করতে হবে৷</td>
                            </tr>
                            <tr>
                                <td className='instruction-title ps-0'>শেষ সময়</td>
                                <td className='assignment-instruction-details'>: &nbsp;&nbsp; অ্যাসাইনমেন্ট জমা দিতে হবে <span className="balooda-medium">{englishToBengaliNumberFormat(props.assignment_time)}</span> মিনিটের মধ্যে৷</td>
                            </tr>
                            <tr>
                                <td className='instruction-title ps-0'>শুরু</td>
                                <td className='assignment-instruction-details'>: &nbsp;&nbsp; অ্যাসাইনমেন্ট শুরু করতে শুরু করুন বাটনে ক্লিক করুন বা ফিরে যেতে বন্ধ করুন বাটনে ক্লিক করুন৷</td>
                            </tr>
                        </tbody>

                    </table>

                </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop:'1px solid #F4F4F4' }}>
                <button className='btn-instruction-close text-center mt-3 me-2' onClick={props.onHide}>বন্ধ করুন</button>
                {
                    props.assignment_question_show === 1 ? 
                    <Link to='/assignment-question' state={{ assignment_id: props.assignment_id}} className='btn-assignment-start text-center mt-3'>শুরু করুন</Link>
                    :
                    <Link to='/assignment-questions'  state={{ assignment_id: props.assignment_id}} className='btn-assignment-start text-center mt-3'>শুরু করুন</Link>

                }
                {/* <Button onClick={props.onHide}>Close</Button>
                <Button onClick={props.onHide}>Close</Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default InstructionModal;
