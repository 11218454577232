import React, {useState, useEffect} from 'react';
import logo from "../../../../../images/logo.png";
import success from "../../../../../images/eft/success.png";
import registred from "../../../../../images/eft/registered.png";
import banner from "../../../../../images/eft/Digital-Marketing.png";
import py from "../../../../../images/eft/py.webp"
import {Link} from "react-router-dom";

const Marketing = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [childAge, setChildAge] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [childAgeError, setChildAgeError] = useState('');
    const [timePreference, setTimePreference] = useState('');
    const [wlcMgs, setWlcMgs] = useState(false);
    const [alreadyRegistered, setAlreadyRegistered] = useState(false);
    const [timeSlot, setTimeSlot] = useState({});
    const [isFormValid, setIsFormValid] = useState(true);
    const [name, setName] = useState("");
    const [className, setClassName] = useState("");
    const [medium, setMedium] = useState("");


    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
    };

    const validatePhoneNumber = (number) => {
        const phoneNumberPattern = /^(01[3-9]\d{8})$/;
        if (phoneNumberPattern.test(number)) {
            setPhoneNumberError('');
            return true;
        } else {
            setPhoneNumberError('Invalid phone number! Please enter a valid phone number.');
            return false;
        }
    };


    console.log(medium);

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <div className="tw-pt-4 tw-mb-24 tw-h-screen">
            <div className="tw-max-w-screen-xl tw-mx-auto tw-mt-8 md:tw-mt-16">
                <div>
                    <div className="tw-flex tw-min-h-full tw-flex-1">
                        <div
                            className="tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-px-4 sm:tw-px-6 lg:tw-flex-none lg:tw-px-20 xl:tw-px-24">
                            <div className="tw-mx-auto tw-w-full tw-max-w-sm lg:tw-w-96">
                                <div>
                                    <Link to='/'><img className="tw-h-8 tw-w-auto" src={logo}
                                                      alt="Your Company"/></Link>
                                    <h2 className="tw-mt-4 tw-text-xl tw-font-semibold tw-leading-9 tw-tracking-tight tw-text-gray-900">
                                        Marketing Information
                                    </h2>
                                    <h2 className="tw-mt-2 tw-text-lg tw-font-semibold tw-leading-9 tw-tracking-tight tw-text-gray-500">
                                    </h2>
                                </div>
                                <div className="tw-mt-12">
                                    <form onSubmit={ handleSubmit} className="">
                                        <div>
                                            <label htmlFor="name"
                                                   className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Your name
                                            </label>
                                            <div className="tw-mt-1">
                                                <input
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    autoComplete="name"
                                                    onChange={(e) => setName(e.target.value)}
                                                    required
                                                    className="tw-pl-4 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="tw-mt-10">
                                            <label htmlFor="phoneNumber"
                                                   className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Mobile Number
                                            </label>
                                            <div className="tw-mt-1">
                                                <input
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    type="text"
                                                    autoComplete="current-number"
                                                    value={phoneNumber}
                                                    onChange={handlePhoneNumberChange}
                                                    placeholder="e.g. 01332555341"
                                                    required
                                                    className="tw-pl-4 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                                />
                                                {phoneNumberError &&
                                                    <p className="tw-mt-2 tw-text-sm tw-text-red-700">{phoneNumberError}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-mt-10">
                                            <label htmlFor="class"
                                                   className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Marketing Category
                                            </label>
                                            <select
                                                id="class"
                                                name="class"
                                                onChange={(e) => setClassName(e.target.value)}
                                                className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                                defaultValue="ক্লাস নির্বাচন করুন"
                                            >
                                                <option value=""
                                                        label="select marketing category"></option>
                                                <option value="digital marketing" label="Digital Marketing"></option>
                                                <option value="SMS Marketing" label="SMS Marketing"></option>
                                                <option value="SEO Marketing" label="SEO Marketing"></option>
                                            </select>
                                        </div>
                                        <div className="tw-mt-10">
                                            <label htmlFor="medium"
                                                   className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Payment Method
                                            </label>
                                            <select
                                                id="medium"
                                                name="medium"
                                                onChange={(e) => setMedium(e.target.value)}
                                                className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-indigo-600 tw-sm:text-sm tw-sm:leading-6"
                                                defaultValue="Payment Method"
                                            >
                                                <option value="" label="Payment Method"></option>
                                                <option value="ceo@mojaru.com" label="Payoneer"></option>
                                                <option value="01610075103"
                                                        label="Bkash"></option>
                                                <option value="01610075102" label="Nagad"></option>
                                            </select>
                                        </div>
                                        <div className="tw-mt-10 tw-flex tw-items-center">
                                            {
                                                medium === "01610075103" ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22"
                                                         viewBox="0 0 24 22"
                                                         fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                              d="M8.41338 10.3615C5.67946 6.88849 2.99902 3.4834 0.318672 0.0783073C0.33197 0.052268 0.345077 0.026134 0.358279 0C3.86798 0.417197 7.37777 0.834394 10.9414 1.25803C10.0982 4.29412 9.26703 7.28713 8.41338 10.3615Z"
                                                              fill="#D41D54"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                              d="M8.83566 10.4413C9.68405 7.39119 10.5218 4.37934 11.3832 1.28199C13.992 4.88649 16.5493 8.41969 19.1064 11.9529C19.0931 11.9799 19.0799 12.0068 19.0664 12.0338C15.6749 11.5059 12.2835 10.9779 8.83566 10.4413Z"
                                                              fill="#E1126E"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                              d="M8.85882 10.8858C12.1679 11.4095 15.4088 11.9225 18.6495 12.4354C18.6557 12.4676 18.6619 12.4998 18.6681 12.532C15.8652 13.9224 13.0624 15.3129 10.2084 16.7288C9.75839 14.7806 9.31822 12.8746 8.85882 10.8858Z"
                                                              fill="#D41D54"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                              d="M5.19748 21.9631C6.24974 18.2905 7.3021 14.618 8.3777 10.8638C8.43577 10.9935 8.47461 11.0525 8.48906 11.1168C8.99266 13.359 9.49128 15.6023 10 17.8433C10.0508 18.0665 10.0024 18.1984 9.8201 18.344C8.36765 19.5044 6.92507 20.6769 5.47875 21.845C5.41073 21.8999 5.33754 21.9485 5.26684 22C5.2436 21.9877 5.22054 21.9754 5.19748 21.9631Z"
                                                              fill="#9E1B3B"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                              d="M16.4826 7.69627C18.1906 7.391 19.8304 7.09794 21.5611 6.78859C20.9099 8.54733 20.2832 10.2402 19.6257 12.0159C18.5643 10.5571 17.5492 9.16215 16.4826 7.69627Z"
                                                              fill="#D41D54"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                              d="M11.5571 16.5102C14.0852 15.2342 16.6132 13.9584 19.1412 12.6826C19.1616 12.6995 19.1819 12.7165 19.2022 12.7334C19.1071 13.0198 19.0191 13.3088 18.9112 13.5904C18.8879 13.6513 18.7997 13.7016 18.7303 13.7293C16.372 14.6718 14.0123 15.6109 11.6526 16.55C11.6345 16.5572 11.6132 16.5567 11.5935 16.5599C11.5814 16.5433 11.5692 16.5267 11.5571 16.5102Z"
                                                              fill="#E1126E"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                              d="M23.9685 8.97646H21.1535C21.4196 8.25834 21.6753 7.56854 21.9077 6.94189C22.6073 7.59212 23.3036 8.23941 24 8.88698C23.9895 8.91681 23.979 8.94673 23.9685 8.97646Z"
                                                              fill="#E1126E"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                              d="M4.31322 5.92295C2.87551 4.57591 1.43771 3.22887 0 1.88174C0.0103322 1.85532 0.0207601 1.82881 0.031188 1.8023C0.395111 1.8023 0.759322 1.79709 1.12296 1.80779C1.17892 1.8094 1.24484 1.87464 1.28559 1.92625C2.29863 3.20965 3.30918 4.49505 4.31905 5.78101C4.34115 5.80923 4.35015 5.84758 4.36526 5.88129C4.34785 5.89521 4.33063 5.90903 4.31322 5.92295Z"
                                                              fill="#9E1B3B"/>
                                                    </svg> : medium === "01610075102" ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                             viewBox="0 0 24 24"
                                                             fill="none">
                                                            <path
                                                                d="M21.2954 13.0693C21.2954 13.377 21.2954 13.6408 21.2515 13.9046C21.1638 15.1354 20.8569 16.2784 20.3746 17.3335C20.1992 17.7731 19.98 18.1687 19.7169 18.5644C17.8754 21.4657 14.6308 23.4 10.9477 23.4C9.36923 23.4 7.87846 23.0483 6.56308 22.4329C3.05538 20.8063 0.6 17.2016 0.6 13.0693C0.6 8.98099 2.96769 5.46416 6.34385 3.79366C6.08077 4.14535 5.81769 4.49703 5.59846 4.89267C5.59846 4.93663 5.55462 4.93663 5.55462 4.98059C5.42308 5.11248 5.29154 5.2004 5.16 5.33228C4.98462 5.46416 4.85308 5.64 4.67769 5.77188C4.63385 5.81584 4.59 5.8598 4.59 5.8598C4.54615 5.90376 4.50231 5.94772 4.50231 5.94772C4.45846 5.99168 4.41462 6.0796 4.32692 6.12356C4.23923 6.25545 4.10769 6.38733 4.02 6.51921C3.58154 7.09069 3.23077 7.70614 2.96769 8.36555C2.92385 8.40951 2.92385 8.49743 2.88 8.54139C2.83615 8.62931 2.83615 8.71723 2.79231 8.76119C2.79231 8.80515 2.74846 8.84911 2.74846 8.89307C2.70462 9.02495 2.66077 9.11287 2.61692 9.24475C2.57308 9.33267 2.57308 9.4206 2.52923 9.46455C2.52923 9.50852 2.48538 9.55248 2.48538 9.59644C2.48538 9.68436 2.44154 9.77228 2.44154 9.8602C2.39769 10.124 2.35385 10.3877 2.31 10.6515C2.31 10.7394 2.31 10.7834 2.26615 10.8713C2.26615 10.9152 2.26615 10.9592 2.26615 11.0471C2.26615 11.135 2.26615 11.223 2.26615 11.2669C2.26615 11.3549 2.26615 11.4428 2.26615 11.5307C2.26615 11.6626 2.26615 11.7945 2.26615 11.9263C2.26615 14.7398 3.53769 17.2895 5.59846 18.916C7.17692 20.1909 9.15 20.9822 11.2985 20.9822C13.2715 20.9822 15.0692 20.3667 16.56 19.2677C17.6562 18.4764 18.5331 17.4653 19.1908 16.2784C19.2785 16.1026 19.3662 15.9707 19.4538 15.7949C19.98 14.6958 20.2869 13.5089 20.2869 12.2341C20.2869 12.1461 20.2869 12.0582 20.2869 11.9263C20.2869 11.6186 20.2869 11.3549 20.2431 11.0471L20.2869 11.0911C20.4185 11.223 20.55 11.3109 20.6815 11.4428C20.8131 11.223 20.9446 11.0471 21.0762 10.8273C21.1638 11.223 21.2077 11.6186 21.2515 12.0582C21.2954 12.4539 21.2954 12.7616 21.2954 13.0693Z"
                                                                fill="#ED1C24"/>
                                                            <path
                                                                d="M12.1754 4.76079L10.2462 1.2C7.04539 2.65069 4.85308 5.8598 4.85308 9.59644C4.85308 11.4867 5.42308 13.2451 6.38769 14.6958C6.3 14.2123 6.3 13.7287 6.3 13.2012C6.34385 9.37663 8.75538 6.12356 12.1754 4.76079Z"
                                                                fill="#F7941D"/>
                                                            <path
                                                                d="M13.71 6.51921C14.4992 6.29941 15.3762 6.21149 16.2092 6.21149C16.7354 6.21149 17.3054 6.25545 17.7877 6.34337L17.6562 1.59564C17.3054 1.55168 16.9108 1.50772 16.5162 1.50772C14.7623 1.50772 13.14 2.07921 11.8246 3.00238L12.8769 4.93663C10.9915 5.59604 9.45692 6.91485 8.49231 8.62931C8.01 9.46455 7.65923 10.4317 7.52769 11.4428C7.79077 10.9152 8.09769 10.4317 8.44846 9.99208C9.72 8.32158 11.5615 7.04673 13.71 6.51921Z"
                                                                fill="#ED1C24"/>
                                                            <path
                                                                d="M18.3138 4.71683L18.4015 6.95881C17.6562 6.73901 16.9108 6.60713 16.0777 6.60713C14.5431 6.60713 13.0962 7.04673 11.8246 7.79406C10.2462 8.76119 9.01846 10.2998 8.40462 12.1022C9.01846 11.2669 9.76385 10.5196 10.6408 9.94812C11.9123 9.11287 13.4469 8.62931 15.0692 8.62931C16.2969 8.62931 17.4369 8.89307 18.4892 9.37663C19.2785 9.72832 19.98 10.2119 20.5938 10.8273L21.8215 8.98099L23.4 6.56317C21.9969 5.4202 20.2431 4.71683 18.3138 4.71683Z"
                                                                fill="#F7941D"/>
                                                            <path
                                                                d="M20.2869 12.0143C20.2869 12.1022 20.2869 12.1901 20.2869 12.322C20.2869 14.1683 19.5415 15.7069 19.4538 15.8828C19.3662 16.0586 19.2785 16.1905 19.1908 16.3663C18.5331 17.5533 17.6562 18.5644 16.56 19.3556C15.0692 20.4107 13.2715 21.0701 11.2985 21.0701C9.15 21.0701 7.13308 20.3228 5.59846 19.004C3.58154 17.3335 2.26615 14.8277 2.26615 12.0143C2.26615 11.8824 2.26615 11.7505 2.26615 11.6186C2.26615 11.5307 2.26615 11.4428 2.26615 11.3549C2.26615 11.2669 2.26615 11.179 2.26615 11.135C2.26615 11.0911 2.26615 11.0471 2.26615 10.9592C2.26615 10.8713 2.26615 10.8273 2.31 10.7394C2.35385 10.4756 2.39769 10.2119 2.44154 9.94812C2.44154 9.8602 2.48538 9.77228 2.48538 9.68436C2.48538 9.6404 2.52923 9.59644 2.52923 9.55247C2.57308 9.46455 2.57308 9.37663 2.61692 9.33267C2.66077 9.20079 2.70462 9.06891 2.74846 8.98099C2.74846 8.93703 2.79231 8.89307 2.79231 8.84911C2.83615 8.76119 2.83615 8.67327 2.88 8.62931C2.92385 8.58535 2.92385 8.49743 2.96769 8.45347C3.23077 7.79406 3.62538 7.17861 4.02 6.60713C4.10769 6.47525 4.23923 6.34337 4.32692 6.21149C4.37077 6.16752 4.41462 6.0796 4.50231 6.03564C4.54615 5.99168 4.54615 5.94772 4.59 5.94772C4.63385 5.90376 4.63385 5.8598 4.67769 5.8598C4.80923 5.68396 4.98462 5.55208 5.16 5.4202C5.29154 5.28832 5.42308 5.2004 5.55462 5.06852C5.55462 5.11248 5.51077 5.11248 5.51077 5.15644C5.51077 5.2004 5.46692 5.24436 5.46692 5.24436C4.94077 6.29941 4.59 7.57426 4.45846 8.89307C4.41462 9.24475 4.41462 9.59644 4.41462 9.99208C4.41462 14.7398 7.17692 18.5644 10.5969 18.5644H10.6408C10.7285 18.5644 10.86 18.5644 10.9477 18.5644C11.43 18.5644 11.9123 18.4764 12.3946 18.3885C14.7185 17.7731 16.4285 15.619 16.4285 13.1133C16.4285 13.0693 16.4285 13.0253 16.4285 12.9814C16.3846 11.4867 15.7708 10.124 14.7623 9.20079C15.4638 9.20079 16.1654 9.33267 16.8231 9.50852C18.0946 9.81624 19.2346 10.4317 20.2431 11.223L20.2869 11.2669C20.2431 11.4428 20.2869 11.7505 20.2869 12.0143Z"
                                                                fill="#F7941D"/>
                                                            <path
                                                                d="M20.2869 12.0143C20.2869 12.1022 20.2869 12.2341 20.2869 12.322C20.2869 14.2562 19.41 15.9268 19.41 15.9268C19.3223 16.1026 19.2346 16.2345 19.1469 16.4103C18.4892 17.5973 17.5685 18.6083 16.4723 19.3996C14.9377 20.5426 13.0961 21.1141 11.1669 21.1141C9.0623 21.1141 7.04538 20.3668 5.42307 19.0479C3.31845 17.3335 2.09076 14.7398 2.09076 12.0143C2.09076 9.28874 3.31845 6.69508 5.42307 4.98062L5.51076 4.8927C5.51076 4.93666 5.46691 4.93666 5.46691 4.98062C5.46691 5.02458 5.42307 5.02458 5.42307 5.06854C3.3623 6.783 2.17845 9.28874 2.17845 12.0143C2.17845 14.7398 3.3623 17.2456 5.46691 19.004C7.04538 20.3228 9.10614 21.0262 11.1669 21.0262C13.0523 21.0262 14.8938 20.4547 16.4285 19.3117C17.5246 18.5204 18.4015 17.5093 19.0592 16.3224C19.1469 16.1466 19.2346 16.0147 19.2785 15.8388C19.2785 15.8388 20.1554 14.2123 20.1554 12.278C20.1554 12.1901 20.1554 12.0582 20.1554 11.9703C20.1554 11.6626 20.1554 11.3988 20.1115 11.0911V11.0472L20.1992 11.1351C20.2869 11.4868 20.2869 11.7505 20.2869 12.0143Z"
                                                                fill="#F7941D"/>

                                                        </svg> : medium === "ceo@mojaru.com" ? <img className="tw-w-24" src={py} alt=""/> : ""
                                            }
                                            <p className="tw-px-4 tw-font-semibold tw-border-2 tw-px-2 tw-py-1 tw-ml-3 tw-rounded-lg">{
                                                medium ? medium : ""
                                            }</p>
                                        </div>
                                        <div className="tw-mt-12">

                                            <button
                                                type="submit"
                                                className={`tw-flex tw-w-full tw-justify-center tw-rounded-md tw-px-3 tw-py-1.5 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white  ${isFormValid ? "tw-bg-indigo-600 tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600" : "tw-bg-gray-400"} `}
                                                disabled={!isFormValid}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className=" tw-relative tw-hidden tw-w-0 tw-flex-1 lg:tw-block">
                            <img
                                className=" tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-rounded-2xl"
                                src={banner}
                                alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Marketing;
