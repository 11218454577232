import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../../images/Logo-purple.png";
import image1 from "../../../../../images/eft_new/1.png";
import image2 from "../../../../../images/eft_new/2.png";
import image3 from "../../../../../images/eft_new/3.png";
import banner from "../../../../../images/ela/ela_card_big.png";
import "./EarlyLeaningAssessment.css";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { HiArrowRight, HiUser } from "react-icons/hi";
import Faq from "../../../shared/CourseDetails/CourseDetailsInformation/Faq/Faq";
import backButton from "../../../../../images/OneToOne/backButton.png";

import ScrollToTop from "../../../shared/ScrollToTop/ScrollToTop";
import GreetingMsg from "../Shared/GreetingMsg/GreetingMsg";
import FormFooter from "../../../shared/FormFooter/FormFooter";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const faqs = [
  {
    course_faq_answer:
      "মনোযোগ, সামাজিক ও আবেগীয় বিকাশ, প্রাক গণিত, প্রাক লেখা, সৃজনশীলতা, সমস্যা সমাধান-এর মতো ক্ষেত্রে এই এসেসমেন্ট হবে।",

    course_faq_id: 1,
    course_faq_question: "মেধা বিকাশের কোন কোন ক্ষেত্রে এসেসমেন্ট হবে?",
  },
  {
    course_faq_answer:
      "অবশ্যই! এই পরীক্ষা ঘরে বসেই দেয়া যাবে। একজন অভিজ্ঞ আর্লি চাইল্ড এডুকেটর এই এসেসমেন্ট নিবেন!",
    course_faq_id: 2,
    course_faq_question: "এই পরীক্ষা কি ঘরে বসে দেয়া যাবে?",
  },
  {
    course_faq_answer:
      "অবশ্যই সম্ভব! কারণ এখানে এসেসমেন্ট উপকরণগুলো শিশু সুলভ থাকে। অভিজ্ঞতা আছে এমন প্রশিক্ষক দিয়ে শিশুকে মূল্যায়ন করা হয়।",
    course_faq_id: 3,
    course_faq_question:
      "অনলাইনে কি শিশুদের মনোযোগ ধরে রেখে এসেসমেন্ট করা সম্ভব?",
  },
];

// const faqs = [
//   {
//     course_faq_id: 1,
//     course_faq_question: {
//       bn: "মেধা বিকাশের কোন কোন ক্ষেত্রে এসেসমেন্ট হবে?",
//       en: "In which areas will the assessment take place for cognitive development?",
//     },
//     course_faq_answer: {
//       bn: "মনোযোগ, সামাজিক ও আবেগীয় বিকাশ, প্রাক গণিত, প্রাক লেখা, সৃজনশীলতা, সমস্যা সমাধান-এর মতো ক্ষেত্রে এই এসেসমেন্ট হবে।",
//       en: "The assessment will cover areas like attention, social and emotional development, pre-math, pre-writing, creativity, and problem-solving.",
//     },
//   },
//   {
//     course_faq_id: 2,
//     course_faq_question: {
//       bn: "এই পরীক্ষা কি ঘরে বসে দেয়া যাবে?",
//       en: "Can this exam be taken at home?",
//     },
//     course_faq_answer: {
//       bn: "অবশ্যই! এই পরীক্ষা ঘরে বসেই দেয়া যাবে। একজন অভিজ্ঞ আর্লি চাইল্ড এডুকেটর এই এসেসমেন্ট নিবেন!",
//       en: "Absolutely! This test can be taken at home. An experienced early child educator will conduct the assessment!",
//     },
//   },
//   {
//     course_faq_id: 3,
//     course_faq_question: {
//       bn: "অনলাইনে কি শিশুদের মনোযোগ ধরে রেখে এসেসমেন্ট করা সম্ভব?",
//       en: "Is it possible to keep children's attention during online assessments?",
//     },
//     course_faq_answer: {
//       bn: "অবশ্যই সম্ভব! কারণ এখানে এসেসমেন্ট উপকরণগুলো শিশু সুলভ থাকে। অভিজ্ঞতা আছে এমন প্রশিক্ষক দিয়ে শিশুকে মূল্যায়ন করা হয়।",
//       en: "Absolutely possible! As the assessment materials are child-friendly here. Children are evaluated by experienced trainers.",
//     },
//   },
// ];

const EarlyLeaningAssessment = () => {
  const [studentName, setStudentName] = useState("");
  const [guardianName, setGuardianName] = useState("");
  const [phone, setPhone] = useState("");
  const [className, setClassName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [language, setLanguage] = useState("bn");
  const [isFormValid, setIsFormValid] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const validateForm = () => {
    if (studentName && phone && guardianName) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [studentName, phone, className, guardianName]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const objectToInsert = {
      name: studentName,
      guardian_name: guardianName,
      mobile_number: phone,
      age: age,
      gender: gender,
      preference_day: selectedDay,
      class: className,
      time_preference: selectedTimeSlot,
      type: "ELA",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}api/v1/skill-test-schedule`, {
      method: "POST",
      body: JSON.stringify(objectToInsert),
      headers: {
        "Content-Type": "application/json",
        secretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.success === true) {
          setOpen(true);
          setClassName("");
          setAge("");
          setStudentName("");
          setGuardianName("");
          setGender("");
          setPhone("");
          setSelectedDay("");
          setSelectedTimeSlot("");
          setIsFormValid(false);
        }
      });
  };

  // Language switcher
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");

    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  // Toggle Language
  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem("useLanguage");

    if (getLanguage === "bn") {
      sessionStorage.setItem("useLanguage", "en");
      i18next.changeLanguage("en");
      setLanguage("en");
    } else {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    }
  }

  return (
    <div>
      <ScrollToTop />

      <div className="-tw-mt-[88px] tw-pb-1">
        {/* Width container  */}
        <div className="tw-max-w-[1440px] tw-mx-auto">
          {/* Hero section  */}
          <div className="tw-w-full hero_bg_ela tw-h-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mt-32">
            {/*Left side Content */}
            <div className="tw-mt-14 lg:tw-mt-20 tw-my-10 xl:tw-my-20 tw-px-6 md:tw-px-0 md:tw-ml-10 xl:tw-ml-14">
              {/* Back button  */}
              <div
                className="tw-flex tw-items-center tw-cursor-pointer tw-mb-8 "
                onClick={() => navigate(-1)}
              >
                <img src={backButton} alt="icon" />
                <p className="tw-ml-3 tw-text-gray-600">{t("back")}</p>
              </div>
              {/* Logo */}
              <div className="tw-flex tw-flex-col md:tw-items-start">
                <Link to="/">
                  <img className="" src={logo} alt="logo" />
                </Link>
              </div>

              {/*Heading and Sub heading  */}
              <div className="tw-mt-6">
                <h2 className="tw-text-3xl lg:tw-text-[50px] tw-text-[#374151]tw-font-[700] tw-leading-[30px] lg:tw-leading-[60px] xl:tw-leading-[60px]">
                  {t("Fun and Interactive")}
                  <p className="tw-text-[#6F39B7] tw-mt-4">
                    {t("Early Learning Assessment")}
                  </p>
                </h2>
                <p className="tw-text-sm lg:tw-text-[22px] tw-font-[500] tw-text-[#111827] tw-mt-10 md:tw-mt-10">
                  {t("Register to know your Early Learning Score")}
                </p>
              </div>

              {/*Form Section */}
              <div
                // ref={formRef}
                className="tw-w-auto md:tw-w-[70%] lg:tw-w-[70%] xl:tw-w-[70%] tw-mt-6 lg:tw-mt-8 tw-border tw-p-5 tw-rounded-3xl tw-bg-white"
              >
                <h2 className="tw-text-lg tw-font-semibold tw-text-center">
                  {t("Your Name")}
                </h2>
                <form onSubmit={handleSubmit} className="tw-mt-6">
                  <div>
                    <div className="tw-relative tw-mt-1">
                      <HiUser className="tw-absolute tw-top-3 tw-left-3 tw-text-2xl tw-text-gray-500" />
                      <input
                        id="name"
                        name="name"
                        type="text"
                        autoComplete="name"
                        value={studentName}
                        onChange={(e) => setStudentName(e.target.value)}
                        required
                        className="tw-pl-12 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-gap-2 tw-mt-4">
                    <div className="tw-flex tw-shadow-lg tw-p-3 tw-rounded-xl tw-border-2 tw-w-1/2 hover:tw-border-violet-500">
                      <input
                        type="radio"
                        id="studentGender"
                        name="studentGender"
                        value="male"
                        onChange={(e) => setGender(e.target.value)}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-font-[500] tw-text-[18px] tw-leading-[20px] tw-mx-1">
                        👦🏻 {t("Boy")}
                      </label>
                    </div>
                    <div className="tw-flex tw-shadow-lg tw-p-3 tw-rounded-xl tw-border-2 tw-w-1/2 hover:tw-border-violet-500">
                      <input
                        type="radio"
                        id="studentGender"
                        name="studentGender"
                        value="female"
                        onChange={(e) => setGender(e.target.value)}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-font-[500] tw-text-[18px] tw-leading-[20px] tw-mx-1">
                        👩🏻 {t("Girl")}
                      </label>
                    </div>
                  </div>

                  <div className="tw-mt-4">
                    <button
                      // type="button"
                      onClick={handleButtonClick}
                      className={`tw-flex tw-w-full tw-justify-center tw-items-center tw-rounded-3xl tw-px-3 tw-py-3 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white  ${
                        studentName !== "" && gender !== ""
                          ? "tw-bg-[#532D80]"
                          : " tw-bg-gray-400"
                      }`}
                    >
                      <span className="tw-mr-2">{t("Next")}</span>{" "}
                      <HiArrowRight />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/*Right side Image */}
            <div className="tw-relative tw-h-auto tw-mt-[75%] md:tw-mt-0 ">
              <img
                className="tw-w-auto tw-h-auto tw-absolute tw-bottom-0 tw-right-0"
                src={banner}
                alt="student image"
              />
            </div>
          </div>

          {/* Form Sections  */}

          {/* Registration Section */}
          <div className="tw-flex tw-flex-col tw-gap-6 lg:tw-gap-20 lg:tw-flex-row tw-justify-between tw-mt-16 tw-mx-4 md:tw-mx-14">
            <div className="md:tw-w-[100%] lg:tw-w-[50%]">
              <h2 className="tw-font-bold tw-text-3xl tw-mt-3 tw-text-[#6F39B7]">
                {t("What is the Early")}
                <span className="tw-text-[#111827]">
                  {t("Learning Assessment?")}
                </span>
              </h2>

              <div className="tw-mt-10">
                <div>
                  <p className="tw-font-[400] tw-mt-2 tw-leading-[32px] tw-text-[20px] tw-text-justify">
                    {t("Learning Assessment Details")}
                  </p>
                </div>

                <div className="tw-mt-10">
                  <h2 className="tw-font-bold tw-text-2xl tw-mt-3 tw-text-[#1F2937]">
                    👉{t("Why this")}
                    <span className=" tw-text-[#6F39B7]">
                      {t("Assessment?")}
                    </span>
                  </h2>

                  <ul className="tw-ml-10">
                    <li className="tw-font-[500] tw-text-[20px] tw-mt-2 tw-list-disc ">
                      {t(
                        "Understand your child’s current level of cognitive and social skills"
                      )}
                    </li>
                    <li className="tw-font-[500] tw-text-[19px] tw-mt-2 tw-list-disc">
                      {t(
                        "Get guidance based on expert recommendations in Early Child Development"
                      )}
                    </li>
                    <li className="tw-font-[500] tw-text-[20px] tw-mt-2 tw-list-disc">
                      {t(
                        "Take steps to ensure your child’s proper development"
                      )}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tw-mt-10">
                <h2 className="tw-font-bold tw-text-2xl tw-mt-3 tw-text-[#1F2937]">
                  👉 {t("Who is this")}
                  <span className=" tw-text-[#6F39B7]">
                    {" "}
                    {t("Assessment For?")}
                  </span>
                </h2>
              </div>

              <div className="tw-flex tw-mt-6">
                <div>
                  <img className="tw-w-14 tw-h-auto" src={image1} alt="icon" />
                </div>
                <div className="tw-ml-6">
                  <p className="tw-font-[400] tw-text-[20px] tw-mt-2">
                    {t("Any child aged 3 to 5")}
                  </p>
                </div>
              </div>

              <div className="tw-flex tw-mt-6">
                <div>
                  <img className="tw-w-14 tw-h-auto" src={image2} alt="icon" />
                </div>
                <div className="tw-ml-6">
                  <p className="tw-font-[400] tw-text-[20px] tw-mt-2">
                    {t(
                      "Parents who want to know if their child’s development is on track for their age"
                    )}
                  </p>
                </div>
              </div>

              <div className="tw-flex tw-mt-6">
                <div>
                  <img className="tw-w-14 tw-h-auto" src={image3} alt="icon" />
                </div>
                <div className="tw-ml-6">
                  <p className="tw-font-[400] tw-text-[20px] tw-mt-2">
                    {t(
                      "Those seeking guidance from an Early Child Development expert"
                    )}
                  </p>
                </div>
              </div>
            </div>
            {/* Registration Form  */}
            <div
              ref={formRef}
              className="md:tw-w-[100%] lg:tw-w-[50%]  tw-mt-8 md:tw-mt-0 tw-border tw-p-8 tw-rounded-3xl tw-bg-white tw-shadow-sm"
            >
              <h2 className="tw-text-lg tw-font-semibold">
                {t("Register now")}
              </h2>

              <form onSubmit={handleSubmit} className="tw-mt-6">
                <div className="tw-my-2">
                  <label
                    htmlFor="guardianName"
                    className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]"
                  >
                    {t("Your Guardian’s Name")}{" "}
                    <span className="tw-text-red-700">*</span>
                  </label>
                  <div className="tw-relative tw-mt-1">
                    <HiUser className="tw-absolute tw-top-3 tw-left-3 tw-text-2xl tw-text-gray-500" />
                    <input
                      id="guardianName"
                      name="guardianName"
                      type="text"
                      autoComplete="guardianName"
                      value={guardianName}
                      onChange={(e) => setGuardianName(e.target.value)}
                      required
                      className="tw-pl-12 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    />
                  </div>
                </div>
                <div className="tw-my-2">
                  <label
                    htmlFor="studentName"
                    className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]"
                  >
                    {t("Your Name")} <span className="tw-text-red-700">*</span>
                  </label>
                  <div className="tw-relative tw-mt-1">
                    <HiUser className="tw-absolute tw-top-3 tw-left-3 tw-text-2xl tw-text-gray-500" />
                    <input
                      id="studentName"
                      name="studentName"
                      type="text"
                      autoComplete="studentName"
                      value={studentName}
                      onChange={(e) => setStudentName(e.target.value)}
                      required
                      className="tw-pl-12 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    />
                  </div>
                </div>
                <div className="tw-my-2">
                  <label className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]">
                    {t("Phone number")}{" "}
                    <span className="tw-text-red-700">*</span>
                  </label>
                  <div className="tw-mt-1">
                    <PhoneInput
                      country={"bd"}
                      value={phone}
                      onChange={(mobile) => setPhone(mobile)}
                      enableSearch={true}
                    />
                    {/*{phoneNumberError &&*/}
                    {/*    <p className="tw-mt-2 tw-text-sm tw-text-red-700">{phoneNumberError}</p>}*/}
                  </div>
                </div>
                <div className="tw-mt-4">
                  <label
                    htmlFor="class"
                    className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]"
                  >
                    {t("Your class")}
                  </label>
                  <div className="tw-relative">
                    <select
                      id="class"
                      name="class"
                      className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6 tw-appearance-none tw-bg-white"
                      value={className}
                      onChange={(e) => setClassName(e.target.value)}
                      defaultValue="ক্লাস নির্বাচন করো"
                    >
                      <option value="" label="ক্লাস নির্বাচন করো"></option>
                      <option
                        value="Not Admitted"
                        label="Not Admitted - ভর্তি করা হয়নি"
                      ></option>
                      <option value="Play" label="Play - প্লে"></option>
                      <option
                        value="Nursery"
                        label="Nursery - নার্সারী"
                      ></option>
                      <option value="KG" label="KG - কেজি"></option>
                      <option
                        value="Class 1"
                        label="Class 1 - শ্রেণী ১"
                      ></option>
                      <option
                        value="Class 2"
                        label="Class 2 - শ্রেণী ২"
                      ></option>
                      <option
                        value="Class 3"
                        label="Class 3 - শ্রেণী ৩"
                      ></option>
                      <option
                        value="Class 4"
                        label="Class 4 - শ্রেণী ৪"
                      ></option>
                      <option
                        value="Class 5"
                        label="Class 5 - শ্রেণী ৫"
                      ></option>
                      <option
                        value="Class 6"
                        label="Class 6 - শ্রেণী ৬"
                      ></option>
                      <option
                        value="Class 7"
                        label="Class 7 - শ্রেণী ৭"
                      ></option>
                      <option
                        value="Class 8"
                        label="Class 8 - শ্রেণী ৮"
                      ></option>
                      <option
                        value="Class 9"
                        label="Class 9 - শ্রেণী ৯"
                      ></option>
                      <option
                        value="Class 10"
                        label="Class 10 - শ্রেণী ১০"
                      ></option>
                      <option
                        value="Class 11"
                        label="Class 11 - শ্রেণী ১১"
                      ></option>
                      <option
                        value="Class 12"
                        label="Class 12 - শ্রেণী ১২"
                      ></option>
                      <option value="Other" label="Other - অন্যান্য"></option>
                    </select>
                    <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                      <svg
                        className="tw-h-5 tw-w-5 tw-text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="tw-mt-4">
                  <label
                    htmlFor="class"
                    className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]"
                  >
                    {t('Your Age')}
                  </label>
                  <div className="tw-relative">
                    <select
                      id="class"
                      name="class"
                      className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6 tw-appearance-none tw-bg-white"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      defaultValue="বয়স নির্বাচন করুন"
                    >
                      <option value="5" label="৫"></option>
                      <option value="6" label="৬"></option>
                      <option value="7" label="৭"></option>
                      <option value="8" label="৮"></option>
                      <option value="9" label="৯"></option>
                      <option value="10" label="১০"></option>
                      <option value="11" label="১১"></option>
                      <option value="12" label="১২"></option>
                      <option value="13" label="১৩"></option>
                      <option value="14" label="১৪"></option>
                      <option value="15" label="১৫"></option>
                      <option value="Other" label="Other - অন্যান্য"></option>
                    </select>
                    <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                      <svg
                        className="tw-h-5 tw-w-5 tw-text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="tw-flex tw-gap-2 tw-mt-4">
                  <div className="tw-w-1/2">
                    <label
                      htmlFor="class"
                      className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]"
                    >
                      {t('Your Preferred Day')}
                    </label>
                    <div className="tw-relative">
                      <select
                        id="class"
                        name="class"
                        className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6 tw-appearance-none tw-bg-white"
                        value={selectedDay}
                        onChange={(e) => setSelectedDay(e.target.value)}
                        defaultValue="তোমার পছন্দের দিন"
                      >
                        <option value="Saturday" label="শনিবার"></option>
                        <option value="Sunday" label="রবিবার"></option>
                        <option value="Monday" label="সোমবার"></option>
                        <option value="Tuesday" label="মঙ্গলবার"></option>
                        <option value="Wednesday" label="বুধবার"></option>
                        <option value="Thursday" label="বৃহস্পতিবার"></option>
                        <option value="Friday" label="শুক্রবার"></option>
                      </select>
                      <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                        <svg
                          className="tw-h-5 tw-w-5 tw-text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="tw-w-1/2">
                    <label
                      htmlFor="class"
                      className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]"
                    >
                      {t('Your Time')}
                    </label>
                    <div className="tw-relative">
                      <select
                        id="class"
                        name="class"
                        className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6 tw-appearance-none tw-bg-white"
                        value={selectedTimeSlot}
                        onChange={(e) => setSelectedTimeSlot(e.target.value)}
                        defaultValue="তোমার সময়"
                      >
                        <option value="3:00" label="৩:০০"></option>
                        <option value="5:00" label="৫:০০"></option>
                        <option value="7:00" label="৭:০০"></option>
                      </select>
                      <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                        <svg
                          className="tw-h-5 tw-w-5 tw-text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tw-mt-8">
                  <button
                    type="submit"
                    className={`tw-flex tw-w-full tw-justify-center tw-items-center tw-rounded-3xl tw-px-3 tw-py-3 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white  ${
                      isFormValid ? "tw-bg-[#532D80]" : " tw-bg-gray-400"
                    }`}
                    disabled={!isFormValid}
                  >
                    <span className="tw-mr-2">{t('Submit')}</span> <HiArrowRight />
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* FAQ */}
          <div className="tw-px-4 md:tw-px-10">
            <div className="tw-mt-16 tw-bg-gray-50 tw-rounded-3xl">
              <div className="md:tw-w-3/5 tw-mx-auto tw-py-16">
                <h2 className="tw-text-xl tw-font-semibold tw-ml-12 tw-mb-8">
                  {t("General")}
                  <span className="tw-text-[#6F39B7]"> {t("Questions")}</span>
                </h2>
                {faqs.map((faq) => (
                  <Faq course_faq={faq} handleButtonClick={handleButtonClick} />
                ))}
              </div>
            </div>
          </div>

          {/* Footer  */}
          <div className="tw-px-4 md:tw-px-10 tw-mt-16">
            <FormFooter />
          </div>
        </div>
      </div>
      {<GreetingMsg open={open} setOpen={setOpen} />}
    </div>
  );
};

export default EarlyLeaningAssessment;
