import React from "react";
import "./Faq.css";

const Faq = ({ course_faq }) => {
    return (
        <div>
            <div className="accordion-item course-question_section">
                <h2 className="accordion-header">
                    <button
                        className="accordion-button collapsed month-count question text-dark course-faq-question"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#faq-content-${course_faq.course_faq_id}`}
                    >
                        {course_faq?.course_faq_question}
                    </button>
                </h2>
                <div
                    id={`faq-content-${course_faq.course_faq_id}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist2"
                >
                    <div>
                        <div className="accordion-body py-0">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <p className="mb-0 course-faq-answer">
                                                {course_faq.course_faq_answer}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
