import { CheckIcon } from "@heroicons/react/20/solid";

const tiers = [
  {
    name: "ERP Plan",
    id: "tier-freelancer",
    href: "http://103.191.178.225/",
    priceMonthly: "$1090",
    description: "Simplify Complexity – Drive Efficiency",
    features: [
      "Real-time inventory and supply chain management",
      "Centralized data for better decision-making",
      "Financial management and reporting tools",
      "Customizable modules for different industries",
      "Scalable for businesses of all sizes",
    ],
    mostPopular: false,
  },
  {
    name: "LMS Plan",
    id: "tier-startup",
    href: "https://mojaru.com/",
    priceMonthly: "$2600",
    description: "Seamless Learning, Anywhere, Anytime",
    features: [
      "User-friendly interface for educators and learners",
      "Comprehensive course management and tracking",
      "Integration with video conferencing tools",
      "Advanced analytics to measure progress",
      "Mobile app for learning on the go",
    ],
    mostPopular: true,
  },
  {
    name: "CRM Plan",
    id: "tier-enterprise",
    href: "https://202.4.103.213/crm/login.php",
    priceMonthly: "$579",
    description: "Your Customers Deserve the Best – Make It Happen",
    features: [
      "Lead tracking and management.",
      "Automated workflows and task assignments",
      "AI-driven customer insights and analytics",
      "Omnichannel communication tools",
      "Integration with email, social media, and other platforms",
    ],
    mostPopular: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Pricing() {
  return (
    <div className="">
      <div className="tw-mx-auto tw-max-w-7xl tw-px-6 lg:tw-px-8">
        <div className="tw-mx-auto tw-text-center">
          <h2 className=" tw-text-4xl tw-font-semibold tw-text-white">
            Pricing
          </h2>
        </div>

        <div className="tw-isolate tw-mx-auto tw-mt-16 tw-grid tw-max-w-md tw-grid-cols-1 tw-gap-y-8 sm:tw-mt-20 lg:tw-mx-0 lg:tw-max-w-none lg:tw-grid-cols-3">
          {tiers.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "lg:tw-z-10 lg:tw-rounded-b-none"
                  : "lg:tw-mt-8",
                tierIdx === 0 ? "lg:tw-rounded-r-none" : "",
                tierIdx === tiers.length - 1 ? "lg:tw-rounded-l-none" : "",
                "tw-flex tw-flex-col tw-justify-between tw-rounded-3xl tw-bg-white tw-p-8 tw-ring-1 tw-ring-gray-200 xl:tw-p-10"
              )}
            >
              <div>
                <div className="tw-flex tw-items-center tw-justify-between tw-gap-x-4">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular
                        ? "tw-text-indigo-600"
                        : "tw-text-gray-900",
                      "tw-text-lg/8 tw-font-semibold"
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="tw-rounded-full tw-bg-indigo-600/10 tw-px-2.5 tw-py-1 tw-text-xs/5 tw-font-semibold tw-text-indigo-600">
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className="tw-mt-4 tw-text-sm/6 tw-text-gray-600">
                  {tier.description}
                </p>
                <p className="tw-mt-6 tw-flex tw-items-baseline tw-gap-x-1">
                  <span className="tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-gray-900">
                    {tier.priceMonthly}
                  </span>
                </p>
                <ul
                  role="list"
                  className="tw-mt-8 tw-space-y-3 tw-text-sm/6 tw-text-gray-600"
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="tw-flex tw-gap-x-3">
                      <CheckIcon
                        aria-hidden="true"
                        className="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "tw-bg-[#391c5f] tw-text-white tw-shadow-sm hover:tw-bg-[#391c5f]"
                    : "tw-text-indigo-600 tw-ring-1 tw-ring-inset tw-ring-indigo-200 hover:tw-ring-indigo-300",
                  "tw-mt-8 tw-block tw-rounded-md tw-px-3 tw-py-2 tw-text-center tw-text-sm/6 tw-font-semibold focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                )}
              >
                Buy plan
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
