import { HiArrowLeft, HiArrowRight } from "react-icons/hi";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    // console.log(totalPages);
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200 tw-px-4 sm:tw-px-0">
            <div className="-tw-mt-px tw-flex tw-w-0 tw-flex-1">
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    className="tw-inline-flex tw-items-center tw-border-t-2 tw-border-transparent tw-pr-1 tw-pt-4 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700"
                    disabled={currentPage === 1}
                >
                    <HiArrowLeft className="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                    Previous
                </button>
            </div>
            <div className="tw-hidden md:-tw-mt-px md:tw-flex">
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        onClick={() => onPageChange(number)}
                        className={`tw-inline-flex tw-items-center tw-border-t-2 tw-px-4 tw-pt-4 tw-text-sm tw-font-medium ${
                            currentPage === number
                                ? 'tw-border-indigo-500 tw-text-indigo-600'
                                : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700'
                        }`}
                    >
                        {number}
                    </button>
                ))}
            </div>
            <div className="-tw-mt-px tw-flex tw-w-0 tw-flex-1 tw-justify-end">
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    className="tw-inline-flex tw-items-center tw-border-t-2 tw-border-transparent tw-pl-1 tw-pt-4 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700"
                    disabled={currentPage === totalPages}
                >
                    Next
                    <HiArrowRight className="tw-ml-3 tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                </button>
            </div>
        </nav>
    );
};

export default Pagination;

