import React from "react";
import "./Banner.css";
import banner_girl from "../../../../../images/Job Preparation.png";

const Banner = () => {
    return (
        <div className="academic_background ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <div>
                            <p className="banner-title">
                                যতই টাফ হোক না কেন
                                <br />
                                জব ভাইভা এডমিশন
                                <br />
                                মজারুতে ঘরে বসেই
                                <br />
                                নেই কমপ্লিট প্রিপারেশন
                            </p>
                            <p className="banner-text">
                                চাকরির সোনার হরিণ ধরা দেবে ঘরে বসেই। এখানে
                                চাকরির বাজারে মন্দার সলুশন দেয়া হয়।
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <img
                            src={banner_girl}
                            alt=""
                            className="img-fluid pb-5 mt-5"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
