import React, { useState, useEffect, useRef } from "react";
import "./DemoClassBooking.css";
import { HiArrowRight, HiUser } from "react-icons/hi";
import PhoneInput from "react-phone-input-2";
import ScrollToTop from "../../shared/ScrollToTop/ScrollToTop";
import image1 from "../../../../images/demo_class/demoLogo1.png";
import messageIcon from "../../../../images/demo_class/icon_email.png";
import callIcon from "../../../../images/demo_class/icon_phone.png";
import GreetingMsg from "../AdmissionLanding/Shared/GreetingMsg/GreetingMsg";
import {useTranslation} from "react-i18next";

const DemoClassBooking = () => {
  const [guardianName, setGuardianName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [phone, setPhone] = useState("");
  const [className, setClassName] = useState("");
  const [subject, setSubject] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();

  const validateForm = () => {
    if (studentName && guardianName && phone && className && subject) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [studentName, guardianName, phone, className, subject]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      class_name: className,
      student_name: studentName,
      guardian_name: guardianName,
      mobile: phone,
      interested_courses: subject,
      type: "demoClassBooking",
    };
    fetch(`${process.env.REACT_APP_BASE_URL}api/v1/registration-lead-store`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        secretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOpen(true);
        setSubject("");
        setClassName("");
        setGuardianName("");
        setPhone("");
      });
  };

  const sendEmail = () => {
    window.open("mailto:support@mojaru.net?subject=SendMail&body=Description");
  };

  return (
    <>
      <ScrollToTop />

      <div className="">
        <div className="tw-w-full tw-mt-8 xl:tw-mt-16 tw-p-4 2xl:tw-p-0 md:tw-w-md lg:tw-w-lg xl:tw-w-xl 2xl:tw-w-[1440px] tw-mx-auto">
          {/* background image container  */}
          <div className="hero_bg_demo_class_book tw-rounded-3xl">
            {/* Form and heading container  */}

            <div className="tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 lg:tw-grid-cols-2 md:tw-grid-cols-1">
              {/* Form section  LEFT side */}
              <div>
                <div
                  ref={formRef}
                  className="tw-w-auto lg:tw-w-[70%] xl:tw-w-[70%] tw-border tw-p-8 tw-m-4 lg:tw-m-20 xl:tw-m-20 tw-rounded-3xl tw-bg-white tw-shadow-sm "
                >
                  <h2 className="tw-text-lg tw-font-semibold">
                    {t("Register to Book a Free Demo Class")}
                  </h2>
                  <form onSubmit={handleSubmit} className="tw-mt-6">
                    <div>
                      <label
                        htmlFor="name"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                          {t("Your Name")}  <span className="tw-text-red-700">*</span>
                      </label>
                      <div className="tw-relative tw-mt-1">
                        <HiUser className="tw-absolute tw-top-3 tw-left-3 tw-text-2xl tw-text-gray-500" />
                        <input
                          id="name"
                          name="name"
                          type="text"
                          autoComplete="name"
                          value={studentName}
                          onChange={(e) => setStudentName(e.target.value)}
                          required
                          className="tw-pl-12 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                        />
                      </div>
                    </div>
                    <div className="tw-my-2">
                      <label
                        htmlFor="guardianName"
                        className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]"
                      >
                          {t("Your Guardian’s Name")}
                        <span className="tw-text-red-700">*</span>
                      </label>
                      <div className="tw-relative tw-mt-1">
                        <HiUser className="tw-absolute tw-top-3 tw-left-3 tw-text-2xl tw-text-gray-500" />
                        <input
                          id="guardianName"
                          name="guardianName"
                          type="text"
                          autoComplete="guardianName"
                          value={guardianName}
                          onChange={(e) => setGuardianName(e.target.value)}
                          required
                          className="tw-pl-12 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                        />
                      </div>
                    </div>
                    <div className="tw-my-2">
                      <label className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]">
                          {t("Phone number")} <span className="tw-text-red-700">*</span>
                      </label>
                      <div className="tw-mt-1">
                        <PhoneInput
                          country={"bd"}
                          value={phone}
                          onChange={(mobile) => setPhone(mobile)}
                          enableSearch={true}
                        />
                        {/*{phoneNumberError &&*/}
                        {/*    <p className="tw-mt-2 tw-text-sm tw-text-red-700">{phoneNumberError}</p>}*/}
                      </div>
                    </div>
                    <div className="tw-mt-4">
                      <label
                        htmlFor="class"
                        className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]"
                      >
                          {t("Your class")}
                      </label>
                      <div className="tw-relative">
                        <select
                          id="class"
                          name="class"
                          className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6 tw-appearance-none tw-bg-white"
                          value={className}
                          onChange={(e) => setClassName(e.target.value)}
                          defaultValue="ক্লাস নির্বাচন করো"
                        >
                          <option value="" label="ক্লাস নির্বাচন করো"></option>
                          <option
                            value="Not Admitted"
                            label="Not Admitted - ভর্তি করা হয়নি"
                          ></option>
                          <option value="Play" label="Play - প্লে"></option>
                          <option
                            value="Nursery"
                            label="Nursery - নার্সারী"
                          ></option>
                          <option value="KG" label="KG - কেজি"></option>
                          <option
                            value="Class 1"
                            label="Class 1 - শ্রেণী ১"
                          ></option>
                          <option
                            value="Class 2"
                            label="Class 2 - শ্রেণী ২"
                          ></option>
                          <option
                            value="Class 3"
                            label="Class 3 - শ্রেণী ৩"
                          ></option>
                          <option
                            value="Class 4"
                            label="Class 4 - শ্রেণী ৪"
                          ></option>
                          <option
                            value="Class 5"
                            label="Class 5 - শ্রেণী ৫"
                          ></option>
                          <option
                            value="Class 6"
                            label="Class 6 - শ্রেণী ৬"
                          ></option>
                          <option
                            value="Class 7"
                            label="Class 7 - শ্রেণী ৭"
                          ></option>
                          <option
                            value="Class 8"
                            label="Class 8 - শ্রেণী ৮"
                          ></option>
                          <option
                            value="Class 9"
                            label="Class 9 - শ্রেণী ৯"
                          ></option>
                          <option
                            value="Class 10"
                            label="Class 10 - শ্রেণী ১০"
                          ></option>
                          <option
                            value="Class 11"
                            label="Class 11 - শ্রেণী ১১"
                          ></option>
                          <option
                            value="Class 12"
                            label="Class 12 - শ্রেণী ১২"
                          ></option>
                          <option
                            value="Other"
                            label="Other - অন্যান্য"
                          ></option>
                        </select>
                        <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                          <svg
                            className="tw-h-5 tw-w-5 tw-text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="tw-mt-4">
                      <label
                        htmlFor="class"
                        className="tw-block tw-text-[14px] tw-font-[500] tw-leading-[20px] tw-text-[#374151]"
                      >
                          {t("Which course are you interested in")}
                      </label>
                      <div className="tw-relative">
                        <select
                          id="medium"
                          name="medium"
                          className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6 tw-appearance-none tw-bg-white"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          defaultValue={t("Select your preferred course")}
                        >
                          <option
                            value=""
                            label={t("Select your preferred course")}
                          ></option>
                          <option
                            value="অ্যাবাকাস মাইন্ড ম্যাথ"
                            label="অ্যাবাকাস মাইন্ড ম্যাথ"
                          ></option>
                          <option value="আদর্শলিপি" label="আদর্শলিপি"></option>
                          <option value="মজার গণিত" label="মজার গণিত"></option>
                          <option
                            value="গণিত অলিম্পিয়াড"
                            label="গণিত অলিম্পিয়াড"
                          ></option>
                          <option
                            value="একাডেমিক প্রস্তুতি- ৭ম শ্রেণি"
                            label="একাডেমিক প্রস্তুতি- ৭ম শ্রেণি"
                          ></option>
                          <option
                            value="ক্যাডেট প্রি-প্রিপারেশন"
                            label="ক্যাডেট প্রি-প্রিপারেশন"
                          ></option>
                          <option
                            value="মিশন ক্যাডেট - ২৪"
                            label="মিশন ক্যাডেট - ২৪"
                          ></option>
                          <option
                            value="স্মার্ট ইংলিশ"
                            label="স্মার্ট ইংলিশ"
                          ></option>
                        </select>

                        <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                          <svg
                            className="tw-h-5 tw-w-5 tw-text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div className="tw-mt-8">
                      <button
                        type="submit"
                        className={`tw-flex tw-w-full tw-justify-center tw-items-center tw-rounded-3xl tw-px-3 tw-py-3 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white  ${
                          isFormValid ? "tw-bg-[#532D80]" : " tw-bg-gray-400"
                        }`}
                        disabled={!isFormValid}
                      >
                        <span className="tw-mr-2">{t("Submit")}</span>{" "}
                        <HiArrowRight />
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              {/* Right side heading  */}
              <div className="md:tw-p-8 tw-flex tw-flex-col tw-justify-center tw-items-center lg:tw-items-start tw-mt-10">
                {/* Icon  */}
                <div>
                  <img className="tw-w-14 tw-h-auto" src={image1} alt="icon" />
                </div>
                {/* Heading  */}
                <h2 className="tw-text-lg lg:tw-text-2xl tw-text-[#532D80] tw-font-[700] tw-leading-8 md:tw-leading-[40px] tw-mt-4">
                    {t("Register to Book a Free Demo Class")}
                  {/*<span className="tw-text-[#6D758F]"> বুক করুন</span>*/}
                </h2>
                {/* Sub heading  */}
                <p className="tw-text-sm lg:tw-text-[16px] tw-font-[500] tw-text-[#6D758F]  tw-mt-4 tw-leading-[24px] tw-text-left">
                    {t("To Know More About The Classes Contact Us at")}
                </p>

                <div className=" tw-mb-10 lg:tw-mb-0">
                  <div className="tw-w-[270px] tw-h-[88px] tw-bg-white tw-rounded-3xl tw-mt-10">
                    <div className="tw-flex">
                      <div>
                        <img
                          className="tw-w-14 tw-h-14 m-3"
                          src={messageIcon}
                          alt="icon"
                        />
                      </div>
                      <div className="tw-inline-block tw-mt-3">
                        <div className="tw-ml-4 tw-mr-10">
                          <p className="tw-font-[400] tw-text-[14px] tw-mt-2 tw-leading-[20px] tw-text-[#6D758F]">
                              {t("Email")}
                            <br />
                            <span className="tw-text-[#4B5563] tw-text-[14px] tw-font-[600] tw-leading-[20px]">
                              Support@mojaru.net
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tw-w-[270px] tw-h-[88px] tw-bg-white tw-rounded-3xl tw-mt-10 ">
                    <div className="tw-flex">
                      <div>
                        <img
                          className="tw-w-14 tw-h-14 m-3"
                          src={callIcon}
                          alt="icon"
                        />
                      </div>
                      <div className="tw-inline-block tw-mt-3">
                        <div className="tw-ml-4 tw-mr-10">
                          <p className="tw-font-[400] tw-text-[14px] tw-mt-2 tw-leading-[20px] tw-text-[#6D758F]">
                              {t("Phone")}
                            <br />
                            <span className="tw-text-[#4B5563] tw-text-[14px] tw-font-[600] tw-leading-[20px]">
                              09610990880
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {<GreetingMsg open={open} setOpen={setOpen} />}
    </>
  );
};

export default DemoClassBooking;
