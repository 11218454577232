import React, {useState, useEffect} from 'react';
import {BsStopwatch} from "react-icons/bs";
import { FaAngleRight } from "react-icons/fa";
import TimeFinish from "./TimeFinish";
import {useNavigate} from "react-router-dom";

const Quiz = ({dob, name, phone, data}) => {


    const [index, setIndex] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState(data.map(() => null));
    const [next, setNext] = useState(false);
    const [remainingTime, setRemainingTime] = useState(600);
    const [open, setOpen] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime === 0) {
                    clearInterval(timer);
                    setOpen(true);
                }
                return prevTime > 0 ? prevTime - 1 : 0;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    const nextQuestionHandler = () => {
        if (index < data.length - 1) {
            setIndex(prevIndex => prevIndex + 1);
            setNext(false);
        }
    }

    useEffect(() => {
        if (index === data.length - 1) {
            setShowSubmit(true);
        }
    }, [index]);

    const selectedAns = (selectedOptionIndex) => {
        const updatedSelectedOptions = [...selectedOptions];
        updatedSelectedOptions[index] = selectedOptionIndex;
        setSelectedOptions(updatedSelectedOptions);
        setNext(true);
    }

    const submitHandler = () => {
        const correctAnswers = data.filter((question, idx) =>
            question.options[selectedOptions[idx]]?.text === question.ans).length;

        const studentInfo = {
            phone_number: phone,
            student_name: name,
            age: dob,
            obtain_mark: correctAnswers,
            total_mark: data.length,
            type:"free"
        }

        fetch(`https://new.mojaru.com/api/v1/free-exam-data-store`, {
            method: 'POST',
            body: JSON.stringify(studentInfo),
                headers: {
                    "Content-Type": "application/json",
                },
        })
            .then((res) => res.json())


        navigate('/quiz-result', { state: { score: correctAnswers, total: data.length, selectedOptions, data: data} });
        setOpen(false);
    }

    return (
        <div className="tw-max-w-[640px] tw-mx-auto tw-bg-white tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-10 tw-rounded-2xl">
            <div className="tw-w-full">
                <div className="tw-mb-6 tw-flex tw-justify-between">
                    <p>
                        <span className="tw-text-5xl tw-font-bold tw-text-purple-600">{index + 1}</span>
                        <span className="tw-text-2xl tw-font-semibold tw-text-gray-400"> / {data.length}</span>
                    </p>
                    <div className="tw-flex tw-items-center">
                        <BsStopwatch className="tw-text-4xl tw-text-purple-600 tw-mr-6" />
                        <span className="tw-text-4xl tw-text-purple-600 tw-font-semibold"> {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds} </span>
                    </div>
                </div>
                <div className="tw-mb-6">
                    <h2 className="">{data[index].question}</h2>
                </div>
                {
                    data[index].images && <div className="tw-border tw-mb-3 tw-shadow-lg">
                        <img src={data[index].images} alt="Question"/>
                    </div>
                }
                <ul className="tw-max-w-[450px] tw-mt-8">
                    {
                        data[index].options.map((option, idx) => (
                            <li key={idx} onClick={() => selectedAns(idx)}
                                className={selectedOptions[index] === idx ? "tw-flex tw-items-center tw-h-12 tw-pl-4 tw-rounded-lg tw-mb-6 tw-font-semibold tw-cursor-pointer tw-bg-purple-100 tw-border tw-border-purple-500" : "tw-flex tw-items-center tw-h-12 tw-pl-4 tw-border tw-rounded-lg tw-mb-6 tw-font-semibold tw-cursor-pointer"}>{idx + 1}. {option.text}</li>
                        ))
                    }
                </ul>
                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                    {
                        showSubmit ? <button onClick={submitHandler} disabled={!next} className={`tw-w-[250px] tw-h-[65px] tw-px-8 tw-py-2 tw-text-white tw-rounded-2xl tw-text-2xl tw-flex tw-justify-center tw-items-center ${next ? 'tw-bg-purple-500 hover:tw-bg-purple-400' : 'tw-bg-gray-400 cursor-not-allowed'}`}>submit</button> : <button
                            onClick={()=>nextQuestionHandler()}
                            disabled={!next}
                            className={`tw-w-[250px] tw-h-[65px] tw-px-8 tw-py-2 tw-text-white tw-rounded-2xl tw-text-2xl tw-flex tw-justify-center tw-items-center ${next ? 'tw-bg-purple-500 hover:tw-bg-purple-400' : 'tw-bg-gray-400 cursor-not-allowed'}`}>
                            <span className="tw-mr-4">Next</span>
                            <FaAngleRight />
                        </button>
                    }
                    <p className="tw-mt-4">{index + 1} of {data.length} Question</p>
                </div>
            </div>
            <TimeFinish open={open} setOpen={setOpen} submitHandler={submitHandler}/>
        </div>
    );
};

export default Quiz;

