import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './AssignmentSubmitAutoCount.css';
import StudentPageTitle from "../../../../shared/StudentPageTitle/StudentPageTitle";
import CoursePlaylist from "../../Syllabus/CoursePlaylist/CoursePlaylist";
import {FaCalendarAlt} from "react-icons/fa";
import certificateIcon from "../../../../../../images/course_play_list/certificate-icon 1.png";
import {englishToBengaliNumberFormat} from "../../../../../../utilities/utilities";

const AssignmentSubmitAutoCount = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const assignment_name = location?.state?.assignment_name;
    const wrong_answer = location?.state?.wrong_answer;
    const correct_answer = location?.state?.correct_answer;
    const get_marks = location?.state?.get_marks;
    const total_marks = location?.state?.total_marks;
    const course_id = location?.state?.course_id;
    const lessons = location?.state?.lessons;
    const topic = location?.state?.topic;
    const batch_id = location?.state?.batch_id;
    const index = location?.state?.index;
    const lesion_name = location?.state?.lesion_name;
    let progressPercent = location?.state?.progressPercent;

    const handelGoToDashboard =()=>{
        //navigate("/dashboard")
        navigate("/my-course/lms", {
            state: {
                course_id: course_id,
                batch_id: batch_id
            },
        });
    }
    return (
        <>
            {
                lessons ? (
                    <div className="main-content">
                        <StudentPageTitle title="View Smart Note"></StudentPageTitle>
                        <div className="page-content p-0">
                            <div className="container-fluid">
                                <div className="my-course-section">
                                    <h2 className="tw-px-16 tw-mt-8 tw-mb-8">ক্লাস : {lesion_name}</h2>
                                    <div className="md:tw-flex md:tw-justify-between tw-px-16">
                                        <div className="tw-flex">
                                            {/*<img className="tw-w-10 tw-h-10 tw-mr-4 tw-rounded-lg"*/}
                                            {/*     src={`${lectureDetail?.video_lecture?.teacher_image_path}${lectureDetail?.video_lecture?.teacher_image}`}*/}
                                            {/*     alt="profile image"/>*/}
                                            <div>
                                                <p className="tw-text-[#6B7280] tw-text-sm">Published by</p>
                                                {/*<p className="tw-text-gray-700">{lectureDetail?.video_lecture?.teacher_name}</p>*/}
                                            </div>
                                        </div>
                                        <div className="tw-flex">
                                            <FaCalendarAlt className="tw-text-4xl tw-mr-4 tw-text-gray-500"/>
                                            <div>
                                                <p className="tw-text-[#6B7280] tw-text-sm">Published on</p>
                                                <p className="tw-text-gray-700">২১ এপ্রিল, ২০২৩</p>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-bg-white tw-px-5 tw-py-3 tw-rounded-lg">
                                            <div
                                                className="tw-mr-4 tw-border-r-2 tw-border-gray-300 tw-border-sm tw-px-7">
                                                <p className="tw-text-2xl tw-font-semibold">কোর্স
                                                    প্রোগ্রেস {englishToBengaliNumberFormat(index + 1)}/ {englishToBengaliNumberFormat(lessons.length)}
                                                </p>
                                                <div
                                                    className="tw-w-[200px] tw-bg-gray-200 tw-rounded-full h-4 tw-text-white tw-text-sm tw-text-center">
                                                    <div
                                                        className="tw-bg-green-400 tw-h-4 tw-rounded-full"
                                                        style={{width: `${progressPercent}%`}}
                                                    >{progressPercent}%
                                                    </div>
                                                </div>
                                            </div>
                                            <img className="tw-w-10 tw-h-10" src={certificateIcon} alt="image"/>
                                        </div>
                                    </div>
                                    <div className="md:tw-flex tw-justify-between tw-p-10">
                                        <div className="md:tw-w-[65%]">
                                            <div>
                                                <div className='d-flex justify-content-between align-items-center p-4'>
                                                </div>
                                                <div className='total-assignment-divider'></div>

                                                <div className='container mt-3'>


                                                    <div className='d-flex justify-content-center align-items-center'
                                                         style={{height: '80vh'}}>

                                                        <div>
                                                            <div className='auto-count-score'>
                                                                <p className='text-center mb-0 score-title'>Your
                                                                    Score</p>
                                                                <p className='text-center mb-0 get-score'>{get_marks}</p>
                                                            </div>
                                                            <p className='complete-title text-center'>Completed
                                                                Successfully!</p>
                                                            {/*                     <p className='get-result-notice text-center'>We have received your submission. Mentor will evaluate the paper & publish the <br/> result within a very shotrt time.</p>
 */}
                                                            <div
                                                                className='d-flex justify-content-center score-short-description mb-3 w-100'>
                                                                <div className='w-100'>
                                                                    <div
                                                                        className='d-flex justify-content-between w-100 score-short-description-total'>
                                                                        <p className='mb-0'
                                                                           style={{
                                                                               color: '#656367',
                                                                               fontWeight: '400'
                                                                           }}>Total
                                                                            Marks</p>
                                                                        <p className='mb-0 pb-1'
                                                                           style={{
                                                                               color: '#000',
                                                                               fontWeight: '500'
                                                                           }}>{total_marks}</p>
                                                                    </div>
                                                                    <div
                                                                        className='d-flex justify-content-between w-100 score-short-description-total mt-2'>
                                                                        <p className='mb-0'
                                                                           style={{
                                                                               color: '#656367',
                                                                               fontWeight: '400'
                                                                           }}>Total
                                                                            Questions</p>
                                                                        <p className='mb-0 pb-1' style={{
                                                                            color: '#000',
                                                                            fontWeight: '500'
                                                                        }}>{correct_answer + wrong_answer}</p>
                                                                    </div>
                                                                    <div
                                                                        className='d-flex justify-content-between w-100 score-short-description-total mt-2'>
                                                                        <p className='mb-0'
                                                                           style={{
                                                                               color: '#656367',
                                                                               fontWeight: '400'
                                                                           }}>Right
                                                                            Answer(s)</p>
                                                                        <p className='mb-0 pb-1 text-success'
                                                                           style={{fontWeight: '500'}}>{correct_answer}</p>
                                                                    </div>
                                                                    <div
                                                                        className='d-flex justify-content-between w-100 mt-2'>
                                                                        <p className='mb-0'
                                                                           style={{
                                                                               color: '#656367',
                                                                               fontWeight: '400'
                                                                           }}>Wrong
                                                                            Answer(s)</p>
                                                                        <p className='mb-0 pb-1 text-danger'
                                                                           style={{fontWeight: '500'}}>{wrong_answer}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="md:tw-w-[30%]">
                                            <CoursePlaylist lessons={lessons} topic={topic} courseId={course_id}
                                                            batchId={batch_id} index={index}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='d-flex justify-content-between align-items-center p-4'>
                            <div className='btn-assignment-submit-and-close'>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={handelGoToDashboard}></button>
                            </div>
                            <p className='text-center mb-0 show-assignment-name'>{assignment_name}</p>
                            <div></div>
                        </div>
                        <div className='total-assignment-divider'></div>

                        <div className='container mt-3'>


                            <div className='d-flex justify-content-center align-items-center' style={{height: '80vh'}}>

                                <div>
                                    <div className='auto-count-score'>
                                        <p className='text-center mb-0 score-title'>Your Score</p>
                                        <p className='text-center mb-0 get-score'>{get_marks}</p>
                                    </div>
                                    <p className='complete-title text-center'>Completed Successfully!</p>
                                    {/*                     <p className='get-result-notice text-center'>We have received your submission. Mentor will evaluate the paper & publish the <br/> result within a very shotrt time.</p>
 */}
                                    <div className='d-flex justify-content-center score-short-description mb-3 w-100'>
                                        <div className='w-100'>
                                            <div
                                                className='d-flex justify-content-between w-100 score-short-description-total'>
                                                <p className='mb-0' style={{color: '#656367', fontWeight: '400'}}>Total
                                                    Marks</p>
                                                <p className='mb-0 pb-1'
                                                   style={{color: '#000', fontWeight: '500'}}>{total_marks}</p>
                                            </div>
                                            <div
                                                className='d-flex justify-content-between w-100 score-short-description-total mt-2'>
                                                <p className='mb-0' style={{color: '#656367', fontWeight: '400'}}>Total
                                                    Questions</p>
                                                <p className='mb-0 pb-1' style={{
                                                    color: '#000',
                                                    fontWeight: '500'
                                                }}>{correct_answer + wrong_answer}</p>
                                            </div>
                                            <div
                                                className='d-flex justify-content-between w-100 score-short-description-total mt-2'>
                                                <p className='mb-0' style={{color: '#656367', fontWeight: '400'}}>Right
                                                    Answer(s)</p>
                                                <p className='mb-0 pb-1 text-success'
                                                   style={{fontWeight: '500'}}>{correct_answer}</p>
                                            </div>
                                            <div className='d-flex justify-content-between w-100 mt-2'>
                                                <p className='mb-0' style={{color: '#656367', fontWeight: '400'}}>Wrong
                                                    Answer(s)</p>
                                                <p className='mb-0 pb-1 text-danger'
                                                   style={{fontWeight: '500'}}>{wrong_answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Link to='/my-course/lms' state={{
                                            course_id: course_id,
                                            batch_id: batch_id
                                        }} className='btn-submitAssignment-to-dashboard px-4 py-2'
                                              onClick={handelGoToDashboard}>Go to dashboard</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default AssignmentSubmitAutoCount;