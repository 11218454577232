// import React, { useEffect } from "react";
// import { useState } from "react";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
// import morning from "../../../../../../images/course_details_icon/Morning.png";
// import afternoon from "../../../../../../images/course_details_icon/Afternoon.png";
// import evening from "../../../../../../images/course_details_icon/Eveninig.png";
// import night from "../../../../../../images/course_details_icon/night.png";
// import infoIcon from "../../../../../../images/course_details_icon/Information_circle.png";
// import officeOff from "../../../../../../images/course_details_icon/offDay.png";
// import { timeSlots } from "../../../../../../utils/Constant";
// import { CalendarIcon } from "@heroicons/react/24/solid";
// import { HiArrowRight, HiUser } from "react-icons/hi";
// import PhoneInput from "react-phone-input-2";
// import GreetingMsg from "../../../../components/AdmissionLanding/Shared/GreetingMsg/GreetingMsg";

// const TimeSchedule = ({
//   className,
//   guardianName,
//   subject,
//   studentName,
//   phone,
// }) => {
//   const [value, onChange] = useState(new Date());
//   const [tabSelected, setTabSelected] = useState("সকাল");
//   const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
//   const [anotherTime, setAnotherTime] = useState(false);
//   const [isOffDay, setIsOffDay] = useState(false);
//   const [userPreferenceTime, setUserPreferenceTime] = useState("");
//   const [open, setOpen] = useState(false);

//   const handleTabSelected = (value) => {
//     setTabSelected(value);
//   };

//   const handleSubmit = () => {
//     const preference_date = value.toISOString().split("T")[0];
//     const guardian_name = guardianName;
//     const class_name = className;
//     const student_name = studentName;
//     const preference_time = selectedTimeSlot;
//     const mobile = phone;
//     const client_requirment = userPreferenceTime;
//     const interested_courses = subject;
//     const data = {
//       preference_date,
//       guardian_name,
//       class_name,
//       student_name,
//       preference_time,
//       mobile,
//       client_requirment,
//       interested_courses,
//       type: "1-on-1-slot",
//     };
//     fetch(`${process.env.REACT_APP_BASE_URL}api/v1/registration-lead-store`, {
//       method: "POST",
//       body: JSON.stringify(data),
//       headers: {
//         "Content-Type": "application/json",
//         secretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setOpen(true);
//       });
//   };

//   const getSelectedTimeSlots = () => {
//     switch (tabSelected) {
//       case "সকাল":
//         return timeSlots[0].morningTimes;
//       case "দুপুর":
//         return timeSlots[1].afterNoonTimes;
//       case "বিকেল":
//         return timeSlots[2].eveningTimes;
//       case "সন্ধ্যা / রাত":
//         return timeSlots[3].nightTimes;
//       default:
//         return [];
//     }
//   };

//   const handleTimeSlot = (time) => {
//     setSelectedTimeSlot(time);
//   };

//   useEffect(() => {
//     async function fetchApi() {
//       try {
//         const formattedDate = value.toISOString().split("T")[0];
//         const response = await fetch(
//           `${process.env.REACT_APP_BASE_URL}api/v2/check-holiday?date=${formattedDate}`,
//           {
//             method: "GET",
//             headers: {
//               "Content-Type": "application/json",
//               secretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
//             },
//           }
//         );
//         const data = await response.json();
//         setIsOffDay(data.data.holiday);
//       } catch (error) {
//         console.error("Error fetching API:", error);
//       }
//     }
//     fetchApi();
//   }, [value]);

//   return (
//     <div className="tw-mt-8">
//       <h2 className="tw-text-lg tw-font-bold ">এসেসমেন্টের সময়</h2>
//       {/* ***Start *** */}
//       <div className="tw-w-full tw-mt-4 tw-bg-white tw-rounded-3xl tw-p-6">
//         {/* Calendar and Time Table */}
//         <div
//           className={`tw-flex tw-flex-col lg:tw-flex-row tw-gap-6 ${
//             isOffDay ? "" : "lg:tw-justify-center"
//           }`}
//         >
//           {/* Calendar Section */}
//           <div className="tw-w-full lg:tw-w-1/2">
//             <Calendar
//               className="border-0 tw-rounded-xl tw-shadow-md tw-bg-red-200 rounded-t-xl tw-overflow-hidden"
//               onChange={onChange}
//               value={value}
//               minDate={new Date()}
//             />
//           </div>

//           {/* Time Selection Section */}
//           <div className="tw-w-full lg:tw-w-1/2">
//             {isOffDay ? (
//               <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
//                 <div>
//                   <img src={officeOff} alt="icon" />
//                 </div>
//                 <div className="tw-mt-8">
//                   <h2 className="tw-text-3xl tw-font-bold tw-text-gray-900 tw-text-center">
//                     সাপ্তাহিক ছুটির দিন!
//                   </h2>
//                   <p className="tw-text-sm tw-text-gray-500 tw-text-center tw-mt-2">
//                     নির্ধারিত তারিখে কোন সময় পাওয়া যাই নি।
//                     <br /> অন্য তারিখ সিলেক্ট করো
//                   </p>
//                 </div>
//               </div>
//             ) : (
//               <div>
//                 <h2 className="tw-text-lg tw-font-bold">তোমার সময় বেছে নাও</h2>
//                 <div className="tw-flex tw-justify-between tw-mt-6 tw-flex-wrap">
//                   <div
//                     onClick={() => handleTabSelected("সকাল")}
//                     className={`tw-flex tw-items-center tw-mr-4 tw-cursor-pointer ${
//                       tabSelected === "সকাল"
//                         ? "tw-border-[#FFCE39] tw-border-2 tw-p-2 tw-rounded-3xl tw-bg-[#FFFAE8]"
//                         : ""
//                     }`}
//                   >
//                     <img className="tw-mr-2" src={morning} alt="morning" />
//                     <p className="tw-mt-1">সকাল</p>
//                   </div>
//                   <div
//                     onClick={() => handleTabSelected("দুপুর")}
//                     className={`tw-flex tw-items-center tw-mr-4 tw-cursor-pointer ${
//                       tabSelected === "দুপুর"
//                         ? "tw-border-[#FFCE39] tw-border-2 tw-p-2 tw-rounded-3xl tw-bg-[#FFFAE8]"
//                         : ""
//                     }`}
//                   >
//                     <img className="tw-mr-3" src={afternoon} alt="afternoon" />
//                     <p className="tw-mt-0">দুপুর</p>
//                   </div>
//                   <div
//                     onClick={() => handleTabSelected("বিকেল")}
//                     className={`tw-flex tw-items-center tw-mr-4 tw-cursor-pointer ${
//                       tabSelected === "বিকেল"
//                         ? "tw-border-[#FFCE39] tw-border-2 tw-p-2 tw-rounded-3xl tw-bg-[#FFFAE8]"
//                         : ""
//                     }`}
//                   >
//                     <img className="tw-mr-2" src={evening} alt="evening" />
//                     <p className="tw-mt-1">বিকেল</p>
//                   </div>
//                   <div
//                     onClick={() => handleTabSelected("সন্ধ্যা / রাত")}
//                     className={`tw-flex tw-items-center tw-mr-4 tw-cursor-pointer ${
//                       tabSelected === "সন্ধ্যা / রাত"
//                         ? "tw-border-[#FFCE39] tw-border-2 tw-p-2 tw-rounded-3xl tw-bg-[#FFFAE8]"
//                         : ""
//                     }`}
//                   >
//                     <img className="tw-mr-2" src={night} alt="night" />
//                     <p className="">সন্ধ্যা / রাত</p>
//                   </div>
//                 </div>
//                 <div className="tw-mt-8">
//                   {getSelectedTimeSlots().map((timeSlot, idx) => (
//                     <div
//                       onClick={() => handleTimeSlot(timeSlot)}
//                       key={idx}
//                       className={`tw-inline-block tw-mr-4 ${
//                         getSelectedTimeSlots().length === idx ? "" : "tw-mb-6"
//                       }`}
//                     >
//                       <span
//                         className={`tw-p-2 ${
//                           selectedTimeSlot === timeSlot
//                             ? " tw-bg-[#532D80] tw-text-white"
//                             : " tw-border"
//                         } tw-rounded-3xl tw-cursor-pointer`}
//                       >
//                         {timeSlot}
//                       </span>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       {/* ***End *** */}
//       {<GreetingMsg open={open} setOpen={setOpen} />}
//     </div>
//   );
// };

// export default TimeSchedule;

import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import morning from "../../../../../../images/course_details_icon/Morning.png";
import afternoon from "../../../../../../images/course_details_icon/Afternoon.png";
import evening from "../../../../../../images/course_details_icon/Eveninig.png";
import night from "../../../../../../images/course_details_icon/night.png";
import infoIcon from "../../../../../../images/course_details_icon/Information_circle.png";
import officeOff from "../../../../../../images/course_details_icon/offDay.png";
import { timeSlots } from "../../../../../../utils/Constant";
import { CalendarIcon } from "@heroicons/react/24/solid";
import { HiArrowRight } from "react-icons/hi";
import GreetingMsg from "../../../../components/AdmissionLanding/Shared/GreetingMsg/GreetingMsg";

const TimeSchedule = ({
  className,
  guardianName,
  subject,
  studentName,
  phone,
}) => {
  const [value, onChange] = useState(new Date());
  const [tabSelected, setTabSelected] = useState("সকাল");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [anotherTime, setAnotherTime] = useState(false);
  const [isOffDay, setIsOffDay] = useState(false);
  const [userPreferenceTime, setUserPreferenceTime] = useState("");
  const [open, setOpen] = useState(false);

  const formatDate = (date) => {
    const offsetDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return offsetDate.toISOString().split("T")[0];
  };

  const handleTabSelected = (value) => {
    setTabSelected(value);
  };

  const handleSubmit = () => {
    const preference_date = formatDate(value); // Fixed here
    const guardian_name = guardianName;
    const class_name = className;
    const student_name = studentName;
    const preference_time = selectedTimeSlot;
    const mobile = phone;
    const client_requirment = userPreferenceTime;
    const interested_courses = subject;

    const data = {
      preference_date,
      guardian_name,
      class_name,
      student_name,
      preference_time,
      mobile,
      client_requirment,
      interested_courses,
      type: "1-on-1-slot",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}api/v1/registration-lead-store`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        secretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        setOpen(true);
      });
  };

  const getSelectedTimeSlots = () => {
    switch (tabSelected) {
      case "সকাল":
        return timeSlots[0].morningTimes;
      case "দুপুর":
        return timeSlots[1].afterNoonTimes;
      case "বিকেল":
        return timeSlots[2].eveningTimes;
      case "সন্ধ্যা / রাত":
        return timeSlots[3].nightTimes;
      default:
        return [];
    }
  };

  const handleTimeSlot = (time) => {
    setSelectedTimeSlot(time);
  };

  useEffect(() => {
    async function fetchApi() {
      try {
        const formattedDate = formatDate(value); // Fixed here
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}api/v2/check-holiday?date=${formattedDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              secretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
            },
          }
        );
        const data = await response.json();
        setIsOffDay(data.data.holiday);
      } catch (error) {
        console.error("Error fetching API:", error);
      }
    }
    fetchApi();
  }, [value]);

  return (
    <div className="tw-mt-8">
      <h2 className="tw-text-lg tw-font-bold">এসেসমেন্টের সময়</h2>
      {/* <div className="tw-w-full tw-mt-4 tw-bg-white tw-rounded-3xl tw-p-6">
        <div className={`tw-flex tw-flex-col lg:tw-flex-row tw-gap-6`}>
          <div className="tw-w-full lg:tw-w-1/2">
            <Calendar
              className="border-0 tw-rounded-xl tw-shadow-md tw-bg-red-200 rounded-t-xl tw-overflow-hidden"
              onChange={onChange}
              value={value}
              minDate={new Date()}
            />
          </div>
          <div className="tw-w-full lg:tw-w-1/2">
            {isOffDay ? (
              <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                <div>
                  <img src={officeOff} alt="icon" />
                </div>
                <div className="tw-mt-8">
                  <h2 className="tw-text-3xl tw-font-bold tw-text-gray-900 tw-text-center">
                    সাপ্তাহিক ছুটির দিন!
                  </h2>
                  <p className="tw-text-sm tw-text-gray-500 tw-text-center tw-mt-2">
                    নির্ধারিত তারিখে কোন সময় পাওয়া যাই নি।
                    <br /> অন্য তারিখ সিলেক্ট করো
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <h2 className="tw-text-lg tw-font-bold">তোমার সময় বেছে নাও</h2>
                <div className="tw-flex tw-justify-between tw-mt-6 tw-flex-wrap">
                  {["সকাল", "দুপুর", "বিকেল", "সন্ধ্যা / রাত"].map((period) => (
                    <div
                      key={period}
                      onClick={() => handleTabSelected(period)}
                      className={`tw-flex tw-items-center tw-mr-4 tw-cursor-pointer ${
                        tabSelected === period
                          ? "tw-border-[#FFCE39] tw-border-2 tw-p-2 tw-rounded-3xl tw-bg-[#FFFAE8]"
                          : ""
                      }`}
                    >
                      <img
                        className="tw-mr-2"
                        src={
                          period === "সকাল"
                            ? morning
                            : period === "দুপুর"
                            ? afternoon
                            : period === "বিকেল"
                            ? evening
                            : night
                        }
                        alt={period}
                      />
                      <p className="tw-mt-1">{period}</p>
                    </div>
                  ))}
                </div>
                <div className="tw-mt-8">
                  {getSelectedTimeSlots().map((timeSlot, idx) => (
                    <div
                      key={idx}
                      onClick={() => handleTimeSlot(timeSlot)}
                      className={`tw-inline-block tw-mr-4 ${
                        getSelectedTimeSlots().length === idx ? "" : "tw-mb-6"
                      }`}
                    >
                      <span
                        className={`tw-p-2 ${
                          selectedTimeSlot === timeSlot
                            ? "tw-bg-[#532D80] tw-text-white"
                            : "tw-border"
                        } tw-rounded-3xl tw-cursor-pointer`}
                      >
                        {timeSlot}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div> */}
      <div className="tw-w-full tw-mt-4 tw-bg-white tw-rounded-3xl tw-p-6">
        <div
          className={`tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-center tw-gap-6`}
        >
          {/* Calendar Section */}
          <div className="tw-w-full lg:tw-w-1/2 tw-flex tw-justify-center lg:tw-justify-start">
            <Calendar
              className="tw-w-full lg:tw-max-w-[90%] border-0 tw-rounded-xl tw-shadow-md tw-bg-red-200 tw-overflow-hidden"
              onChange={onChange}
              value={value}
              minDate={new Date()}
            />
          </div>

          {/* Right Content Section */}
          <div className="tw-w-full lg:tw-w-1/2 tw-flex tw-justify-center">
            {isOffDay ? (
              <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-center">
                <div>
                  <img src={officeOff} alt="icon" />
                </div>
                <div className="tw-mt-8">
                  <h2 className="tw-text-3xl tw-font-bold tw-text-gray-900">
                    সাপ্তাহিক ছুটির দিন!
                  </h2>
                  <p className="tw-text-sm tw-text-gray-500 tw-mt-2">
                    নির্ধারিত তারিখে কোন সময় পাওয়া যাই নি।
                    <br /> অন্য তারিখ সিলেক্ট করো
                  </p>
                </div>
              </div>
            ) : (
              <div className="tw-w-full lg:tw-max-w-[90%]">
                <h2 className="tw-text-lg tw-font-bold">তোমার সময় বেছে নাও</h2>
                <div className="tw-flex tw-justify-between tw-mt-6 tw-flex-wrap">
                  {["সকাল", "দুপুর", "বিকেল", "সন্ধ্যা / রাত"].map((period) => (
                    <div
                      key={period}
                      onClick={() => handleTabSelected(period)}
                      className={`tw-flex tw-items-center tw-mr-4 tw-cursor-pointer ${
                        tabSelected === period
                          ? "tw-border-[#FFCE39] tw-border-2 tw-p-2 tw-rounded-3xl tw-bg-[#FFFAE8]"
                          : ""
                      }`}
                    >
                      <img
                        className="tw-mr-2"
                        src={
                          period === "সকাল"
                            ? morning
                            : period === "দুপুর"
                            ? afternoon
                            : period === "বিকেল"
                            ? evening
                            : night
                        }
                        alt={period}
                      />
                      <p className="tw-mt-1">{period}</p>
                    </div>
                  ))}
                </div>
                <div className="tw-mt-8">
                  {getSelectedTimeSlots().map((timeSlot, idx) => (
                    <div
                      key={idx}
                      onClick={() => handleTimeSlot(timeSlot)}
                      className={`tw-inline-block tw-mr-4 ${
                        getSelectedTimeSlots().length === idx ? "" : "tw-mb-6"
                      }`}
                    >
                      <span
                        className={`tw-p-2 ${
                          selectedTimeSlot === timeSlot
                            ? "tw-bg-[#532D80] tw-text-white"
                            : "tw-border"
                        } tw-rounded-3xl tw-cursor-pointer`}
                      >
                        {timeSlot}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {<GreetingMsg open={open} setOpen={setOpen} />}
    </div>
  );
};

export default TimeSchedule;
