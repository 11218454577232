import React from 'react';
import './PartialPayment.css';

import { useState } from 'react';
import PaymentCalculation from './PaymentCalculation/PaymentCalculation';
import PaymentMethod from '../../Pages/PaymentMethod/PaymentMethod';
/* import PaymentMethod from './PaymentMethod/PaymentMethod'; */

const PartialPayment = ({courseBasicInfo}) => {

    const [active, setActive] = useState("payment-calculation");
    const [data, setData] = useState({});
    
    const handleActivePaymentMethod = () => {
        setActive("payment-method");
    };

    /* const {batch_id, course_id} = useContext(LMSContext); */


    

    return (
        <div className="offcanvas offcanvas-end result-details" tabIndex="-1" id="offcanvasPartialPayment" aria-labelledby="offcanvasPartialPaymentLabel" style={{ overflowY:'auto' }}>
            <div className="offcanvas-header student-profile p-4">
                <h5 id="offcanvasRightLabel">Payment</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close">
                </button>
            </div>
            <div className='total-assignment-divider'></div>
            <div className="offcanvas-body">
                {active === "payment-calculation" && (
                    <PaymentCalculation
                        handleActivePaymentMethod={handleActivePaymentMethod}
                        courseBasicInfo={courseBasicInfo}
                        setData = {setData}
                    />
                )}

                {active === "payment-method" && <PaymentMethod data={data}/>}
                
            </div>
        </div>
    );
};

export default PartialPayment;