import React from "react";
import "./WelcomeSection.css";
import calenderCard from "../../../../../../images/calendar_card.png";
import backgroundImage from "../../../../../../images/morning_illustration.png";
import {
  dateConverterEnglish,
  welComeSection,
} from "../../../../../../utilities/utilities";
import { Link } from "react-router-dom";

const WelcomeSection = () => {
  return (
    <div className="col-lg-12 w-100 welcome-section-mobile-view hide-tab-view">
      <div
        className="welcome-section"
        style={{ background: `url(${backgroundImage})` }}
      >
        <div className="p-3 pb-0">
          {/* <h3>শুভ সকাল !</h3> */}
          <h3>{welComeSection(new Date().getHours())}</h3>
          <p style={{ fontWeight: "500" }}>
            লাইভ ক্লাসের
            <br />
            খুঁটিনাটি সব জেনে নাও ড্যাশবোর্ডে
          </p>

          <div className="today-date-section">
            <p className="pt-lg-2 mb-2 today-date-text">Today's Date </p>
            <div className="pb-3">
              <span
                className="px-2 py-lg-1 rounded-3"
                style={{
                  backgroundColor: "rgba(255, 255, 255, .60)",
                  color: "#532d80",
                }}
              >
                <span className="pe-2">
                  <img src={calenderCard} alt="" />
                </span>
                <span className="pt-1 today-date" style={{ fontWeight: "500" }}>
                  {dateConverterEnglish(new Date())}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* ****** Generate Avatar Button ******** */}
      {/* <div>
        <Link to="http://localhost:3000/avatar">
          <button className="tw-bg-[#532d80] tw-rounded-lg tw-mt-4 tw-text-white tw-px-4 tw-py-2">
            Generate Avatar
          </button>
        </Link>
      </div> */}
    </div>
  );
};

export default WelcomeSection;
