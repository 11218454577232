import React, { createContext, useContext, useState } from 'react';

// Create the context
const ModalContext = createContext();

// Create the provider component
export const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState(null);

    const openModal = (content) => {
        setContent(content);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setContent(null);
    };

    return (
        <ModalContext.Provider value={{ isOpen, openModal, closeModal }}>
            {children}
            {isOpen && (
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center bg-black bg-opacity-70"
                    onClick={closeModal} // Close the modal when clicking outside
                >
                    <div className="relative z-50">
                        {content}
                    </div>
                </div>
            )}
        </ModalContext.Provider>
    );
};

// Custom hook to use the modal context
export const useModal = () => useContext(ModalContext);
