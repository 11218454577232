import React from "react";

// CSS for the star
const styles = {
  star: {
    fontSize: "24px",
    margin: "5px",
    color: "gold", // Always golden
  },
};
const SingleStarRating = ({ rating }) => {
  // Ensure the rating is between 1 and 5
  const validRating = Math.min(Math.max(rating, 1), 5);

  // Determine the color of the star
  const starStyle =
    validRating === 3 || validRating === 4 ? styles.golden : styles.star;

  return (
    <div>
      <span style={starStyle}>★</span>
    </div>
  );
};

export default SingleStarRating;
