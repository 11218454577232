import React, { useEffect, useRef, useState } from "react";
import logo from "../../../../../images/logo-yellow.png";
import checkIcon from "../../../../../images/OneToOne/Check-circle.svg";
import image1 from "../../../../../images/OneToOne/image1.png";
import image2 from "../../../../../images/OneToOne/image2.png";
import image3 from "../../../../../images/OneToOne/image3.png";
import image4 from "../../../../../images/OneToOne/image4.png";
import "./OneToOne.css";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { HiArrowRight, HiUser } from "react-icons/hi";
import Faq from "../../../shared/CourseDetails/CourseDetailsInformation/Faq/Faq";
import CallImage from "../../../../../images/leadCollectImage/MojaruWebCCR.jpg";

import cardImage1 from "../../../../../images/OneToOne/cardImage1.png";
import cardImage2 from "../../../../../images/OneToOne/cardImage2.png";
import cardImage3 from "../../../../../images/OneToOne/cardImage3.png";
import cardImage4 from "../../../../../images/OneToOne/cardImage4.png";
import cardImage5 from "../../../../../images/OneToOne/cardImage5.png";
import cardImage6 from "../../../../../images/OneToOne/cardImage6.png";
import ScrollToTop from "../../../shared/ScrollToTop/ScrollToTop";
import { toast } from "react-toastify";
import GreetingMsg from "../Shared/GreetingMsg/GreetingMsg";
import PopularCourse from "../Shared/PopularCourse/PopularCourse";
import FormFooter from "../../../shared/FormFooter/FormFooter";
import backButton from "../../../../../images/OneToOne/backButton.png";
import TimeSchedule from "./Components/TimeSchedule";
import childImage from "../../../../../images/OneToOne/studentCropImg.png";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const faqs = [
  {
    course_faq_answer:
      "মজারুর ওয়ান টু ওয়ান কাস্টমাইজড লার্নিং এমন একটি প্রোগ্রাম যেখানে শিক্ষার্থীরা নিজের প্রয়োজন অনুযায়ী সম্পূর্ণ সিলেবাসের প্রস্তুতি একজন পার্সোনাল শিক্ষক থেকে নিতে পারবে।",
    course_faq_id: 1,
    course_faq_question: "ওয়ান টু ওয়ান কাস্টমাইজড লার্নিং মানে কী? ",
  },
  {
    course_faq_answer: (
      <>
        তোমার তথ্য ও প্রয়োজনীয়তা উল্লেখ করে{" "}
        <span className="tw-text-blue-500 tw-underline tw-cursor-pointer">
          ফর্মটি
        </span>{" "}
        ফিলাপ করো। আমাদের একজন স্টুডেন্ট কাউন্সিলর খুব দ্রুতই তোমার সাথে এই
        বিষয়ে যোগাযোগ করবে।
      </>
    ),
    course_faq_id: 2,
    course_faq_question: "আমি কীভাবে এই প্রোগ্রামে ভর্তি হতে পারি? ",
  },
  {
    course_faq_answer:
      "জ্বী! এই প্রোগ্রামে পরীক্ষাগুলো শিক্ষার্থীদের প্রয়োজন ও চাহিদা বুঝে নেয়া হয়। এবং এই পরীক্ষাগুলো অবশ্যই অভিভাবকদের সাথে আলোচনা করে সেট করা হয়।",
    course_faq_id: 3,
    course_faq_question:
      "এই প্রোগ্রামে পরীক্ষাগুলো কি শিক্ষার্থীদের চাহিদা অনুযায়ী নেয়া হয়?",
  },
  {
    course_faq_answer:
      "মজারুর ওয়ান টু ওয়ান কাস্টমাইজড লার্নিংয়ে ইংলিশ ও বাংলা ভার্সন, দুটোই রয়েছে। শিক্ষার্থী যেই মাধ্যমে স্বাচ্ছন্দ্যবোধ করে, সেই মাধ্যমেই তার কোর্স প্ল্যান সাজানো হবে।",
    course_faq_id: 4,
    course_faq_question: "এই প্রোগ্রামে কি ইংলিশ ভার্সন রয়েছে? ",
  },
  {
    course_faq_answer:
      "আমরা শিক্ষার্থীর প্রয়োজন বুঝে সাপ্তাহিক ক্লাসের সংখ্যা ও শিডিউল সেট করি। সাধারণত ক্লাসের নির্ধারিত সময় এক ঘন্টা। কিন্তু কোনো শিক্ষার্থীর যদি এর বেশি সময় প্রয়োজন হয় তাহলে তার চাহিদা অনুযায়ী ক্লাসের সময় নির্ধারণ করা হয়। ",
    course_faq_id: 5,
    course_faq_question: "ক্লাসের শিডিউল ও ডিউরেশন কেমন হয়? ",
  },
  {
    course_faq_answer:
      "অবশ্যই পারবে! বিশেষ চাহিদা সম্পন্ন শিশুর পাশাপাশি স্পিচ ডিলে, প্রবাসী শিশু, কোনো নির্দিষ্ট বিষয়ে দুর্বল এমন যেকোনো শিশু এই প্রোগামে জয়েন করতে পারবে।",
    course_faq_id: 6,
    course_faq_question: "বিশেষ চাহিদা সম্পন্ন শিশুরা কি ক্লাস করতে পারবে? ",
  },
];

const OneToOne = () => {
  const [guardianName, setGuardianName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [language, setLanguage] = useState("bn");
  const [phone, setPhone] = useState("");
  const [className, setClassName] = useState("");
  const [subject, setSubject] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [focus, setFocus] = useState(false);
  const [open, setOpen] = useState(false);
  const [isTimeScheduleOpen, setIsTimeScheduleOpen] = useState(false);
  const timeSchedule = useRef(null);
  const {t} = useTranslation();

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  const validateForm = () => {
    if (guardianName && phone && className && subject) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [studentName, guardianName, phone, className, subject]);

  const handleTimeSchedule = (e) => {
    e.stopPropagation();
    setIsTimeScheduleOpen(true);
  };

  useEffect(() => {
    const useLanguage = sessionStorage.getItem('useLanguage');

    if(!useLanguage){
      sessionStorage.setItem('useLanguage', 'bn');
      i18next.changeLanguage('bn');
      setLanguage('bn');
    }else{
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem('useLanguage')

    if(getLanguage === 'bn'){
      sessionStorage.setItem('useLanguage', 'en');
      i18next.changeLanguage('en');
      setLanguage('en');
    }else{
      sessionStorage.setItem('useLanguage', 'bn');
      i18next.changeLanguage('bn');
      setLanguage('bn');
    }
  }

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            class_name: className,
            student_name: studentName,
            guardian_name: guardianName,
            mobile: phone,
            interested_courses: subject,
            type: "oneToOne",
        };

        fetch(`${process.env.REACT_APP_BASE_URL}api/v1/registration-lead-store`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                secretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setOpen(true);
                setSubject("");
                setClassName("");
                setGuardianName("");
                setPhone("");
            });
    };

  return (
    <div>
      <ScrollToTop />

      {/* Page  */}
      <div className="-tw-mt-[88px]">
        {/* Container  */}
        <div className="tw-max-w-[1440px] tw-mx-auto">
          {/* Hero Section  */}
          <div className="tw-w-full tw-h-full hero_bg tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
            {/* Content */}
            <div className="tw-mt-14 lg:tw-mt-20 tw-my-10 xl:tw-my-20 tw-px-6 md:tw-px-0 md:tw-ml-10 xl:tw-ml-14">
              {/* Back button  */}
              <div
                className="tw-flex tw-items-center tw-cursor-pointer tw-mb-8 tw-mt-14"
                onClick={() => navigate(-1)}
              >

                <img src={backButton} alt="icon" />
                <p className="tw-ml-3 tw-text-white">{t("back")}</p>
              </div>

              {/* Logo */}
              <div className="tw-flex tw-flex-col md:tw-items-start">
                <Link to="/">
                  <img className="" src={logo} alt="logo" />
                </Link>
              </div>

              {/* Heading  */}
              <div className="tw-mt-6">
                <h2 className="tw-text-3xl lg:tw-text-6xl tw-text-white tw-font-bold">
                    {t("One-to-One")}
                  <p className="tw-text-[#FFCE39] tw-mt-2 md:tw-mt-4">
                      {t("Live Customised Learning")}
                  </p>
                </h2>
                <p className="tw-text-md tw-text-white lg:tw-text-xl tw-mt-5 md:tw-mt-7">
                    {t("Personalized Teacher | Personalized Reading Material | Personalized Learning")}
                </p>
              </div>

              {/* Lists  */}
              <div className="tw-mt-6 tw-text-white tw-text-sm  lg:tw-text-lg">
                <div className="tw-flex">
                  <img className="tw-mr-4" src={checkIcon} alt="check icon" />
                  <p>{t("Share your needs")}</p>
                </div>
                <div className="tw-flex">
                  <img className="tw-mr-4" src={checkIcon} alt="check icon" />
                  <p>{t("Take an assessment to evaluate yourself")} </p>
                </div>
                <div className="tw-flex">
                  <img className="tw-mr-4" src={checkIcon} alt="check icon" />
                  <p>{t("Set your goals")}</p>
                </div>
                <div className="tw-flex">
                  <img className="tw-mr-4" src={checkIcon} alt="check icon" />
                  <p>{t("Join the live customised classes")}</p>
                </div>
              </div>

              {/* Button */}
              <div className="tw-mt-10">
                <p className="tw-text-white tw-text-xl tw-font-semibold tw-mb-2">
                    {t("Start your premium learning journey with fun")}
                </p>
              </div>
              <button
                onClick={handleButtonClick}
                className="tw-bg-[#6F39B7] tw-px-6 tw-py-3 tw-rounded-3xl tw-font-semibold tw-text-white"
              >
                  {t("Register now")}
              </button>
            </div>

            {/* Right Image  */}
            <div className="tw-relative tw-h-auto tw-mt-56 md:tw-mt-0 ">
              <img
                className="tw-w-auto tw-h-auto tw-absolute tw-bottom-0 tw-right-0"
                src={childImage}
                alt="student image"
              />
            </div>
          </div>
          <div className="tw-px-4 md:tw-px-10">
            {/* Form Sections  */}
            <div className="tw-flex tw-flex-col tw-gap-6 lg:tw-gap-20 lg:tw-flex-row tw-justify-between tw-mt-16 ">
              {/* Left side  */}
              <div className="">
                {/* Heading  */}
                <div>
                  <h2 className="tw-font-bold tw-text-3xl xl:tw-text-4xl">
                    {t("Why Mojaru's Customized Education?")}
                </h2>
              </div>
              {/* Listing  */}
              <div className="tw-mt-8 tw-w-full  tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-1 tw-gap-6">
                {/* সীমিত সংখ্যাক শিক্ষার্থী */}
                <div className="tw-flex">
                  <div>
                    <img
                      className="tw-w-14 tw-h-10 xl:tw-h-auto"
                      src={image1}
                      alt="icon"
                    />
                  </div>
                  <div className="tw-ml-6">
                    <h2 className="tw-text-lg tw-font-semibold">
                        {t("Personalised Teacher")}
                    </h2>
                    <p className="tw-font-[400] tw-mt-2">
                        {t("Trained subject experts assigned based on your specific requirements")}
                    </p>
                  </div>
                </div>

                  {/* সাবজেক্ট এক্সপার্ট শিক্ষক */}
                  <div className="tw-flex ">
                    <div>
                      <img
                        className="tw-w-20 tw-h-10 xl:tw-w-14 xl:tw-h-auto"
                        src={image2}
                        alt="icon"
                      />
                    </div>
                    <div className="tw-ml-6">
                      <h2 className="tw-text-lg tw-font-semibold">
                        {t("Exams and Evaluation")}
                      </h2>
                      <p className="tw-font-[400] tw-mt-2">
                        {t(
                          "After assessments, weaknesses are identified and addressed accordingly"
                        )}
                      </p>
                    </div>
                  </div>

                  {/* লাইভ ইন্টার‍্যাক্টিভ ক্লাসরুম */}
                  <div className="tw-flex ">
                    <div>
                      <img
                        className="tw-w-14 tw-h-auto xl:tw-w-14 xl:tw-h-auto"
                        src={image3}
                        alt="icon"
                      />
                    </div>
                    <div className="tw-ml-6">
                      <h2 className="tw-text-lg tw-font-semibold">
                        {t("Personalised Learning")}
                      </h2>
                      <p className="tw-font-[400] tw-mt-2">
                        {t(
                          "Lesson plans and classes tailored to your age, needs, and schedule"
                        )}
                      </p>
                    </div>
                  </div>

                  {/* 100% কিডস ফ্রেন্ডলি */}
                  <div className="tw-flex ">
                    <div>
                      <img
                        className="tw-w-14 tw-h-auto"
                        src={image4}
                        alt="icon"
                      />
                    </div>
                    <div className="tw-ml-6">
                      <h2 className="tw-text-lg tw-font-semibold">
                        {t("100% Kid-Friendly")}
                      </h2>
                      <p className="tw-font-[400] tw-mt-2">
                        {t(
                          "Learn at your own pace in a fun, pressure-free environment"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right side  */}
              <div className=" tw-w-full lg:tw-w-1/2">
                <div
                  ref={formRef}
                  className={`tw-mt-8 md:tw-mt-0 ${
                    focus ? "tw-border" : "tw-border"
                  } tw-border tw-p-8 tw-rounded-3xl`}
                >
                  <h2 className="tw-text-lg tw-font-semibold">
                    {t("Register now")}
                  </h2>
                  <div className="tw-mt-6">
                    <div>
                      <label
                        htmlFor="name"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        {t("Your Name")}
                        <span className="tw-text-red-700">*</span>
                      </label>
                      <div className="tw-relative tw-mt-1">
                        <HiUser className="tw-absolute tw-top-3 tw-left-3 tw-text-2xl tw-text-gray-500" />
                        <input
                          id="name"
                          name="name"
                          type="text"
                          autoComplete="name"
                          value={studentName}
                          onChange={(e) => setStudentName(e.target.value)}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          required
                          className="tw-pl-12 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="name"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        {t("Your Guardian’s Name")}
                        <span className="tw-text-red-700">*</span>
                      </label>
                      <div className="tw-relative tw-mt-1">
                        <HiUser className="tw-absolute tw-top-3 tw-left-3 tw-text-2xl tw-text-gray-500" />
                        <input
                          id="name"
                          name="name"
                          type="text"
                          autoComplete="name"
                          value={guardianName}
                          onChange={(e) => setGuardianName(e.target.value)}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          required
                          className="tw-pl-12 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                        />
                      </div>
                    </div>
                    <div className="tw-mt-4">
                      <label>
                        {t("Phone number")}{" "}
                        <span className="tw-text-red-700">*</span>
                      </label>
                      <div className="tw-mt-1">
                        <PhoneInput
                          country={"bd"}
                          value={phone}
                          onChange={(mobile) => setPhone(mobile)}
                          enableSearch={true}
                        />
                        {/*{phoneNumberError &&*/}
                        {/*    <p className="tw-mt-2 tw-text-sm tw-text-red-700">{phoneNumberError}</p>}*/}
                      </div>
                    </div>
                    <div className="tw-mt-4">
                      <label
                        htmlFor="class"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        {t("Your class")}
                      </label>
                      <div className="tw-relative">
                        <select
                          id="class"
                          name="class"
                          className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6 tw-appearance-none tw-bg-white"
                          value={className}
                          onChange={(e) => setClassName(e.target.value)}
                          defaultValue="ক্লাস নির্বাচন করো"
                        >
                          <option value="" label="ক্লাস নির্বাচন করো"></option>
                          <option
                            value="Not Admitted"
                            label="Not Admitted - ভর্তি করা হয়নি"
                          ></option>
                          <option value="Play" label="Play - প্লে"></option>
                          <option
                            value="Nursery"
                            label="Nursery - নার্সারী"
                          ></option>
                          <option value="KG" label="KG - কেজি"></option>
                          <option
                            value="Class 1"
                            label="Class 1 - শ্রেণী ১"
                          ></option>
                          <option
                            value="Class 2"
                            label="Class 2 - শ্রেণী ২"
                          ></option>
                          <option
                            value="Class 3"
                            label="Class 3 - শ্রেণী ৩"
                          ></option>
                          <option
                            value="Class 4"
                            label="Class 4 - শ্রেণী ৪"
                          ></option>
                          <option
                            value="Class 5"
                            label="Class 5 - শ্রেণী ৫"
                          ></option>
                          <option
                            value="Class 6"
                            label="Class 6 - শ্রেণী ৬"
                          ></option>
                          <option
                            value="Class 7"
                            label="Class 7 - শ্রেণী ৭"
                          ></option>
                          <option
                            value="Class 8"
                            label="Class 8 - শ্রেণী ৮"
                          ></option>
                          <option
                            value="Class 9"
                            label="Class 9 - শ্রেণী ৯"
                          ></option>
                          <option
                            value="Class 10"
                            label="Class 10 - শ্রেণী ১০"
                          ></option>
                          <option
                            value="Class 11"
                            label="Class 11 - শ্রেণী ১১"
                          ></option>
                          <option
                            value="Class 12"
                            label="Class 12 - শ্রেণী ১২"
                          ></option>
                          <option
                            value="Other"
                            label="Other - অন্যান্য"
                          ></option>
                        </select>
                        <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                          <svg
                            className="tw-h-5 tw-w-5 tw-text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="tw-mt-4">
                      <label
                        htmlFor="medium"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        {t("Which course are you interested in")}
                      </label>
                      <div className="tw-relative">
                        <select
                          id="medium"
                          name="medium"
                          className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6 tw-appearance-none tw-bg-white"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          defaultValue={t("Select your preferred course")}
                        >
                          <option
                            value=""
                            label={t("Select your preferred course")}
                          ></option>
                          <option
                            value="অ্যাবাকাস মাইন্ড ম্যাথ"
                            label="অ্যাবাকাস মাইন্ড ম্যাথ"
                          ></option>
                          <option value="আদর্শলিপি" label="আদর্শলিপি"></option>
                          <option value="মজার গণিত" label="মজার গণিত"></option>
                          <option
                            value="গণিত অলিম্পিয়াড"
                            label="গণিত অলিম্পিয়াড"
                          ></option>
                          <option
                            value="একাডেমিক প্রস্তুতি- ৭ম শ্রেণি"
                            label="একাডেমিক প্রস্তুতি- ৭ম শ্রেণি"
                          ></option>
                          <option
                            value="ক্যাডেট প্রি-প্রিপারেশন"
                            label="ক্যাডেট প্রি-প্রিপারেশন"
                          ></option>
                          <option
                            value="মিশন ক্যাডেট - ২৪"
                            label="মিশন ক্যাডেট - ২৪"
                          ></option>
                          <option
                            value="স্মার্ট ইংলিশ"
                            label="স্মার্ট ইংলিশ"
                          ></option>
                        </select>

                        <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                          <svg
                            className="tw-h-5 tw-w-5 tw-text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="tw-mt-8">
                      <button
                        onClick={handleTimeSchedule}
                        className={`tw-flex tw-w-full tw-justify-center tw-items-center tw-rounded-3xl tw-px-3 tw-py-3 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white  ${
                          isFormValid ? "tw-bg-[#532D80]" : " tw-bg-gray-400"
                        }`}
                        disabled={!isFormValid}
                      >
                        <span className="tw-mr-2">
                          {t("Choose Your Preferred Time")}
                        </span>
                        <HiArrowRight />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isTimeScheduleOpen && (
              // <div className="tw-mt-16 md:tw-w-3/4 tw-mx-auto">
              <div className="tw-mt-16 tw-mx-auto">
                <TimeSchedule
                  studentName={studentName}
                  guardianName={guardianName}
                  phone={phone}
                  className={className}
                  subject={subject}
                />
              </div>
            )}

            <div className="tw-w-full tw-mt-12 ">
              <PopularCourse handleFocus={handleFocus} id={8} />
            </div>

            {/* শিক্ষার্থীর প্রাপ্তি */}
            <div className="tw-mt-20">
              <div className="tw-text-center">
                <h2 className="tw-text-2xl md:tw-text-4xl tw-font-bold tw-mt-3">
                  {t("What Benefits Are You Getting From This Program?")}
                </h2>
              </div>

              {/* <div className="tw-mt-14 tw-px-4 md:tw-px-10 "> */}
              <div className="tw-mt-6">
                <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4">
                  <div className="tw-bg-blue-50 md:tw-col-span-3 tw-rounded-3xl  tw-px-6 tw-py-10 tw-relative tw-flex tw-justify-between ">
                    <div>
                      <h2 className="tw-text-2xl tw-font-bold">
                        {t("Receive lesson plans customised to your needs")}
                      </h2>
                    </div>
                    <div className="tw-mt-10">
                      <img className="" src={cardImage6} alt="icon" />
                    </div>
                  </div>

                  {/* নিজের গতিতে শিখতে পারবে */}
                  <div className="tw-bg-orange-50 md:tw-col-span-3 lg:tw-col-span-6 tw-rounded-3xl tw-flex tw-justify-between tw-px-6 tw-py-10 tw-flex-row">
                    <div>
                      <h2 className="tw-text-2xl tw-font-bold tw-pl-4">
                        {t("Learn at your own pace")}
                      </h2>
                      <ul className="tw-ml-12 tw-mt-3 tw-list-disc tw-leading-7">
                        <li>
                          {t(
                            "You will determine your own learning time and method"
                          )}
                        </li>
                        <li>
                          {t(
                            "You can progress at your own pace through both easy and complex topics"
                          )}
                        </li>
                        <li>
                          {t(
                            "You can practice and review at a time that suits you"
                          )}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <img src={cardImage5} alt="icon" />
                    </div>
                  </div>

                  {/* শিক্ষক থেকে সময় */}

                  <div className="tw-bg-cyan-50 md:tw-col-span-3 tw-rounded-3xl tw-px-6 tw-py-10 tw-relative tw-flex tw-justify-between ">
                    <div>
                      <h2 className="tw-text-2xl tw-font-bold">
                        {t("Take time to learn directly from your teacher")}
                      </h2>
                    </div>
                    <div className="tw-mt-10">
                      <img src={cardImage4} alt="icon" />
                    </div>
                  </div>

                  {/* নির্দিষ্ট বিষয়ের */}
                  <div className="tw-bg-red-50 md:tw-col-span-4 tw-rounded-3xl tw-px-6 tw-py-10 tw-relative tw-flex tw-justify-between ">
                    <div>
                      <h2 className="tw-text-2xl tw-font-bold">
                        {t("Overcome weaknesses in specific subjects")}
                      </h2>
                    </div>
                    <div className="tw-mt-10">
                      <img src={cardImage3} alt="icon" />
                    </div>
                  </div>

                  {/* যেকোনো স্থান থেকে */}
                  <div className="tw-bg-indigo-50 md:tw-col-span-4 tw-rounded-3xl tw-px-6 tw-py-10 tw-relative tw-flex tw-justify-between ">
                    <div>
                      <h2 className="tw-text-2xl tw-font-bold">
                        {t(
                          "Enjoy premium one-on-one learning from anywhere in the world"
                        )}
                      </h2>
                    </div>
                    <div className="tw-mt-10">
                      <img src={cardImage2} alt="icon" />
                    </div>
                  </div>

                  {/* নিজের চাহিদা অনুযায়ী */}
                  <div className="tw-bg-pink-50 md:tw-col-span-4 tw-rounded-3xl tw-px-6 tw-py-6 tw-relative tw-flex tw-justify-between ">
                    <div>
                      <h2 className="tw-text-2xl tw-font-bold">
                        {t("Set your class time based on your convenience")}
                      </h2>
                    </div>
                    <div className="tw-mt-10">
                      <img src={cardImage1} alt="icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* FAQ */}
            <div className="lg:tw-px-0">
              <div className="tw-mt-16 tw-bg-gray-50 tw-rounded-3xl">
                <div className="md:tw-w-3/5 tw-mx-auto tw-py-16">
                  <h2 className="tw-text-xl tw-font-semibold tw-ml-12 tw-mb-8">
                    {t("General")}{" "}
                    <span className="tw-text-[#6F39B7]">{t("Questions")}</span>
                  </h2>
                  {faqs.map((faq) => (
                    <Faq
                      course_faq={faq}
                      handleButtonClick={handleButtonClick}
                    />
                  ))}
                </div>
              </div>
            </div>

            {/* Footer  */}
            <div className="tw-mt-16">
              <FormFooter />
            </div>
          </div>
        </div>
      </div>

      {<GreetingMsg open={open} setOpen={setOpen} />}
    </div>
  );
};

export default OneToOne;
