import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import LmsImg from "../../../../images/business/lms.png";
import ProductOne from "../../../../images/business/product1.png";
import Dashboard from "../../../../images/business/dashboard.png";
import CRM from "../../../../images/business/CRM.png";
import { CheckIcon } from "@heroicons/react/20/solid";
import { IoCartOutline } from "react-icons/io5";
import Pricing from "./Pricing";

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
];

const StarIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2.5l4.9 9.9h10.4l-8.4 7.3 3.2 10.5-8.1-5.7-8.1 5.7 3.2-10.5-8.4-7.3h10.4z"
      fill="yellow"
    />
  </svg>
);

const tiers = [
  {
    name: "Team",
    id: "tier-team",
    href: "#",
    priceMonthly: "$99",
    description:
      "Explicabo quo fugit vel facere ullam corrupti non dolores. Expedita eius sit sequi.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Business() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="">
      <div className="tw-bg-[#391c5f] ">
        {/* Heading ******** */}
        <div className="tw-relative tw-isolate">
          <svg
            aria-hidden="true"
            className="tw-absolute tw-inset-x-0 tw-top-0 -tw-z-10 tw-h-[64rem] tw-w-full tw-stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
          >
            <defs>
              <pattern
                x="50%"
                y={-1}
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="tw-overflow-visible tw-fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
              width="100%"
              height="100%"
              strokeWidth={0}
            />
          </svg>
          <div
            aria-hidden="true"
            className="tw-absolute tw-left-1/2 tw-right-0 tw-top-0 -tw-z-10 -tw-ml-24 tw-transform-gpu tw-overflow-hidden tw-blur-3xl lg:tw-ml-24 xl:tw-ml-48"
          >
            <div
              style={{
                clipPath:
                  "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
              className="tw-aspect-[801/1036] tw-w-[50.0625rem] tw-bg-gradient-to-tr tw-from-[#ff80b5] tw-to-[#9089fc] tw-opacity-30"
            />
          </div>
          <div className="tw-overflow-hidden">
            <div className="tw-mx-auto tw-max-w-7xl tw-px-6 tw-pb-32 tw-mt-10 lg:tw-px-8 ">
              <div className="tw-mx-auto tw-max-w-2xl tw-gap-x-14 lg:tw-mx-0 lg:tw-flex lg:tw-max-w-none lg:tw-items-center">
                <div className="tw-relative tw-w-full lg:tw-max-w-xl lg:tw-shrink-0 xl:tw-max-w-2xl">
                  <h1 className="tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-gray-200 sm:tw-text-6xl">
                    Transforming your Businesses with our Solutions
                  </h1>
                  <p className="tw-mt-8 tw-text-pretty tw-text-lg tw-font-medium tw-text-gray-300 sm:tw-max-w-md sm:tw-text-xl/8 lg:tw-max-w-none">
                    Premium LMS, ERP, and CRM Systems – Ready to Take Your
                    Organization to the Next Level
                  </p>
                  <div className="tw-mt-10 tw-flex tw-items-center tw-gap-x-6">
                    <div></div>
                  </div>
                </div>
                <div className="tw-mt-14 tw-flex tw-justify-end tw-gap-8 sm:-tw-mt-44 sm:tw-justify-start sm:tw-pl-20 lg:tw-mt-0 lg:tw-pl-0">
                  <div className="tw-ml-auto tw-w-44 tw-flex-none tw-space-y-8 tw-pt-32 sm:tw-ml-0 sm:tw-pt-80 lg:tw-order-last lg:tw-pt-36 xl:tw-order-none xl:tw-pt-80">
                    <div className="tw-relative">
                      <img
                        alt=""
                        src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        className="tw-aspect-[2/3] tw-w-full tw-rounded-xl tw-bg-gray-900/5 tw-object-cover tw-shadow-lg"
                      />
                      <div className="tw-pointer-events-none tw-absolute tw-inset-0 tw-rounded-xl tw-ring-1 tw-ring-inset tw-ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="tw-mr-auto tw-w-44 tw-flex-none tw-space-y-8 sm:tw-mr-0 sm:tw-pt-52 lg:tw-pt-36">
                    <div className="tw-relative">
                      <img
                        alt=""
                        src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        className="tw-aspect-[2/3] tw-w-full tw-rounded-xl tw-bg-gray-900/5 tw-object-cover tw-shadow-lg"
                      />
                      <div className="tw-pointer-events-none tw-absolute tw-inset-0 tw-rounded-xl tw-ring-1 tw-ring-inset tw-ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Heading ******** */}

        {/* Hero Secion  */}
        <div className="tw-relative tw-isolate">
          <div
            aria-hidden="true"
            className="tw-absolute tw-inset-x-0 tw--top-40 tw--z-10 tw-transform-gpu tw-overflow-hidden tw-blur-3xl sm:tw--top-80"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="tw-relative tw-left-[calc(50%-11rem)] tw-aspect-[1155/678] tw-w-[36.125rem] tw--translate-x-1/2 tw-rotate-[30deg] tw-bg-gradient-to-tr tw-from-[#ff80b5] tw-to-[#9089fc] tw-opacity-20 sm:tw-left-[calc(50%-30rem)] sm:tw-w-[72.1875rem]"
            />
          </div>
          <div className="tw-py-20 sm:tw-py-20 lg:tw-pb-40">
            <div className="tw-mx-auto tw-max-w-[1440px] tw-px-6 lg:tw-px-8">
              <div className="tw-mx-auto tw-max-w-2xl tw-text-center">
                <h1 className="tw-text-3xl tw-font-semibold tw-tracking-tight tw-text-white sm:tw-text-6xl">
                  Change Your Business path with Mojaru
                </h1>
              </div>
              <img
                alt="App screenshot"
                src={LmsImg}
                width={2432}
                height={1442}
                className="tw-mt-16 tw-rounded-xl tw-bg-white/5 tw-shadow-2xl tw-ring-1 tw-ring-white/10 sm:tw-mt-24"
              />
            </div>
          </div>
          <div
            aria-hidden="true"
            className="tw-absolute tw-inset-x-0 tw-top-[calc(100%-13rem)] tw--z-10 tw-transform-gpu tw-overflow-hidden tw-blur-3xl sm:tw-top-[calc(100%-30rem)]"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="tw-relative tw-left-[calc(50%+3rem)] tw-aspect-[1155/678] tw-w-[36.125rem] tw--translate-x-1/2 tw-bg-gradient-to-tr tw-from-[#ff80b5] tw-to-[#9089fc] tw-opacity-20 sm:tw-left-[calc(50%+36rem)] sm:tw-w-[72.1875rem]"
            />
          </div>
        </div>

        {/* Product Heading  */}
        {/* Content section */}
        <div className="tw-mx-auto tw-mt-12 tw-max-w-[1440px] tw-px-6 sm:tw-mt-0 lg:tw-px-8 xl:tw-mt-8">
          <div className="tw-mx-auto tw-max-w-2xl lg:tw-mx-0 lg:tw-max-w-none">
            <h2 className="tw-text-pretty tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-white sm:tw-text-5xl">
              Our mission
            </h2>
            <div className="tw-mt-6 tw-flex tw-flex-col tw-gap-x-8 tw-gap-y-20 lg:tw-flex-row">
              <div className="lg:tw-w-full lg:tw-max-w-2xl lg:tw-flex-auto">
                <p className="tw-text-xl/8 tw-text-gray-300">
                  At Mojaru, our mission is to empower businesses and educators
                  with innovative digital solutions that simplify processes,
                  enhance learning, and build meaningful relationships.
                </p>
              </div>
              <div className="lg:tw-flex lg:tw-flex-auto lg:tw-justify-center">
                <dl className="tw-w-64 tw-space-y-4 xl:tw-w-80">
                  {/* two  */}
                  <div className="tw-flex tw-flex-col-reverse tw-gap-y-4">
                    <dt className="tw-text-base/7 tw-text-gray-400">
                      Elevating user experiences through smart tools
                    </dt>
                    <dd className="tw-text-5xl tw-font-semibold tw-tracking-tight tw-text-white">
                      Engagement
                    </dd>
                  </div>

                  {/* Three  */}
                  <div className="tw-flex tw-flex-col-reverse tw-gap-y-4">
                    <dt className="tw-text-base/7 tw-text-gray-400">
                      Driving success with scalable, future-ready platforms
                    </dt>
                    <dd className="tw-text-5xl tw-font-semibold tw-tracking-tight tw-text-white">
                      Growth
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>

        {/* Popular Product */}
        <div className="tw-mx-auto tw-mt-12 tw-max-w-[1440px] tw-px-6 sm:tw-mt-0 lg:tw-px-8 xl:tw-mt-8">
          <div className="tw-mx-auto tw-max-w-2xl lg:tw-mx-0 lg:tw-max-w-none tw-mt-10">
            <h2 className="tw-text-pretty tw-text-4xl tw-font-semibold tw-tracking-tight tw-py-0 tw-text-white sm:tw-text-5xl">
              Our Popular Product
            </h2>
          </div>

          {/* Product 1  */}
          <div className="tw-relative tw-isolate tw-px-6 tw-py-10 lg:tw-px-8">
            <div className="tw-mx-auto">
              <p className="tw-mt-2 tw-text-balance tw-text-5xl tw-font-semibold tw-tracking-tight tw-text-gray-300 sm:tw-text-3xl">
                Learning Management System (LMS)
              </p>
            </div>
            {/* <p className="tw-mt-6 tw-max-w-2xl tw-text-pretty tw-text-lg tw-font-medium tw-text-gray-400 sm:tw-text-xl/8">
              Choose an affordable plan that’s packed with the best features for
              engaging your audience, creating customer loyalty, and driving
              sales.
            </p> */}
            <p className=" tw-flex tw-items-center">
              <span className=" tw-text-white tw-mr-2">Review:</span>
              {Array.from({ length: 5 }, (_, index) => (
                <StarIcon key={index} />
              ))}{" "}
              <span className=" tw-text-white tw-ml-1">(4.9)</span>
              <p className=" tw-text-white tw-ml-8 tw-flex tw-gap-1 tw-items-center">
                <IoCartOutline size={20} />
                Sells: 403
              </p>
            </p>

            {/* Product 1 start*/}
            <div className=" tw-my-8">
              <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-items-center">
                {/* Image */}
                <div className="tw-col-span-2">
                  <img
                    src={ProductOne}
                    alt="Product"
                    className="tw-rounded-md tw-w-full"
                  />
                </div>
                {/* Pricing */}
                <div className="tw-col-span-1">
                  <div className="tw-relative tw-rounded-3xl tw-bg-[#5d2f9b] tw-p-8 tw-shadow-2xl tw-ring-1 tw-ring-gray-900/10 sm:tw-p-10">
                    <h3
                      id="tier-enterprise"
                      className="tw-text-base/7 tw-font-semibold tw-text-indigo-400"
                    >
                      Features:
                    </h3>
                    <p className="tw-mt-4 tw-flex tw-items-baseline tw-gap-x-2">
                      <span className="tw-text-3xl tw-font-semibold tw-tracking-tight tw-text-white">
                        Empower Education with Our Advanced LMS
                      </span>
                    </p>
                    <p className="tw-mt-6 tw-text-base/7 tw-text-gray-300">
                      Seamless Learning, Anywhere, Anytime
                    </p>
                    <ul
                      role="list"
                      className="tw-mt-8 tw-space-y-3 tw-text-sm/6 tw-text-gray-300 sm:tw-mt-10"
                    >
                      {[
                        "Increase learner engagement with interactive tools",
                        "Save time with automated administration",
                        "Enhance outcomes through data-driven insights",
                      ].map((feature) => (
                        <li key={feature} className="tw-flex tw-gap-x-3">
                          <CheckIcon
                            aria-hidden="true"
                            className="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-400"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <a
                      href="#"
                      aria-describedby="tier-enterprise"
                      className="tw-mt-8 tw-block tw-rounded-md tw-bg-white tw-px-3.5 tw-py-2.5 tw-text-center tw-text-sm tw-font-semibold tw-text-[#391c5f] tw-shadow-sm hover:tw-bg-indigo-400 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-500 sm:tw-mt-10"
                    >
                      Get started today
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Product 1 end*/}
          </div>

          {/* Product 2 Start*/}
          <div className="tw-relative tw-isolate  tw-px-6 tw-py-10 lg:tw-px-8">
            <div className="tw-mx-auto">
              <p className="tw-mt-2 tw-text-balance tw-text-5xl tw-font-semibold tw-tracking-tight tw-text-gray-300 sm:tw-text-3xl">
                Enterprise Resource Planning (ERP)
              </p>
            </div>
            {/* <p className="tw-mt-6 tw-max-w-2xl tw-text-pretty tw-text-lg tw-font-medium tw-text-gray-400 sm:tw-text-xl/8">
              Choose an affordable plan that’s packed with the best features for
              engaging your audience, creating customer loyalty, and driving
              sales.
            </p> */}
            <p className=" tw-flex tw-items-center">
              <span className=" tw-text-white tw-mr-2">Review:</span>
              {Array.from({ length: 5 }, (_, index) => (
                <StarIcon key={index} />
              ))}{" "}
              <span className=" tw-text-white tw-ml-1">(4.9)</span>
              <p className=" tw-text-white tw-ml-8 tw-flex tw-gap-1 tw-items-center">
                <IoCartOutline size={20} />
                Sells: 403
              </p>
            </p>

            {/* Product 2 Pricing and img*/}
            <div className=" tw-my-8">
              <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-items-center">
                {/* Pricing */}
                <div className="tw-col-span-1 tw-order-2 md:tw-order-1">
                  <div className="tw-relative tw-rounded-3xl tw-bg-[#5d2f9b] tw-p-8 tw-shadow-2xl tw-ring-1 tw-ring-gray-900/10 sm:tw-p-10">
                    <h3
                      id="tier-enterprise"
                      className="tw-text-base/7 tw-font-semibold tw-text-indigo-400"
                    >
                      Features:
                    </h3>
                    <p className="tw-mt-4 tw-flex tw-items-baseline tw-gap-x-2">
                      <span className="tw-text-3xl tw-font-semibold tw-tracking-tight tw-text-white">
                        Streamline Operations with Our Robust ERP
                      </span>
                    </p>
                    <p className="tw-mt-6 tw-text-base/7 tw-text-gray-300">
                      Simplify Complexity – Drive Efficiency
                    </p>
                    <ul
                      role="list"
                      className="tw-mt-8 tw-space-y-3 tw-text-sm/6 tw-text-gray-300 sm:tw-mt-10"
                    >
                      {[
                        "Reduce costs with streamlined processes",
                        "Gain actionable insights from unified data",
                        "Adapt quickly to market changes",
                      ].map((feature) => (
                        <li key={feature} className="tw-flex tw-gap-x-3">
                          <CheckIcon
                            aria-hidden="true"
                            className="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-400"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <a
                      href="#"
                      aria-describedby="tier-enterprise"
                      className="tw-mt-8 tw-block tw-rounded-md tw-bg-white tw-px-3.5 tw-py-2.5 tw-text-center tw-text-sm tw-font-semibold tw-text-[#391c5f] tw-shadow-sm hover:tw-bg-indigo-400 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-500 sm:tw-mt-10"
                    >
                      Get started today
                    </a>
                  </div>
                </div>

                {/* Image */}
                <div className="tw-col-span-2 tw-order-1 md:tw-order-2">
                  <img
                    src={Dashboard}
                    alt="Product"
                    className="tw-rounded-md tw-w-full "
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Product 2 end*/}

          {/* Product 3  */}
          <div className="tw-relative tw-isolate  tw-px-6 tw-py-10 lg:tw-px-8">
            <div className="tw-mx-auto">
              <p className="tw-mt-2 tw-text-balance tw-text-5xl tw-font-semibold tw-tracking-tight tw-text-gray-300 sm:tw-text-3xl">
                Customer Relationship Management (CRM)
              </p>
            </div>
            {/* <p className="tw-mt-6 tw-max-w-2xl tw-text-pretty tw-text-lg tw-font-medium tw-text-gray-400 sm:tw-text-xl/8">
              Choose an affordable plan that’s packed with the best features for
              engaging your audience, creating customer loyalty, and driving
              sales.
            </p> */}
            <p className=" tw-flex tw-items-center">
              <span className=" tw-text-white tw-mr-2">Review:</span>
              {Array.from({ length: 5 }, (_, index) => (
                <StarIcon key={index} />
              ))}{" "}
              <span className=" tw-text-white tw-ml-1">(4.9)</span>
              <p className=" tw-text-white tw-ml-8 tw-flex tw-gap-1 tw-items-center">
                <IoCartOutline size={20} />
                Sells: 403
              </p>
            </p>

            {/* Product 3 Content start*/}
            <div className=" tw-my-8">
              <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-items-center">
                {/* Image */}
                <div className="tw-col-span-2">
                  <img
                    src={CRM}
                    alt="Product"
                    className="tw-rounded-md tw-w-full"
                  />
                </div>
                {/* Pricing */}
                <div className="tw-col-span-1">
                  <div className="tw-relative tw-rounded-3xl tw-bg-[#5d2f9b] tw-p-8 tw-shadow-2xl tw-ring-1 tw-ring-gray-900/10 sm:tw-p-10">
                    <h3
                      id="tier-enterprise"
                      className="tw-text-base/7 tw-font-semibold tw-text-indigo-400"
                    >
                      Features:
                    </h3>
                    <p className="tw-mt-4 tw-flex tw-items-baseline tw-gap-x-2">
                      <span className="tw-text-3xl tw-font-semibold tw-tracking-tight tw-text-white">
                        Build Lasting Customer Relationships with Our CRM
                      </span>
                    </p>
                    <p className="tw-mt-6 tw-text-base/7 tw-text-gray-300">
                      Your Customers Deserve the Best – Make It Happen
                    </p>
                    <ul
                      role="list"
                      className="tw-mt-8 tw-space-y-3 tw-text-sm/6 tw-text-gray-300 sm:tw-mt-10"
                    >
                      {[
                        "Boost sales with better lead management",
                        "Increase customer retention through personalized experiences",
                        "Improve team productivity with automation",
                      ].map((feature) => (
                        <li key={feature} className="tw-flex tw-gap-x-3">
                          <CheckIcon
                            aria-hidden="true"
                            className="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-400"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <a
                      href="#"
                      aria-describedby="tier-enterprise"
                      className="tw-mt-8 tw-block tw-rounded-md tw-bg-white tw-px-3.5 tw-py-2.5 tw-text-center tw-text-sm tw-font-semibold tw-text-[#391c5f] tw-shadow-sm hover:tw-bg-indigo-400 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-500 sm:tw-mt-10"
                    >
                      Get started today
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Product 3 Content end*/}
          </div>
        </div>

        <Pricing />
        <div className="tw-py-28"></div>
      </div>
    </div>
  );
}

export default Business;
