import React from "react";
import AnalyticContent from "../../Dashboard/MainContent/AnalyticContent/AnalyticContent";
import StudentPageTitle from "../../shared/StudentPageTitle/StudentPageTitle";
import './TransactionTable.css';
import { useState } from "react";
import { useEffect } from "react";
import TransactionRow from "./TransactionRow/TransactionRow";


const TransactionTable = () => {
    const [transactionInfos, setTransactionInfos] = useState([]);

    useEffect(()=>{

        async function fetchTransactionInformation(){
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL_STUDENT}transactions?user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                }
            );
            response = await response.json();
            setTransactionInfos(response?.data?.transactions);
        }

        fetchTransactionInformation();
    },[])
    

    const handelFilterHistory = (e) =>{
       async function fetchTransactionInformation() {
           let response = await fetch(
               `${ process.env.REACT_APP_BASE_URL_STUDENT}transactions?user_id=${localStorage.getItem("user_id")}&date=${e.target.value}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                }
           );
           response = await response.json();
           setTransactionInfos(response?.data?.transactions);
       }

       fetchTransactionInformation();
    }
    

    return (
        <div className="main-content" style={{ backgroundColor: "#F4F1EB" }}>
            <StudentPageTitle title="Transaction Information"></StudentPageTitle>

            <div className="page-content p-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9 col-md-10 col-12 order-lg-1 order-md-1 order-2 live-class-row">
                            <div className="live-class-section">
                            <div className="d-flex justify-content-between">
                                <p className="top-title mb-0">সর্বশেষ ক্রয়কৃত কোর্স</p>
                                <div className="mb-3">
                                    <select className="form-select form-select-lg py-1" onChange={handelFilterHistory} style={{ fontSize: '20px', fontWeight: '600', backgroundColor: '#D7D7D7', color: '#7C7C7C'}}>
                                        <option value='7'>Last 7 days</option>
                                        <option value='15'>Last 15 days</option>
                                        <option value='30'>Last 30 days</option>
                                        <option value='90'>Last 90 days</option>
                                    </select>
                                </div>
                                
                            </div>
                                <div className="mid-section">
                                    <div className="bg-white p-3 mt-2 table-responsive" style={{ borderRadius: "15px", height:'90%', overflowY:'auto' }}>
                                        <table className="w-100">
                                            <thead>
                                                <tr className="text-center table-title" style={{ backgroundColor: '#F4F1EB', borderRadius:'8px ' }}>
                                                    <th className="py-2">ট্রান. আইডি</th>
                                                    <th className="py-2">পেমেন্ট আইডি</th>
                                                    <th className="py-2">কোর্স নাম</th>
                                                    <th className="py-2">তারিখ</th>
                                                    <th className="py-2">ধরন</th>
                                                    <th className="py-2">পরিমান</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            {transactionInfos.length === 0 ? <tr><td colSpan={6} className='text-center pt-5' style={{ fontSize:'26px', fontWeight:'600' }}>তোমার এখনো কোন লেনদেন করা হয় নি</td></tr> 
                                                :
                                            transactionInfos?.map(transactionInfo =><TransactionRow transactionInfo={transactionInfo} key={transactionInfo.id}></TransactionRow>)
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-2 col-12 order-lg-2 order-md-2 order-1 analysis-section">
                            <AnalyticContent></AnalyticContent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionTable;
