import React, { useState } from 'react';
import './ScheduleClassCard.css';
import clockIcon from '../../../../../../../images/due-clock-fill.png';
import liveIcon from '../../../../../../../images/live type.png';
import { formatAMPM } from '../../../../../../../utilities/utilities';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

const ScheduleClassCard = ({orientationClasse, user_name, mobile_number, age}) => {
      const [show, setShow] = useState(false);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);


    let classDuration = Math.abs(new Date(orientationClasse?.slot?.orientation_class_schedule_end) - new Date(orientationClasse?.slot?.orientation_class_schedule));
    let convertInHours = classDuration / 3600000;

    const today = new Date(orientationClasse?.slot?.orientation_class_date);
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const date = today.getDate(); 
    const month =  months[today.getMonth()]; 
    const day = today.toLocaleDateString('en-US', {weekday: 'short'});

    const handelStudentInfo = (event) =>{
        event.preventDefault();
        
       /*  const demoClassStudentInfo = {
            'user_name':event.target.student_name.value,
            'mobile_number':event.target.student_phone_number.value,
            'guardian_name':event.target.guardian_name.value,
            'age':event.target.age.value,
            'medium':event.target.medium.value,
            'email':event.target.email.value,
        } */

        fetch(`${process.env.REACT_APP_BASE_URL}api/free-class-login-registration?user_name=${event.target.student_name.value}
        &mobile_number=${event.target.student_phone_number.value}
        &guardian_name=${event.target.guardian_name.value}&age=${event.target.age.value}
        &medium=${event.target.medium.value}&email=${event.target.email.value}`,{
                    method:'POST',
                    headers:{
                        'content-type' : 'application/json',
                    },
                    
                })
            .then((res) => res.json())
            .then((data) => {
              if(data?.message_type === 'success'){
                const url = `${process.env.REACT_APP_BASE_URL}student/join-orientation-class?live_class_id=${orientationClasse.slot.host_id}&user_name=${user_name}`;
                window.location.href = url;
                
              }
            });
    }


    

    return (
        <div className='col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
            <div className='p-3 live-class-card custom-card-shadow' style={new Date(orientationClasse?.slot?.orientation_class_schedule).getTime() < new Date().getTime() && new Date(orientationClasse?.slot?.orientation_class_schedule_end).getTime() > new Date().getTime() ? { border : '1px solid #D0202F'} : { border : 'none'}} >
                <div className='mt-1 d-flex justify-content-between'>
                    <p className='course-title'>{orientationClasse?.course_name}</p>
                    {new Date(orientationClasse?.slot?.orientation_class_schedule).getTime() < new Date().getTime() && new Date(orientationClasse?.slot?.orientation_class_schedule_end).getTime() > new Date().getTime() && <img src={liveIcon} className="img-fluid" alt="" />}
                </div>

                <div className='mt-3 d-flex align-items-center mb-3'>
                    <div className='class-date-view'>
                        <p className='mb-0 text-center class-month'>{month}</p>
                        <p className='mb-0 text-center class-date'>{!isNaN(date) && date}</p>
                        <p className='mb-0 text-center class-day'>{day}</p>
                    </div>
                    <div className='ms-3'>
                        
                        <div className='d-flex align-items-center mb-2'>
                            <p className='class-subject-name mb-0 me-2'>{orientationClasse?.course_name}</p>
                            
                             
                        </div>
                        <div className='d-flex align-items-center mb-2'>
                            <img src={clockIcon} alt="" width={15} height={15}/>
                            <p className='font-400 mx-2 mb-0'>{formatAMPM(new Date(orientationClasse?.slot?.orientation_class_schedule))}</p>
                            <div className='class-time-pointer'></div>
                            <p className='font-400 ms-2 mb-0'>{convertInHours > 1 ? `${Math.round(convertInHours)} hours` : `${convertInHours*60} minutes`}</p>
                        </div>
                    </div>
                </div>

                <div className='w-100 mt-3'>
                    {
                        (new Date().getFullYear() === today.getFullYear() &&
                        new Date().getMonth() === today.getMonth() &&
                        new Date().getDate() === today.getDate()) ? 
                            new Date(orientationClasse?.slot?.orientation_class_schedule).getTime() < new Date().getTime() && new Date(orientationClasse?.slot?.orientation_class_schedule_end).getTime() > new Date().getTime() ?
                        
                        
                            (age ? <Link to={`${process.env.REACT_APP_BASE_URL}student/join-orientation-class?live_class_id=${orientationClasse.slot.host_id}&user_name=${user_name}`} className='btn-class-join w-100 text-center' style={{ border:'none' }}> ক্লাসে যোগ দিন </Link> 
                            :
                            <button className='btn-class-join w-100 text-center' style={{ border:'none' }} onClick={handleShow}> ক্লাসে যোগ দিন </button> 
                            )
                        
                        :
                        
                        <p className='btn-class-join w-100 text-center mb-0'>ক্লাস শুরু হয়নি</p>
                        :

                        ''

                    }
                </div>

            </div>
            <Modal show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false}>
                <Modal.Header closeButton>
                {/* <Modal.Title>Modal title</Modal.Title> */}
                <p className='mb-0' style={{ fontSize:'20px' }}>তোমার কিছু তথ্য</p>
                </Modal.Header>
                <Modal.Body style={{ paddingBottom:'5px' }}>
                    <form onSubmit={handelStudentInfo}>
                        <div className='row'>
                            <div className="col-lg-6 mb-4">
                                <p className='mb-2 font-400'>Name <span className='text-danger'>*</span></p>
                                <input className="form-control form-control-lg student-profile-info" name="student_name" type="text" placeholder="Enter Your Full Name" defaultValue={user_name && user_name}  required/>
                            </div>

                            <div className="col-lg-6 mb-4">
                                <p className='mb-2 font-400'>Mobile Number <span className='text-danger'>*</span></p>
                                <input className="form-control form-control-lg student-profile-info" name="student_phone_number" type="text"  pattern="[0-1]{2}[3-9]{1}[0-9]{8}" defaultValue={mobile_number && mobile_number} placeholder="Enter Your Phone Number" required/>
                            </div>
                            

                            <div className="col-lg-6 mb-4">
                                <p className='mb-2 font-400'>Email</p>
                                <input className="form-control form-control-lg student-profile-info" name="email" type="email" placeholder="Enter Your Email"/>
                            </div>

                            <div className="col-lg-6 mb-4">
                                <p className='mb-2 font-400'>Age <span className='text-danger'>*</span></p>
                                <input className="form-control form-control-lg student-profile-info" name="age" type="text" pattern="[0-9]{2}" placeholder="Enter Your Age"  required/>
                            </div>

                            <div className="col-lg-6 mb-4">
                                <p className='mb-2 font-400'>Guardian Name<span className='text-danger'>*</span></p>
                                <input className="form-control form-control-lg student-profile-info" name="guardian_name" type="text" placeholder="Enter Your Guardian Name"  required/>
                            </div>

                            <div className="col-lg-6 mb-4">
                                <p className='mb-2 font-400'>Medium</p>
                                <div className='align-items-center'>
                                    <div className="radio">
                                    <label style={{ fontWeight:'500', cursor:'pointer', display: 'inline-block', marginRight: '10px' }}>
                                        <input type="radio" name="medium" id="bangla" value="Bangla" />
                                        &nbsp;&nbsp;Bangla
                                    </label>
                                    <label style={{ fontWeight:'500', cursor:'pointer', display: 'inline-block', marginRight: '10px' }}>
                                        <input type="radio" name="medium" id="english" value="English" />
                                        &nbsp;&nbsp;English
                                    </label>
                                    <label style={{ fontWeight:'500', cursor:'pointer', display: 'inline-block' }}>
                                        <input type="radio" name="medium" id="madrasa" value="Madrasa" />
                                        &nbsp;&nbsp;Madrasa
                                    </label>
                                    </div>
                                </div> 
                            </div>
                            <input className="btn text-white mt-4 py-2" style={{ backgroundColor:'#532D80', fontSize:'22' }} type="submit" value='Submit and Join Class now'></input>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ScheduleClassCard;