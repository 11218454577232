import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import success from "../../../../../../images/eft/success.png";
import {Link} from "react-router-dom";
import React from "react";

export default function GreetingMsg({ open, setOpen }) {


    return (
        <Dialog open={open} onClose={() => setOpen(false)} className="tw-relative tw-z-10">
            <DialogBackdrop
                transition
                className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in"
            />

            <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
                <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                    <DialogPanel
                        transition
                        className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all data-[closed]:tw-translate-y-4 data-[closed]:tw-opacity-0 data-[enter]:tw-duration-300 data-[leave]:tw-duration-200 data-[enter]:tw-ease-out data-[leave]:tw-ease-in sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6 data-[closed]:sm:tw-translate-y-0 data-[closed]:sm:tw-scale-95"
                    >
                        <div>
                            <div
                                className="tw-mx-auto tw-flex tw-items-center tw-w-16 tw-justify-center tw-rounded-full">
                                <img className="" src={success} alt="success"/>
                            </div>
                            <div className="tw-mt-3 tw-text-center sm:tw-mt-5">
                            <DialogTitle as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                                রেজিস্ট্রেশন করার জন্য ধন্যবাদ!
                            </DialogTitle>
                                <div className="tw-mt-6">
                                    <p className="tw-mt-3 tw-text-center tw-text-gray-500">আমাদের একজন প্রতিনিধি খুব দ্রুতই তোমার সাথে এই বিষয়ে যোগাযোগ করবে </p>
                                    <p className="tw-mt-8 tw-text-gray-700">মজারু কোর্স সম্পর্কে জানতে</p>
                                    <div className="tw-w-full tw-mt-2">
                                        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                                            <div className="tw-flex tw-items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16"
                                                     viewBox="0 0 17 16"
                                                     fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M3.76637 7.20001H5.32291C5.39427 5.96289 5.62983 4.82326 5.99316 3.90583C4.83402 4.61708 4.00011 5.80679 3.76637 7.20001ZM8.50001 1.60001C4.96538 1.60001 2.10001 4.46538 2.10001 8.00001C2.10001 11.5346 4.96538 14.4 8.50001 14.4C12.0346 14.4 14.9 11.5346 14.9 8.00001C14.9 4.46538 12.0346 1.60001 8.50001 1.60001ZM8.50001 3.20001C8.43917 3.20001 8.3143 3.22539 8.12777 3.40948C7.93779 3.59697 7.73001 3.90793 7.53827 4.35534C7.22732 5.08089 6.99993 6.06847 6.9259 7.20001H10.0741C10.0001 6.06847 9.77269 5.08089 9.46175 4.35534C9.27 3.90793 9.06222 3.59697 8.87225 3.40948C8.68571 3.22539 8.56085 3.20001 8.50001 3.20001ZM11.6771 7.20001C11.6057 5.96289 11.3702 4.82326 11.0069 3.90583C12.166 4.61708 12.9999 5.80679 13.2336 7.20001H11.6771ZM10.0741 8.80001H6.9259C6.99993 9.93154 7.22732 10.9191 7.53827 11.6447C7.73001 12.0921 7.93779 12.403 8.12777 12.5905C8.3143 12.7746 8.43917 12.8 8.50001 12.8C8.56085 12.8 8.68571 12.7746 8.87225 12.5905C9.06222 12.403 9.27 12.0921 9.46175 11.6447C9.77269 10.9191 10.0001 9.93154 10.0741 8.80001ZM11.0069 12.0942C11.3702 11.1767 11.6057 10.0371 11.6771 8.80001H13.2336C12.9999 10.1932 12.166 11.3829 11.0069 12.0942ZM5.99316 12.0942C5.62983 11.1767 5.39427 10.0371 5.32291 8.80001H3.76637C4.00011 10.1932 4.83402 11.3829 5.99316 12.0942Z"
                                                          fill="#3B82F6"/>
                                                </svg>
                                                <a className="tw-block tw-ml-1 tw-text-blue-500 "
                                                   href="https://mojaru.com/">https://mojaru.com</a>
                                            </div>
                                        </div>
                                        <p className="tw-text-gray-500">অথবা</p>
                                        <div  className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                                            <div className="tw-flex tw-items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16"
                                                     viewBox="0 0 17 16"
                                                     fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M14.1 2.3C12.6 0.8 10.6 0 8.5 0C4.1 0 0.5 3.6 0.5 8C0.5 9.4 0.900006 10.8 1.60001 12L0.5 16L4.70001 14.9C5.90001 15.5 7.2 15.9 8.5 15.9C12.9 15.9 16.5 12.3 16.5 7.9C16.5 5.8 15.6 3.8 14.1 2.3ZM8.5 14.6C7.3 14.6 6.10001 14.3 5.10001 13.7L4.89999 13.6L2.39999 14.3L3.10001 11.9L2.89999 11.6C2.19999 10.5 1.89999 9.3 1.89999 8.1C1.89999 4.5 4.9 1.5 8.5 1.5C10.3 1.5 11.9 2.2 13.2 3.4C14.5 4.7 15.1 6.3 15.1 8.1C15.1 11.6 12.2 14.6 8.5 14.6ZM12.1 9.6C11.9 9.5 10.9 9 10.7 9C10.5 8.9 10.4 8.9 10.3 9.1C10.2 9.3 9.80001 9.7 9.70001 9.9C9.60001 10 9.49999 10 9.29999 10C9.09999 9.9 8.50001 9.7 7.70001 9C7.10001 8.5 6.70001 7.8 6.60001 7.6C6.50001 7.4 6.60001 7.3 6.70001 7.2C6.80001 7.1 6.9 7 7 6.9C7.1 6.8 7.10001 6.7 7.20001 6.6C7.30001 6.5 7.20001 6.4 7.20001 6.3C7.20001 6.2 6.80001 5.2 6.60001 4.8C6.50001 4.5 6.30001 4.5 6.20001 4.5C6.10001 4.5 5.99999 4.5 5.79999 4.5C5.69999 4.5 5.49999 4.5 5.29999 4.7C5.09999 4.9 4.60001 5.4 4.60001 6.4C4.60001 7.4 5.29999 8.3 5.39999 8.5C5.49999 8.6 6.79999 10.7 8.79999 11.5C10.5 12.2 10.8 12 11.2 12C11.6 12 12.4 11.5 12.5 11.1C12.7 10.6 12.7 10.2 12.6 10.2C12.5 9.7 12.3 9.7 12.1 9.6Z"
                                                          fill="#25D366"/>
                                                </svg>
                                                <a
                                                    href="https://api.whatsapp.com/send/?phone=8801610075103&text&type=phone_number&app_absent=0"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="tw-ml-1"
                                                >
                                                    <span className="tw-text-green-500">+880 1610-075103</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
