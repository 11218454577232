import React from "react";
import newAdmissionIcon from "../../../../images/new-admission-icon.png";
import "./AdmissionType.css";

const AdmissionType = ({ handleActiveBalanceSection }) => {
    return (
        <div>
            <p style={{ fontSize: "20px", color: "#7C7C7C" }}>
                ভর্তির ধরন নির্বাচন করুন
            </p>
            <p className="mb-5" style={{ fontSize: "24px" }}>
                এখনো ভর্তি না হয়ে থাকলে <strong>“নতুন ভর্তি”</strong> সিলেক্ট
                করুন
            </p>
            <button className="btn-new-admission" onClick={() => handleActiveBalanceSection()}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="tw-flex">
                    <img src={newAdmissionIcon} alt="" style={{ color:'red' }} className='img-admit'/>
                        <span className="ps-3">
                            নতুন ভর্তি 
                        </span>
                    </div>

                    <div><i className="fa-solid fa-angle-right"></i></div>
                </div>
            </button>
        </div>
    );
};

export default AdmissionType;
