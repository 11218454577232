import React, {useState} from "react";
import OtpInput from 'react-otp-input';
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import { FaRegCircleXmark } from "react-icons/fa6";
import TagManager from "react-gtm-module";

const Login = ({isOpen, setIsOpen}) => {

    const [showOTP, setShowOTP] = useState(false);
    const [error, setError] = useState("");
    const [otp, setOtp] = useState('');
    const [userId, setUserId] = useState(null);
    const [emailOrPhone, setEmailOrPhone] = useState("");

    const navigate = useNavigate();

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const emailOrPhone = e.target.emailOrPhone.value;
        setEmailOrPhone(emailOrPhone);

        const login_info = {
            mobile_or_email: emailOrPhone,
        }

        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}login`,{
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(login_info),
        })
            .then(res => res.json())
            .then((data) => {
                if (data.message_type === "success") {
                    setUserId(data.data.user_id);
                    setShowOTP(true);
                    setError("");
                } else {
                    toast.error(data.message);
                    setError(data.message);
                }
            })
    }



    const handleVerifyOTP = () => {
        const otp_information = {
            mobile_or_email: emailOrPhone,
            user_id: userId,
            otp: otp,
        };
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}verify-otp`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(otp_information),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message_type === "success"){
                    localStorage.setItem("auth_token", data.auth_token);
                    localStorage.setItem("auth_credential", data.auth_credential);
                    localStorage.setItem("user_id", userId);
                    localStorage.setItem("user_name", userId);
                    localStorage.setItem("mobile_or_email", emailOrPhone);
                    setShowOTP(false);
                    setIsOpen(false);
                    navigate("/course-payment", {
                        state: {
                            hello: "hello bro"
                        }
                    })

                } else {
                    toast.error(data.message);
                    setShowOTP(false);
                    setIsOpen(false);
                }
            });
    }

    return (
        <>
            {isOpen && (
                <div className="tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center tw-bg-gray-500 tw-bg-opacity-75">
                    <div className="tw-bg-white tw-w-1/2 tw-mx-auto tw-p-8 tw-rounded-lg tw-shadow-lg">
                        <div className="tw-flex tw-justify-end">
                            <button
                                className="tw-text-gray-700 tw-text-lg tw-hover:text-gray-900 tw-transition tw-ease-out tw-duration-300"
                                onClick={closeModal}
                            >
                                <FaRegCircleXmark className="tw-text-3xl tw-text-purple-600"/>
                            </button>

                        </div>
                        {
                            showOTP ? <div>
                                <p className="tw-text-center tw-font-400 tw-text-purple-600 tw-mb-6">ওটিপি প্রদান করুন</p>
                                    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={4}
                                            renderSeparator={<span style={{width: "8px"}}></span>}
                                            isInputNum={true}
                                            shouldAutoFocus={true}
                                            inputStyle={{
                                                border: "1px solid purple",
                                                borderRadius: "8px",
                                                width: "54px",
                                                height: "54px",
                                                fontSize: "14px",
                                                color: "#000",
                                                fontWeight: "400",
                                                caretColor: "blue"
                                            }}
                                            focusStyle={{
                                                border: "1px solid #CFD3DB",
                                                outline: "none"
                                            }}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>
                                    <div className=" tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-6">
                                        <button
                                            onClick={handleVerifyOTP}
                                            className="tw-bg-purple-500 tw-text-white tw-px-4 tw-py-1 tw-rounded-2xl tw-text-center">Submit
                                        </button>
                                    </div>

                                </div> :
                                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                                    <h2 className="tw-text-xl tw-font-bold">User Login</h2>
                                    <form onSubmit={handleSubmit} className="tw-mt-6">
                                        <input
                                            className="tw-border-2 tw-border-purple-500 tw-px-10 tw-py-2 tw-rounded-2xl"
                                            placeholder="Email / Phone number" name="emailOrPhone" type="text"/>
                                        <button
                                            className="tw-block tw-w-full tw-bg-purple-500 tw-text-white tw-mt-6 tw-py-2 tw-rounded-2xl">Submit
                                        </button>

                                    </form>
                                    <p className="tw-mt-6">You don't have an account? <span
                                        className="tw-text-purple-600 tw-font-semibold"> <Link
                                        to="/sign-up">Registration</Link></span></p>
                                </div>
                        }
                    </div>
                </div>

            )}
        </>
    );
};

export default Login;
