 import ReactPixel from 'react-facebook-pixel';

// Provide your Facebook Pixel ID here
// const FB_PIXEL_ID =  `${process.env.FB_PIXEL_ID}`;
const FB_PIXEL_ID =  `3914759148771512`;

function addFacebookPageView(_advancedMatching) {
    let advancedMatching = {} || _advancedMatching;
    if(localStorage.getItem('mobile_or_email')){
        advancedMatching =  { 
          ph : Number(localStorage.getItem('mobile_or_email')),
       external_id: localStorage.getItem('user_id')
      };
    }
    // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init(FB_PIXEL_ID, advancedMatching, options);
    ReactPixel.pageView();
}
  
function addFacebookEvent(eventName, data, _advancedMatching) {
    let advancedMatching = {} || _advancedMatching;

    if(localStorage.getItem('mobile_or_email')){
        advancedMatching =  { 
          ph : Number(localStorage.getItem('mobile_or_email')),
       external_id: localStorage.getItem('user_id')
      };
    
    }
    // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init(FB_PIXEL_ID, advancedMatching, options);
  
    ReactPixel.pageView();
    ReactPixel.trackCustom(eventName, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
}

// Exporting named functions instead of an anonymous default object
export default {
    addFacebookPageView,
    addFacebookEvent
};
