import React, { useEffect } from 'react';
import './Lessons.css';
import Topics from './Topics/Topics';

const Lessons = ({ lesson, LessonSerial, lessons, handelGetCertificate, course_name, user_name, setShowCertificate }) => {

    useEffect(() => {
        // Check if the last topic of the last lesson is viewed
        if (lessons[lessons.length - 1]?.topics[lesson?.topics?.length - 1]?.is_viewed) {
            handelGetCertificate();
        }
    }, [lessons, handelGetCertificate, lesson?.topics?.length]);

    return (
        <div className="accordion-item lesson-accordion-body">
            <h3 className="accordion-header change">
                <button
                    className={`accordion-button ${LessonSerial === 0 ? '' : 'collapsed'} btn-lesson`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#faq-content-${LessonSerial}-${lesson?.lesson_id}`}
                >
                    <span className="fw-500">{lesson?.lesson_name}</span>
                </button>
            </h3>
            <div id={`faq-content-${LessonSerial}-${lesson?.lesson_id}`}
                 className={`accordion-collapse collapse ${LessonSerial === 0 ? 'show' : ''}`}
                 data-bs-parent="#syllabus-list">
                <div className="total-assignment-divider"></div>
                <div className="mt-3 px-2 table-responsive">
                    <table className="table table-borderless">
                        <tbody>
                        {lesson?.topics?.length === 0 ? (
                            <tr>
                                <td colSpan={5}>
                                    <p className="d-flex justify-content-center mb-0 w-100" style={{ fontSize: "20px", fontWeight: "600" }}>
                                        এখনো কোনো বিষয়বস্তু যুক্ত করা হয়নি।
                                    </p>
                                </td>
                            </tr>
                        ) : (
                            lesson?.topics?.map((topic, index) => (
                                <Topics
                                    unlock={lesson?.topics[index - 1]?.is_viewed === 1 && lesson?.topics[index]?.is_viewed === 0}
                                    key={topic?.topic_id}
                                    topic={topic}
                                    topicSerial={index}
                                    LessonSerial={LessonSerial}
                                    lessonName={lesson?.lesson_name}
                                    lessons={lessons}
                                    topicslist={lesson?.topics}
                                />
                            ))
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Lessons;
