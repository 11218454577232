import React, { useState } from "react";
import { HiArrowRight } from "react-icons/hi";
import MojaruLogo from "../../../../../images/community.png";
import heroBg from "../../../../../images/HomePageImage/homePageHeroSection/MainBG.png";
import image1 from "../../../../../images/HomePageImage/homePageHeroSection/Boy.png";
import image2 from "../../../../../images/HomePageImage/homePageHeroSection/Teacher.png";
import image3 from "../../../../../images/HomePageImage/homePageHeroSection/Girl.png";
import bulbIcon from "../../../../../images/HomePageImage/homePageHeroSection/Bulb-icon.png";
import abacusIcon from "../../../../../images/HomePageImage/homePageHeroSection/Abacus-icon.png";
import cadetCap from "../../../../../images/HomePageImage/homePageHeroSection/cadet-cap.png";
import world from "../../../../../images/HomePageImage/homePageHeroSection/world.png";
import codeIcon from "../../../../../images/HomePageImage/homePageHeroSection/Code.png";
import math from "../../../../../images/HomePageImage/homePageHeroSection/math.png";
import abacusIconNew from "../../../../../images/HomePageImage/homePageHeroSection/abacusIcon.png";
import ellipse from "../../../../../images/HomePageImage/homePageHeroSection/EllipseMojaruLogo.png";
import image from "../../../../../images/HomePageImage/homePageHeroSection/image.png";

import { Link } from "react-router-dom";
import Modal from "./Modal/Modal";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleReport = () => {
    setOpen(true);
  };
  return (
    <div className="tw-w-full tw-max-w-[1440px] tw-mx-auto tw-pt-10 md:tw-pt-7">
      <div className="tw-flex tw-flex-col tw-justify-center tw-items-center md:tw-mt-10 tw-px-4 2xl:tw-px-0">
        <div className="">
          <h1 className="tw-text-4xl md:tw-text-[3.5rem] lg:tw-text-[4rem]  tw-font-semibold md:tw-font-bold tw-text-center tw-text-gray-700 tw-leading-10 md:tw-leading-[4.5rem]">
            <span className="tw-text-[#6F39B7]">{t("Learn in a Fun")}</span>{" "}
            <span className="tw-text-[#FFCE39]">{t("way with")} </span>{" "}
            <span className="tw-text-[#6F39B7]">{t("Mojaru_heading")}</span>
          </h1>
          <p className="tw-mt-6 md:tw-mt-10 tw-text-base md:tw-text-4xl tw-text-center tw-text-gray-600">
            {t("hero sub title")}
          </p>
        </div>

        <div className="tw-mt-8 md:tw-mt-12 tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center tw-px-3 tw-z-10">
          <Link to="/info">
            <button className="tw-w-full md:tw-w-[300px] tw-p-4 tw-bg-[#532D80] tw-flex tw-justify-center tw-items-center md:tw-mr-8 md:tw-px-3 md:tw-py-4 tw-rounded-[32px] tw-text-white">
              <span className="tw-font-bold tw-text-center tw-text-lg">
                {t("Let’s Learn Together")}
              </span>
              <HiArrowRight className="tw-ml-2 tw-font-bold" />
            </button>
          </Link>
          <button
            onClick={handleReport}
            className="tw-flex tw-items-center tw-mt-4 md:tw-mt-0 tw-border tw-py-3 tw-px-12 tw-rounded-[32px] tw-shadow-lg"
          >
            <img src={MojaruLogo} alt="facebook" className="tw-mr-3 tw-w-10" />
            <span className="tw-text-xl tw-text-gray-500 tw-font-semibold">
              {t("Mojaru Community")}
            </span>
          </button>
        </div>

        {/* Desktop Images */}
        <div className="tw-hidden md:tw-block tw-relative tw-w-full tw-mt-[6rem] lg:tw-mt-[6rem] xl:tw-mt-16">
          {/* background image  */}
          <img
            className="tw-w-full tw-h-auto tw-overflow-hidden  "
            src={heroBg}
            alt="background"
          />

          <div className=" tw-w-full tw-flex tw-justify-center tw-items-center tw-absolute md:-tw-top-[150px] lg:-tw-top-[140px] xl:lg:-tw-top-[120px] tw-overflow-hidden  ">
            {/* image 1 */}
            <div className="tw-relative tw-mr-2  lg:tw-mr-[1.5rem] xl:tw-mr-16">
              <img
                className="tw-w-[275px] md:tw-w-[300px] lg:tw-w-[350px] xl:tw-w-[375px] tw-h-auto"
                src={image1}
                alt="placeholder"
              />
              <img
                className="tw-absolute tw-top-8 -tw-left-24 tw-animate-bounce-custom"
                src={bulbIcon}
                alt="icon"
              />
              <img
                className="tw-absolute tw-bottom-0 -tw-right-20 tw-animate-bounce-custom"
                src={math}
                alt="icon"
              />
            </div>
            {/* image 2 */}
            <div className="tw-relative lg:tw-mr-[1.5rem] xl:tw-mr-12 tw-mt-40 lg:tw-mt-[8rem] xl:tw-mt-48">
              <img
                // className="tw-w-[275px] md:tw-w-[375px] tw-h-auto"
                className="tw-w-[275px] md:tw-w-[300px] lg:tw-w-[350px] xl:tw-w-[375px] tw-h-auto"
                src={image2}
                alt="placeholder"
              />
              <img
                className="tw-absolute -tw-top-12 -tw-left-8 tw-animate-rotate-lr"
                src={world}
                alt="icon"
              />
              <img
                className="tw-absolute md:-tw-bottom-4 lg:-tw-bottom-6 -tw-right-24 tw-animate-bounce-custom"
                src={codeIcon}
                alt="icon"
              />
            </div>
            {/* image 3 */}
            <div className="tw-relative tw-mt-32 lg:tw-mt-20 tw-mr-2 lg:tw-mr-0 ">
              <img
                // className="tw-w-[275px] md:tw-w-[375px] tw-h-auto"
                className="tw-w-[275px] md:tw-w-[300px] lg:tw-w-[350px] xl:tw-w-[375px] tw-h-auto"
                src={image3}
                alt="placeholder"
              />
              <img
                className="tw-w-[100px] tw-absolute -tw-top-14 tw-left-7"
                src={ellipse}
                alt="icon"
              />
              <img
                className="tw-absolute -tw-top-20 -tw-right-20 tw-animate-bounce-custom"
                src={abacusIconNew}
                alt="icon"
              />
              <img
                className="tw-absolute -tw-bottom-16 -tw-right-16 tw-animate-bounce-custom"
                src={image}
                alt="icon"
              />
            </div>
          </div>
        </div>

        {/* Mobile Images */}
        <div className="md:tw-hidden tw-w-full tw-mt-10">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
            <img
              className="tw-w-full tw-h-auto"
              src={image1}
              alt="placeholder"
            />
          </div>
        </div>
      </div>
      {open && <Modal open={open} setOpen={setOpen} />}
    </div>
  );
};

export default Hero;

// Fixing with %
// {/* <div className="md:tw-w-md lg:tw-w-lg xl:tw-w-xl tw-max-w-[1440px] tw-mx-auto tw-pt-10 md:tw-pt-7 tw-w-full">
// <div className="tw-flex tw-flex-col tw-justify-center tw-mx-auto tw-items-center tw-w-full ">
//   {/* Heading  */}
//   <div className="">
//     <h1 className="tw-text-4xl md:tw-text-[4rem] tw-font-semibold md:tw-font-bold tw-text-center tw-text-gray-700">
//       <span className="tw-text-[#6F39B7]">{t("Learn in a Fun")}</span>{" "}
//       <span className="tw-text-[#FFCE39]">{t("way with")} </span>{" "}
//       <span className="tw-text-[#6F39B7]">{t("Mojaru")}</span>
//     </h1>
//     <p className="tw-mt-6 md:tw-mt-10 tw-text-base md:tw-text-4xl tw-text-center tw-text-gray-600">
//       {t("hero sub title")}
//     </p>
//   </div>

//   {/* Buttons  */}
//   <div className="tw-mt-8 md:tw-mt-12 tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center tw-px-3 tw-z-10">
//     <Link to="/info">
//       <button className="tw-w-full md:tw-w-[300px] tw-p-4 tw-bg-[#532D80] tw-flex tw-justify-center tw-items-center md:tw-mr-8 md:tw-px-3 md:tw-py-4 tw-rounded-[32px] tw-text-white">
//         <span className="tw-font-bold tw-text-center tw-text-lg">
//           {t("Let’s Learn Together")}
//         </span>
//         <HiArrowRight className="tw-ml-2 tw-font-bold" />
//       </button>
//     </Link>
//     <button
//       onClick={handleReport}
//       className="tw-flex tw-items-center tw-mt-4 md:tw-mt-0 tw-border tw-py-3 tw-px-12 tw-rounded-[32px] tw-shadow-lg"
//     >
//       <img src={MojaruLogo} alt="facebook" className="tw-mr-3 tw-w-10" />
//       <span className="tw-text-xl tw-text-gray-500 tw-font-semibold">
//         {t("Mojaru Community")}
//       </span>
//     </button>
//   </div>

//   {/* Desktop Images */}
//   <div className="tw-hidden md:tw-block tw-relative tw-w-full">
//     {/* Background image */}
//     <img className="tw-w-full tw-h-auto" src={heroBg} alt="background" />
//     {/* 3 image container */}
//     {/* <div className="tw-flex tw-justify-center tw-items-center tw-absolute -tw-top-[120px] md:tw-left-[6.70%] lg:tw-left-[3%]"> */}

//     <div className="tw-flex tw-items-center tw-w-full tw-gap-12 tw-justify-center tw-absolute -tw-top-[120px]">
//       {/* Image 1  */}
//       {/* <div className="tw-relative tw-mr-16"> */}
//       <div className="tw-relative -tw-mt-[2%]">
//         <img
//           className="tw-w-[275px] md:tw-w-[375px] tw-h-auto "
//           src={image1}
//           alt="placeholder"
//         />
//         <img
//           className="tw-absolute tw-top-8 -tw-left-24 tw-animate-bounce-custom"
//           src={bulbIcon}
//           alt="icon"
//         />
//         <img
//           className="tw-absolute tw-bottom-0 -tw-right-20 tw-animate-bounce-custom"
//           src={math}
//           alt="icon"
//         />
//       </div>

//       {/* Image 2  */}
//       {/* <div className="tw-relative tw-mr-12 tw-mt-48"> */}
//       <div className="tw-relative tw-mt-[12%]">
//         <img
//           className="tw-w-[275px] md:tw-w-[375px] tw-h-auto"
//           src={image2}
//           alt="placeholder"
//         />
//         <img
//           className="tw-absolute -tw-top-12 -tw-left-8 tw-animate-rotate-lr"
//           src={world}
//           alt="icon"
//         />
//         <img
//           className="tw-absolute -tw-bottom-16 -tw-right-24 tw-animate-bounce-custom"
//           src={codeIcon}
//           alt="icon"
//         />
//       </div>

//       {/* Image 3  */}
//       {/* <div className="tw-relative tw-mt-20"> */}
//       <div className="tw-relative tw-mt-[7%]">
//         <img
//           className="tw-w-[275px] md:tw-w-[375px] tw-h-auto"
//           src={image3}
//           alt="placeholder"
//         />
//         <img
//           className="tw-w-[100px] tw-absolute -tw-top-14 tw-left-7"
//           src={ellipse}
//           alt="icon"
//         />
//         <img
//           className="tw-absolute -tw-top-20 -tw-right-20 tw-animate-bounce-custom"
//           src={abacusIconNew}
//           alt="icon"
//         />
//         <img
//           className="tw-absolute -tw-bottom-16 -tw-right-16 tw-animate-bounce-custom"
//           src={image}
//           alt="icon"
//         />
//       </div>
//     </div>
//   </div>

//   {/* Mobile Images */}
//   <div className="md:tw-hidden tw-w-full tw-mt-10">
//     <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
//       <img
//         className="tw-w-full tw-h-auto"
//         src={image1}
//         alt="placeholder"
//       />
//     </div>
//   </div>
// </div>
// {open && <Modal open={open} setOpen={setOpen} />}
// </div> */}

// ***************My first code
// {/* <div className="tw-w-full 2xl:tw-w-[1440px] tw-mx-auto tw-pt-6 md:tw-pt-2 lg:tw-pt-4 ">
// <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-overflow-hidden">
//   {/* Hero Text */}
//   <div className=" tw-flex tw-justify-center">
//     <span>
//       <h1 className="tw-text-4xl md:tw-leading-[1.3] lg:tw-landing-none md:tw-text-[3rem] lg:tw-text-[3.5rem] xl:tw-text-[4rem] tw-font-semibold md:tw-font-bold tw-text-center tw-text-gray-700">
//         <span className="tw-text-[#6F39B7]">{t("Learn in a Fun")}</span>{" "}
//         <span className="tw-text-[#FFCE39]">{t("way with")} </span>
//         <span className="tw-text-[#6F39B7]">{t("Mojaru")}</span>
//       </h1>
//       <p className="tw-mt-4 md:tw-mt-7 tw-text-base md:tw-text-3xl lg:tw-text-3xl tw-text-center tw-text-gray-600">
//         {t("hero sub title")}
//       </p>
//     </span>
//   </div>

//   {/* Hero 2 buttons */}
//   <div className="tw-mt-6 md:tw-mt-8 lg:tw-mt-12 tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center tw-px-3 tw-z-10">
//     <Link to="/info">
//       <button className="tw-w-full md:tw-w-[300px] tw-p-4 tw-bg-[#532D80] tw-flex tw-justify-center tw-items-center md:tw-mr-8 md:tw-px-3 md:tw-py-4 tw-rounded-[32px] tw-text-white">
//         <span className="tw-font-bold tw-text-center tw-text-lg ">
//           {t("Let’s Learn Together")}
//         </span>
//         <HiArrowRight className="tw-ml-2 tw-font-bold" />
//       </button>
//     </Link>

//     <button
//       onClick={handleReport}
//       className="tw-flex tw-items-center tw-mt-4 md:tw-mt-0 tw-border tw-py-3 tw-px-5 md:tw-px-12 tw-rounded-[32px] tw-shadow-lg"
//     >
//       <img src={MojaruLogo} alt="facebook" className="tw-mr-3 tw-w-10" />
//       <span className="tw-text-xl tw-text-gray-500 tw-font-semibold">
//         {t("Mojaru Community")}
//       </span>
//     </button>
//   </div>

//   {/* Desktop Images */}
//   <div className="tw-hidden md:tw-block tw-relative tw-w-full  tw-mt-16 md:tw-mt-36 lg:tw-mt-16 xl:tw-mt-2 2xl:tw-mt-16 tw-overflow-visible">
//     {/* Background Image */}
//     <img className="tw-w-full tw-h-auto" src={heroBg} alt="background" />

//     {/* Container for images */}
//     <div className="tw-flex tw-justify-between tw-items-start tw-absolute -tw-top-[100px] xl:-tw-top-[180px] tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-w-[90%] lg:tw-w-[85%] xl:tw-w-[80%] 2xl:tw-w-[90%]">
//       {/* Desktop Image 1 */}

//       {/* <div className="tw-relative tw-flex-shrink-0 lg:tw-mt-14 xl:tw-mt-20 2xl:tw-mt-12 md:tw-mr-2 lg:tw-mr-0 "> */}

//       <div className="tw-relative tw-flex-shrink-0 md:tw-mt-[6%] lg:tw-mt-[6%] xl:tw-mt-[13%] 2xl:tw-mt-[7%] md:tw-mr-2 lg:tw-mr-0 ">
//         <img
//           className="tw-w-[200px] md:tw-w-[230px] lg:tw-w-[275px] xl:tw-w-[300px] 2xl:tw-w-[345px] tw-h-auto"
//           src={image1}
//           alt="placeholder"
//           style={{ objectFit: "contain" }} // Prevent image from cropping
//         />
//         {/* Dis img light  */}
//         <img
//           className="tw-absolute tw-top-8 -tw-left-16 md:-tw-left-24 tw-animate-bounce-custom"
//           src={bulbIcon}
//           alt="icon"
//         />
//         {/* Dis img cube  */}
//         <img
//           className="tw-absolute tw-bottom-0 -tw-right-16 md:-tw-right-20 tw-animate-bounce-custom"
//           src={math}
//           alt="icon"
//         />
//       </div>

//       {/* Desktop Image 2 */}
//       {/* <div className="tw-relative tw-mt-20 md:tw-mt-[20%] lg:tw-mt-[14rem] xl:tw-mt-[13rem] 2xl:tw-mt-[12rem]  tw-flex-shrink-0 md:tw-mr-2 lg:tw-mr-0"> */}
//       <div className="tw-relative tw-mt-20 md:tw-mt-[18%] lg:tw-mt-[10%] xl:tw-mt-[13rem] 2xl:tw-mt-[12rem]  tw-flex-shrink-0 md:tw-mr-2 lg:tw-mr-0">
//         <img
//           className="tw-w-[200px] md:tw-w-[230px] lg:tw-w-[275px] xl:tw-w-[300px] 2xl:tw-w-[100%] tw-h-auto"
//           src={image2}
//           alt="placeholder"
//           style={{ objectFit: "contain" }} // Prevent image from cropping
//         />
//         <img
//           className="tw-absolute -tw-top-12 -tw-left-8 tw-animate-rotate-lr"
//           src={world}
//           alt="icon"
//         />
//         <img
//           className="tw-absolute -tw-bottom-12 md:-tw-bottom-16 -tw-right-16 md:-tw-right-24 tw-animate-bounce-custom"
//           src={codeIcon}
//           alt="icon"
//         />
//       </div>

//       {/* Desktop Image 3 */}

//       {/* <div className="tw-relative tw-flex-shrink-0 md:tw-mt-20 lg:tw-mt-32 xl:tw-mt-44  2xl:tw-mt-40"> */}
//       <div className="tw-relative tw-flex-shrink-0 tw-mt-20 md:tw-mt-[18%] lg:tw-mt-[10%] xl:tw-mt-[20%] 2xl:tw-mt-[8%]">
//         <img
//           className="tw-w-[200px] md:tw-w-[230px] lg:tw-w-[275px]  xl:tw-w-[310px] 2xl:tw-w-[23rem] tw-h-auto"
//           // className="tw-w-[200px] md:tw-w-[250px] lg:tw-w-[275px] xl:tw-w-[300px] 2xl:tw-w-[350px] tw-h-auto"
//           src={image3}
//           alt="placeholder"
//           style={{ objectFit: "contain" }} // Prevent image from cropping
//         />
//         <img
//           className="tw-w-[100px] tw-absolute -tw-top-14 tw-left-7"
//           src={ellipse}
//           alt="icon"
//         />
//         <img
//           className="tw-absolute -tw-top-20 -tw-right-16 tw-animate-bounce-custom"
//           src={abacusIconNew}
//           alt="icon"
//         />
//         <img
//           className="tw-absolute -tw-bottom-16 -tw-right-16 tw-animate-bounce-custom"
//           src={image}
//           alt="icon"
//         />
//       </div>
//     </div>
//   </div>

//   {/* Mobile Images */}
//   <div className="md:tw-hidden tw-w-full tw-mt-8">
//     <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
//       <img
//         className="tw-w-full tw-h-auto"
//         src={image1}
//         alt="placeholder"
//       />
//     </div>
//   </div>
// </div>
// {open && <Modal open={open} setOpen={setOpen} />}
// </div> */}
