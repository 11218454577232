import React, {useEffect, useState} from 'react';
import CampaignForm from "./Components/CampaignForm";
import { userInfoStore } from "../../../../../store/userInfoStore";
import ExamInfo from "./Components/ExamInfo";
import Quiz from "./Components/Quiz";

import { dataAge4To5, dataAge6To7, dataAge8T09, dataAge10To11, dataAge12To13, dataAge14To15} from './Components/Question';

const AbacusCampaign = () => {
    const [startQuiz, setStartQuiz] = useState(false);
    const { name, phone, dob } = userInfoStore();
    localStorage.setItem('age', dob);

    let data;

    if(dob >= 0 && dob <= 5){
        data = dataAge4To5;
    }else if(dob >= 6 && dob <= 7){
        data = dataAge6To7;
    }else if(dob >= 8 && dob <=9){
        data = dataAge8T09;
    }else if(dob >= 10 && dob <= 11){
        data = dataAge10To11;
    }else if(dob >= 12 && dob <= 13) {
        data = dataAge12To13;
    }else {
        data = dataAge14To15;
    }

    if (data.length > 10) {
        for (let i = data.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [data[i], data[j]] = [data[j], data[i]];
        }
        data = data.slice(0, 10);
    }
    return (
        <div className="tw-max-w-[1080px] tw-mx-auto tw-bg-gray-100 md:tw-p-10 tw-mt-24 tw-rounded-2xl">
            {name && phone && dob ? (
                startQuiz ? (
                    <Quiz dob={dob} name={name} phone={phone} data={data}/>
                ) : (
                    <ExamInfo setStartQuiz={setStartQuiz} />
                )
            ) : (
                <CampaignForm />
            )}
        </div>
    );
};

export default AbacusCampaign;