// import React, { useMemo, useState, useEffect } from "react";

// const ShowRating = ({ count, rating, color }) => {
//   const [isSmallScreen, setIsSmallScreen] = useState(false);

//   // Update the screen size state based on window width
//   useEffect(() => {
//     const handleResize = () => {
//       setIsSmallScreen(window.innerWidth < 768); // Change the breakpoint as needed
//     };

//     // Set initial value and add listener
//     handleResize();
//     window.addEventListener("resize", handleResize);

//     // Cleanup event listener on component unmount
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   // Function to get the color of the star (full, half, or empty)
//   const getColor = (index) => {
//     if (rating >= index) {
//       return color.filled; // Full star
//     } else if (rating >= index - 0.5) {
//       return color.halfFilled || color.unfilled; // Optionally handle half-star
//     }
//     return color.unfilled; // Empty star
//   };

//   const showRating = useMemo(() => {
//     const starsToShow = isSmallScreen ? 1 : count; // Display only 1 star on small screens
//     return Array(starsToShow)
//       .fill(0)
//       .map((_, i) => i + 1)
//       .map((idx) => (
//         <i
//           key={idx}
//           className="fa-solid fa-star"
//           style={{ color: getColor(idx) }}
//         />
//       ));
//   }, [count, rating, color, isSmallScreen]); // Include dependencies

//   return <div>{showRating}</div>;
// };

// ShowRating.defaultProps = {
//   count: 5,
//   rating: 0,
//   color: {
//     filled: "#FB923C",
//     unfilled: "#DCDCDC",
//     halfFilled: "#FFDD99", // Optional for half stars
//   },
// };

// export default ShowRating;

import React from "react";

// CSS for the stars
const styles = {
  star: {
    fontSize: "24px",
    margin: "5px",
    color: "gray", // Default star color
  },
  golden: {
    color: "gold", // Highlighted star color
  },
};

const ShowRating = ({ rating }) => {
  // Ensure the rating is between 1 and 5
  const validRating = Math.min(Math.max(rating, 1), 5);

  return (
    <div>
      {Array.from({ length: 5 }, (_, index) => (
        <span
          key={index}
          style={{
            ...styles.star,
            ...(index < validRating ? styles.golden : {}),
          }}
        >
          ★
        </span>
      ))}
    </div>
  );
};

export default ShowRating;
