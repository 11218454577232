import React from 'react';
import { useLocation } from "react-router-dom";
import PageTitle from "../../../../shared/PageTitle/PageTitle";
import parse from 'html-react-parser';

const PublisherDetail = () => {
    const location = useLocation();
    const publisher = location.state.publisherDetail;
    const imageLink = location.state.imagePath;
    return (
        <div>
            <PageTitle title="Publisher Detail"></PageTitle>
            <div className="borderTop" style={{ paddingBottom: "21rem" }}>
                <div className="container">
                    <div className="author_detail-section mt-5">
                    <div className="row">
                        <div className="col-lg-4 author-image-section"> 
                        <img
                            src={`${imageLink}${publisher?.publisher_image}`}
                            alt=""
                            className="img-fluid my-5 author_img"
                        />
                        </div>
                        <div className="col-lg-6 d-flex author-details-section">
                            <div>
                            <p className="authorName mb-1">{publisher?.publisher_name}</p>
                            <p className="book-language mb-1">Email: <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{publisher?.publisher_email}</span></p>
                            <p className="book-language mb-1">Contact: <span>&nbsp;&nbsp;&nbsp;{publisher?.publisher_contact}</span></p>
                            <p className="book-language mb-1">Address: <span>&nbsp;&nbsp;&nbsp;{publisher?.publisher_address}</span></p>
                            </div>
                        </div>
                    </div>

                    </div>

                    <div className="author-des">
                        <div>{ parse(publisher?.publisher_details) }</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublisherDetail;