import React from 'react';
import { HiVideoCamera, HiUsers, HiArrowNarrowRight } from "react-icons/hi";
import {Link} from "react-router-dom";
const Card = ({news}) => {
    const imageBaseUrl = "https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/news/"
    return (
        <a href={`${news?.news_url}`}>
            <div
                className="tw-w-full tw-h-auto md:tw-w-[376px] md:tw-h-[480px] md:tw-bg-white tw-p-4 md:tw-p-0 tw-rounded-3xl tw-shadow">
                <img className="tw-w-full tw-h-[192px] tw-rounded-tr-3xl tw-rounded-tl-3xl"
                     src={imageBaseUrl + news?.news_image} alt="course"/>
                <div className="tw-bg-white tw-rounded-bl-3xl tw-rounded-br-3xl tw-p-6">
                    <h2 className="tw-text-lg tw-text-gray-700 tw-font-bold tw-mt-2">{news?.news_title ? news?.news_title.slice(0, 60) : ""}</h2>
                    <p className="tw-hidden md:tw-block md:tw-mt-4 tw-text-gray-500">{news?.news_short_details ? news?.news_short_details.slice(0, 80): ""}</p>
                    <div className="tw-flex tw-items-center tw-mt-4 ">
                        {
                            news?.publisher_logo &&
                            <img className="tw-h-9" src={imageBaseUrl + news?.publisher_logo} alt="media"/>
                        }
                        <div className="tw-w-6 tw-h-[1px] tw-bg-gray-300 tw-mr-2 tw-ml-2">
                        </div>
                        <p className="tw-text-sm tw-text-gray-500">{news?.news_published_date}</p>
                    </div>
                    <div className="tw-mt-6">
                        <button className="tw-flex tw-items-center tw-font-bold tw-text-blue-800"><span
                            className="tw-mr-2">বিস্তারিত</span><HiArrowNarrowRight/></button>
                    </div>
                </div>
            </div>
        </a>
    );
};

export default Card;