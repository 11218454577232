import React from 'react';
import Notice from './Notice/Notice';

const Notices = () => {
    return (
        <div className="bg-white custom-card-shadow" style={{ borderRadius: "16px" }}>

            <p className="course-total-assignment mb-0">৩টি নোটিস</p>
            <div className="total-assignment-divider"></div>

            <div className="">
                <div className='row mt-4 py-3 px-4  g-5 mx-0'>
                    <Notice/>
                    <Notice/>
                    <Notice/>
                    <Notice/>
                    <Notice/>
                </div>
            </div>
            
        </div>
    );
};

export default Notices;