import React, { useState } from 'react';
import bg from "../../../../images/login/BG.png";
import successStudent from "../../../../images/login/successStudent.png";
import SliderComponent from "../SliderComponent/SliderComponent";
import PageTitle from "../../shared/PageTitle/PageTitle";
import noPerson from "../../../../images/login/noImage.png";
import "./UserRegistration.css";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const UserRegistration = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [parentPhone, setParentPhone] = useState('');
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const isValidInput = name.trim() !== '' && parentPhone.trim() !== '';

    const handleNameChange = (event) => setName(event.target.value);
    const handleParentPhoneChange = (event) => setParentPhone(event.target.value);

    const handleFileChange = (event) => {
        setImage(event.target.files[0]);
        const selectedFile = event.target.files[0];
        if (selectedFile) setFile(URL.createObjectURL(selectedFile));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isValidInput) return;

        setLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('user_name', name);
        formData.append('guardian_mobile_number', parentPhone);
        formData.append('profile_picture', image);


        fetch(
            `${
                process.env.REACT_APP_BASE_URL_STUDENT
            }update-profile?user_id=${localStorage.getItem("user_id")}`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                },
                body: formData,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.message_type === "success") {
                    toast.success(data.message);
                    navigate("/dashboard");
                } else {
                    toast.error(data.message);
                }
            });
    };

    return (
        <section className="tw-h-screen tw-flex tw-items-center tw-justify-center -tw-mt-[88px]">
            <PageTitle title="Registration" />
            <div className="md:tw-flex tw-w-full tw-h-full">
                <div className="tw-w-full md:tw-w-1/2 tw-h-full tw-flex tw-flex-col tw-justify-center">
                    <div className="tw-w-[90%] md:tw-w-[50%] tw-mx-auto">
                        <h2 className="tw-text-2xl tw-font-bold">মজার শিক্ষাগুরু মজারুতে স্বাগতম!</h2>
                        <p className="tw-mt-2 tw-text-gray-600">
                            মজারুতে জয়েন করতে তোমার কিছু তথ্য দিয়ে বক্সগুলো পুরন করে দাও।
                        </p>
                        <div className=" tw-mt-8">
                            <label htmlFor="imageUpload" className="tw-cursor-pointer">
                                <img
                                    className=" tw-w-[92px] tw-h-[92px] tw-object-cover"
                                    id="imagePreview"
                                    src={file || noPerson}
                                    alt="User profile"
                                />
                            </label>
                            <input
                                type="file"
                                id="imageUpload"
                                accept=".png, .jpg, .jpeg"
                                className="tw-hidden"
                                onChange={handleFileChange}
                            />
                        </div>
                        <form className="tw-mt-6" onSubmit={handleSubmit}>
                            <div>
                                <label
                                    htmlFor="name"
                                    className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-700"
                                >
                                    তোমার নাম <span className="tw-text-red-700">*</span>
                                </label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={name}
                                    onChange={handleNameChange}
                                    placeholder="এখানে তোমার নাম লিখো"
                                    className="tw-block tw-w-full tw-mt-1 tw-rounded-md tw-border-0 tw-pl-4 tw-py-3 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-orange-200 tw-sm:tw-text-sm tw-sm:tw-leading-6"
                                />
                            </div>
                            <div className="tw-mt-4">
                                <label
                                    htmlFor="parentPhone"
                                    className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-700"
                                >
                                    অভিভাবকের ফোন নাম্বার <span className="tw-text-red-700">*</span>
                                </label>
                                <input
                                    id="parentPhone"
                                    name="parentPhone"
                                    type="text"
                                    value={parentPhone}
                                    onChange={handleParentPhoneChange}
                                    placeholder="এখানে তোমার অভিভাবকের ফোন নাম্বার লিখো"
                                    className="tw-block tw-w-full tw-mt-1 tw-rounded-md tw-border-0 tw-pl-4 tw-py-3 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-orange-200 tw-sm:tw-text-sm tw-sm:tw-leading-6"
                                />
                            </div>

                            <button
                                type="submit"
                                className={`tw-mt-6 tw-w-full tw-py-3 tw-rounded-3xl tw-text-white ${!isValidInput ? 'tw-cursor-not-allowed tw-bg-[#D2BEF4]' : 'tw-bg-[#532D80]'}`}
                                disabled={!isValidInput || loading}
                            >
                                {loading ? 'Registering...' : 'মজায় শেখার জার্নি শুরু করো'}
                            </button>
                            {error && <p className="tw-mt-4 tw-text-red-500">{error}</p>}
                        </form>
                    </div>
                </div>
                <div className="tw-hidden md:tw-block md:tw-w-1/2 tw-h-full tw-relative">
                    <img className="tw-w-full tw-h-full tw-object-cover" src={bg} alt="background image" />
                    <div
                        className="tw-absolute tw-top-[20%] tw-left-[10%] tw-w-[80%] tw-mx-auto tw-bg-white tw-p-8 tw-rounded-3xl">
                        <img className="tw-w-full" src={successStudent} alt="Success" />
                        <div className="tw-overflow-hidden tw-mt-8">
                            <SliderComponent />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UserRegistration;
