import React, { useEffect, useState } from "react";
import trailing from "../../../../../../images/course_details_icon/Trailing-icon.png";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Faq = ({ course_faq, handleButtonClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState("bn");
  const toggleFaq = () => {
    setIsOpen(!isOpen);
  };

  // Language switcher
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");

    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  // Toggle Language
  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem("useLanguage");

    if (getLanguage === "bn") {
      sessionStorage.setItem("useLanguage", "en");
      i18next.changeLanguage("en");
      setLanguage("en");
    } else {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    }
  }

  return (
    <div
      className={`${
        handleButtonClick ? "" : "tw-bg-white"
      } tw-px-8 tw-rounded-3xl`}
    >
      <div className="tw-border-b ">
        <p className="tw-text-lg">
          <button
            className={`tw-flex tw-justify-between tw-w-full tw-px-5 tw-py-5 tw-font-lg tw-text-left tw-text-gray-900 tw-border-0 ${
              handleButtonClick ? "" : "tw-bg-white"
            } transition tw-text-lg ${
              isOpen ? "tw-rounded-t-xl" : "tw-rounded-xl"
            }`}
            onClick={toggleFaq}
          >
            {course_faq?.course_faq_question}
            <span
              className={`tw-inline-block tw-transform tw-transition-transform ${
                isOpen ? "tw-rotate-180" : ""
              }`}
            >
              <img src={trailing} alt="icon" />
            </span>
          </button>
        </p>
        {isOpen && (
          <div
            className={`tw-accordion-body tw-px-5 tw-py-3 ${
              handleButtonClick ? "" : "tw-bg-white"
            } tw-text-gray-500`}
          >
            <div onClick={handleButtonClick}>
              {course_faq.course_faq_answer}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Faq;

//************* */ STAGE 2 TRYING
// import React, { useEffect, useState } from "react";
// import trailing from "../../../../../../images/course_details_icon/Trailing-icon.png";
// import i18next from "i18next";
// import { useTranslation } from "react-i18next";

// const Faq = ({ course_faq, handleButtonClick }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [language, setLanguage] = useState("bn");

//   const toggleFaq = () => {
//     setIsOpen(!isOpen);
//   };

//   // Language switcher
//   useEffect(() => {
//     const useLanguage = sessionStorage.getItem("useLanguage");

//     if (!useLanguage) {
//       sessionStorage.setItem("useLanguage", "bn");
//       i18next.changeLanguage("bn");
//       setLanguage("bn");
//     } else {
//       i18next.changeLanguage(useLanguage);
//       setLanguage(useLanguage);
//     }
//   }, []);

//   // Toggle Language function to switch between bn and en
//   function toggleLanguage() {
//     const getLanguage = sessionStorage.getItem("useLanguage");

//     if (getLanguage === "bn") {
//       sessionStorage.setItem("useLanguage", "en");
//       i18next.changeLanguage("en");
//       setLanguage("en");
//     } else {
//       sessionStorage.setItem("useLanguage", "bn");
//       i18next.changeLanguage("bn");
//       setLanguage("bn");
//     }
//   }

//   const question = course_faq.course_faq_question[language];
//   const answer = course_faq.course_faq_answer[language];

//   return (
//     <div
//       className={`${
//         handleButtonClick ? "" : "tw-bg-white"
//       } tw-px-8 tw-rounded-3xl`}
//     >
//       <div className="tw-border-b ">
//         <p className="tw-text-lg">
//           <button
//             className={`tw-flex tw-justify-between tw-w-full tw-px-5 tw-py-5 tw-font-lg tw-text-left tw-text-gray-900 tw-border-0 ${
//               handleButtonClick ? "" : "tw-bg-white"
//             } transition tw-text-lg ${
//               isOpen ? "tw-rounded-t-xl" : "tw-rounded-xl"
//             }`}
//             onClick={toggleFaq}
//           >
//             {question}
//             <span
//               className={`tw-inline-block tw-transform tw-transition-transform ${
//                 isOpen ? "tw-rotate-180" : ""
//               }`}
//             >
//               <img src={trailing} alt="icon" />
//             </span>
//           </button>
//         </p>
//         {isOpen && (
//           <div
//             className={`tw-accordion-body tw-px-5 tw-py-3 ${
//               handleButtonClick ? "" : "tw-bg-white"
//             } tw-text-gray-500`}
//           >
//             <div onClick={handleButtonClick}>{answer}</div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Faq;

// ************ STAGE 3 TRYING

// import React, { useState } from "react";
// import trailing from "../../../../../../images/course_details_icon/Trailing-icon.png";

// const Faq = ({ course_faq, handleButtonClick, currentLanguage }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleFaq = () => {
//     setIsOpen(!isOpen);
//   };

//   const question = course_faq.course_faq_question[currentLanguage];
//   const answer = course_faq.course_faq_answer[currentLanguage];

//   return (
//     <div className="tw-bg-white tw-px-8 tw-rounded-3xl">
//       <div className="tw-border-b ">
//         <button
//           className={`tw-flex tw-justify-between tw-w-full tw-px-5 tw-py-5 tw-font-lg tw-text-left transition tw-text-lg ${
//             isOpen ? "tw-rounded-t-xl" : "tw-rounded-xl"
//           }`}
//           onClick={toggleFaq}
//         >
//           {question}
//           <span
//             className={`tw-inline-block tw-transform tw-transition-transform ${
//               isOpen ? "tw-rotate-180" : ""
//             }`}
//           >
//             <img src={trailing} alt="icon" />
//           </span>
//         </button>
//         {isOpen && (
//           <div className="tw-accordion-body tw-px-5 tw-py-3 tw-bg-white tw-text-gray-500">
//             <div onClick={handleButtonClick}>{answer}</div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Faq;
