import lessonVideoLectureIcon from '../images/lesson video lecture icon.png';
import lessonSmartNoteIcon from '../images/lesson smart note icon.png';
import lessonQuizIcon from '../images/lesson quiz icon.png';
import lessonLiveClassIcon from '../images/play live class icon.png';
import lessonModelTestIcon from '../images/model test icon.png';
import lessonAssignmentIcon from '../images/assignment icon.png';
import lessonExamIcon from '../images/exam icon.png'; 
 
 
 /* English to bangla number converter */
 export const englishToBengaliNumberFormat = number => number?.toString().replace(/[0-9]/g, d => "০১২৩৪৫৬৭৮৯"[d]);

 export const englishToBengaliNumberFormatWithZero = number =>{
   if(number < 10 && number > 0)
      number = '0'+ number;
   return number?.toString().replace(/[0-9]/g, d => "০১২৩৪৫৬৭৮৯"[d]);
 } 

 /* date formate converter */
 export const dateConverter = date => {
    const today = new Date(date);
        const month = ['জানুয়ারী', 'ফেব্রুয়ারী', 'মার্চ', 'এপ্রিল', 'মে', 'জুন', 'জুলাই', 'আগস্ট', 'সেপ্টেম্বর', 'অক্টোবর', 'নভেম্বর', 'ডিসেম্বর',];
        
         function numberConverter(number){
            return number?.toString().replace(/[0-9]/g, d => "০১২৩৪৫৬৭৮৯"[d]);
         }
        return numberConverter(today.getDate()) + ' ' + month[today.getMonth()] + ', ' + numberConverter(today.getFullYear());
 };

 export const dateConverterWithSlashFormate = date => {
    const today = new Date(date);
        
         function numberConverter(number){
            return number?.toString().replace(/[0-9]/g, d => "০১২৩৪৫৬৭৮৯"[d]);
         }
         let convertMonth;
          if(today.getMonth()+1 < 10){
            convertMonth = '0'+(parseInt(today.getMonth())+1);
          }else{
            convertMonth = (parseInt(today.getMonth())+1);
          }
        return numberConverter(today.getDate()) + '/' + numberConverter(convertMonth) + '/' + numberConverter(today.getFullYear());
 };

 export const dateConverterWithOutYear = date => {
  const today = new Date(date);
      const month = ['জানুয়ারী', 'ফেব্রুয়ারী', 'মার্চ', 'এপ্রিল', 'মে', 'জুন', 'জুলাই', 'আগস্ট', 'সেপ্টেম্বর', 'অক্টোবর', 'নভেম্বর', 'ডিসেম্বর',];
      
       function numberConverter(number){
          return number?.toString().replace(/[0-9]/g, d => "০১২৩৪৫৬৭৮৯"[d]);
       }
      return numberConverter(today.getDate()) + ' ' + month[today.getMonth()];
};

 export const certificateDate = date => {
  const today = new Date(date);
      const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',];
      
      return  month[today.getMonth()] +' ' + (today.getDate()) +', ' + (today.getFullYear());
};
 export const dateConverterEnglish = date => {
  const today = new Date(date);
      const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',];
      
      return (today.getDate()) + ' ' + month[today.getMonth()] + ', ' + (today.getFullYear());
};
 export const dateConverterDay= date => {
      const today = new Date(date);
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return days[today.getDay()];
};
      

 export const dateConverterSortMonthEnglish = date => {
  const today = new Date(date);
      const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];
      
      return (today.getDate()) + ' ' + month[today.getMonth()] + ', ' + (today.getFullYear());
};

export const dateConverterEnglishWithOutYear = date => {
  const today = new Date(date);
      const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',];

      let convertDate;
      if(today.getDate() < 10){
        convertDate = '0'+today.getDate();
      }else{
        convertDate = today.getDate();
      }
      
      return convertDate + ' ' + month[today.getMonth()];
};

 /* date formate converter */
 export const getMonth = value => {
      const month = ['জানুয়ারী', 'ফেব্রুয়ারী', 'মার্চ', 'এপ্রিল', 'মে', 'জুন', 'জুলাই', 'আগস্ট', 'সেপ্টেম্বর', 'অক্টোবর', 'নভেম্বর', 'ডিসেম্বর',];
      
      return month[value-1];
};

export const getTimeHoursMin = date => {
  /* let milliSeconds = Date.parse(new Date(date));
  */
  let converInMili = Math.abs(new Date() - new Date(date));
  let convertInMin = converInMili / 60000;

  if(convertInMin > 60 && convertInMin < 1440 ){
    let convertInHours = (convertInMin / 60);
    let convertInmMin = (convertInMin % 60);
    return {
      hour: convertInHours | 0,
      min: convertInmMin | 0,
      convertBy: 'h',
    }
  } else if(convertInMin < 60){
    return {
      min: convertInMin | 0,
      convertBy: 'm',
    }
  }
  else{
    let convertInDay = convertInMin/1440
    return {
      day: convertInDay | 0,
      convertBy: 'd',
    }
  }

};

 /* event date convert */
 export const eventDate = date => {
 
      let clickedDate = new Date(date);
      return((clickedDate.getFullYear())+ '-' + (clickedDate.getMonth()+1) + '-' +(clickedDate.getDate()));
};


 /* Assessment Name */
 export const dayDateMonth = givenDate =>{

    const today = new Date(givenDate);
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const date = today.getDate(); 
    const month =  months[today.getMonth()]; 
    const day = today.toLocaleDateString('en-US', {weekday: 'short'});
    return {
        date,
        month,
        day
      };

  
}

/* welcome section */
export const welComeSection = hr =>{
  if (hr >= 0 && hr < 12) {
    return 'শুভ সকাল !'
} else if (hr >= 12 && hr <= 17) {
  return 'শুভ অপরাহ্ন !'
} else {
  return 'শুভ সন্ধ্যা !'
}
}


/* get time with am or pm */
export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();    
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours %= 12;
  hours = hours || 12;    
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  hours = hours < 10 ? `0${hours}` : hours;

  const strTime = `${hours}:${minutes} ${ampm}`;

  return strTime;
};
/* get time with am or pm */
export const formatAMPMDate = (time) => {
    let timeParts = time.split(":"); // split the time string by colon
  timeParts.pop(); // remove the last element (representing seconds)
  let hours = parseInt(timeParts[0]); // extract the hours part
  let minutes = parseInt(timeParts[1]); // extract the minutes part
  let ampm = hours >= 12 ? "PM" : "AM"; // determine AM/PM designation
  hours = hours % 12 || 12; // convert hours to 12-hour format
  let formattedTime = hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm; // format the time string
  return formattedTime; // return the formatted time string
};







 /* Assessment Type */
 export const topicType = assessment_type =>{
  switch(assessment_type) {
     case 1:
       return <>
               <img src={lessonAssignmentIcon} alt="" /> 
               <p className='mb-0 ms-2'>এসাইনমেন্ট</p>
             </> ;
     case 2:
       return <>
               <img src={lessonExamIcon} alt="" /> 
               <p className='mb-0 ms-2'>পরীক্ষা</p>
             </> ;
     case 3:
       return  <>
                 <img src={lessonQuizIcon} className='video-quiz-icon' alt="" /> 
                 <p className='mb-0 ms-2'> কুইজ</p>
               </> ;
     case 4:
       return  <>
                 <img src={lessonLiveClassIcon} className='video-lecture-icon bg-white' alt="" /> 
                 <p className='mb-0 ms-2'>লাইভ ক্লাস</p>
               </>;
     case 5:
       return  <>
                 <img src={lessonSmartNoteIcon} className='video-smart-note-icon' alt="" /> 
                 <p className='mb-0 ms-2'>স্মার্ট নোট</p>
               </>;
     case 6:
      return  <>
                <img src={lessonVideoLectureIcon} className='video-lecture-icon' alt="" /> 
                <p className='mb-0 ms-2'>ভিডিও লেকচার</p>
              </>;
     case 7:
       return <>
                <img src={lessonModelTestIcon} alt="" /> 
                <p className='mb-0 ms-2'>মডেল টেস্ট</p>
              </> ;
     default:
       return ''
   }
}

 /* Assessment Type */
 export const topicTypeImage = assessment_type =>{
  switch(assessment_type) {
    case 1:
      return  <img src={lessonAssignmentIcon} alt="" />  ;
    case 2:
      return <img src={lessonExamIcon} alt="" /> ;
    case 3:
      return <img src={lessonQuizIcon} className='video-quiz-icon' alt="" /> ;
    case 4:
      return <img src={lessonLiveClassIcon} className='video-lecture-icon bg-white' alt="" /> ;
    case 5:
      return <img src={lessonSmartNoteIcon} className='video-smart-note-icon' style={{ borderRadius:'18px' }} alt="" />;
    case 6:
     return  <img src={lessonVideoLectureIcon} className='video-lecture-icon' alt="" />;
    case 7:
      return <img src={lessonModelTestIcon} alt="" /> ;
    default:
      return ''
  }
}
 /* Assessment Name */
 export const topicTypeName = assessment_type =>{
  switch(assessment_type) {
    case 1:
      return {
        name : 'এসাইনমেন্ট',
        img: lessonAssignmentIcon,
        color_class: '#D6AE3D'
      };
    case 2:
      return {
        name : 'পরীক্ষা',
        img: lessonExamIcon,
        color_class: '#D53E4F'
      };
    case 3:
      return {
        name : 'কুইজ',
        img: lessonQuizIcon,
        color_class: '#FDAE61'
      };
    case 4:
      return {
        name : 'লাইভ ক্লাস',
        img: lessonLiveClassIcon,
        color_class: '#66C2A5'
      };
    case 5:
      return {
        name : 'স্মার্ট নোট',
        img: lessonSmartNoteIcon,
        color_class: '#6EC063'
      };
    case 6:
      return {
        name : 'ভিডিও লেকচার',
        img: lessonVideoLectureIcon,
        color_class:'#3288BD'
      };
    case 7:
      return {
        name : 'মডেল টেস্ট',
        img: lessonModelTestIcon,
        color_class: '#F46D43'
      };
    default:
      return ''
  }
}



/* Assignment Question Type */
export const questionType = question_type =>{
  switch(question_type) {
     case 1:
       return 'Multiple Choice ' ;
     case 2:
       return 'Multiple Answer ' ;
     case 3:
       return 'True False ' ;
     case 4:
       return 'Broad Question ' ;
     case 5:
       return 'File Submission' ;
     default:
       return ''
   }
}

 //submission_selection
 export const acceptanceType = acceptance_type =>{
  switch(acceptance_type) {
     case 1:
       return 'First Submission' ;
     case 2:
       return 'Last Submission' ;
     case 3:
       return 'Average Marks' ;
     case 4:
       return 'BestObtain Marks' ;
     default:
       return ''
   }
}


export const fileSeperate = filePath =>{
  let pathArray = filePath.split(",");
  return pathArray;
}

// defaultOptions
export const defaultHeaders = {
  method:'GET',
  headers: {
        'content-type' : 'application/json',
        'accept' : 'application/json',
        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
    },
  };







