import React from "react";
import CallImage from "../../../../images/leadCollectImage/MojaruWebCCR.png";
import "./FormFooter.css";
import {useTranslation} from "react-i18next";

const FormFooter = () => {
    const {t} = useTranslation();
  return (
    <div className="">
      <div className="tw-bg-[#542E80] tw-rounded-3xl">
        <div className="tw-grid md:tw-grid-cols-1 lg:tw-grid-cols-2">
          {/* Content  */}
          <div className=" tw-w-full tw-order-2 lg:md:tw-order-1 tw-p-10 md:tw-rounded-bl-3xl md:tw-rounded-tl-2xl tw-flex tw-justify-center tw-items-center">
            <div className="">
              <h3 className="tw-text-white tw-mb-2 tw-text-3xl tw-font-bold">
                  {t("For any assistance, call Mojaru")}
              </h3>
              <p className="tw-text-3xl tw-font-bold tw-text-[#FFCE39]">
                  {t("(9 AM - 10 PM)")}
              </p>
              <p className="tw-text-white tw-mb-3 tw-mt-3">
                  {t("For any inquiries related to Mojaru, reach out to our helpline")}
              </p>
              <div className="tw-flex tw-items-center tw-max-w-[200px] tw-bg-[#FFCE39] tw-py-3 tw-px-4 tw-rounded-3xl">
                <svg
                  className="tw-ml-2 tw-mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M17.9241 2.61722C17.8757 2.50014 17.804 2.3904 17.7092 2.29502C17.7078 2.2936 17.7064 2.29219 17.705 2.29078C17.5242 2.11106 17.2751 2 17 2H13C12.4477 2 12 2.44772 12 3C12 3.55228 12.4477 4 13 4H14.5858L11.2929 7.29289C10.9024 7.68342 10.9024 8.31658 11.2929 8.70711C11.6834 9.09763 12.3166 9.09763 12.7071 8.70711L16 5.41421V7C16 7.55228 16.4477 8 17 8C17.5523 8 18 7.55228 18 7V3C18 2.86441 17.973 2.73512 17.9241 2.61722Z"
                    fill="#6B7280"
                  />
                  <path
                    d="M2 3C2 2.44772 2.44772 2 3 2H5.15287C5.64171 2 6.0589 2.35341 6.13927 2.8356L6.87858 7.27147C6.95075 7.70451 6.73206 8.13397 6.3394 8.3303L4.79126 9.10437C5.90756 11.8783 8.12168 14.0924 10.8956 15.2087L11.6697 13.6606C11.866 13.2679 12.2955 13.0492 12.7285 13.1214L17.1644 13.8607C17.6466 13.9411 18 14.3583 18 14.8471V17C18 17.5523 17.5523 18 17 18H15C7.8203 18 2 12.1797 2 5V3Z"
                    fill="#6B7280"
                  />
                </svg>
                <a className="tw-text-lg" href="tel:09610990880">
                  09610990880
                </a>
              </div>
            </div>
          </div>

          {/* image */}
          <div className=" tw-w-full tw-h-full tw-order-1 lg:md:tw-order-2">
          <img
              className="tw-w-full tw-h-full tw-rounded-t-3xl tw-h-auto lg:tw-rounded-l-none md:tw-rounded-br-3xl md:tw-rounded-tr-2xl tw-object-cover"
              src={CallImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormFooter;
