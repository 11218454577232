import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import TagManager from "react-gtm-module";

const queryClient = new QueryClient();

const tagManagerArgs = {
  gtmId: "GTM-WQLQXZR5",
};

const originalConsoleError = console.error;
console.error = (...args) => {
  if (args[0] && args[0].includes("ResizeObserver loop limit exceeded")) {
    return; // Ignore ResizeObserver error
  }
  originalConsoleError(...args);
};
TagManager.initialize(tagManagerArgs);
//   ReactPixel.init('YOUR_PIXEL_ID', options);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
    .then((registration) => {
      console.log("Service Worker registered with scope:");
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </HelmetProvider>
);

reportWebVitals();
