import React from "react";
import { Link, useLocation } from "react-router-dom";
import dot from "../../../../images/HomePageImage/Dot.svg";
import { HiArrowNarrowRight, HiUsers, HiVideoCamera } from "react-icons/hi";

const SearchCourses = () => {
  const location = useLocation();
  const { searchResults } = location.state || {};
  const data = searchResults?.data;
  const imagePath = data?.course_image_path;
  const courses = data?.courses;

  return (
    // <div className="tw-max-w-[1440px] tw-mt-20 xl:tw-mt-32 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-col-span-3 tw-mx-auto tw-justify-center tw-items-center md:tw-ml-8">

    <div className="tw-max-w-[1440px] tw-mx-auto tw-mt-28 xl:tw-mt-32 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-5 tw-my-20">
      {courses?.map((course) => (
        <Link
          to={`/${course?.course_category_link}/${course?.course_slug}`}
          state={{ course_id: `${course?.id}` }}
          className="tw-flex tw-justify-center"
        >
          {/* <div className="tw-w-full tw-h-auto md:tw-max-w-[360px] md:tw-h-[398px] md:tw-bg-white tw-p-4 md:tw-p-0 tw-rounded-3xl tw-shadow-lg "> */}

          <div className="tw-w-full tw-h-auto tw-max-w-[360px] md:tw-h-[398px] md:tw-bg-white tw-p-4 md:tw-p-0 tw-rounded-3xl tw-shadow-lg ">
            <img
              className="tw-w-full tw-h-[192px] tw-rounded-tr-3xl tw-rounded-tl-3xl"
              src={imagePath + course?.course_picture}
              alt="course"
            />
            <div className="tw-w-full tw-bg-white tw-rounded-bl-3xl tw-rounded-br-3xl tw-p-6">
              <div className="tw-flex">
                <div className="tw-flex tw-justify-center tw-items-center tw-bg-[#FEE2E2] tw-px-3 tw-rounded-3xl">
                  <img className="tw-w-2 tw-h-2 tw-mr-2" src={dot} alt="logo" />
                  <span className="tw-text-red-800">{course?.course_mode}</span>
                </div>
                {course?.course_total_duration_in_days && (
                  <span className="tw-ml-8 tw-bg-gray-100 tw-px-3 tw-rounded-3xl tw-text-gray-700">
                    {course?.course_total_duration_in_days}
                  </span>
                )}
              </div>
              <h2 className="tw-text-lg tw-text-gray-700 tw-font-bold tw-mt-4">
                {course?.course_name}
              </h2>
              <div className="tw-flex">
                <p className="tw-flex tw-items-center tw-mr-4 tw-text-gray-500">
                  <HiVideoCamera className="tw-mr-2 tw-text-gray-400" />{" "}
                  {course?.course_total_live_class} Live class
                </p>
                <p className="tw-flex tw-items-center tw-text-gray-500">
                  <HiUsers className="tw-mr-2 tw-text-gray-400" />{" "}
                  {course?.students_count} Students
                </p>
              </div>
              <div className="tw-w-full tw-flex tw-justify-between tw-mt-4">
                {course?.course_payment_segments?.course_final_price ? (
                  <span className="tw-text-lg tw-font-bold tw-text-green-500">
                    ৳{course?.course_payment_segments?.course_final_price}
                  </span>
                ) : (
                  <span className="tw-text-lg tw-font-bold tw-text-green-500">
                    ফ্রি কোর্স
                  </span>
                )}
                <button className="tw-flex tw-items-center tw-font-bold tw-text-blue-800">
                  <span className="tw-mr-2">বিস্তারিত</span>
                  <HiArrowNarrowRight />
                </button>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SearchCourses;
