import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";

const geoUrl = "https://unpkg.com/world-atlas@2.0.2/countries-110m.json";

const markers = [
  {
    markerOffset: -30,
    name: "Argentina",
    coordinates: [-58.3816, -34.6037],
  },
  { markerOffset: -25, name: "Bangladesh", coordinates: [90.4074, 23.7104] },
  { markerOffset: 15, name: "India", coordinates: [72.8826, 19.0728] },
  { markerOffset: 15, name: "England", coordinates: [-0.118092, 51.509865] },
  { markerOffset: 15, name: "United States", coordinates: [-74.006, 40.7128] },
  { markerOffset: -25, name: "Iran", coordinates: [51.4215, 35.6944] },
  { markerOffset: 15, name: "Russia", coordinates: [37.6156, 55.7522] },
  { markerOffset: 15, name: "Australia", coordinates: [151.2099, -33.8651] },
  { markerOffset: 10, name: "Egypt", coordinates: [31.2497, 30.0626] },
  {
    markerOffset: 9,
    name: "Saudi Arabia",
    coordinates: [46.738586, 24.774265],
  },
  { markerOffset: 10, name: "Italy", coordinates: [41.902782, 12.496366] },
];

const MapChart = () => {
  return (
    <ComposableMap
      projection="geoMercator"
      projectionConfig={{
        rotate: [-10, 0, 0],
        scale: 150,
      }}
    >
      {/* <ZoomableGroup center={[0, 0]} zoom={9}> */}
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#C4B5FD"
              stroke="#FFFFFF"
              strokeWidth={0.8}
              style={{
                default: {
                  fill: "#C4B5FD",
                },
                hover: {
                  fill: "#FFCE39",
                },
                pressed: {
                  fill: "#FFCE39",
                },
              }}
            />
          ))
        }
      </Geographies>
      {markers.map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <g
            fill="#FFCE39"
            stroke="#6F39B7"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-12, -24)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{
              fontFamily: "system-ui",
              fill: "#5D5A6D",
              fontSize: "12px",
            }}
          >
            {name}
          </text>
        </Marker>
      ))}
      {/* </ZoomableGroup> */}
    </ComposableMap>
  );
};

export default MapChart;
