import React,{ useEffect, useState, useContext }from 'react';
import { englishToBengaliNumberFormat } from '../../../../../utilities/utilities';
import { LMSContext } from '../LMS';

import allAssignment from '../../../../../images/all-assignment.png';
import bookIcon from '../../../../../images/icon_book.webp';
import Lecture from './Lecture/Lecture';

const Lectures = () => {
    const[lectures, setLectures] = useState([]);
    const {batch_id, course_id} = useContext(LMSContext);
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}video-lectures?course_id=${course_id}&batch_id=${batch_id}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            .then((res) => res.json())
            .then((data) => setLectures(data.data));
    },[course_id, batch_id]);

    const [imageLoadedMap, setImageLoadedMap] = useState({});
    const handleImageLoad = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: true
        }));
    };

    const handleImageError = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: false
        }));
    };
    
    
    
    return (

        <div className="row gx-5 mx-0">


            <div className=' col-lg-3 lms-subject-section'>
                <div className="nav flex-column nav-pills assignment-subject p-2 bg-white custom-card-shadow mb-4" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ borderRadius:'16px' }}>
                    <button className="nav-link text-dark active" id="v-pills-home-lecture-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home-lecture" type="button" role="tab" aria-controls="v-pills-home-lecture" aria-selected="true">
                    <img src={allAssignment} alt="" width={48} height={48} className='me-2' style={{ borderRadius: '15px' }}/>
                    সকল বিষয়
                    </button>
                    {
                        lectures?.course_subjects?.map((course_subject, index) => 
                            <button key={course_subject?.course_subject_id} className="nav-link text-dark mt-2" id={`v-pills-lecture-subject_${course_subject?.course_subject_id}-tab`} data-bs-toggle="pill" data-bs-target={`#v-pills-lecture-subject_${course_subject?.course_subject_id}`} type="button" role="tab" aria-controls={`v-pills-lecture-subject_${course_subject?.course_subject_id}`} aria-selected="false">
                                {/* <img src={`${lectures?.subject_image_path}${course_subject?.subject_image}`} alt="" width={48} height={48} className='me-2' style={{ borderRadius: '8px' }}/> */}
                                {imageLoadedMap[index] !== false && (
                                <img
                                    src={`${lectures?.subject_image_path}${course_subject?.subject_image}`}
                                    width={48} height={48} alt=""
                                    className="px-1"
                                    onLoad={() => handleImageLoad(index)}
                                    onError={() => handleImageError(index)}
                                />
                                )}
                                {imageLoadedMap[index] === false && <img
                                    src={bookIcon}
                                    width={48} height={48} alt=""
                                />}
                                {course_subject?.subject_name}
                            </button>

                        )
                    }
                    
                </div>
            </div>
            <div className="col-lg-9 px-0 mx-0 tab-content bg-white custom-card-shadow all-assignment-section" id="v-pills-tabContent">
        {/* <div className='bg-white custom-card-shadow all-assignment-section'> */}
                <div className="tab-pane fade show active" id="v-pills-home-lecture" role="tabpanel" aria-labelledby="v-pills-home-lecture-tab">
                    
                        <p className='course-total-assignment mb-0'><span className='balooda-medium'>{englishToBengaliNumberFormat(lectures?.video_lectures?.length)}</span> টি লেকচার</p>
                        <div className='total-assignment-divider'></div>

                            <div className='row mt-4 p-3 g-5 mx-0'>
                            {   lectures?.length === 0 ? <p className="d-flex justify-content-center mb-0 w-100" style={{ fontSize: "22px", fontWeight: "600" }}>
                                তোমাকে এখনো কোন লেকচার দেওয়া হয়নি </p> :
                                lectures?.video_lectures?.map(lecture => <Lecture key={lecture.id} lecture={lecture}/> )
                            }
                        </div>
                        <p className='text-center mb-4' style={{ color: '#BDBCBE' }}>আপনি তালিকার শেষে পৌঁছে গিয়েছেন</p>
                    
                </div>
                
                {
                    lectures?.course_subjects?.map(course_subject => {
                        return(
                            <div key={course_subject?.course_subject_id} className="tab-pane fade" id={`v-pills-lecture-subject_${course_subject?.course_subject_id}`} role="tabpanel" aria-labelledby={`v-pills-lecture-subject_${course_subject?.course_subject_id}-tab`}>
                            <p className='course-total-assignment mb-0'>{course_subject?.subject_name} লেকচার</p>
                            <div className='total-assignment-divider'></div>
                                <div className='row mt-4 p-3 g-5 mx-0'>
                                {
                                    lectures?.video_lectures?.map(lecture => {
                                        if(course_subject?.course_subject_id === lecture?.course_subject_id) {
                                            return <Lecture key={lecture.id} lecture={lecture}/>
                                        }
                                        return null;
                                        
                                    } )
                                }
                                </div>
                            <p className='text-center mb-4' style={{ color: '#BDBCBE' }}>আপনি তালিকার শেষে পৌঁছে গিয়েছেন</p>
                        </div>
                        )
                    }
                        
                    )
                }
            
            </div> 
        </div> 
   
        
    );
};

export default Lectures;