import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import image1 from "../../../../../../images/HomePageImage/goalSet/abacus.png";
import image2 from "../../../../../../images/HomePageImage/goalSet/mathOlypiad.png";
import image3 from "../../../../../../images/HomePageImage/goalSet/Moral.png";
import  image4 from "../../../../../../images/HomePageImage/goalSet/smartEnglish.png";
import image5 from "../../../../../../images/HomePageImage/goalSet/missionCadet.png";
import image6 from "../../../../../../images/HomePageImage/goalSet/Programmig.png";
import image7 from "../../../../../../images/HomePageImage/goalSet/academic.png";
import image8 from "../../../../../../images/HomePageImage/goalSet/Islamic.png";
import image9 from "../../../../../../images/HomePageImage/goalSet/others.png";
import { HiExternalLink } from "react-icons/hi";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const PopularCourse = ({handleFocus, id}) => {
    const {t} = useTranslation();
    const categories = [
        {
            id: 1,
            courseName: `${t("Pre- Schooling")}`,
            courseIcon: image3,
            bg: 'bg-pink-50',
            categoryId: 35
        },
        {
            id: 2,
            courseName: `${t("Abacus")}`,
            courseIcon: image1,
            bg: 'bg-orange-50',
            categoryId: 37
        },
        {
            id: 3,
            courseName: `${t("Maths")}`,
            courseIcon: image2,
            bg: 'bg-cyan-50',
            categoryId: 15
        },
        {
            id: 4,
            courseName: `${t("Languages - English, Bengali, Arabic")}`,
            courseIcon: image4,
            bg: 'bg-indigo-50',
            categoryId: 6
        },
        {
            id: 5,
            courseName: `${t("Programming")}`,
            courseIcon: image5,
            bg: 'bg-blue-50',
            categoryId: 9
        },
        {
            id: 6,
            courseName: `${t("Cadet Preparation")}`,
            courseIcon: image6,
            bg: 'bg-yellow-50',
            categoryId: 7
        },
        {
            id: 7,
            courseName: `${t("Academic Preparation")}`,
            courseIcon: image7,
            bg: 'bg-green-50',
            categoryId: 15
        },
        {
            id: 8,
            courseName: `${t("Islamic")}`,
            courseIcon: image8,
            bg: 'bg-red-50',
        },
        {
            id: 9,
            courseName: `${t("Others")}`,
            courseIcon: image9,
            bg: 'bg-yellow-50',
        }
    ]
    const filterCategory = categories.filter(category => category.id !== id);


  return (
    <div className="">
      {/* Heading and Button */}

      <div className="tw-grid tw-grid-cols-1 lg:tw-flex lg:tw-justify-between tw-items-center tw-gap-6 xl:tw-gap-0">
        {/* Button Div */}
        <div className="tw-h-full lg:tw-w-1/2 tw-flex-col tw-order-2 lg:tw-order-1 ">
          <button onClick={handleFocus} >
            <span className="tw-flex tw-bg-[#532D80] tw-text-white tw-font-semibold tw-py-2 tw-px-4 tw-rounded-3xl">
              {t("Join a Free Demo Class")}
              <ArrowRightIcon className="tw-w-4 tw-ml-2" />
            </span>
          </button>
        </div>

        {/* Heading Text Div */}
        <div className=" tw-order-1 xl:tw-order-2  tw-text-right tw-w-full ">
          <h2 className="tw-text-left lg:tw-text-right tw-mt-3 tw-text-3xl xl:tw-text-4xl tw-font-bold">
              {t("Choose your favorite subject")}
          </h2>
        </div>
      </div>

      {/* cards  */}
      <div
        className={`tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-8 tw-mt-6 tw-justify-center tw-items-center`}
      >
        {filterCategory.map((category, index) => (
          <>
            <div className={`tw-${category?.bg} tw-rounded-3xl`}>
              <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-6">
                <img src={category?.courseIcon} alt={category?.courseName} />
                <p className="tw-mt-6 tw-text-lg tw-font-bold">
                  {category?.courseName}
                </p>
                <Link
                  to={
                    category?.categoryId
                      ? `/all?id=${category?.categoryId}`
                      : `/all`
                  }
                >
                  <button className="tw-bg-white tw-px-6 tw-py-3 hover:tw-bg-[#532D80] hover:tw-text-white tw-rounded-3xl tw-mt-6">
                    <HiExternalLink className="" />
                  </button>
                </Link>
              </div>
            </div>
          </>
        ))}
      </div>


    </div>
  );
};

export default PopularCourse;
