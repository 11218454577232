import React from 'react';
import paymentFailed from '../../../../images/payment-failed.png';
import backHome from '../../../../images/back-icon.png';

const PaymentFailed = () => {
    console.log('payment failed')
    return (
        <div className='' style={{ height: '98vh' }}>
            <div className='d-flex justify-content-center align-items-center h-100' >
                <div>
                    <img src={paymentFailed} alt="" className='img-fluid' />
                    <p className='text-center mt-4 mb-2' style={{ fontSize:'40px', fontWeight:'700' }}>Payment Failed!</p>
                    <p className='text-center mb-5' style={{ fontSize:'20px', fontWeight:'400', color:'#7C7C7C' }}>Your payment process has been failed. Please try again.</p>
                    <div className='d-flex justify-content-center'>
                        <button style={{ backgroundColor: '#F27321', color: '#ffffff', fontSize: '20px', fontWeight: '600', border: 'none', padding: '12px 30px', borderRadius: '12px'}}><img src={backHome} alt="" /> <span className="ps-3">Back to Home</span></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentFailed;