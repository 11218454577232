import React, { useEffect, useState } from "react";
import logo from "../../../../images/logo.png";
import google_play from "../../../../images/btn_google_play.png";
import { Link } from "react-router-dom";
import "./Footer.css";
import ReactWhatsapp from "react-whatsapp";
import { englishToBengaliNumberFormat } from "../../../../utilities/utilities";
import sslWirelsss from "../../../../images/ssl-wireless.jpg";
import isoCertificate from "../../../../images/footerSocial/isocertificate.png";
import steamCertificate from "../../../../images/footerSocial/stemcertificate.png";
import { useTranslation } from "react-i18next";
import LinkSsl from "../../../../images/footerSocial/Links.png";

import { socialLinks } from "../../../../utils/Constant";

const Footer = ({ location }) => {
  const [settings, setSettings] = useState([]);
  const { t } = useTranslation();

  //setting
  useEffect(() => {
    async function fetchWebsiteSetting() {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/settings`
      );
      response = await response.json();
      setSettings(response.data.settings);
    }
    fetchWebsiteSetting();
  }, []);

  const year = new Date().getFullYear();
  const yeraBn = englishToBengaliNumberFormat(year);

  const startingYear = 2020;
  const startingYearBn = englishToBengaliNumberFormat(startingYear);

  return (
    <footer
      style={
        location === "/login" ||
        location === "/registration" ||
        location === "/user-registration" ||
        location === "/sign-up" ||
        location === "/otp" ||
        location === "/schedule/class" ||
        location === "/schedule/class-list" ||
        location === "/one-to-one" ||
        location === "/batch" ||
        location === "/eft" ||
        location === "/book-demo-class" ||
        location === "/ela"
          ? { display: "none" }
          : { display: "block" }
      }
    >
      <div className="tw-max-w-[1440px] tw-w-full tw-mx-auto tw-mt-8 xl:tw-mt-16">
        <div className="tw-w-full tw-mx-auto tw-grid tw-grid-cols-2 xl:tw-grid-cols-5 tw-grid-rows-3 md:tw-grid-rows-1 tw-gap-8 tw-justify-around tw-px-4 2xl:tw-px-0">
          {/* column 1 */}
          <div className="tw-row-start-1 tw-col-start-1 tw-col-end-[-1] xl:tw-col-start-1 xl:tw-col-end-2 ">
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center md:tw-block">
              <img className="tw-h-10" src={logo} alt="" />
            </div>
            <div className="tw-mt-5 md:tw-mt-8">
              <p className="tw-text-gray-500">{t("footer about")}</p>
            </div>
            <div className="tw-mt-6 tw-flex tw-space-x-6 tw-px-8 md:tw-px-0">
              {socialLinks.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="tw-text-gray-400 hover:tw-text-gray-500"
                  target="_blank"
                >
                  <span className="tw-sr-only">{item.name}</span>
                  <img
                    className="tw-h-6 tw-w-6"
                    src={item.icon}
                    alt={item.name}
                  />
                </a>
              ))}
            </div>
          </div>

          {/* column 2 */}

          <div className="xl:tw-col-start-2 ">
            <p className="tw-text-gray-600 tw-font-semibold">{t("Explore")}</p>
            <div className="tw-mt-5 md:tw-mt-8">
              <a
                className="tw-text-gray-500 pt-4"
                href="https://mojaru.zohorecruit.com/jobs/Careers"
                target="_blank"
                rel="noreferrer"
              >
                {t("Career")}
              </a>
            </div>
            <div className="tw-mt-1">
              <Link to="/about" className="tw-text-gray-500">
                {t("About Us")}
              </Link>
            </div>
            <div className="tw-mt-1">
              <Link to="/terms_and_condition" className="tw-text-gray-500">
                {t("Terms of Service")}
              </Link>
            </div>
            <div className="tw-mt-1">
              <Link to="/faq" className="tw-text-gray-500">
                {t("FAQ")}
              </Link>
            </div>
            <div className="tw-mt-1">
              <Link to="/e-book" className="tw-text-gray-500">
                {t("E-Book")}
              </Link>
            </div>
          </div>

          {/* column 3 */}

          <div className="xl:tw-col-start-3 ">
            <p className="tw-text-gray-600 tw-font-semibold">{t("Support")}</p>
            <div className="tw-mt-5 md:tw-mt-8">
              <Link to="/refund" className="tw-text-gray-500">
                {t("Refund and Return Policy")}
              </Link>
            </div>
            <div className="tw-mt-1">
              <Link to="/privacy_policy" className="tw-text-gray-500">
                {t("Privacy Policy")}
              </Link>
            </div>
            <div className="tw-mt-1">
              <Link to="/blog" className="tw-text-gray-500">
                {t("Blogs")}
              </Link>
            </div>
            <div className="tw-mt-1">
              <Link to="/contact" className="tw-text-gray-500 ">
                {t("Contact Us")}
              </Link>
            </div>
            <div className="tw-mt-1">
              <Link to="/b2b" className="tw-text-gray-500">
                {t("B2B")}
              </Link>
            </div>
          </div>

          {/* column 4 */}
          {/* <div className="tw-hidden md:tw-flex tw-justify-start tw-items-start tw-bg-green-300"> */}
          <div className="tw-flex tw-justify-start tw-items-start ">
            {/* <div className="tw-mt-5 md:tw-mt-8"> */}
            <div className="">
              <p className="tw-text-gray-600 tw-font-semibold">
                {t("Certificate")}
              </p>
              <div className="tw-mt-5 md:tw-mt-8">
                <img
                  src={steamCertificate}
                  alt="Iso Certification"
                  className="tw-h-[70px] tw-w-[155px]"
                />
              </div>

              {/* <div className="tw-mt-1">
                <img
                  src={isoCertificate}
                  alt="Iso Certification"
                  className="tw-h-[70px] tw-w-[70px]"
                />
              </div> */}
            </div>
          </div>

          {/* column 5 */}
          {/* <div className="tw-col-span-2 md:tw-col-span-1 md:tw-col-start-4 md:tw-col-end-[-1] xl:tw-col-start-5 tw-bg-fuchsia-300"> */}
          <div className="md:tw-col-span-1 md:tw-col-start-4 md:tw-col-end-[-1] xl:tw-col-start-5 ">
            <div className="">
              <div>
                <p className="tw-text-gray-600 tw-font-semibold ">
                  Pay with SSL commerce
                </p>
                <img className="tw-mt-5 md:tw-mt-8" src={LinkSsl} alt="ssl" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Copy write  */}
      <div className="tw-border-b tw-mt-4"></div>
      <div>
        <p className=" tw-text-lg tw-text-gray-400 tw-text-center tw-py-8">
          {t("Copyright")}{" "}
          {sessionStorage.getItem("useLanguage") === "bn"
            ? startingYearBn
            : startingYear}{" "}
          - {sessionStorage.getItem("useLanguage") === "bn" ? yeraBn : year}{" "}
          {t("© Mojaru Education Technologies Limited. All rights reserved.")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
