import React from "react";
import "./Review.css";
import dummyImage from "../../../../../../../images/Default Person Avatar.png";
import {
  dateConverter,
  formatAMPM,
} from "../../../../../../../utilities/utilities";
import ShowRating from "../../../../Rating/ShowRating";

const Review = ({ review, path }) => {
  return (
    <div className="review-section">
      <div className="">
        <div className="">
          <div className="">
            <div className="tw-flex tw-items-center">
              <ShowRating rating={review.ratings} />
              <p
                className="mb-0 progress-bar-title ms-2"
                style={{ color: "#7C7C7C" }}
              >
                ({review.ratings})
              </p>
            </div>
            <div className="review">{review.review_detail}</div>
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-mt-6">
          <div className="">
            {review.profile_picture !== undefined &&
            review.profile_picture !== null ? (
              <img
                src={`${path}${review.profile_picture}`}
                alt=""
                className="tw-w-12 tw-h-12 tw-rounded-full tw-mr-3 tw-object-cover"
              />
            ) : (
              <img
                src={dummyImage}
                className="tw-w-12 tw-h-12 tw-rounded-full tw-mr-3 tw-object-cover"
                alt=""
                id="course-img"
              />
            )}
          </div>

          <div className="">
            <div className="reviewer_name">{review.user_name}</div>
            <div className="review_date">
              {dateConverter(review.created_at)} &nbsp;{" "}
              {formatAMPM(new Date(review.created_at))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
