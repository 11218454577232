import React from "react";
import { englishToBengaliNumberFormat, topicType } from "../../../../../../../utilities/utilities";
import './TotalClass.css';

const TotalClass = ({ topic, topicSerial, LessonSerial }) => {
    return (
        <tr>
            <td className="topic-serial balooda-regular">
                {englishToBengaliNumberFormat(LessonSerial + 1)}.
                {englishToBengaliNumberFormat(topicSerial + 1)}
            </td>
            <td className="topic-category" style={{ width: "12rem" }}>
                <div className="d-flex align-items-center">
                    {topicType(topic?.assesment_type)}
                </div>
            </td>
            <td className="topic-serial">:</td>
            <td className="topic-details">{topic?.topic_name}</td>
        </tr>
    );
};

export default TotalClass;
