import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import noPerson from '../../../../../images/blank.png';
import watermarkLogo from '../../../../../images/m_watermark.png';
import logo from '../../../../../images/dashboard-logo.png';
import { eventDate } from '../../../../../utilities/utilities';
import { toast } from 'react-toastify';
import PageTitle from '../../../shared/PageTitle/PageTitle';

const PaidMarketingStudentInformation = () => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [image, setImage] = useState('');
    const [file, setFile] = useState();
    const [isChangeImage, setIsChangeImage] = useState(false);
    const [startDate, setStartDate] = useState();
    const [selectedMediumOption, setSelectedMediumOption] = useState('Bangla');
    const [selectedGenderOption, setSelectedGenderOption] = useState('Male');
    const [inputMobileNumber, setInputMobileNumber] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);




    const handleChange = (e) => {
        setImage(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
        setIsChangeImage(true);
    }

    function handleInputChange(event) {
        const newValue = event.target.value.replace(/[^0-9]/g, ''); 
        setInputMobileNumber(newValue);
    }

   

    const handleMediumOptionChange = (event) => {
        setSelectedMediumOption(event.target.value);
    };

    const handleGenderOptionChange = (event) => {
        setSelectedGenderOption(event.target.value);
    };

    function handleCheckboxChange(event) {
        const value = event.target.value;
        if (event.target.checked) {
          setSelectedValues([...selectedValues, value]);
        } else {
          setSelectedValues(selectedValues.filter(v => v !== value));
        }
      }

    const handelStudentInfo = (e) => {
        e.preventDefault();
        let data = new FormData();
        data.append('children_image', image);
        data.append('parents_name', e.target.parents_name.value);
        data.append('email_address', e.target.email_address.value);
        data.append('whatsapp_number', e.target.whatsapp_number.value);
        data.append('children_medium', e.target.children_medium.value);
        data.append('interested_course', selectedValues.join(','));
        data.append('children_birth_date', eventDate(startDate));
        data.append('children_gender', e.target.children_gender.value);
        data.append('children_religion', e.target.children_religion.value);
        data.append('know_about_mojaru', e.target.know_about_mojaru.value);
        data.append('guardian_profession', e.target.guardian_profession.value);

        const currentAddress = e.target.current_address.value;
        if (!currentAddress) {
            toast.error('Please enter children name');
            return;
        }
        data.append('current_address', currentAddress);

        if (!inputMobileNumber) {
            toast.error('Please enter mobile number');
            return;
        }
        data.append('phone_number', inputMobileNumber);

        const childrenName = e.target.children_name.value;
        if (!childrenName) {
            toast.error('Please enter children name');
            return;
        }
        data.append('children_name', childrenName);

        const childrenClass = e.target.children_class.value;
        if (!childrenClass) {
            toast.error('Please enter children class');
            return;
        }
        data.append('children_class', childrenClass);

        const childrenAge = e.target.children_age.value;
        if (!childrenAge) {
            toast.error('Please enter children age');
            return;
        }
        data.append('children_age', childrenAge);
        setBtnLoading(true);
    
    
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}paid-marketing-student-form-save`, {
            method: 'POST',
            body: data,
          }).then((res) => res.json())
            .then((data) => {
              if (data.message_type === 'success') {
                toast.success(data.message);
                e.target.reset();
                setInputMobileNumber();
                setFile();
                setStartDate();
                setBtnLoading(false);
              } else {
                toast.error(data.message);
                setBtnLoading(false);
              }
            })
            .catch((error) => {
              console.error('Error:', error);
              toast.error('An error occurred while submitting the form. Please try again later.');
            });
    }



    return (
        <section className='container'>
        <PageTitle title='Paid Marketing Student Information Form'></PageTitle>

        <div className='mt-4 mb-2 d-flex justify-content-between align-items-center'>
            <p className='lead-collect-title mb-0'>নিচের ফর্মটি পূরণ করুন</p>
            <img src={logo} alt="" className='img-fluid'/>
        </div>
        
        <div className='mb-5'>
        <img src={watermarkLogo} alt="Watermark Logo" className="watermark-logo" />
            <div>
                <p className='mb-2 font-400'>তোমার ছবি<span className='text-danger'> (Selfie not acceptable)</span></p>
                <div className="avatar-upload">
                    <div className="avatar-edit">
                        <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"  onChange={handleChange} />
                        <label htmlFor="imageUpload"></label> 
                    </div>
                    <div className="avatar-preview">

                        {
                            file ? 
                                <img className="profile-user-img img-responsive" id="imagePreview" src={file} alt="User profile "/>
                                : 
                                <img className="profile-user-img img-responsive" id="imagePreview" src={noPerson} alt="User profile "/>
                            
                        }

                    </div>
                </div>
            </div>
            <form onSubmit={handelStudentInfo}>
            <div className="row">
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>অভিভাবকের নাম</p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="parents_name" type="text" placeholder="অভিভাবকের নাম লিখুন" />
                </div>
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>ফোন নাম্বার<span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="phone_number" type="text" placeholder="ফোন নাম্বার লিখুন" value={inputMobileNumber} onChange={handleInputChange} />
                </div>
                
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>বর্তমান ঠিকানা<span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="current_address" type="text" placeholder="বর্তমান ঠিকানা লিখুন" />
                </div>
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>ই-মেইল অ্যাড্রেস</p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="email_address" type="text" placeholder="ই-মেইল অ্যাড্রেস লিখুন" />
                </div>
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>হোয়াটস্যাপ নাম্বার</p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="whatsapp_number" type="text" placeholder="হোয়াটস্যাপ নাম্বার লিখুন" />
                </div>
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>সন্তানের নাম<span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="children_name" type="text" placeholder="সন্তানের নাম লিখুন " />
                </div>
                <div className="col-lg-4">
                    <p className='mb-2 font-400'>আপনার সন্তান কোন ক্লাসে পড়ে<span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="children_class" type="text" placeholder="ক্লাস" />
                </div>
                <div className="col-lg-4">
                    <p className='mb-2 font-400'>আপনার সন্তানের বয়স<span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="children_age" type="number" placeholder="সন্তানের বয়স লিখুন"/>
                </div>
                <div className="col-lg-4">
                    <p className='mb-2 font-400'>জন্ম তারিখ</p>
                    <DatePicker showYearDropdown  yearDropdownItemNumber={40} scrollableYearDropdown className="form-control form-control-lg student-profile-info" selected={startDate} onChange={(date) => setStartDate(date)} placeholderText={'তোমার জন্ম তারিখটি দাও'} />
                </div>

                <div className="col-lg-6 mb-2">
                    <p className="mb-2 font-400">
                        মিডিয়াম
                    </p>
                    <div className="d-flex align-items-center">
                        <div className="radio">
                        <label style={{ fontWeight: '500', cursor: 'pointer' }}>
                            <input
                            type="radio"
                            name="children_medium"
                            id="bangla"
                            value="Bangla"
                            checked={selectedMediumOption === 'Bangla'}
                            onChange={handleMediumOptionChange}
                            />
                            &nbsp;&nbsp;বাংলা
                        </label>
                        <br />
                        <label style={{ fontWeight: '500', cursor: 'pointer' }}>
                            <input
                            type="radio"
                            name="children_medium"
                            id="english"
                            value="English"
                            checked={selectedMediumOption === 'English'}
                            onChange={handleMediumOptionChange}
                            />
                            &nbsp;&nbsp;ইংরেজি
                        </label>
                        <br />
                        <label style={{ fontWeight: '500', cursor: 'pointer' }}>
                            <input
                            type="radio"
                            name="children_medium"
                            id="madrasa"
                            value="Madrasa"
                            checked={selectedMediumOption === 'Madrasa'}
                            onChange={handleMediumOptionChange}
                            />
                            &nbsp;&nbsp;মাদ্রাসা
                        </label>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 mb-2">
                    <p className='mb-2 font-400'>লিঙ্গ</p>
                    <div className='d-flex align-items-center'>

                        <div className="radio">
                            <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                <input type="radio" name="children_gender" id="boy" value="Male"
                                checked={selectedGenderOption === 'Male'}
                                onChange={handleGenderOptionChange}
                                 />
                                &nbsp;&nbsp;পুরুষ
                            </label><br />
                            <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                <input type="radio" name="children_gender" id="girl" value="Female"
                                checked={selectedGenderOption === 'Female'}
                                onChange={handleGenderOptionChange}
                                />
                                &nbsp;&nbsp;মহিলা
                            </label><br />
                            <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                <input type="radio" name="children_gender" id="other" value="Other" 
                                checked={selectedGenderOption === 'Other'}
                                onChange={handleGenderOptionChange}
                                />
                                &nbsp;&nbsp;অন্যান্য
                            </label>
                        </div>
                    </div> 
                </div>

                <div className="col-lg-6">
                    <p className='mb-2 font-400'>ধর্ম</p>
                    <select name="children_religion" className="form-select form-control form-control-lg student-profile-info" id="select_box" >
                        <option value="">তোমার ধর্ম বাছাই করো</option>
                        <option value="Muslim">ইসলাম ধর্মাবলম্বী</option>
                        <option value="Hindu">হিন্দু ধর্মাবলম্বী</option>
                        <option value="Cristian">ক্রিস্টিয়ান ধর্মাবলম্বী</option>
                        <option value="Buddhist">বৌদ্ধ ধর্মাবলম্বী</option>
                        <option value="Other" >অন্যান্য</option> 
                    </select>
                </div>

                <div className="col-lg-6 mb-2">
                    <p className='mb-2 font-400'>মজারু সম্পর্কে আপনি কথাথেকে জেনেছেন</p>
                    <select name="know_about_mojaru" className="form-select form-control form-control-lg student-profile-info" id="select_box" >
                        <option value="">নির্বাচন করুন</option>
                        <option value="মজারুর অন্য কোর্সে যুক্ত আছি">মজারুর অন্য কোর্সে যুক্ত আছি</option>
                        <option value="মজারু জিনিয়াস ফেসবুক গ্রুপ থেকে জেনেছি">মজারু জিনিয়াস ফেসবুক গ্রুপ থেকে জেনেছি </option>
                        <option value="পরিচিতজনদের কাছ থেকে শুনেছি">পরিচিতজনদের কাছ থেকে শুনেছি</option>
                        <option value="ফেসবুক বিজ্ঞাপন থেকে জেনেছি">ফেসবুক বিজ্ঞাপন থেকে জেনেছি</option>
                        <option value="ইউটিউব ভিডিও থেকে জেনেছি" >ইউটিউব ভিডিও থেকে জেনেছি</option>
                        <option value="হোয়াটসঅ্যাপ মেসেজের মাধমে জেনেছি" >হোয়াটসঅ্যাপ মেসেজের মাধমে জেনেছি</option> 
                    </select>
                </div>

                <div className="col-lg-12 mb-2">
                    <p className='mb-2 font-400'>অভিভাবকের পেশা</p>
                    <select name="guardian_profession" className="form-select form-control form-control-lg student-profile-info" id="select_box" >
                        <option value="">নির্বাচন করুন</option>
                        <option value="সরকারি চাকরি">সরকারি চাকরি</option>
                        <option value="বেসরকারি চাকরি">বেসরকারি চাকরি</option>
                        <option value="কলেজ/বিশ্ববিদ্যালয়ের শিক্ষক">কলেজ/বিশ্ববিদ্যালয়ের শিক্ষক</option>
                        <option value="স্কুলের শিক্ষক">স্কুলের শিক্ষক</option>
                        <option value="ডাক্তার" >ডাক্তার</option>
                        <option value="ইঞ্জিনিয়ার/স্থপতি" >ইঞ্জিনিয়ার/স্থপতি</option>
                        <option value="কৃষি কাজ" >কৃষি কাজ</option>
                        <option value="ব্যবসা" >ব্যবসা</option>
                        <option value="সাংবাদিক" >সাংবাদিক</option>
                        <option value="স্বাধীন পেশা" >স্বাধীন পেশা</option>
                        <option value="অন্যান্য" >অন্যান্য</option>
                    </select>
                </div>


                <div className="col-lg-12">
                    <div className="form-group mb-2">
                        <label className='mb-2'>আপনার সোনামণিকে মজারুতে কোন কোর্সটি করাতে চান<span className='text-danger'>*</span></label>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="অ্যাবাকাস মাইন্ড ম্যাথ" value="অ্যাবাকাস মাইন্ড ম্যাথ" onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor="অ্যাবাকাস মাইন্ড ম্যাথ">
                            অ্যাবাকাস মাইন্ড ম্যাথ
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="ক্যাডেট প্রি-প্রিপারেশন (৩য়, ৪র্থ ও ৫ম শ্রেণির শিক্ষার্থীদের জন্য)" value="ক্যাডেট প্রি-প্রিপারেশন (৩য়, ৪র্থ ও ৫ম শ্রেণির শিক্ষার্থীদের জন্য)" onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor="ক্যাডেট প্রি-প্রিপারেশন (৩য়, ৪র্থ ও ৫ম শ্রেণির শিক্ষার্থীদের জন্য)">
                            ক্যাডেট প্রি-প্রিপারেশন (৩য়, ৪র্থ ও ৫ম শ্রেণির শিক্ষার্থীদের জন্য)
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="মিশন ক্যাডেট - ২৪ (৬ষ্ঠ শ্রেণির শিক্ষার্থীদের জন্য)" value="মিশন ক্যাডেট - ২৪ (৬ষ্ঠ শ্রেণির শিক্ষার্থীদের জন্য)" onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor="মিশন ক্যাডেট - ২৪ (৬ষ্ঠ শ্রেণির শিক্ষার্থীদের জন্য)">
                            মিশন ক্যাডেট - ২৪ (৬ষ্ঠ শ্রেণির শিক্ষার্থীদের জন্য)
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="স্মার্ট ইংলিশ" value="স্মার্ট ইংলিশ" onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor="স্মার্ট ইংলিশ">
                            স্মার্ট ইংলিশ
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="ম্যাথ চ্যাম্পস" value="ম্যাথ চ্যাম্পস" onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor="ম্যাথ চ্যাম্পস">
                            ম্যাথ চ্যাম্পস
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="প্রোগ্রামিং ফর কিডস (৭ থেকে ১২ বছর বয়সী দেরা জন্য)" value="প্রোগ্রামিং ফর কিডস (৭ থেকে ১২ বছর বয়সী দেরা জন্য)" onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor="প্রোগ্রামিং ফর কিডস (৭ থেকে ১২ বছর বয়সী দেরা জন্য)">
                            প্রোগ্রামিং ফর কিডস (৭ থেকে ১২ বছর বয়সী দেরা জন্য)
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="আদর্শলিপি (৩ থেকে ৪ বছর বয়সী দেরা জন্য)" value="আদর্শলিপি (৩ থেকে ৪ বছর বয়সী দেরা জন্য)" onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor="আদর্শলিপি (৩ থেকে ৪ বছর বয়সী দেরা জন্য)">
                            আদর্শলিপি (৩ থেকে ৪ বছর বয়সী দেরা জন্য)
                            </label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="প্রাইমারি স্কলারশিপ প্রিপারেশন" value="প্রাইমারি স্কলারশিপ প্রিপারেশন" onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor="প্রাইমারি স্কলারশিপ প্রিপারেশন">
                            প্রাইমারি স্কলারশিপ প্রিপারেশন
                            </label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="একাডেমিক এ্যাক্টিভিটি ফর ক্লাস সেভেন" value="একাডেমিক এ্যাক্টিভিটি ফর ক্লাস সেভেন" onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor="একাডেমিক এ্যাক্টিভিটি ফর ক্লাস সেভেন">
                            একাডেমিক এ্যাক্টিভিটি ফর ক্লাস সেভেন
                            </label>
                        </div>
                    </div>
                </div>
            </div>


                <div className=" d-flex justify-content-end">
                    <input
                        className="btn text-white mt-4 py-2 mb-5"
                        disabled={btnLoading ? true : false}
                        style={{ backgroundColor:'#532D80'}}
                        type="submit"
                        value={btnLoading ? "সংরক্ষণ করুন..." : "সংরক্ষণ করুন"}
                    />
                </div>
            </form>
        </div>
            
        </section>
    );
};

export default PaidMarketingStudentInformation;