import React, { useEffect, useState } from "react";
import "./Header.css";
import logo from "../../../../images/dashboard-logo.png";
import bell from "../../../../images/bell.png";
import studentProfile from "../../../../images/blank.png";
import profile_icon from "../../../../images/profile_icon.png";
import transaction_icon from "../../../../images/transaction-icon.png";
import profile_logout from "../../../../images/profile_logout.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  dateConverterSortMonthEnglish,
  englishToBengaliNumberFormatWithZero,
  formatAMPM,
  getTimeHoursMin,
} from "../../../../utilities/utilities";
import NotificationShowModal from "./NotificationShowModal/NotificationShowModal";
import ReactWhatsapp from "react-whatsapp";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { generateToken, messaging } from "../../../../notifications/firebase";
import { onMessage } from "firebase/messaging";
import { json } from "react-router";

const Header = () => {
  const [navlinks, setNavlinks] = useState([]);
  const [getNotification, setGetNotification] = useState([]);
  const [notificationDetails, setNotificationDetails] = useState({});
  const [language, setLanguage] = useState();
  const navigate = useNavigate();

  const [studentInfo, setStudentInfo] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const [isStudentNameExits, setIsStudentNameExits] = useState(true);
  const [refetchData, setRefetchData] = useState(0);
  const [settings, setSettings] = useState([]);
  const [error, setError] = useState(null);
  const handleClose = () => setModalShow(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetch(
      `${
        process.env.REACT_APP_BASE_URL_STUDENT
      }check-user-access-token?user_id=${localStorage.getItem("user_id")}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.message_type === "error") {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_credential");
          localStorage.removeItem("user_id");
          navigate("/login");
        }
      });
  }, []);

  //Language Change button
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");

    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);
  //setting
  useEffect(() => {
    async function fetchWebsiteSetting() {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/settings`
      );
      //if (componentMounted) {
      response = await response.json();

      console.log("*********API SETTINGS********");
      console.log(response);

      setSettings(response.data.settings);
      //}
    }
    fetchWebsiteSetting();
    /* return () => {
            componentMounted = false;
        }; */
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}api/settings`
        );
        response = await response.json();
        setSettings(response.data.settings);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
    return () => {
      // Clean up the effect
      setSettings(null);
      setError(null);
    };
  }, []);

  if (error) {
    console.log(error);
  }

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL_STUDENT
        }profile?user_id=${localStorage.getItem("user_id")}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
        }
      );
      response = await response.json();
      setStudentInfo(response.data);
    }
    fetchMyAPI();
  }, [refetchData]);

  if (studentInfo?.student_detail?.user_name === null && isStudentNameExits) {
    setModalShow(true);
    setIsStudentNameExits(false);
  }

  const handelName = (e) => {
    e.preventDefault();
    let data = new FormData();

    //student name
    if (e.target.student_name.value) {
      data.append("user_name", e.target.student_name.value);
    } else {
      toast.error("Please type your name");
      return;
    }

    //guardian mobile number
    if (e.target.guardian_mobile_number.value) {
      data.append(
        "guardian_mobile_number",
        e.target.guardian_mobile_number.value
      );
    } else {
      toast.error("Please enter your guardian mobile number");
      return;
    }

    //if mobile number not exits
    if (studentInfo?.student_detail?.mobile_number === null) {
      if (e.target.student_phone_number.value)
        data.append("mobile_number", e.target.student_phone_number.value);
      else {
        toast.error("Please enter your mobile number");
        return;
      }
    }
    /* data.append('user_name', e.target.student_name.value); */
    /* studentInfo?.student_detail?.mobile_number === null && data.append("mobile_number", e.target.student_phone_number.value); */
    /* data.append("guardian_mobile_number", e.target.guardian_mobile_number.value); */

    fetch(
      `${
        process.env.REACT_APP_BASE_URL_STUDENT
      }update-profile?user_id=${localStorage.getItem("user_id")}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
        },
        body: data,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message_type === "success") {
          toast.success(data.message);
          setRefetchData(refetchData + 1);
          setModalShow(false);
        } else {
          toast.error(data.message);
        }
      });
  };

  //get NavLink
  useEffect(() => {
    async function fetchNotice() {
      let data = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/course-categories`
      );
      //if (componentMounted) {
      data = await data.json();
      setNavlinks(data?.data?.course_categories);
      //}
    }
    fetchNotice();
    /* return () => {
              componentMounted = false;
          }; */
  }, []);

  //show Notification List
  useEffect(() => {
    fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }api/v2/student/push-notifications/${localStorage.getItem("user_id")}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setGetNotification(data.data))
      .catch((error) => console.error("Error fetching notices:", error));
  }, []);

  /* const handelShowNotification = () =>{
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}notification-log`)
          .then((res) => res.json())
          .then((data) => setGetNotices(data.data));
       }
 */
  const handelShowNotificationDetails = (notificationId) => {
    fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }api/v2/student/push-notifications/${notificationId}/view?user_id=${localStorage.getItem(
        "user_id"
      )}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setNotificationDetails(data?.data));
    setNotificationModalShow(true);
  };

  //show dropdown
  const handelProfileDropdown = (event) => {
    document.getElementById("Profile-dropdown").classList.toggle("show");
  };

  //close dropdown by clicking any where
  useEffect(() => {
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
  }, []);

  // Logout
  const userId = localStorage.getItem("user_id");
  /* const handelLogout =(event)=>{
          event.preventDefault();
            axios.post(`${process.env.REACT_APP_BASE_URL_STUDENT}student-logout?user_id=${localStorage.getItem('user_id')}`)
            .then((res) => {
                if (res.data.message_type === "success") {
                    localStorage.removeItem("auth_token");
                    localStorage.removeItem("auth_credential");
                    localStorage.removeItem("user_id");
                    navigate("/login");
                }
            });
          } */
  const handelLogout = (event) => {
    event.preventDefault();
    fetch(
      `${
        process.env.REACT_APP_BASE_URL_STUDENT
      }student-logout?user_id=${localStorage.getItem("user_id")}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
        },
        body: JSON.stringify({ user_id: userId }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message_type === "success") {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_credential");
          localStorage.removeItem("user_id");
          localStorage.removeItem("mobile_or_email");
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {});
  }, []);

  const tokenData = {
    user_id: localStorage.getItem("user_id"),
    fcm_token: localStorage.getItem("notification-token"),
    platform: "web",
  };

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL_STUDENT}fcm-token`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
          body: JSON.stringify(tokenData),
        }
      );
      response = await response.json();
    }
    if (studentInfo && studentInfo?.fcm_token_exist === false) {
      fetchMyAPI();
    }
  }, [studentInfo]);

  const unseenNotificationCount = getNotification.filter(
    (notification) => notification.status === 0
  );

  return (
    <div>
      <header id="page-topbar" className="" style={{ boxShadow: "none" }}>
        {/* Social Link  */}

        <div className="bg_color_blue hide-mobile-view">
          <div className="container-fluid d-flex justify-content-between pt-lg-2 px-lg-5 py-lg-0 px-4 py-2">
            <div className="d-flex align-items-center pb-lg-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`${settings?.website_facebook_link}`}
              >
                <i className="fa-brands fa-facebook ps-lg-3 text-white icon-font-size"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`${settings?.website_linkdein_link}`}
              >
                <i className="fa-brands fa-linkedin ps-lg-3 ps-2 text-white icon-font-size"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`${settings?.website_instagram_link}`}
              >
                <i className="fa-brands fa-instagram ps-lg-3 ps-2 text-white icon-font-size"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`${settings?.website_youtube_page_link}`}
              >
                <i className="fa-brands fa-youtube ps-lg-3 ps-2 text-white icon-font-size"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`${settings?.website_tiktok_link}`}
              >
                <i className="fa-brands fa-tiktok ps-lg-3 ps-2 text-white icon-font-size"></i>
              </a>
              <ReactWhatsapp
                number={`${settings?.whatsapp_number}`}
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <i className="fa-brands fa-whatsapp ps-lg-3 ps-2 text-white icon-font-size"></i>
              </ReactWhatsapp>
            </div>
            <div className="d-flex pt-2">
              <i className="fa-solid fa-envelope-open pe-2 text-white fs-5 hide-mobile-view"></i>
              <p className="text-white hide-mobile-view">support@mojaru.net</p>
            </div>
          </div>
        </div>

        {/* Mobile responsive */}
        <nav
          className="navbar navbar-expand-lg bg_color_blue hide show-mobile-view "
          style={{ padding: "0.5rem 0rem" }}
        >
          {/* All Link On Navbar  */}
          <div className="container px-4">
            <div className="d-flex align-items-center pb-lg-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`${settings?.website_facebook_link}`}
              >
                <i className="fa-brands fa-facebook ps-lg-3 text-white icon-font-size"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`${settings?.website_linkdein_link}`}
              >
                <i className="fa-brands fa-linkedin ps-lg-3 ps-2 text-white icon-font-size"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`${settings?.website_instagram_link}`}
              >
                <i className="fa-brands fa-instagram ps-lg-3 ps-2 text-white icon-font-size"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`${settings?.website_youtube_page_link}`}
              >
                <i className="fa-brands fa-youtube ps-lg-3 ps-2 text-white icon-font-size"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`${settings?.website_tiktok_link}`}
              >
                <i className="fa-brands fa-tiktok ps-lg-3 ps-2 text-white icon-font-size"></i>
              </a>
              <ReactWhatsapp
                number={`${settings?.whatsapp_number}`}
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <i className="fa-brands fa-whatsapp ps-lg-3 ps-2 text-white icon-font-size"></i>
              </ReactWhatsapp>

              <Link to="contact">
                <i className="fa-solid fa-envelope-open ps-2 text-white show-mobile-view hide icon-font-size"></i>
              </Link>
            </div>

            {/* Notification  */}
            <div className="">
              <li className="nav-item dropdown notification mx-3">
                <p
                  className="nav-link dropdown-toggle mb-0 position-relative"
                  data-bs-toggle="dropdown"
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="fa-solid fa-bell text-white pe-2"
                    style={{ fontSize: "18px" }}
                  ></i>
                  {unseenNotificationCount.length > 0 && (
                    <span
                      className="position-absolute top-0 start-100 translate-middle bg-danger border border-light rounded-circle text-white fw-500 balooda-regular"
                      style={{
                        padding: "0px 4px",
                        fontSize: "12px",
                      }}
                    >
                      {englishToBengaliNumberFormatWithZero(
                        unseenNotificationCount?.length
                      )}
                    </span>
                  )}
                </p>

                {/* <div className="dropdown-menu dropdown-menu-end notification-menu custom-card-shadow notification-section pb-0 margin_left_sm"> */}
                <div className="dropdown-menu dropdown-menu-end notification-menu custom-card-shadow notification-section pb-0 margin_left_sm">
                  <div className="d-flex justify-content-between align-items-center my-2 px-3">
                    <p className="mb-0 notification-top-title">Notifications</p>
                    <p className="mb-0 total-notification balooda-regular">
                      {englishToBengaliNumberFormatWithZero(
                        getNotification.length
                      )}
                    </p>
                  </div>

                  <div className="dropdown-divider mb-0 "></div>

                  <div
                    style={{
                      height: "290px",
                      overflowY: "auto",
                    }}
                  >
                    {getNotification?.map((notification) => (
                      <div
                        key={notification?.id}
                        className={`notification-list dropdown-item py-2 ${
                          notification?.status === 0
                            ? "tw-bg-indigo-100"
                            : "tw-bg-white"
                        }`}
                        onClick={() =>
                          handelShowNotificationDetails(notification?.id)
                        }
                      >
                        <div className="d-flex align-items-center w-100">
                          {/*{*/}
                          {/*    (notification?.notification?.image === null || notification?.notification?.image === "") ?*/}
                          {/*        <div className="d-flex justify-content-center me-2 notification-bell">*/}
                          {/*            <img src={bell} alt="" className="mt-1"/>*/}
                          {/*        </div>*/}
                          {/*        :*/}
                          {/*        <img src={`${notification?.image_url}${notification?.notification?.image}`}*/}
                          {/*             alt="" width={40} height={40} className='me-2'*/}
                          {/*             style={{borderRadius: '50%'}}/>*/}
                          {/*}*/}
                          <div className="d-flex justify-content-center me-2 notification-bell">
                            <img src={bell} alt="" className="mt-1" />
                          </div>

                          <div className="d-flex justify-content-between align-items-center w-100">
                            <div className="w-100">
                              <p
                                className="result-exam-name mb-0"
                                style={{ fontSize: "16px" }}
                              >
                                {notification?.notification?.title}
                              </p>
                              <div className="d-flex justify-content-between">
                                <p
                                  className="result-subject-name mb-0"
                                  style={{ fontSize: "12px" }}
                                >
                                  {getTimeHoursMin(notification?.created_at)
                                    .convertBy === "h"
                                    ? `${
                                        getTimeHoursMin(
                                          notification?.created_at
                                        ).hour
                                      } hour ${
                                        getTimeHoursMin(
                                          notification?.created_at
                                        ).min
                                      } min ago`
                                    : getTimeHoursMin(notification?.created_at)
                                        .convertBy === "m"
                                    ? `${
                                        getTimeHoursMin(
                                          notification?.created_at
                                        ).min
                                      } min ago`
                                    : `${
                                        getTimeHoursMin(
                                          notification?.created_at
                                        ).day
                                      } day ago`}
                                </p>

                                <p
                                  className="result-subject-name mb-0"
                                  style={{ fontSize: "12px" }}
                                >
                                  {formatAMPM(
                                    new Date(notification?.created_at)
                                  )}{" "}
                                  &nbsp;{" "}
                                  {dateConverterSortMonthEnglish(
                                    notification?.created_at
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </li>

              <Link to="profile">
                <i
                  className="fa-solid fa-circle-user text-white ps-1 pe-3"
                  style={{ fontSize: "18px" }}
                ></i>
              </Link>

              <i
                className="navbar-toggler fa-solid fa-bars text-white"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                style={{ border: "none" }}
              ></i>
            </div>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div
                className="navbar-nav ms-auto menu5"
                style={{ fontSize: "20px" }}
              >
                <NavLink
                  to="/"
                  className="nav-item nav-link custom text-white font-16 mt-2"
                >
                  {t("Home")}
                </NavLink>

                {/* {
                          navlinks.map((navlink, index) => (
                              <NavLink to={`/${navlink.course_category_link}`} state={{ from: `${navlink.course_category_link}`,enable: `${navlink.is_enabled}`}} key={index} className="nav-item nav-link text-white custom font-16">
                                  {navlink.course_category_name}
                              </NavLink>
                          ))
                      } */}

                {navlinks.map((navlink, index) => (
                  <NavLink
                    to={`/${navlink.course_category_link}`}
                    state={{
                      from: `${navlink.course_category_link}`,
                      enable: `${navlink.is_enabled}`,
                    }}
                    key={index}
                    className="nav-item nav-link text-white custom font-16"
                  >
                    {sessionStorage.getItem("useLanguage") === "en"
                      ? navlink.course_category_en_name
                      : navlink.course_category_name}
                  </NavLink>
                ))}
                <NavLink
                  to="/e-book"
                  className="nav-item nav-link custom text-white font-16"
                >
                  {t("E-Book")}
                </NavLink>

                <NavLink
                  to="/"
                  className="nav-item nav-link custom text-white font-16"
                  onClick={handelLogout}
                >
                  লগআউট
                </NavLink>
              </div>
            </div>
          </div>
        </nav>

        {/* Navbar */}
        <div className="navbar-header hide-mobile-view pt-0">
          <div className="d-flex">
            {/* LOGO  */}
            <div className="navbar-brand-box mt-2 pe-0">
              <Link to="/" className="header-logo logo-light">
                <span className="logo-sm">
                  <img src={logo} className="tw-w-16" alt="" />
                </span>
                <span className="logo-lg pt-2">
                  <img src={logo} className="tw-w-24 tw-ml-4" alt="" />
                </span>
              </Link>
            </div>
            {/* Button & Navbar option */}
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-sm ps-3 font-size-24 header-item waves-effect mt-2"
                id="vertical-menu-btn"
              ></button>
              <div className="d-flex justify-content-center align-items-center text-nav">
                <NavLink to="/" className="option hide-tab-view">
                  {t("Home")}
                </NavLink>
                {navlinks.map((navlink, index) => (
                  <NavLink
                    to={`/${navlink.course_category_link}`}
                    state={{
                      from: `${navlink.course_category_link}`,
                      enable: `${navlink.is_enabled}`,
                    }}
                    key={index}
                    className="option hide-tab-view"
                  >
                    {language === "en"
                      ? navlink.course_category_en_name
                      : navlink.course_category_name}
                  </NavLink>
                ))}
                <NavLink to="/e-book" className="option hide-tab-view">
                  {t("E-Book")}
                </NavLink>
              </div>
            </div>
          </div>

          {/* Navbar right site */}
          <div className="d-flex align-items-center navbar-right">
            {/* <div className="header-wallet">
            <img data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight1" aria-controls="offcanvasRight1" data-backdrop="static" data-keyboard="false" src={wallet} width="24px" height="24px" alt="" style={{ cursor: 'pointer' }}/>
            <>
            <div className="offcanvas offcanvas-end offcanvas-wallet" tabIndex="-1" id="offcanvasRight1" aria-labelledby="offcanvasRight1Label">
              {active === "wallet" && ( <WalletOffcanvas handleAddMoney={handleAddMoney} walletBalance={walletBalance}/> )}
              {active === "add-money" && ( <AddMoney handelBack={handelBack} walletBalance={walletBalance}/> )}
            </div>
            </>
          </div> */}

            {/* <div className="user-icon">
            <img src={bell} width="24px" height="24px" alt=""/>
          </div> */}

            <li className="nav-item dropdown notification mx-3">
              <p
                className="nav-link dropdown-toggle mb-0 position-relative"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
              >
                <img src={bell} width="24px" height="24px" alt="" />
                {unseenNotificationCount.length > 0 && (
                  <span
                    className="position-absolute top-0 start-100 translate-middle bg-danger border border-light rounded-circle text-white fw-500 balooda-regular"
                    style={{
                      padding: "0px 4px",
                      fontSize: "12px",
                    }}
                  >
                    {englishToBengaliNumberFormatWithZero(
                      unseenNotificationCount?.length
                    )}
                  </span>
                )}
              </p>
              <div className="dropdown-menu dropdown-menu-end notification-menu custom-card-shadow notification-section pb-0">
                <div className="d-flex justify-content-between align-items-center my-2 px-3">
                  <p className="mb-0 notification-top-title">Notifications</p>
                  <p className="mb-0 total-notification balooda-regular">
                    {englishToBengaliNumberFormatWithZero(
                      getNotification.length
                    )}
                  </p>
                </div>
                <div className="dropdown-divider mb-0"></div>

                <div style={{ height: "290px", overflowY: "auto" }}>
                  {getNotification?.map((notification) => (
                    <div
                      key={notification?.id}
                      className={`notification-list dropdown-item py-2 ${
                        notification?.status === 0
                          ? "tw-bg-indigo-100"
                          : "tw-bg-white"
                      }`}
                      onClick={() =>
                        handelShowNotificationDetails(notification?.id)
                      }
                    >
                      <div className="d-flex align-items-center w-100">
                        {/*{*/}
                        {/*    (notification?.notification?.image === null || notification?.notification?.image === "") ?*/}
                        {/*        <div className="d-flex justify-content-center me-2 notification-bell">*/}
                        {/*            <img src={bell} alt="" className="mt-1"/>*/}
                        {/*        </div>*/}
                        {/*        :*/}
                        {/*        <img src={`${notification?.image_url}${notification?.notification?.image}`}*/}
                        {/*             alt="" width={40} height={40} className='me-2'*/}
                        {/*             style={{borderRadius: '50%'}}/>*/}
                        {/*}*/}
                        <div className="d-flex justify-content-center me-2 notification-bell">
                          <img src={bell} alt="" className="mt-1" />
                        </div>

                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="w-100">
                            <p
                              className="result-exam-name mb-0"
                              style={{ fontSize: "16px" }}
                            >
                              {notification?.notification?.title}
                            </p>
                            <div className="d-flex justify-content-between">
                              <p
                                className="result-subject-name mb-0"
                                style={{ fontSize: "12px" }}
                              >
                                {getTimeHoursMin(notification?.created_at)
                                  .convertBy === "h"
                                  ? `${
                                      getTimeHoursMin(notification?.created_at)
                                        .hour
                                    } hour ${
                                      getTimeHoursMin(notification?.created_at)
                                        .min
                                    } min ago`
                                  : getTimeHoursMin(notification?.created_at)
                                      .convertBy === "m"
                                  ? `${
                                      getTimeHoursMin(notification?.created_at)
                                        .min
                                    } min ago`
                                  : `${
                                      getTimeHoursMin(notification?.created_at)
                                        .day
                                    } day ago`}
                              </p>

                              <p
                                className="result-subject-name mb-0"
                                style={{ fontSize: "12px" }}
                              >
                                {formatAMPM(new Date(notification?.created_at))}{" "}
                                &nbsp;{" "}
                                {dateConverterSortMonthEnglish(
                                  notification?.created_at
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </li>

            {/* show notification details */}
            <NotificationShowModal
              show={notificationModalShow}
              notificationdetails={notificationDetails}
              onHide={() => setNotificationModalShow(false)}
            />

            {/* Student Profile modal  */}
            <Modal
              show={modalShow}
              onHide={handleClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  প্রোফাইল
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handelName}>
                  <div className="row">
                    <div className="col-lg-6">
                      <p className="mb-2 font-400">
                        নাম <span className="text-danger">*</span>
                      </p>
                      <input
                        className="mb-3 form-control form-control-lg student-profile-info"
                        name="student_name"
                        type="text"
                        placeholder="Write Your Full Name"
                        required
                      />
                    </div>

                    {studentInfo?.student_detail?.mobile_number === null && (
                      <div className="col-lg-6">
                        <p className="mb-2 font-400">
                          ফোন নাম্বার <span className="text-danger">*</span>
                        </p>
                        <input
                          className="mb-3 form-control form-control-lg student-profile-info"
                          name="student_phone_number"
                          type="text"
                          placeholder="Write Your Mobile Number"
                          required
                        />
                      </div>
                    )}

                    <div className="col-lg-6">
                      <p className="mb-2 font-400">
                        অভিভাবকের ফোন নাম্বার{" "}
                        <span className="text-danger">*</span>
                      </p>
                      <input
                        className="mb-3 form-control form-control-lg student-profile-info"
                        name="guardian_mobile_number"
                        type="text"
                        placeholder="Write Your Guardian Phone Number"
                        required
                      />
                    </div>
                  </div>

                  <div className=" d-flex justify-content-end">
                    <input
                      className="btn text-white mt-4 py-2"
                      style={{ backgroundColor: "#532D80", fontSize: "22" }}
                      type="submit"
                      value="সংরক্ষণ করুন"
                    ></input>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            <div className="">
              {studentInfo?.student_detail?.profile_picture ? (
                <img
                  src={`${studentInfo?.student_profile_image}${studentInfo?.student_detail?.profile_picture}`}
                  alt=""
                  style={{ borderRadius: "4px", width: "30px", height: "30px" }}
                />
              ) : (
                <img
                  src={studentProfile}
                  alt=""
                  style={{ borderRadius: "4px", width: "30px", height: "30px" }}
                />
              )}
            </div>

            {/* dropdown */}
            <div className="dropdown">
              <button onClick={handelProfileDropdown} className="dropbtn">
                {studentInfo?.student_detail?.user_name
                  ? studentInfo?.student_detail?.user_name
                  : "Student name"}{" "}
                <i className="fa-solid fa-angle-down dropbtn"></i>
              </button>
              <div id="Profile-dropdown" className="dropdown-content">
                <Link className="profile-menu" to="/profile">
                  <div className="d-flex">
                    <img src={profile_icon} height={25} width={25} alt="" />
                    <p className="mb-0 ps-2 menu">{t("Profile")}</p>
                  </div>
                </Link>
                <Link className="profile-menu" to="transaction-info">
                  <div className="d-flex">
                    <img src={transaction_icon} height={25} width={25} alt="" />
                    <p className="mb-0 ps-2 menu">{t("Transection")}</p>
                  </div>
                </Link>
                <Link className="profile-menu" to="/" onClick={handelLogout}>
                  <div className="d-flex">
                    <img src={profile_logout} height={25} width={25} alt="" />
                    <p className="mb-0 ps-2 menu">{t("LogOut")}</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
