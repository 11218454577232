import React from "react";
import courseImage from "../../../../../../images/HomePageImage/dummyCourse.png";
import dot from "../../../../../../images/HomePageImage/Dot.svg";
import { HiVideoCamera, HiUsers, HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
const Card = ({ course, courseImagePath }) => {
  return (
    <Link
      to={`/${course?.course_category_link}/${course?.course_slug}`}
      state={{ course_id: `${course?.id}` }}
      className="tw-w-full tw-relative tw-h-auto tw-flex tw-items-center tw-justify-center tw-p-2 group"
    >
      <div className="tw-w-full tw-h-auto md:tw-w-[360px] md:tw-h-auto md:tw-bg-white tw-p-4 md:tw-p-0 tw-rounded-3xl tw-transform tw-transition tw-duration-500 hover:tw-scale-105 tw-flex tw-flex-col tw-justify-center tw-items-center">
        <img
          className="tw-w-full tw-h-[192px] tw-rounded-tr-3xl tw-rounded-tl-3xl"
          src={courseImagePath + course?.course_picture}
          alt="course"
        />
        <div className="tw-w-full tw-bg-white tw-rounded-bl-3xl tw-rounded-br-3xl tw-p-6">
          <div className="tw-flex">
            <div className="tw-flex tw-justify-center tw-items-center tw-bg-[#FEE2E2] tw-px-3 tw-rounded-3xl">
              <img className="tw-w-2 tw-h-2 tw-mr-2" src={dot} alt="logo" />
              <span className="tw-text-red-800">{course?.course_mode}</span>
            </div>
            {course?.course_total_duration_in_days && (
              <span className="tw-ml-4 tw-bg-gray-100 tw-px-3 tw-rounded-3xl tw-text-gray-700">
                {course?.course_total_duration_in_days}
              </span>
            )}
          </div>
          <h2 className="tw-text-lg tw-text-gray-700 tw-font-bold tw-mt-4">
            {course?.course_name}
          </h2>
          <div className="tw-flex">
            <p className="tw-flex tw-items-center tw-mr-4 tw-text-gray-500">
              <HiVideoCamera className="tw-mr-2 tw-text-gray-400" />
              {course?.course_total_live_class} {course?.course_mode} ক্লাস
            </p>
            <p className="tw-flex tw-items-center tw-text-gray-500">
              <HiUsers className="tw-mr-2 tw-text-gray-400" />
              {course?.students_count}
              <span className="md:tw-hidden lg:tw-block tw-ml-1">Students</span>
            </p>
          </div>
          <div
            className={`tw-w-full tw-flex ${
              course?.course_payment_segments?.course_final_price
                ? "tw-justify-center "
                : " tw-justify-between"
            } tw-mt-4`}
          >
            {course?.course_payment_segments?.course_final_price ? (
              <span className="tw-text-lg tw-font-bold tw-text-green-500">
                {/*৳{course?.course_payment_segments?.course_final_price}*/}
              </span>
            ) : (
              <span className="tw-text-lg tw-font-bold tw-text-green-500">
                ফ্রি কোর্স
              </span>
            )}
            <button className="tw-flex tw-items-center tw-font-bold tw-text-blue-800">
              <span className="tw-mr-2">বিস্তারিত</span>
              <HiArrowNarrowRight />
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
