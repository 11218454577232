import React from 'react';
import PageTitle from '../../../shared/PageTitle/PageTitle';
import Details from './Details/Details';

const About = () => {
    
    return (
        <div>
        <PageTitle title="About Us"></PageTitle>
            <div style={{ paddingBottom:"23rem" }}>
            <Details></Details>
            </div>
        </div>
    );
};

export default About;