import React from "react";
import "./ClassSchedule.css";
import { englishToBengaliNumberFormat } from "../../../../../../utilities/utilities";
import playIcon from "../../../../../../images/course_details_icon/Live-class-icon.png";
import dot from '../../../../../../images/HomePageImage/Dot.svg';

const ClassSchedule = ({ lesson, LessonSerial }) => {
    return (
        <div>
            <div className="accordion-item lesson-accordion-body">
                <h3 className="accordion-header">
                    <button
                        className={`accordion-button ${LessonSerial === 0 ? '' : 'collapsed'}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#faq-content-${LessonSerial}-${lesson?.lesson_id}`}
                        aria-expanded={LessonSerial === 0 ? "true" : "false"}
                        aria-controls={`faq-content-${LessonSerial}-${lesson?.lesson_id}`}
                    >
                        <span className=" ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                            <path
                                d="M10 5.29066V16.124M10 5.29066C9.02675 4.64406 7.70541 4.24667 6.25 4.24667C4.79459 4.24667 3.47325 4.64406 2.5 5.29066V16.124C3.47325 15.4774 4.79459 15.08 6.25 15.08C7.70541 15.08 9.02675 15.4774 10 16.124M10 5.29066C10.9732 4.64406 12.2946 4.24667 13.75 4.24667C15.2054 4.24667 16.5268 4.64406 17.5 5.29066V16.124C16.5268 15.4774 15.2054 15.08 13.75 15.08C12.2946 15.08 10.9732 15.4774 10 16.124"
                                stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                        <span className="tw-text-[16px] tw-font-semibold tw-ml-2">{lesson?.lesson_name}</span>
                    </button>
                </h3>

                <div
                    id={`faq-content-${LessonSerial}-${lesson?.lesson_id}`}
                    className={`accordion-collapse collapse ${LessonSerial === 0 ? 'show' : ''}`}
                    aria-labelledby={`heading-${LessonSerial}-${lesson?.lesson_id}`}
                    data-bs-parent={`#subject-accordion-${LessonSerial}`}
                >
                    <div className="accordion" id={`topic-accordion-${lesson?.lesson_id}`}>
                        {lesson?.topics?.length === 0 ? (
                            <p className="tw-text-center tw-text-xl">
                                এখনো কোনো বিষয়বস্তু যুক্ত করা হয়নি।
                            </p>
                        ) : (
                            lesson?.topics?.map((topic, index) => (
                                <div className="accordion-item" key={`topic-${lesson?.lesson_id}-${index}`}>
                                    <h3 className="accordion-header tw-pl-6">
                                        <button
                                            className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#topic-content-${LessonSerial}-${index}`}
                                            aria-expanded={index === 0 ? "true" : "false"}
                                            aria-controls={`topic-content-${LessonSerial}-${index}`}
                                        >
                                            <span className="tw-text-[16px] tw-font-semibold tw-text-gray-700">{englishToBengaliNumberFormat(index + 1)}. {topic?.topic_name}</span>
                                        </button>
                                    </h3>
                                    <div
                                        id={`topic-content-${LessonSerial}-${index}`}
                                        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                        aria-labelledby={`topic-heading-${LessonSerial}-${index}`}
                                        data-bs-parent={`#topic-accordion-${lesson?.lesson_id}`}
                                    >
                                        <div className="accordion-body tw-flex tw-justify-between tw-items-center">
                                            <div className="tw-flex tw-items-center tw-px-12 ">
                                                <img src={playIcon}/>
                                                <span
                                                    className="tw-ml-4 tw-text-[16px] tw-font-semibold tw-text-gray-600">{topic?.topic_name || "No details available"}</span>
                                            </div>
                                            <div className="tw-bg-red-100 tw-flex tw-px-2 tw-py-0.5 tw-rounded-3xl">
                                                <img src={dot} alt=""/>
                                                <span className="tw-text-red-800 tw-ml-2">লাইভ</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClassSchedule;
