import React from 'react';
/* import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'; */
import parse from 'html-react-parser';

const AuthorDescription = ({authorDescription, writer}) => {
    const des = authorDescription;
    return (
        <div className='details-container'>
        <p className='fw-bold'>{writer}</p>
        <div>{ parse(des) }</div>
        </div>
    );
};

export default AuthorDescription;