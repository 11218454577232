import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../../../../images/Logo-purple.png";
import bigStudentImage from "../../../../../images/batch/cropStudent2.png";
import mediumStudentImage from "../../../../../images/batch/BatchChildS2.png";
import smallStudentImage from "../../../../../images/batch/BatchChildS1.png";
import checkIcon from "../../../../../images/OneToOne/Check-circle.svg";
import image1 from "../../../../../images/batch/Students.png";
import image2 from "../../../../../images/batch/Teacher.png";
import image3 from "../../../../../images/batch/live.png";
import image4 from "../../../../../images/batch/course_metarials.png";
import "./Batch.css";
import {Link} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import {HiArrowRight, HiUser} from "react-icons/hi";
import Faq from "../../../shared/CourseDetails/CourseDetailsInformation/Faq/Faq";
import CallImage from "../../../../../images/leadCollectImage/MojaruWebCCR.jpg";
import backButton from "../../../../../images/OneToOne/backButton.png";

import cardImage1 from "../../../../../images/OneToOne/cardImage1.png";
import cardImage2 from "../../../../../images/OneToOne/cardImage2.png";
import cardImage3 from "../../../../../images/OneToOne/cardImage3.png";
import students from "../../../../../images/batch/studentLarge.png";
import world from "../../../../../images/batch/world.png";
import computer from "../../../../../images/batch/computer.png";
import ScrollToTop from "../../../shared/ScrollToTop/ScrollToTop";
import { toast } from "react-toastify";
import PopularCourse from "../Shared/PopularCourse/PopularCourse";
import GreetingMsg from "../Shared/GreetingMsg/GreetingMsg";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import FormFooter from "../../../shared/FormFooter/FormFooter";
import {useTranslation} from "react-i18next";
import i18next from "i18next";


const faqs = [
  {
    course_faq_answer: (
      <>
        তোমার তথ্য উল্লেখ করে
        <span className="tw-text-blue-500 tw-underline tw-cursor-pointer">
          ফর্মটি
        </span>{" "}
        ফিলাপ করো। আমাদের একজন স্টুডেন্ট কাউন্সিলর খুব দ্রুত এই বিষয়ে যোগাযোগ
        করবে।
      </>
    ),
    course_faq_id: 2,
    course_faq_question: "আমি কীভাবে এই প্রোগ্রামে ভর্তি হতে পারি? ",
  },
  {
    course_faq_answer:
      "প্রত্যেকটি কোর্সের জন্য আলাদা করে নির্ধারিত সময় রয়েছে। সাধারণত ক্লাসগুলোর সময়সীমা ৬০ মিনিটের হয়ে থাকে।",
    course_faq_id: 3,
    course_faq_question: "ক্লাসের শিডিউলগুলো কেমন হয়?",
  },
  {
    course_faq_answer:
      "প্রতিটি ক্লাসের টপিক অনুযায়ী ভিডিও নোটস ওয়েবসাইটে দেয়া থাকবে। যা দেখে তুমি তোমার মিস হওয়া ক্লাসের পড়াগুলো বুঝে নিতে পারবে।",
    course_faq_id: 4,
    course_faq_question: "আমার কোনো ক্লাস মিস হয়ে গেলে উপায় কী?",
  },
  {
    course_faq_answer:
      "তোমার রেজিস্ট্রেশন সম্পন্ন হলে একজন স্টুডেন্ট কাউন্সিলর তোমাকে সকল তথ্য জানিয়ে দিবে।",
    course_faq_id: 5,
    course_faq_question: "পেমেন্ট কীভাবে করবো? ",
  },
];

const Batch = () => {
  const [guardianName, setGuardianName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [phone, setPhone] = useState("");
  const [className, setClassName] = useState("");
  const [subject, setSubject] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [language, setLanguage] = useState("bn");
  const {t} = useTranslation();

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  const validateForm = () => {
    if (studentName && guardianName && phone && className && subject) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [studentName && guardianName, phone, className, subject]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      class_name: className,
      student_name: studentName,
      guardian_name: guardianName,
      mobile: phone,
      interested_courses: subject,
      type: "batch",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}api/v1/registration-lead-store`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        secretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOpen(true);
        setSubject("");
        setClassName("");
        setStudentName("");
        setPhone("");
      });
  };

  //   tw-px-6 md:tw-px-10 lg:tw-ml-14 tw-my-10
  // md:tw-ml-10 xl:tw-ml-14
  // xl:tw-px-28
  useEffect(() => {
    const useLanguage = sessionStorage.getItem('useLanguage');

    if(!useLanguage){
      sessionStorage.setItem('useLanguage', 'bn');
      i18next.changeLanguage('bn');
      setLanguage('bn');
    }else{
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem('useLanguage')

    if(getLanguage === 'bn'){
      sessionStorage.setItem('useLanguage', 'en');
      i18next.changeLanguage('en');
      setLanguage('en');
    }else{
      sessionStorage.setItem('useLanguage', 'bn');
      i18next.changeLanguage('bn');
      setLanguage('bn');
    }
  }

  return (
    <div>
      <ScrollToTop />
      <div className="-tw-mt-[88px]">
        <div className="tw-max-w-[1440px] tw-mx-auto">
          {/* Hero Section  */}
          <div className="tw-w-full tw-h-full hero_bg_batch tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mt-32">
            {/* Content */}

            <div className="tw-mt-14 lg:tw-mt-20 tw-my-10 xl:tw-my-20 tw-px-6 md:tw-px-0 md:tw-ml-10 xl:tw-ml-14">
              {/* Back button  */}
              <div
                className="tw-flex tw-items-center tw-cursor-pointer tw-mb-8 "
                onClick={() => navigate(-1)}
              >
                <img src={backButton} alt="icon" />
                <p className="tw-ml-3 tw-text-gray-600">{t("back")}</p>
              </div>
              {/* Logo */}
              <div className="tw-flex tw-flex-col md:tw-items-start">
                <Link to="/">
                  <img className="" src={logo} alt="logo" />
                </Link>
              </div>

              {/*Heading and Sub heading  */}
              <div className="tw-mt-6">
                <h2 className="tw-text-3xl lg:tw-text-6xl tw-text-[#6F39B7] tw-font-bold">
                    {t("Batch-Based")}
                  <p className="tw-text-gray-700 tw-mt-2 md:tw-mt-4">
                      {t("Online Classes")}
                  </p>
                </h2>
                <p className="tw-text-md lg:tw-text-xl tw-mt-5 md:tw-mt-7">
                    {t("Limited number of students |Subject expert teachers |Live interactive classroom| Fun reading materials")}
                </p>
              </div>

              {/* Lists  */}
              <div className="tw-mt-6 tw-text-gray-700 tw-text-sm  lg:tw-text-lg">
                <div className="tw-flex">
                  <img className="tw-mr-4" src={checkIcon} alt="check icon" />
                  <p>{t("Choose your preferred course")}</p>
                </div>
                <div className="tw-flex">
                  <img className="tw-mr-4" src={checkIcon} alt="check icon" />
                  <p>{t("Provide your information")}</p>
                </div>
                <div className="tw-flex">
                  <img className="tw-mr-4" src={checkIcon} alt="check icon" />
                  <p>{t("Get the details of the class")} </p>
                </div>
                <div className="tw-flex">
                  <img className="tw-mr-4" src={checkIcon} alt="check icon" />
                  <p>{t("Enroll in your chosen batch and start your journey")}</p>
                </div>
              </div>

              <div className="tw-mt-10">
                {/*<img className="tw-mr-4" src={checkIcon} alt="check icon"/>*/}
                <p className="tw-text-gray-700 tw-text-xl tw-font-semibold tw-mb-2">
                    {t("Start Your Exciting Journey of Online Learning")}
                </p>
              </div>
              <button
                onClick={handleButtonClick}
                className="tw-bg-[#6F39B7] tw-px-6 tw-py-3 tw-rounded-3xl tw-font-semibold tw-text-white"
              >
                  {t("Register now")}
              </button>
            </div>

            {/* image */}
            <div className="tw-relative tw-h-auto tw-mt-56 md:tw-mt-0 ">
              <img
                className="tw-w-auto tw-h-auto tw-absolute tw-bottom-0 tw-right-0"
                src={bigStudentImage}
                alt="student image"
              />
            </div>
          </div>

          {/* Form Sections  */}
          <div className="tw-flex tw-flex-col tw-gap-6 lg:tw-gap-20 lg:tw-flex-row tw-justify-between tw-mt-16 tw-px-4 md:tw-px-10 ">
            {/* Left side  */}
            <div className="">
              {/* Heading  */}
              <div>
                <h2 className="tw-font-bold tw-text-3xl xl:tw-text-4xl">
                    {t("Why Choose Mojaru's Online Batch-Based Classes?")}
                </h2>
              </div>
              {/* Listing  */}
              <div className="tw-mt-8 tw-w-full  tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-1 tw-gap-6">
                {/* সীমিত সংখ্যাক শিক্ষার্থী */}

                <div className="tw-flex">
                  <div>
                    <img
                      className="tw-w-14 tw-h-auto"
                      src={image1}
                      alt="icon"
                    />
                  </div>
                  <div className="tw-ml-6">
                    <h2 className="tw-text-lg tw-font-semibold">
                        {t("Limited Number of Students")}
                    </h2>
                    <p className="tw-font-[400] tw-mt-2">
                        {t("Learn in a fun environment with a small group of students.")}
                    </p>
                  </div>
                </div>

                {/* সাবজেক্ট এক্সপার্ট শিক্ষক */}
                <div className="tw-flex ">
                  <div>
                    <img
                      className="tw-w-14 tw-h-auto"
                      src={image2}
                      alt="icon"
                    />
                  </div>
                  <div className="tw-ml-6">
                    <h2 className="tw-text-lg tw-font-semibold">
                        {t("Subject Expert Teachers")}
                    </h2>
                    <p className="tw-font-[400] tw-mt-2">
                        {t("Learn from the best expert teachers in the country. Some special courses feature early childhood education experts.")}
                    </p>
                  </div>
                </div>

                {/* লাইভ ইন্টার‍্যাক্টিভ ক্লাসরুম */}
                <div className="tw-flex ">
                  <div>
                    <img
                      className="tw-w-14 tw-h-auto"
                      src={image3}
                      alt="icon"
                    />
                  </div>
                  <div className="tw-ml-6">
                    <h2 className="tw-text-lg tw-font-semibold">
                        {t("Live Interactive Classroom")}
                    </h2>
                    <p className="tw-font-[400] tw-mt-2">
                        {t("Engage in direct conversations with teachers and solve problems during class.")}
                    </p>
                  </div>
                </div>

                {/* 100% কিডস ফ্রেন্ডলি */}
                <div className="tw-flex ">
                  <div>
                    <img
                      className="tw-w-14 tw-h-auto"
                      src={image4}
                      alt="icon"
                    />
                  </div>
                  <div className="tw-ml-6">
                    <h2 className="tw-text-lg tw-font-semibold">
                        {t("100% Kids Friendly")}
                    </h2>
                    <p className="tw-font-[400] tw-mt-2">
                        {t("Enjoy animated video notes, slides, and fun workbooks.")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Right side  */}
            <div className=" tw-w-full lg:tw-w-1/2">
              <div
                ref={formRef}
                className={`tw-mt-8 md:tw-mt-0 ${
                  focus ? "tw-border" : "tw-border"
                } tw-border tw-p-8 tw-rounded-3xl`}
              >
                <h2 className="tw-text-lg tw-font-semibold">
                    {t("Join a Free Demo Class")}
                </h2>
                <form onSubmit={handleSubmit} className="tw-mt-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                    >
                        {t("Your Name")} <span className="tw-text-red-700">*</span>
                    </label>
                    <div className="tw-relative tw-mt-1">
                      <HiUser className="tw-absolute tw-top-3 tw-left-3 tw-text-2xl tw-text-gray-500" />
                      <input
                        id="name"
                        name="name"
                        type="text"
                        autoComplete="name"
                        value={studentName}
                        onChange={(e) => setStudentName(e.target.value)}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        required
                        className="tw-pl-12 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                    >
                        {t("Your Guardian’s Name")}
                      <span className="tw-text-red-700">*</span>
                    </label>
                    <div className="tw-relative tw-mt-1">
                      <HiUser className="tw-absolute tw-top-3 tw-left-3 tw-text-2xl tw-text-gray-500" />
                      <input
                        id="name"
                        name="name"
                        type="text"
                        autoComplete="name"
                        value={guardianName}
                        onChange={(e) => setGuardianName(e.target.value)}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        required
                        className="tw-pl-12 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                      />
                    </div>
                  </div>
                  <div className="tw-mt-4">
                    <label>
                        {t("Phone number")} <span className="tw-text-red-700">*</span>
                    </label>
                    <div className="tw-mt-1">
                      <PhoneInput
                        country={"bd"}
                        value={phone}
                        onChange={(mobile) => setPhone(mobile)}
                        enableSearch={true}
                      />
                      {/*{phoneNumberError &&*/}
                      {/*    <p className="tw-mt-2 tw-text-sm tw-text-red-700">{phoneNumberError}</p>}*/}
                    </div>
                  </div>
                  <div className="tw-mt-4">
                    <label
                      htmlFor="class"
                      className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                    >
                        {t("Your class")}
                    </label>
                    <div className="tw-relative">
                      <select
                        id="class"
                        name="class"
                        className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6 tw-appearance-none tw-bg-white"
                        value={className}
                        onChange={(e) => setClassName(e.target.value)}
                        defaultValue="ক্লাস নির্বাচন করো"
                      >
                        <option value="" label="ক্লাস নির্বাচন করো"></option>
                        <option
                          value="Not Admitted"
                          label="Not Admitted - ভর্তি করা হয়নি"
                        ></option>
                        <option value="Play" label="Play - প্লে"></option>
                        <option
                          value="Nursery"
                          label="Nursery - নার্সারী"
                        ></option>
                        <option value="KG" label="KG - কেজি"></option>
                        <option
                          value="Class 1"
                          label="Class 1 - শ্রেণী ১"
                        ></option>
                        <option
                          value="Class 2"
                          label="Class 2 - শ্রেণী ২"
                        ></option>
                        <option
                          value="Class 3"
                          label="Class 3 - শ্রেণী ৩"
                        ></option>
                        <option
                          value="Class 4"
                          label="Class 4 - শ্রেণী ৪"
                        ></option>
                        <option
                          value="Class 5"
                          label="Class 5 - শ্রেণী ৫"
                        ></option>
                        <option
                          value="Class 6"
                          label="Class 6 - শ্রেণী ৬"
                        ></option>
                        <option
                          value="Class 7"
                          label="Class 7 - শ্রেণী ৭"
                        ></option>
                        <option
                          value="Class 8"
                          label="Class 8 - শ্রেণী ৮"
                        ></option>
                        <option
                          value="Class 9"
                          label="Class 9 - শ্রেণী ৯"
                        ></option>
                        <option
                          value="Class 10"
                          label="Class 10 - শ্রেণী ১০"
                        ></option>
                        <option
                          value="Class 11"
                          label="Class 11 - শ্রেণী ১১"
                        ></option>
                        <option
                          value="Class 12"
                          label="Class 12 - শ্রেণী ১২"
                        ></option>
                        <option value="Other" label="Other - অন্যান্য"></option>
                      </select>
                      <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                        <svg
                          className="tw-h-5 tw-w-5 tw-text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="tw-mt-4">
                    <label
                      htmlFor="medium"
                      className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                    >
                        {t("Which course are you interested in")}
                    </label>
                    <div className="tw-relative">
                      <select
                        id="medium"
                        name="medium"
                        className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-3 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6 tw-appearance-none tw-bg-white"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        defaultValue={t("Select your preferred course")}
                      >
                        <option
                          value=""
                          label={t("Select your preferred course")}
                        ></option>
                        <option
                          value="অ্যাবাকাস মাইন্ড ম্যাথ"
                          label="অ্যাবাকাস মাইন্ড ম্যাথ"
                        ></option>
                        <option value="আদর্শলিপি" label="আদর্শলিপি"></option>
                        <option value="মজার গণিত" label="মজার গণিত"></option>
                        <option
                          value="গণিত অলিম্পিয়াড"
                          label="গণিত অলিম্পিয়াড"
                        ></option>
                        <option
                          value="একাডেমিক প্রস্তুতি- ৭ম শ্রেণি"
                          label="একাডেমিক প্রস্তুতি- ৭ম শ্রেণি"
                        ></option>
                        <option
                          value="ক্যাডেট প্রি-প্রিপারেশন"
                          label="ক্যাডেট প্রি-প্রিপারেশন"
                        ></option>
                        <option
                          value="মিশন ক্যাডেট - ২৪"
                          label="মিশন ক্যাডেট - ২৪"
                        ></option>
                        <option
                          value="স্মার্ট ইংলিশ"
                          label="স্মার্ট ইংলিশ"
                        ></option>
                      </select>

                      <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                        <svg
                          className="tw-h-5 tw-w-5 tw-text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="tw-mt-8">
                    <button
                      type="submit"
                      className={`tw-flex tw-w-full tw-justify-center tw-items-center tw-rounded-3xl tw-px-3 tw-py-3 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white  ${
                        isFormValid ? "tw-bg-[#532D80]" : " tw-bg-gray-400"
                      }`}
                      disabled={!isFormValid}
                    >
                      <span className="tw-mr-2">{t("Submit")}</span> <HiArrowRight />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Free demo class  */}
          <div className="tw-w-full tw-px-4 md:tw-px-10 tw-mt-16">
            <PopularCourse handleFocus={handleFocus} id={9} />
          </div>

          {/* Free join class  */}
          <div className="tw-px-4 md:tw-px-10">
            <div className="tw-mt-16 bg_demo_class tw-rounded-3xl">
              <div className="tw-py-14">
                <div className="tw-text-center tw-text-gray-700">
                  <h2 className="tw-text-3xl tw-font-bold">
                      {t("Join a Free Demo Class")}
                  </h2>
                  <p className="tw-mt-6 tw-text-xl">
                      {t("Discover how Mojaru's online batch-based classes work and how much fun they are.")}
                  </p>
                  <p className="tw-text-xl">
                      {t("Check out the fun way of learning in the demo class")}
                  </p>
                </div>

                <div className="tw-mt-10 tw-flex tw-flex-col tw-justify-center tw-items-center">
                  <button
                    onClick={handleButtonClick}
                    className="tw-flex tw-items-center tw-bg-[#6F39B7] tw-text-white tw-font-semibold tw-py-2 tw-px-4 tw-rounded-3xl"
                  >
                    <span>{t("Book your demo class")}</span>{" "}
                    <ArrowRightIcon className="tw-w-3 tw-ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Kader jonno ei program */}
          <div className="tw-mt-20 tw-px-4 md:tw-px-10">
            <div className="tw-text-center">
              {/*<p className="tw-text-[#532D80] tw-font-semibold">*/}
              {/*  শিক্ষার্থীর প্রাপ্তি*/}
              {/*</p>*/}
              <h2 className="tw-text-2xl md:tw-text-4xl tw-font-bold tw-mt-3">
                  {t("This Program is Designed For")}
              </h2>
            </div>
            <div className="tw-mt-14">
              <div className="tw-w-full tw-h-full tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 lg:tw-grid-rows-2 gap-4 tw-px-4">
                {/* ৩ থেকে ১৫ বছরের যেকোনো শিশু */}
                <div className="tw-bg-orange-100 tw-w-full tw-rounded-3xl tw-px-6 tw-pt-12 lg:tw-col-span-1 lg:tw-row-span-2 tw-flex">
                  <div className="tw-flex tw-w-full tw-flex-col tw-justify-between tw-px-6">
                    <div>
                      <h2 className="tw-text-4xl tw-font-bold">
                          {t("Any child aged 3 to 15 years")}
                      </h2>
                      <ul className="tw-ml-6 tw-mt-3 tw-list-disc tw-leading-7 tw-text-2xl">
                        <li className="tw-mt-8">
                            {t("Opportunity to learn in a fun way from home")}
                        </li>
                        <li className="tw-mt-3">
                            {t("Chance to learn something new in a child-friendly environment")}
                        </li>
                        <li className="tw-mt-3">
                            {t("Direct problem-solving in an interactive classroom")}
                        </li>
                      </ul>
                    </div>
                    <div className="tw-flex tw-justify-end tw-items-end">
                      <img src={students} alt="icon" />
                    </div>
                  </div>
                </div>

                {/* দেশ ও বিদেশের যেকোনো প্রান্ত */}
                <div className="tw-bg-red-100 tw-rounded-3xl tw-px-6 tw-pt-10 tw-flex tw-flex-col">
                  <div className="tw-flex-grow">
                    <h2 className="tw-text-2xl md:tw-text-4xl tw-font-bold tw-pl-4">
                        {t("Those willing to learn from anywhere")} <br /> {t("in the country or abroad")}
                    </h2>
                  </div>
                  <div className="tw-flex tw-justify-end tw-items-end">
                    <img src={world} alt="icon" />
                  </div>
                </div>

                {/* স্বল্প খরচে ঘরে বসেই উন্নতমানের */}
                <div className="tw-bg-blue-100 tw-rounded-3xl tw-px-6 tw-pt-10 tw-flex tw-flex-col">
                  <div className="tw-flex-grow">
                    <h2 className="tw-text-2xl md:tw-text-4xl tw-font-bold tw-pl-4">
                        {t("Those interested in receiving high-quality")} <br /> {t("education at a low cost from home")}
                    </h2>
                  </div>
                  <div className="tw-flex tw-justify-end tw-items-end">
                    <img src={computer} alt="icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FAQ */}
          <div className="tw-px-4 md:tw-px-10">
            <div className="tw-mt-16 tw-bg-gray-50 tw-rounded-3xl">
              <div className="md:tw-w-3/5 tw-mx-auto tw-py-16">
                <h2 className="tw-text-xl tw-font-semibold tw-ml-12 tw-mb-8">
                    {t("General")} <span className="tw-text-[#6F39B7]">{t("Questions")}</span>
                </h2>
                {faqs.map((faq) => (
                  <Faq course_faq={faq} handleButtonClick={handleButtonClick} />
                ))}
              </div>
            </div>
          </div>

          {/* Footer  */}
          <div className="tw-px-4 md:tw-px-10 tw-mt-16">
            <FormFooter />
          </div>
        </div>
      </div>
      {<GreetingMsg open={open} setOpen={setOpen} />}
    </div>
  );
};

export default Batch;