import React, { useEffect } from 'react';
import parse from 'html-react-parser';

const BroadQuestion = ({question, handelBroadQuestion, endSlice, index}) => {
    useEffect(()=>{
        handelBroadQuestion(null, question?.id);
    },[])
    const handleBroadQuestionAns = (event) => {
        handelBroadQuestion(event.target.value, question?.id);
    };
    return (
        <>
            <div className='d-flex justify-content-between mb-4 mt-5'>
                <p className='mb-0' style={{ fontWeight:'400' }}>Question No #{index ? index : endSlice}</p>
                <p className='mb-0' style={{ fontWeight:'400' }}>Question Type: 
                    <span className='single-question-type ms-1'>
                        {question?.question_type_id === 1 ? 'Multiple Choice Question': 
                        question?.question_type_id === 2 ? 'Multiple Answer': 
                        question?.question_type_id === 3 ? 'TrueFalse': 
                        question?.question_type_id === 4 ? 'Broad Question': ''}
                    </span>
                </p>
            </div>
        <div className='single-question text-center unselectable'>{parse(question?.title)}</div>
        <h1>Answer: </h1>
            <div className="d-flex justify-content-center mb-5">
                <textarea className="form-control" name="broad_answer" placeholder="Write your answer" onChange={handleBroadQuestionAns}  rows="6" style={{ marginBottom:'6rem' }}></textarea>
            </div>
            
        </>
    );
};

export default BroadQuestion;