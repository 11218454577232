import React from 'react';
import { dateConverterEnglishWithOutYear, formatAMPM, topicTypeName } from '../../../../../../../../../utilities/utilities';

const AssignmentModal = ({syllabusContent}) => {
    return (
        <div className='modal-assessment-details'>
            <table className="table table-borderless mb-0">
                <tbody className='modal-assessment-details-text'>
                    <tr className="">
                        <td className="assessment-details">বিষয়</td>
                        <td className="assessment-details">{topicTypeName(syllabusContent?.assesment_type).name}</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr> 
                    <tr className="">
                        <td className="assessment-details">Questions</td>
                        <td className="assessment-details">{syllabusContent?.assignment?.total_questions < 10 && '0'}{syllabusContent?.assignment?.total_questions}</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>
                    <tr className="">
                        <td className="assessment-details">Marks</td>
                        <td className="assessment-details">{syllabusContent?.assignment?.total_marks < 10 && '0'}{syllabusContent?.assignment?.total_marks}</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>
                    <tr className="">
                        <td className="assessment-details">Time</td>
                        <td className="assessment-details">{syllabusContent?.assignment?.assignment_duration < 10 && '0'}{syllabusContent?.assignment?.assignment_duration} Minutes</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>
                    <tr className="">
                        <td className="assessment-details">Negative Marking</td>
                        <td className="assessment-details">OFF</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>
                    <tr className="">
                        <td className="assessment-details">Number of attempts</td>
                        <td className="assessment-details">{syllabusContent?.assignment?.no_of_attempts < 10  && '0'}{syllabusContent?.assignment?.no_of_attempts} Times</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>
                    <tr className="">
                        <td className="assessment-details">Remaining attempt</td>
                        <td className="assessment-details">{syllabusContent?.assignment?.no_of_attempts - syllabusContent?.assignment?.total_attempts < 10 && syllabusContent?.assignment?.no_of_attempts - syllabusContent?.assignment?.total_attempts >= 0 && '0'}{syllabusContent?.assignment?.no_of_attempts - syllabusContent?.assignment?.total_attempts} Times</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>
                    <tr className="">
                        <td className="assessment-details">Due Date</td>
                        <td className="assessment-details">{formatAMPM(new Date(syllabusContent?.assignment?.assignment_due_date))} &nbsp; {dateConverterEnglishWithOutYear(new Date(syllabusContent?.assignment?.assignment_due_date))}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AssignmentModal;