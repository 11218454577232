import React from "react";
import { Link } from "react-router-dom";
import CertificateDemo from '../../../../../images/certificate-demo.webp'
const StudentCertificate = ({Name, CourseName}) => {

    
    return (
        <div className="bg-white custom-card-shadow" style={{ borderRadius: "16px" }}>
            <div className="d-flex justify-content-between align-items-center px-4">
                <p className="course-total-assignment mb-0 ps-0">সার্টিফিকেট</p>
            </div>
            {/* certificate-demo */}
            <div className="total-assignment-divider"></div>

            <div className="attendance-section px-4 pb-4">

                <div className='attendence_list_section'>
                    <div className="row">
                        <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12" >
                            <Link to={`/certificate`} state={{ course_name: Name, user_name: CourseName }}
                            style={{ textDecoration:'none' }}>
                                {/* বিস্তারিত দেখুন */}
            
                                <div className="p-3 card-body my_course_card_body custom-card-shadow">
                                    <div className="row">
                                        <div className="col-lg-12 responsive-course-image">
                                            
                                            <div style={{position: "relative"}}>
                                                <img
                                                src={`${CertificateDemo}`}
                                                className="w-100"
                                                alt=""
                                                style={{ borderRadius: "15px" }}
                                            />

                                            <div style={{position: "absolute", top: 0, left: 0, height: "100%", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "15px"}}>
                                                    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                                                        <i className="fas fa-eye" style={{color: "white", fontSize: "2em"}}></i>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="col-lg-12">
                                            
                                            <p  className="btn-course text-center mb-0 mt-3">
                                                ডাউনলোড করুন
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    );
};

export default StudentCertificate;
