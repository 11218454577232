import React, { useEffect, useState } from "react";
import {  useLocation } from "react-router-dom";
/* import { useParams } from "react-router-dom"; */
import CourseShortInformation from "../../shared/CourseDetails/CourseShortInformation/CourseShortInformation";
import NoVideo from "../../../../images/default_video_banner.jpg";
import "./FreeClassRegistration.css";
import PageTitle from "../../shared/PageTitle/PageTitle";
import { decode as base64_decode} from "base-64";
import { toast } from "react-toastify";
import utils from "../../../../utils/index";
const FreeClassRegistration = () => {
    const [courseDetail, setCourseDetail] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const freeClassRegistration = true;
    /* const { id } = useParams(); */
    
    const [value, setValue] = useState("");
    const search = useLocation().search;
    const course_id = new URLSearchParams(search).get("course_id");
    let id = base64_decode(`${course_id}`);

    useEffect(() => {
        async function fetchMyAPI() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/course-detail?course_id=${id}`
            );
            response = await response.json();
            setCourseDetail(response.data);
            setIsLoading(false);
        }
        fetchMyAPI();
        const pixelData = {page: 'FreeClassRegistration'};
        utils.addFacebookEvent('Registration', pixelData);

        async function fetchDistricts() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/districts`
            );
            response = await response.json();
            setDistricts(response.data.districts);
            setIsLoading(false);
        }
        fetchDistricts();
    }, [id]);

    const handleChange = (event) => {
        const result = event.target.value.replace(/\D/g, "");
        setValue(result);
    };

    const handelFreeClassForm = (event) => {
        event.preventDefault();

        const student_formInfo = {
            student_name: event.target.name.value,
            institution_name: event.target.school_name.value,
            student_age: event.target.age.value,
            contact_number: value,
            student_email: event.target.email.value,
            class_name: event.target.class_name.value,
            district: event.target.district.value,
            comment: event.target.opinion.value,
            course_id: id,
        };

        fetch(`${process.env.REACT_APP_BASE_URL}api/free-course-registration`,{
            method: 'POST',
            headers:{
                'content-type': 'application/json',
            },
            body: JSON.stringify(student_formInfo)
        })
        .then(res => res.json())
        .then(data =>{
            if(data.message_type === 'success'){
                toast.success(data.message);
                event.target.reset();
                setValue('');
            }else{
                toast.error(data.message);
            }
        })
    };

    return (
        <div>
            <PageTitle title="Free Class Form"></PageTitle>
            <>
                <div className="borderTop" style={{ paddingBottom: "23rem" }}>
                    <div className="container">
                        <div className="row mx-0 gy-3 course-detail-top">
                            <div className="col-lg-9">
                                <p className="course-name">
                                    {courseDetail?.course_detail?.course_name}
                                </p>

                                {courseDetail?.course_detail
                                    ?.course_introduction_video_link !==
                                    undefined &&
                                courseDetail?.course_detail
                                    ?.course_introduction_video_link !==
                                    null ? (
                                    <iframe
                                        className="course-video"
                                        src={`https://www.youtube.com/embed/${courseDetail?.course_detail?.course_introduction_video_link}`}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                ) : (
                                    <img
                                        src={NoVideo}
                                        alt=""
                                        className="my-4"
                                        style={{ width: "100%" }}
                                    />
                                )}
 
                                <div className="free-class-form-section">
                                    <form onSubmit={handelFreeClassForm}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <p className='mb-2 font-400'>তোমার  নাম<span className='text-danger'>*</span></p>
                                                <input className="mb-3 form-control form-control-lg student-profile-info" name="name" type="text" placeholder="তোমার নাম লিখ" required/>
                                            </div>

                                            <div className="col-lg-12">
                                                <p className='mb-2 font-400'>শিক্ষা প্রতিষ্ঠানের নাম <span className='text-danger'>*</span></p>
                                                <input className="mb-3 form-control form-control-lg student-profile-info" name="school_name" type="text" placeholder="তোমার প্রতিষ্ঠানের নাম লিখ " required/>
                                            </div>
                                            <div className="col-lg-12">
                                                <p className='mb-2 font-400'>তোমার বয়স<span className='text-danger'>*</span></p>
                                                <input className="mb-3 form-control form-control-lg student-profile-info" name="age" type="text" placeholder="তোমার বয়স" required/>
                                            </div>
                                            <div className="col-lg-12">
                                                <p className='mb-2 font-400'>যোগাযোগের নম্বর <span className='text-danger'>*</span></p>
                                                <input className="mb-3 form-control form-control-lg student-profile-info" name="contact_number" type="text" placeholder="তোমার যোগাযোগের নম্বর" value={value}
                                                onChange={handleChange} required/>
                                            </div>
                                            <div className="col-lg-12">
                                                <p className='mb-2 font-400'>ই-মেইল</p>
                                                <input className="mb-3 form-control form-control-lg student-profile-info" name="email" type="text" placeholder="তোমার যোগাযোগের নম্বর ই-মেইল"/>
                                            </div>
                                            <div className="col-lg-12">
                                                <p className='mb-2 font-400'>ক্লাস <span className='text-danger'>*</span></p>
                                                <select name='class_name' className=" mb-3 form-select form-control form-control-lg student-profile-info" id="select_box" required>
                                                    <option value="" required>তোমার ক্লাস</option>
                                                    {courseDetail?.class_lists?.map((class_list) => (
                                                        <option key={class_list?.class_id} value={`${class_list.class_name}`}>
                                                             {class_list.class_name}
                                                        </option>
                                                    ))}
                                                    
                                                </select>
                                            </div>
                                            <div className="col-lg-12">
                                                <p className='mb-2 font-400'>জেলা <span className='text-danger'>*</span></p>
                                                <select name='district' className=" mb-3 form-select form-control form-control-lg student-profile-info" id="select_box" required>
                                                    <option value="" required>তোমার জেলা</option>
                                                    {districts.map((district) => (
                                                        <option key={district.district_id} value={`${district.district_en_name} - ${district.district_bn_name}`}>
                                                            {district.district_en_name} - {district.district_bn_name}
                                                        </option>
                                                    ))}
                                                    
                                                </select>
                                            </div>

                                            <div className="col-lg-12">
                                                <p className='mb-2 font-400'>মন্তব্য</p>
                                                <textarea className="mb-3 form-control form-control-lg" name="opinion" rows="3"></textarea>
                                            </div>

                                        </div>

                                        <div className=" d-flex justify-content-center">
                                        <input className="btn text-white mt-4 px-5 py-2" style={{ backgroundColor:'#532D80', fontSize:'22' }} type="submit" value='সাবমিট'></input>
                                        </div>
                                        </form>
                                </div>
                            </div>
                            <div className="col-lg-3 ">
                                <CourseShortInformation
                                    freeClassRegistration={
                                        freeClassRegistration
                                    }
                                    course_features={
                                        courseDetail?.course_features
                                    }
                                    course_teachers={
                                        courseDetail?.course_teachers
                                    }
                                    teacher_profile_path={
                                        courseDetail?.teacher_profile_picture_path
                                    }
                                ></CourseShortInformation>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    ); 
};

export default FreeClassRegistration;
