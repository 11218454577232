import React from 'react';
import { Modal } from 'react-bootstrap';

const NotificationShowModal = (props) => {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props?.notificationdetails?.notification?.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-center mb-3'>
                    {
                        props?.notificationdetails?.notification?.image === null ? '' : <img src={`${props?.notificationdetails?.image_url}${props?.notificationdetails?.notification?.image}`} alt="" className='img-fluid' style={{ borderRadius:'12px' }} />
                    }
                </div>
                <div>
                    <p style={{ fontSize:'18px' }}>{props?.notificationdetails?.notification?.body}</p>
                </div>
                <div dangerouslySetInnerHTML={{__html: props?.notificationdetails?.notification?.others}}/>
                {/* <span style={{ fontSize:'18px' }}>{props?.notificationdetails?.notification?.push_notification_message}</span> */}
            </Modal.Body>
        </Modal>
    );
};

export default NotificationShowModal;