import React, { useEffect, useState } from "react";
import cadetBanner from '../../../../images/website-banner.png';
import webBanner from '../../../../images/quizImage/ব্রেইনের সক্ষমতা-যাচাই-করুন-ফ্রি-তে-v2.jpg';
import eidBanner from '../../../../images/banner1.png';
import eft from '../../../../images/eft/WebBannerEnglishFluency.jpg';
import { Link } from "react-router-dom";
import './FreeDemoClass.css';
import  abacusSpeed from '../../../../images/Abacus_Speed_banner.png';
import {encode as base64_encode} from 'base-64';

const FreeDemoClass = ({homePageInfo}) => {
    const [isShaking, setIsShaking] = useState(false);
    const [shakingForBtn, setShakingForBtn] = useState(false);


    useEffect(() => {
        const shakeInterval = setInterval(() => {
            setIsShaking(true);
            setTimeout(() => setIsShaking(false), 820);
        }, 2000);
        return () => clearInterval(shakeInterval);
    }, [])

    useEffect(() => {
        const shakeIntervalForBtn = setInterval(() => {
            setShakingForBtn(true);
            setTimeout(() => setShakingForBtn(false), 820);
        }, 3000);
        return () => clearInterval(shakeIntervalForBtn);
    }, [])

    // function handleInputChange(event) {
    //     const newValue = event.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
    //     setInputMobileNumber(newValue);
    // }

    // const handelShowBookingSlotList = (e) =>{
    //     e.preventDefault();

    //     fetch(`${process.env.REACT_APP_BASE_URL}api/get-orientation-class-user-details?mobile_number=${inputMobileNumber}`,{
    //         method:'get',
    //         headers:{
    //             'content-type' : 'application/json',
    //         },
    //     })
    //     .then((res) => res.json())
    //     .then((data) => {
    //         if(data?.message_type === 'success' && data?.data?.user_details?.id){   
    //          navigate("/schedule/class-list", {
    //                     state: {
    //                         free_class_user_id: data?.data?.user_details?.id,
    //                         age: data?.data?.user_details?.age
    //                     },
    //                 });
    //                 setShow(false);

    //         }else{
    //             toast.error(data?.message);
    //             setShow(false);
    //         }
    //     });
    // }
    let encoded = base64_encode(85);

    return (
        <>
            {/* free class section */}
            <div className="position-sticky bg-white schedule-section position-relative" style={{position: 'relative'}}>
                <div className="container">
                    <div className="row">


                        <div className={`col-lg-5 position-relative ${shakingForBtn ? ' shake' : ''}`}>
                            <Link to='/english-fluency-test'>
                                <div style={{height: '100%'}}>
                                    <img src={eft} style={{borderRadius: '8px', height: '100%'}}
                                         className='img-fluid' alt="schedule-class"/>

                                </div>
                            </Link>
                        </div>


                        <div className="col-lg-2"></div>
                        <div className={`col-lg-5 position-relative ${shakingForBtn ? ' shake' : ''}`}>
                            <Link to='/abacus-campaign'>
                                <div style={{height: '100%'}}>
                                    <img src={webBanner} style={{borderRadius: '8px', height: '100%'}}
                                         className='img-fluid' alt="schedule-class"/>

                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>


            {/* modal */}
            {/* <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton style={{ border:'none' }}>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handelShowBookingSlotList}>
                        <p>ওরিয়েন্টেশন ক্লাসে জয়েন করার জন্য তোমার মোবাইল নম্বর লিখ <span className="text-danger">(যে নম্বর দিয়ে রেজিস্ট্রেশন করেছ)</span></p>
                        <div className="row">
                        <div className="col-lg-12">
                            <p className='mb-2 font-400'>Mobile Number <span className='text-danger'>*</span></p>
                            <input className="mb-3 form-control form-control-lg student-profile-info" value={inputMobileNumber} onChange={handleInputChange} name="student_phone_number" type="text" placeholder="Enter Your Mobile Number" required />
                        </div>
                        </div>
                        <div className=" d-flex justify-content-center">
                        <input className="btn text-white py-2 px-4" style={{ backgroundColor:'#532D80', fontSize:'22' }} type="submit" value='Submit'></input>
                        </div>
                    </form>
                </Modal.Body>
            </Modal> */}
        </>
    );
};

export default FreeDemoClass;