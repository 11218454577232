import React, { useEffect, useState } from 'react';
import TopCircle from '../../../../../../images/top_circle.png'
import BottomCircle from '../../../../../../images/bottom_circle.png'
import CircleIcon from "../../../../../../images/course_details_icon/arrow_white.png"
import {englishToBengaliNumberFormat} from '../../../../../../utilities/utilities';
import { useLocation } from 'react-router-dom';
import { decode as base64_decode} from "base-64";
/* import { toast } from "react-toastify"; */

const CourseEnroll = ({courseDetail,handelPaymentDetail, isCoursePurchase}) => {
    const search = useLocation().search;
    const course_id = new URLSearchParams(search).get("course_id");
    let decode_course_id = base64_decode(`${course_id}`);

    const user_id = localStorage.getItem("user_id");
    const [clicked, setClicked] = useState(false);
    useEffect(() => {
    if (!clicked) {
        if(document.getElementById("myButton"))
            document.getElementById("myButton").click();
        setClicked(true);
    }
  }, [clicked]);


    


    /* const handelFreeCoursePurchases = event =>{
        event.preventDefault();
        
        const freeCourseInfo = {
            user_id: user_id,
            course_id: decode_course_id
        }

            fetch(`${process.env.REACT_APP_BASE_URL}api/free-course-subscription`,{
                method: 'POST',
                headers:{
                    'content-type' : 'application/json'
                },
                body: JSON.stringify(freeCourseInfo)
            })
            .then(res => res.json())
            .then(data =>{
                if(data.message_type === 'success'){
                    toast.success(data.message);
                }else{
                    toast.error(data.message);
                }
            })
        }; */

    return (
        <div>
            <div  className="position-relative" >
                
                    <div className="course-enroll position-relative">
                        <div className="position-absolute top-0 end-0">
                            <img src={TopCircle} alt=""/>
                        </div>
                        <div className='align-items-center justify-content-around mb-3'>

                        {
                            (courseDetail?.course_initial_price === '0' && courseDetail?.course_current_price === '0') ? <p className="mb-0 text-center" style={{ color: '#ffffff', fontSize:'36px', fontWeight:'600'}}>ফ্রি কোর্স</p> 
                            : (courseDetail?.course_initial_price !== '0' && courseDetail?.course_current_price !== '0'&& courseDetail?.course_initial_price === courseDetail?.course_current_price) ? <p className="balance_symbol text-center" style={{ fontSize: '36px', color:'#ffffff' }}>৳<span>{englishToBengaliNumberFormat(courseDetail?.course_initial_price)}</span></p>
                            : <>
                                <p className='price text-white mb-0 text-center '><del className='balooda-medium'>৳ {englishToBengaliNumberFormat(courseDetail?.course_initial_price)}</del></p>
                                <p className='offer-price text-white mb-0 text-center balooda-medium' style={{ fontSize:"35px" }}><span className='balooda-medium'>৳ </span><span className='balooda-medium'>{englishToBengaliNumberFormat(courseDetail?.course_current_price)}</span></p>
                             </>
                        }
                            
                        </div>
                        {/* OffCanvas */}
                        <div className='buy-course'>
                            {
                                isCoursePurchase ? <p className='mb-0'>ইতিমধ্যে ক্রয়কৃত</p> : 
                                <button className='buy-course' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" id="myButton" onClick={()=>handelPaymentDetail(user_id, decode_course_id)}>
                                    কোর্সে ভর্তি হও
                                </button>
                            }
                        </div>

                        {/* <div>
                            {
                                (courseDetail?.course_initial_price === '0' && courseDetail?.course_current_price === '0') ? 
                                <button className='buy-course'
                                    onClick={handelFreeCoursePurchases}
                                >
                                    কোর্সে ভর্তি হও
                                </button>
                                
                                 :
                                <button
                                className='buy-course'
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight"
                                    aria-controls="offcanvasRight"
                                    onClick={()=>handelPaymentDetail(user_id, decode_course_id)}
                                >
                                    কোর্সে ভর্তি হও
                                </button>
                            }
                            
                        </div> */}

                        <p className='text-center for-help font-16'>সাহায্যের জন্য: {courseDetail?.help_line}</p>
                        
                        {
                            courseDetail?.course_video_related_info && 
                                <div className='d-flex align-items-center course-duration'>
                                    <img src={CircleIcon} alt="" height={20} width={20}/>
                                    <p className='mb-0 text-white ps-3'>{courseDetail?.course_video_related_info}</p>
                                </div>
                        }
                        {
                            courseDetail?.course_access_related_info && 
                                <div className='d-flex align-items-center course-duration'>
                                    <img src={CircleIcon} alt="" height={20} width={20}/>
                                    <p className='mb-0 text-white ps-3'>{courseDetail?.course_access_related_info}</p>
                                </div>
                        }
                        {
                            courseDetail?.course_certificate_related_info && 
                                <div className='d-flex align-items-center course-duration'>
                                    <img src={CircleIcon} alt="" height={20} width={20}/>
                                    <p className='mb-0 text-white ps-3'>{courseDetail?.course_certificate_related_info}</p>
                                </div>
                        }

                        {
                            !courseDetail?.course_video_related_info && !courseDetail?.course_access_related_info && !courseDetail?.course_certificate_related_info &&
                                <>
                                    <br />
                                    <br />
                                    <br />
                                </>
                        }

                        {/*<div className='d-flex align-items-center course-duration'>
                            <img src={CircleIcon} alt="" height={20} width={20}/>
                            <p className='mb-0 text-white ps-3' style={{ fontSize:'16px' }}>{courseDetail?.course_video_related_info}</p>
                        </div>

                         <div className='d-flex align-items-center course-duration'>
                            <img src={CircleIcon} alt="" height={20} width={20}/>
                            <p className='mb-0 text-white ps-3' style={{ fontSize:'16px' }}>{courseDetail?.course_access_related_info}</p>
                        </div>

                        <div className='d-flex align-items-center course-duration'>
                            <img src={CircleIcon} alt="" height={20} width={20}/>
                            <p className='mb-0 text-white ps-3' style={{ fontSize:'16px' }}>{courseDetail?.course_certificate_related_info}</p>
                        </div>*/}
                        <div className="position-absolute bottom-0 start-0">
                            <img src={BottomCircle} alt=""/>
                        </div> 

                        
                </div>
            </div>
        </div>
    );
};

export default CourseEnroll;