import React,{ useEffect, useState, useContext }from 'react';
import { LMSContext } from '../LMS';
import bookIcon from '../../../../../images/icon_book.webp';
import './ProgressReport.css';
import { getMonth } from '../../../../../utilities/utilities';

const ProgressReport = ({courseBasicInfo}) => {
    const [reportValue, setReportValue] = useState([]);
    const { batch_id, course_id } = useContext(LMSContext);
    const [month, setMonth] = useState(null);

    // console.log(courseBasicInfo);

    useEffect(() => {
        if (courseBasicInfo?.course_start_date && courseBasicInfo?.course_end_date) {
            const today = new Date();
            const currentMonth = today.getMonth() + 1;
            const currentYear = today.getFullYear();
            const batchStartDate = new Date(courseBasicInfo?.course_start_date);
            const batchEndDate = new Date(courseBasicInfo?.course_end_date);
            const startMonth = batchStartDate.getMonth() + 1;
            const endMonth = batchEndDate.getMonth() + 1;
            const endYear = batchEndDate.getFullYear();
        }
    }, [courseBasicInfo]);


    useEffect(() => {
        if (courseBasicInfo?.course_start_date && courseBasicInfo?.course_end_date && month === null){ // Add condition to check if month is null
            function formatDate(dateString) {
                const date = new Date(dateString);
                const month = date.toLocaleString('default', { month: 'long' });
                const year = date.getFullYear();
                return `${month}-${year}`;
            }
            const formattedDate = formatDate(courseBasicInfo?.course_start_date);
            setMonth(formattedDate);
        }
    }, [courseBasicInfo, month]);




    const generateMonthOptions = () => {
        const startMonth = new Date(courseBasicInfo?.course_start_date).getMonth();
        const endMonth = new Date(courseBasicInfo?.course_end_date).getMonth();
        const startYear = new Date(courseBasicInfo?.course_start_date).getFullYear();
        const endYear = new Date(courseBasicInfo?.course_end_date).getFullYear();
        const monthOptions = [];

        for (let year = startYear; year <= endYear; year++) {
            const start = year === startYear ? startMonth : 0;
            const end = year === endYear ? endMonth : 11;

            for (let month = start; month <= end; month++) {
                const monthYearString = `${new Date(year, month).toLocaleString('default', { month: 'long' })}-${year}`;
                monthOptions.push(<option key={`${year}-${month}`} value={monthYearString}>{monthYearString}</option>);
            }
        }

        return monthOptions;
    };

    function monthNameToNumber(monthName) {
        const months = {
            January: 1, February: 2, March: 3, April: 4, May: 5, June: 6,
            July: 7, August: 8, September: 9, October: 10, November: 11, December: 12
        };
        return months[monthName];
    }

    function convertToDesiredFormat(selectedOption) {
        if (!selectedOption) return null; // Add a check for null or undefined
        const [month, year] = selectedOption.split('-');
        const monthNumber = monthNameToNumber(month);
        let monthNumberWithZero = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
        return `${year}-${monthNumberWithZero}`;
    }

    useEffect(() => {
        if (month !== null) {
            const desiredFormat = convertToDesiredFormat(month);
            fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}student-progress-report-v2?course_id=${course_id}&batch_id=${batch_id}&user_id=${localStorage.getItem("user_id")}&search_date=${desiredFormat}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
                    'SecretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                },
            })
                .then((res) => res.json())
                .then((data) => setReportValue(data.data));
        }
    }, [month, course_id, batch_id]);


    const [imageLoadedMap, setImageLoadedMap] = useState({});
    const handleImageLoad = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: true
        }));
    };

    const handleImageError = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: false
        }));
    };


    return (

        <div className="row gx-5 mx-0">
            <div className=' col-lg-3 lms-subject-section'>
                <div className="nav flex-column nav-pills assignment-subject p-2 bg-white custom-card-shadow mb-4" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ borderRadius:'16px' }}>

                    {
                        reportValue?.course_subjects?.map((course_subject, index) => 
                            <button key={course_subject?.course_subject_id} className={`nav-link text-dark mt-2 ${index === 0 ? 'active' : ''}`} id={`v-pills-progress-report-subject_${course_subject?.course_subject_id}-tab`} data-bs-toggle="pill" data-bs-target={`#v-pills-progress-report-subject_${course_subject?.course_subject_id}`} type="button" role="tab" aria-controls={`v-pills-progress-report-subject_${course_subject?.course_subject_id}`} aria-selected="false">
                                {/* <img src={`${lectures?.subject_image_path}${course_subject?.subject_image}`} alt="" width={48} height={48} className='me-2' style={{ borderRadius: '8px' }}/> */}
                                {imageLoadedMap[index] !== false && (
                                <img
                                    src={`${reportValue?.subject_image_path}${course_subject?.subject_image}`}
                                    width={48} height={48} alt=""
                                    className="px-1"
                                    onLoad={() => handleImageLoad(index)}
                                    onError={() => handleImageError(index)}
                                />
                                )}
                                {imageLoadedMap[index] === false && <img
                                    src={bookIcon}
                                    width={48} height={48} alt=""
                                />}
                                {course_subject?.subject_name}
                            </button>

                        )
                    }
                    
                </div>
            </div>
            <div className="col-lg-9 px-0 mx-0 tab-content bg-white custom-card-shadow all-assignment-section" id="v-pills-tabContent">
            {
                reportValue?.course_subjects?.map((course_subject, index) => {
                        return(
                            <div key={course_subject?.course_subject_id} className={index === 0 ? 'tab-pane fade active show' : 'tab-pane fade' } id={`v-pills-progress-report-subject_${course_subject?.course_subject_id}`} role="tabpanel" aria-labelledby={`v-pills-progress-report-subject_${course_subject?.course_subject_id}-tab`}>

                            <div className='d-flex justify-content-between align-items-center course-total-assignment p-4'>
                                <p className='mb-0'>{course_subject?.subject_name} মূল্যায়ন পত্র</p>
                                
                                    
                                <select
                                    name="religion"
                                    className="form-select form-control form-control-lg student-profile-info"
                                    style={{ width: '200px' }}
                                    id="select_box"
                                    onChange={(e) => setMonth(e.target.value)}
                                    >
                                    {
                                        generateMonthOptions()
                                    }
                                    
                                </select>

                            </div>
                            <div className='total-assignment-divider'></div>
                            
                            {/* <h5>মাসের মূল্যায়ন পত্র</h5> */}
                            <p className='progress-report-month-title'> <span>{month} </span> মাসের মূল্যায়ন পত্র</p>
                            <div className="mid-section">
                                    <div className="bg-white p-3 mt-2 table-responsive" style={{ borderRadius: "15px", height:'90%', overflowY:'auto' }}>
                                        <table className="w-100">
                                            <thead>
                                                <tr className="text-center table-title" style={{ backgroundColor: '#F4F1EB', borderRadius:'8px ' }}>
                                                    <th className="py-2">Evaluation Point</th>
                                                    <th className="py-2">Obtained Mark</th>
                                                    <th className="py-2">Last Month Obtained Mark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="text-center" style={{fontSize: '16px', fontWeight: '500'}}>
                                                <td className="py-3">Attendance</td>
                                                {
                                                    reportValue?.progress_report?.map(value => {
                                                        if (course_subject?.course_subject_id === value?.course_subject_id) {
                                                            return (
                                                                <React.Fragment key={value.course_subject_id}>
                                                                    <td className="py-3">{value.obtain_attendance} / {value.total_attendance}</td>
                                                                    <td className="py-3">{value.obtain_prev_month_attendance} / {value.prev_month_total_attendance}</td>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        return null;

                                                    })
                                                }

                                            </tr>

                                            <tr className="text-center" style={{fontSize: '16px', fontWeight: '500'}}>
                                                <td className="py-3">Home Work</td>
                                                {
                                                    reportValue?.progress_report?.map(value => {
                                                        if (course_subject?.course_subject_id === value?.course_subject_id) {
                                                            return (
                                                                <React.Fragment key={value.course_subject_id}>
                                                                    <td className="py-3">{value.obtain_home_work} / {value.total_home_work}</td>
                                                                    <td className="py-3">{value.obtain_prev_month_home_work} / {value.prev_month_total_home_work}</td>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        return null;

                                                    })
                                                }

                                            </tr>


                                            <tr className="text-center" style={{fontSize: '16px', fontWeight: '500'}}>
                                                <td className="py-3">Class Performance / Class Test</td>
                                                {
                                                    reportValue?.progress_report?.map(value => {
                                                        if (course_subject?.course_subject_id === value?.course_subject_id) {
                                                            return (
                                                                <React.Fragment key={value.course_subject_id}>
                                                                    <td className="py-3">{value.obtain_class_performance} / {value.total_class_performance}</td>
                                                                    <td className="py-3">{value.obtain_prev_month_class_performance} / {value.prev_month_total_class_performance}</td>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        return null;

                                                    })
                                                }

                                            </tr>

                                            <tr className="text-center" style={{fontSize: '16px', fontWeight: '500'}}>
                                                <td className="py-3">Assessment / Exam</td>
                                                {
                                                    reportValue?.progress_report?.map(value => {
                                                        if (course_subject?.course_subject_id === value?.course_subject_id) {
                                                            return (
                                                                <React.Fragment key={value.course_subject_id}>
                                                                    <td className="py-3">{value.obtain_assessment} / {value.total_assessment}</td>
                                                                    <td className="py-3">{value.obtain_prev_month_assessment} / {value.prev_month_total_assessment}</td>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        return null;

                                                    })
                                                }

                                            </tr>

                                            <tr>
                                                <th colSpan="4">
                                                    <div className='total-assignment-divider'
                                                         style={{backgroundColor: '#d0d0d0'}}></div>
                                                </th>
                                            </tr>

                                            <tr className="text-center" style={{fontSize: '16px', fontWeight: '500'}}>
                                                <td className="py-3">Total</td>
                                                {
                                                    reportValue?.progress_report?.map(value => {
                                                        if (course_subject?.course_subject_id === value?.course_subject_id) {
                                                            return (
                                                                <React.Fragment key={value.course_subject_id}>
                                                                    <td className="py-3">{value.obtain_total_mark} / {value.total_mark}</td>
                                                                    <td className="py-3">{value.prev_month_total_obtain} / {value.prev_month_total_mark}</td>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        return null;

                                                    })
                                                }

                                            </tr>


                                            <tr className="text-center" style={{fontSize: '16px', fontWeight: '500'}}>
                                                <td className="py-3">Percentage</td>
                                                {
                                                    reportValue?.progress_report?.map(value => {
                                                        if (course_subject?.course_subject_id === value?.course_subject_id) {
                                                            return (
                                                                <React.Fragment key={value.course_subject_id}>
                                                                    <td className="py-3">{value.percentage} %</td>
                                                                    <td className="py-3">{value.prev_month_percentage} %</td>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        return null;

                                                    })
                                                }

                                            </tr>
                                            <tr className="text-center" style={{fontSize: '16px', fontWeight: '500'}}>
                                                <td className="py-3">Remark</td>
                                                {
                                                    reportValue?.progress_report?.map(value => {
                                                        if (course_subject?.course_subject_id === value?.course_subject_id) {
                                                            return (
                                                                <React.Fragment key={value.course_subject_id}>
                                                                    <td className="py-3">{value?.remark} </td>
                                                                    <td className="py-3">{value?.prev_month_remark} </td>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                        return null;

                                                    })
                                                }

                                            </tr>


                                            </tbody>
                                        </table>
                                    </div>
                            </div>


                                <p className='text-center mb-4' style={{color: '#BDBCBE'}}>আপনি তালিকার শেষে পৌঁছে
                                    গিয়েছেন</p>
                            </div>
                        )
                    }
                )
            }

            </div>
        </div>


    );
};

export default ProgressReport;