// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyATBOKqrqHqGV891tnyue6xNZyI02Dcfes",
//   authDomain: "mojaru-app-event.firebaseapp.com",
//   projectId: "mojaru-app-event",
//   storageBucket: "mojaru-app-event.firebasestorage.app",
//   messagingSenderId: "327183012466",
//   appId: "1:327183012466:web:dc9e2848fe367a3c9328eb",
//   measurementId: "G-FJFENVE9CQ",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const messaging = getMessaging(app);

// export const generateToken = async () => {
//   const permission = await Notification.requestPermission();
//   if (permission === "granted") {
//     const token = await getToken(messaging, {
//       vapidKey:
//         "BFr8G4Z92GBaBjvMAUi153kdIALmafTqVM8FC26fidVOPbNuMuvQdu0MeVgjBcJZs8rNF4AMOkKkxmcxPeeIMyY",
//     });
//     localStorage.setItem("notification-token", token);
//   }
// };

// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyATBOKqrqHqGV891tnyue6xNZyI02Dcfes",
  authDomain: "mojaru-app-event.firebaseapp.com",
  projectId: "mojaru-app-event",
  storageBucket: "mojaru-app-event.firebasestorage.app",
  messagingSenderId: "327183012466",
  appId: "1:327183012466:web:dc9e2848fe367a3c9328eb",
  measurementId: "G-FJFENVE9CQ",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    try {
      const token = await getToken(messaging, {
        vapidKey:
          "BFr8G4Z92GBaBjvMAUi153kdIALmafTqVM8FC26fidVOPbNuMuvQdu0MeVgjBcJZs8rNF4AMOkKkxmcxPeeIMyY",
      });
      localStorage.setItem("notification-token", token);
      return token;
    } catch (error) {
      console.error("Error retrieving token: ", error);
    }
  } else {
    console.warn("Notification permission not granted");
  }
};
