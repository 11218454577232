import React from 'react';
import defaultUser from "../../../../../images/user.png";

const CourseTeacher = ({teacher, teacherImagePath}) => {
    const imageUrl = teacherImagePath+teacher.teacher_profile_picture;
    return (
        <div className="tw-bg-white">
            <div className="tw-flex tw-items-center">
                {
                    teacher.teacher_profile_picture ? <img
                        className="tw-w-16 tw-h-16 tw-rounded-full tw-mr-3 tw-object-cover"
                        src={imageUrl}
                        alt="Teacher"
                    /> : <img
                        className="tw-w-16 tw-h-16 tw-rounded-full tw-mr-3 tw-object-cover"
                        src={defaultUser}
                        alt="Teacher"
                    />
                }
                <div>
                    <p className="tw-text-lg">{teacher?.teacher_name}</p>
                    <p className="tw-text-[14px] tw-text-gray-500 tw-line-clamp-2">
                        {teacher?.teacher_details}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CourseTeacher;