import React from 'react';
import { dateConverterEnglishWithOutYear, formatAMPM, topicTypeName } from '../../../../../../../../../utilities/utilities';

const LiveClass = ({syllabusContent}) => {
    let classDuration = Math.abs(new Date(syllabusContent?.live_class?.live_class_end_time) - new Date(syllabusContent?.live_class?.live_class_start_time));
    let convertInHours = classDuration / 3600000;
    /*  */
    return (
        <div className='modal-assessment-details'>
            <table className="table table-borderless mb-0">
                <tbody className='modal-assessment-details-text'>
                <tr className="">
                        <td className="assessment-details">বিষয়</td>
                        <td className="assessment-details">{topicTypeName(syllabusContent?.assesment_type).name}</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>  
                    <tr className="">
                        <td className="assessment-details">Class Start Time</td>
                        <td className="assessment-details">{formatAMPM(new Date(syllabusContent?.live_class?.live_class_start_time))} &nbsp; {dateConverterEnglishWithOutYear(new Date(syllabusContent?.live_class?.live_class_start_time))}</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>
                    <tr className="">
                        <td className="assessment-details">Class End Time</td>
                        <td className="assessment-details">{formatAMPM(new Date(syllabusContent?.live_class?.live_class_end_time))} &nbsp; {dateConverterEnglishWithOutYear(new Date(syllabusContent?.live_class?.live_class_end_time))}</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>
                    <tr className="">
                        <td className="assessment-details">Class Duration</td>
                        <td className="assessment-details">{convertInHours > 1 ? `${Math.round(convertInHours)} hours` : `${convertInHours*60} minutes`}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default LiveClass;