import React from 'react';

const AnswerImage = ({ images }) => {
    return (
        <div className="row">
            {images.map((url,index) => {
                return (
                    <div key={index} className="col-sm-1">
                        <div className="card">
                            <img src={url} alt='answer-sheet'/>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AnswerImage;