import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const CategoryAuth = ({ children }) => {
    const location = useLocation();

    const { state } = location;
    if (state?.enable === undefined) {
        return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
};

export default CategoryAuth;