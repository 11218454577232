import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HiArrowLeft, HiTrendingUp, HiSearch } from "react-icons/hi";
import { useTranslation } from "react-i18next";

const MobileSearch = ({ handleSearchOpen }) => {
  const [searchValue, setSearchValue] = useState("");
  const [suggestedCourse, setSuggestedCourse] = useState([]);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState("bn");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dropdownRef = useRef(null);
  const targetRef = useRef(null);
  // Language Toggle
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage") || "bn";
    sessionStorage.setItem("useLanguage", useLanguage);
    i18n.changeLanguage(useLanguage);
    setLanguage(useLanguage);
  }, []);

  const toggleLanguage = () => {
    const newLanguage = language === "bn" ? "en" : "bn";
    sessionStorage.setItem("useLanguage", newLanguage);
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  // Handle screen resize to close mobile search on larger screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) setIsInputOpen(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Debounce function
  const debounce = (func, delay) => {
    let debounceTimer;
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  useEffect(() => {
    const handleResize = debounce((entries) => {
      // Handle resize logic here
      console.log("Resized:", entries[0].contentRect);
    }, 200);

    const resizeObserver = new ResizeObserver(handleResize);

    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }

    // Clean up on unmount
    return () => resizeObserver.disconnect();
  }, []);

  const handleSuggestionsClick = (suggestion) => {
    setSearchValue(suggestion);
    handleSearch(suggestion);
  };

  const handleOnChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value.length >= 2) {
      debounceHandleSuggest(value);
      setIsInputOpen(true);
    } else {
      setSuggestedCourse([]);
    }
  };

  const debounceHandleSuggest = debounce((value) => {
    handleSuggest(value);
  }, 300);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchValue);
    }
  };

  const handleSuggest = async (suggestQuery) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/suggest?q=${suggestQuery}`
      );
      const data = await response.json();
      setSuggestedCourse(data?.data?.courses || []);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (searchQuery) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/search?q=${searchQuery}`
      );
      const data = await response.json();
      navigate("/search", { state: { searchResults: data } });
      setIsInputOpen(false);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="tw-bg-[#532D80] tw-mt-2 tw-max-w-full tw-h-[76px]">
      {/* Input Field */}
      <div className="tw-flex tw-items-center tw-w-full">
        <div className="tw-flex tw-justify-between tw-gap-4 tw-items-center tw-w-full tw-mt-3">
          <HiArrowLeft
            className="tw-text-white tw-ml-2"
            size={30}
            onClick={(e) => {
              e.stopPropagation();
              handleSearchOpen(e, false);
              setIsInputOpen(false);
            }}
          />
          <div className="tw-w-full tw-overflow-hidden">
            <label htmlFor="search" className="tw-sr-only">
              Search
            </label>
            <input
              id="search"
              name="search"
              type="search"
              placeholder={t("Search your favourite courses")}
              value={searchValue}
              onChange={handleOnChange}
              onKeyDown={handleKeyPress}
              ref={targetRef}
              onFocus={() => setIsInputOpen(true)}
              className="tw-w-[98%] tw-pl-10 tw-pr-3 tw-bg-white tw-text-gray-900 tw-ring-1 tw-ring-gray-300 tw-py-2 tw-placeholder:text-gray-400 tw-border-0 tw-focus:ring-2 tw-focus:ring-indigo-600 sm:tw-text-sm tw-rounded-3xl"
            />
          </div>
        </div>
      </div>

      {/* Suggestion Dropdown */}
      {isInputOpen && (
        <div
          ref={dropdownRef}
          className="tw-absolute tw-top-[100%] tw-h-svh tw-w-full tw-bg-white tw-rounded-bl-3xl tw-rounded-br-3xl tw-shadow-lg tw-py-2 tw-z-50"
        >
          <div className="tw-px-4">
            {suggestedCourse.length > 0 ? (
              suggestedCourse.map((course, idx) => (
                <div
                  key={idx}
                  onClick={() => handleSuggestionsClick(course.course_name)}
                  className="tw-flex tw-justify-between tw-items-center tw-py-1 tw-text-gray-500 tw-cursor-pointer"
                >
                  <p>{course.course_name}</p>
                  <HiTrendingUp />
                </div>
              ))
            ) : (
              <>
                <p className="tw-py-1 tw-text-gray-800">জনপ্রিয় সার্চ</p>
                {[
                  "ছোটদের কুরআন শিক্ষা",
                  "Abacus Mind Math Buds",
                  "Smart English for Kids",
                  "মজার গণিত ৫",
                ].map((text, idx) => (
                  <div
                    onClick={() => handleSuggestionsClick(text)}
                    key={idx}
                    className="tw-flex tw-justify-between tw-items-center tw-py-1 tw-text-gray-500 tw-cursor-pointer"
                  >
                    <p>{text}</p>
                    <HiTrendingUp />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileSearch;
