import React, { useEffect, useState, useCallback } from "react";
import PageTitle from "../../../shared/PageTitle/PageTitle";
import Blog from "./Blog/Blog";
import './Blogs.css';
import Loader from "../../../shared/Loader/Loader";
import FeaturedBlogs from "./FeaturedBlogs/FeaturedBlogs";
import blogBook from "../../../../../images/blogbook.png";
import Pagination from "./Pagination/Pagination";
import MostPopular from "./MostPopular/MostPopular";
import popular from "../../../../../images/blog/imagepopular.png";
import latest from "../../../../../images/blog/letest.png";
import {useTranslation} from "react-i18next";

const Blogs = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [blogs, setBlogs] = useState({});
    const [featuredBlogs, setFeaturedBlogs] = useState({});
    const [isBlogSearch, setIsBlogSearch] = useState(false);
    const [title, setTitle] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isSearch, setIsSearch] = useState(false);
    const {t} = useTranslation();

    useEffect(()=>{
        async function fetchBlog(){
            let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/v2/blog-feature-popular`);
            data = await data.json();
            setFeaturedBlogs(data.data);
            setIsLoading(false);
        }
        fetchBlog();
    },[]);

    useEffect(()=>{
        async function fetchBlog(){
            let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/v2/blogs`);
            data = await data.json();
            setBlogs(data.data);
            setTotalPages(data.data.blogs.last_page);
            setIsBlogSearch(false);
            setIsSearch(false);
            setIsLoading(false);
        }
        fetchBlog();
    },[]);

    const allCategories = async () => {
        let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/v2/blogs`);
        data = await data.json();
        setBlogs(data.data);
        setTotalPages(data.data.blogs.last_page);
        setIsBlogSearch(false);
        setIsSearch(false)
        setIsLoading(false);
    };

    const fetchSearchBlog = useCallback(async (searchData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v2/blogs?search_data=${searchData}`);
            const result = await response.json();
            setBlogs(result.data);
            setTotalPages(result.data.blogs.last_page);
            setIsSearch(true);
        } catch (error) {
            console.error("Error searching blogs:", error);
        }
    }, []);

    const fetchBlogs = async (page) => {
        try {
            let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/v2/blogs?page=${page}`);
            data = await data.json();
            setBlogs(data.data)
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };

    useEffect(() => {
        fetchBlogs(currentPage);
    }, [currentPage]);


    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    if(isLoading){
        return (<Loader/>);
    }

    const handleSearch = (event) => {
        event.preventDefault();
        fetchSearchBlog(title);
    };

    const handleCategory = (id) => {
        if(id === null || id === "সকল ক্যাটাগরি"){
            allCategories();
        }else {
            setIsBlogSearch(true);
            setIsSearch(true);
            fetchSearchBlog(id);
        }
    }
    const handleChange = (event) => {
        handleCategory(event.target.value);
    }

    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
        handleCategory(categoryId);
    };


    const blog_image_path = blogs?.blog_image_path;


    return (
        <div>
            <PageTitle title="Blogs"></PageTitle>
            <div className="tw-bg-gray-50" >
                <div className="">
                    <div className="background">
                        <div
                            className="container tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
                            <div className="tw-mt-16 tw-text-center lg:tw-text-left">
                                <span className="tw-bg-[#ffce39] tw-px-4 tw-rounded-full tw-font-semibold">{t("Blogs")}</span>
                                <h1 className="tw-mt-4 tw-text-4xl sm:tw-text-5xl tw-text-white tw-font-semibold"> {t("The New Destination for Fun Learning")} </h1>
                                <p className="tw-text-white tw-mt-4 tw-text-base sm:tw-text-lg">{t("blog description")}</p>
                            </div>
                            <div className="tw-hidden md:tw-block lg:tw-mt-0">
                                <img className="" src={blogBook} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" tw-h-1 tw-bg-[#FFCE39] tw-w-full"></div>
                <div className="container md:tw-mt-16">
                    <div className="tw-w-full md:tw-flex md:tw-justify-between">
                        <div className="md:tw-hidden tw-mt-4 tw-mb-4">
                            <select
                                id="category"
                                name="category"
                                className="tw-appearance-none tw-text-center tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                defaultValue=""
                                onChange={handleChange}
                            >
                                <option>সকল ক্যাটাগরি</option>
                                {
                                    blogs?.blog_categories?.map(blog_category => <option
                                        key={blog_category?.blog_category_id} name='search_data'
                                        value={blog_category?.blog_category_id}>{blog_category?.blog_category}</option>)
                                }
                            </select>
                        </div>

                        <div className="tw-hidden md:tw-block tw-w-[35%]">
                            <div className="tw-w-full">
                                <form onSubmit={handleSearch}>
                                    <label
                                        htmlFor="default-search"
                                        className="tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-sr-only dark:tw-text-gray-300"
                                    >
                                        ব্লগ সার্চ করুন
                                    </label>
                                    <div className="tw-relative">
                                        <div
                                            className="tw-flex tw-absolute tw-inset-y-0 tw-left-0 tw-items-center tw-pl-3 tw-pointer-events-none">
                                            <svg
                                                className="tw-w-5 tw-h-5 tw-text-gray-500 dark:tw-text-gray-400"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                ></path>
                                            </svg>
                                        </div>
                                        <input
                                            type="search"
                                            id="default-search"
                                            className="tw-block tw-p-3 tw-pl-10 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg tw-border tw-border-gray-300 focus:tw-ring-blue-500 focus:tw-border-blue-500 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500"
                                            placeholder="ব্লগ সার্চ করুন "
                                            required
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>
                                </form>

                                <div className="tw-mt-6 tw-p-4">
                                    <h3>ক্যাটাগরি</h3>
                                    <ul className="tw-mt-4">
                                        <li
                                            className={`tw-rounded-lg tw-px-4 tw-py-2 tw-cursor-pointer ${
                                                selectedCategory === null ? 'tw-bg-[#532D80] tw-text-white' : 'tw-text-gray-400'
                                            }`}
                                            onClick={() => handleCategoryClick(null)}
                                        >
                                            সকল ক্যাটাগরি
                                        </li>
                                        {blogs?.blog_categories?.map((blog_category) => (
                                            <li
                                                className={`tw-rounded-lg tw-px-4 tw-py-2 tw-cursor-pointer ${
                                                    selectedCategory === blog_category?.blog_category_id
                                                        ? 'tw-bg-[#532D80] tw-text-white'
                                                        : 'tw-text-gray-400'
                                                }`}
                                                key={blog_category?.blog_category_id}
                                                onClick={() => handleCategoryClick(blog_category?.blog_category_id)}
                                            >
                                                {blog_category?.blog_category}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="tw-w-full md:tw-w-[60%]">
                            <FeaturedBlogs blogs={featuredBlogs?.featured_blogs}
                                           blog_image_path={featuredBlogs?.blog_image_path}/>
                        </div>
                    </div>
                    <div className="row mt-lg-4 mt-2 gy-lg-0 gy-3">
                        <div className="col-lg-5 featured-blog">

                        </div>
                        <div className="col-lg-7 tw-mt-16 tw-p-4 md:tw-p-0 md:-tw-mt-20">
                            <div className="row gy-3">
                                {
                                    isBlogSearch ? (<> </>) : (
                                        <div className="tw-flex tw-items-center">
                                            <img className="tw-mr-2" src={popular} alt="logo"/>
                                            <h3>সবথেকে জনপ্রিয়</h3>
                                        </div>
                                    )
                                }
                                {!isBlogSearch && featuredBlogs && featuredBlogs?.popular_blogs?.map(blog => (
                                    <div key={blog.id} className="col-lg-6 col-md-6 tw-mb-4">
                                        <MostPopular blog={blog}/>
                                    </div>
                                ))}
                                <>
                                    <div className="tw-flex tw-items-center">
                                        <img className="tw-mr-2" src={latest} alt="logo"/>
                                        {
                                            !blogs?.blog_category && !blogs?.search_data && <h3>লেটেস্ট ব্লগ</h3>
                                        }
                                        {
                                            blogs?.blog_category ? <h3>{blogs?.blog_category}</h3> :
                                                <h3>{blogs?.search_data}</h3>
                                        }
                                    </div>
                                    {blogs?.blogs?.data?.map(blog => (
                                        <div key={blog.id} className="col-lg-6 col-md-6 tw-mb-4">
                                            <Blog blog={blog} blog_image_path={blog_image_path}/>
                                        </div>
                                    ))}
                                </>

                            </div>

                            <div className="tw-mb-24">
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blogs;
