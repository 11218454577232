import React from "react";
import "./StudentResults.css";
/* import courseImg from "../../../../../images/app_course_image.jpeg"; */
import offcanvasResult from "../../../../../images/offcanvasResult.png";
import ResultDetails from "./ResultDetails/ResultDetails";
import { useState, useEffect } from "react";
import { englishToBengaliNumberFormat } from "../../../../../utilities/utilities";
import defaultImage from "../../../../../images/default_course_image.jpg";

const StudentResults = ({ studentName, profilePath, studentProfile }) => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    fetch(
      `${
        process.env.REACT_APP_BASE_URL_STUDENT
      }result-summary?&user_id=${localStorage.getItem("user_id")}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setResults(data.data));
  }, []);
  return (
    <>
      <div className="p-4 d-flex justify-content-between align-items-center">
        <p className="mb-0" style={{ fontSize: "20px", fontWeight: "500" }}>
          My Results
        </p>
      </div>

      <div className="total-assignment-divider"></div>
      <div className="mt-3 px-4 pb-3">
        <div className="row g-3 mx-0">
          {results?.result_summary?.length <= 0 && (
            <p
              className="text-center"
              style={{ fontWeight: "400", fontSize: "22px" }}
            >
              কোর্স এখনও ক্রয় করা হয়নি
            </p>
          )}
          {results?.result_summary?.map((result, index) =>
            result?.course_card_hide === 0 ? (
              <div key={index} className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div
                  className="p-3 custom-card-shadow"
                  style={{ borderRadius: "16px" }}
                >
                  <div className="row mx-0">
                    <div className="col-lg-4 ps-0">
                      {result?.course_image !== undefined &&
                      result?.course_image !== null ? (
                        <img
                          src={`${results?.course_image_path}${result?.course_image}`}
                          alt=""
                          style={{ borderRadius: "8px" }}
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src={`${defaultImage}`}
                          alt=""
                          style={{ borderRadius: "8px" }}
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="col-lg-8 pe-0">
                      <div className="d-flex justify-content-between align-items-center h-100">
                        <div>
                          <p className="result-course-name">
                            {result?.course_name}
                          </p>
                          <div className="d-flex">
                            <div>
                              <p className="mb-1 result-summary">টোটাল মার্ক</p>
                              <p className="mb-0 total-mark-percentage balooda-medium">
                                {englishToBengaliNumberFormat(
                                  result?.total_attempts_percentage
                                )}
                                %
                              </p>
                            </div>
                            <div className="ms-4">
                              <p className="mb-1 result-summary">
                                এক্সাম কমপ্লিট
                              </p>
                              <p className="mb-0 total-attend-assignment balooda-medium">
                                <span className="balooda-medium">
                                  {result?.exam_complete < 10 && "০"}
                                  {englishToBengaliNumberFormat(
                                    result?.exam_complete
                                  )}
                                </span>
                                /
                                <span className="balooda-medium">
                                  {result?.total_exams < 10 && "০"}
                                  {englishToBengaliNumberFormat(
                                    result?.total_exams
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div>
                          <img
                            data-bs-toggle="offcanvas"
                            data-bs-target={`#offcanvasStudentResult_${index}`}
                            aria-controls={`offcanvasStudentResult_${index}`}
                            src={offcanvasResult}
                            alt=""
                            className="img-fluid"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <ResultDetails
                          studentName={studentName}
                          index={index}
                          profilePath={profilePath}
                          studentProfile={studentProfile}
                          resultPercentage={result?.percentage}
                          examComplete={result?.exam_complete}
                          totalExam={result?.total_exams}
                          exams={result?.exams}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          )}

          {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className='p-3 custom-card-shadow' style={{ borderRadius: '16px' }}>
                            <div className="row mx-0">
                                <div className="col-lg-4 ps-0">
                                    <img src={courseImg} alt="" style={{ borderRadius: '8px' }} className='img-fluid'/>
                                </div>
                                <div className="col-lg-8 pe-0">
                                    <div className='d-flex justify-content-between align-items-center h-100'>
                                        <div>
                                            <p className='result-course-name'>ম্যাথ চ্যাম্পস প্রাইমারি  ফর প্রাইমারি কিডস</p>
                                            <div className='d-flex'>
                                                <div>
                                                    <p className='mb-1 result-summary'>টোটাল মার্ক</p>
                                                    <p className='mb-0 total-mark-percentage'>৮০%</p>
                                                </div>
                                                <div className='ms-4'>
                                                    <p className='mb-1 result-summary'>এক্সাম কমপ্লিট</p>
                                                    <p className='mb-0 total-attend-assignment'><span>০৮</span>/<span>০৯</span></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <img data-bs-toggle="offcanvas" data-bs-target="#offcanvasStudentResult" aria-controls="offcanvasStudentResult" src={offcanvasResult} alt="" className='img-fluid' style={{ cursor: 'pointer' }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
      {/* <ResultDetails/> */}
    </>
  );
};

export default StudentResults;
