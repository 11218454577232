import React, {useEffect, useState} from 'react';
import PageTitle from "../../shared/PageTitle/PageTitle";
import newsLogo from "../../../../images/News/news.png";
import Card from "./Card/Card";
import Pagination from "../../pages/FooterPages/Blogs/Pagination/Pagination";
import Loader from "../../shared/Loader/Loader";
import {useTranslation} from "react-i18next";

const AllNews = () => {
    const [news, setNews] = useState({});
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const {t} = useTranslation();

    useEffect(()=>{
        async function fetchNews(){
            let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/v2/news`);
            data = await data.json();
            setNews(data.data?.news);
            setTotalPage(data.data?.news.last_page);
            setIsLoading(false);
        }
        fetchNews();
    },[]);


    const fetchNews = async (page) => {
        try {
            let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/v2/news?page=${page}`);
            data = await data.json();
            setNews(data.data?.news)
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };

    useEffect(() => {
        fetchNews(currentPage);
    }, [currentPage]);


    const handlePageClick = (page) => {
        setCurrentPage(page);
    };


    if(isLoading){
        return (<Loader/>);
    }


    return (
        <div className="">
            <PageTitle title="News"></PageTitle>
            <div>
                <div className="">
                    <div className="background">
                        <div
                            className="md:tw-w-[1440px] md:tw-mx-auto tw-px-2 tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center md:tw-px-[127px]">
                            <div className="tw-mt-16 tw-text-center lg:tw-text-left">
                                <span className="tw-bg-[#ffce39] tw-px-4 tw-rounded-full tw-font-semibold">{t("All News")}</span>
                                <h1 className="tw-mt-4 tw-text-4xl sm:tw-text-5xl tw-text-white tw-font-semibold">{t("Mojaru in the Media")}</h1>
                            </div>
                            <div className="tw-hidden md:tw-block lg:tw-mt-0">
                                <img className="" src={newsLogo} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:tw-max-w-[1440px] tw-mx-auto tw-mt-4 md:tw-mt-16 md:tw-px-[127px]">
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 md:tw-gap-16">
                    {
                        news?.data?.map((item) => (
                            <div>
                                <Card news={item}/>
                            </div>
                        ))
                    }
                </div>
                <div className="tw-mt-8 md:tw-mt-16 md:tw-w-8/12 tw-mx-auto">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPage}
                        onPageChange={handlePageClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default AllNews;