import React from 'react';
import './Review.css';
import dummyImage from '../../../../../../../images/Default Person Avatar.png';
/* import { dateConverter, formatAMPM } from "../../../../Helper/Helper"; */
/* import ShowRating from "../../../../../../Rating/ShowRating"; */
import { dateConverter,formatAMPM } from '../../../../../../../utilities/utilities';
import ShowRating from '../../../../../../user/shared/Rating/ShowRating';

const Review = ({ review, path }) => {
    return (
        <div className="review-section">
            <div className="row">
                <div className="col-lg-1 col-md-1 d-flex justify-content-center">
                    <div className="image">
                        {review.profile_picture !== undefined &&
                        review.profile_picture !== null ? (
                            <img
                                src={`${path}${review.profile_picture}`}
                                alt=""
                                className="img-fluid"
                            />
                        ) : (
                            <img
                                src={dummyImage}
                                className="book_border img-fluid"
                                alt=""
                                id="course-img"
                            />
                        )}
                    </div>
                </div>
                <div className="col-lg-11 col-md-11">
                    <div className="">
                        <div className="reviewer_name">{review.user_name}</div>
                        <div className="review_date">
                            {dateConverter(review.created_at)} &nbsp;{" "}
                            {formatAMPM(new Date(review.created_at))}
                        </div>
                        <div className="review">{review.review_detail}</div>
                    </div>

                    <div className="d-flex align-items-center">
                        <p
                            className=" mb-0 mt-1 review me-2"
                            style={{ color: "#7C7C7C" }}
                        >
                            রিভিউ:{" "}
                        </p>
                        <ShowRating rating={review.ratings} />
                        <p
                            className="mb-0 progress-bar-title ms-2"
                            style={{ color: "#7C7C7C" }}
                        >
                            ({review.ratings})
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Review;